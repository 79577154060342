import {
  acceptEventName,
  ActivityName,
  CalendarName,
  CompanyName,
  CorpsEditDate,
  CorpsName,
  CountryName,
  DocumentsName,
  FunctionName,
  IndustryName,
  InfoContactsName,
  KreiseName,
  MessagesName,
  passwordDigits,
  PostsName,
  ProfileName,
  SignUpCorpsName,
  StudyName,
} from './specificNames';

const lang = ({
  guest_naming_singular,
  guest_naming_plural,
  member_naming_singular,
  support_email,
}: {
  guest_naming_singular?: string;
  guest_naming_plural?: string;
  member_naming_singular?: string;
  support_email?: string;
} = {}) => [
  {
    key: 'GER',
    key2: 'de',
    responseHandler: {
      messageLabel: `Unbekannter Fehler. Bitte wenden Sie sich an ${support_email}`,
      expiredLinkLabel: 'Link abgelaufen. Fordern Sie eine neue Passwortänderung an.',
      emailExistLabel: 'Entschuldigung, diese E-Mail-Adresse ist bereits registriert.',
      profileUpdated: 'Profil aktualisiert',
      profileUpdatedCorpsError: `Profil aktualisiert! Das folgende ${
        CorpsName('GER').single
      } konnte jedoch aufgrund eines falschen Login-Codes nicht hinzugefügt werden:`,
      appNeedsUpdate: 'Bitte updaten Sie die App im App Store / Google Play.',
      maximalVideoSize: 'Die maximale Dateigröße beträgt 2 GB!',
    },
    report: {
      notBelongLabel: 'Der Benutzer ist kein Mitglied mehr.',
      incProfileLabel: 'Der Benutzer macht falsche Profilangeboten',
      badPicLabel: 'Das Profilbild des Nutzers verstößt gegen die Richtlinien',
      badComLabel: 'Die Äußerungen des Nutzers verstoßen gegen die Richtlilien',
      spamLabel: 'Der Benutzer sendet Spam im Chat',
      reasonsLabel: 'Gründe für die Meldung',
    },
    drawer: {
      news: `Neuigkeiten`,
      kreise: KreiseName('GER').multi,
      search: 'Suche',
      calendar: CalendarName('GER'),
      documents: `${DocumentsName('GER')}`,
      contacts: 'Kontakte',
      requests: 'Kontaktanfragen',
      messages: MessagesName('GER'),
      profile: ProfileName('GER'),
      logout: 'Ausloggen',
      map: 'Karte',
      mentoring: 'Mentoring',
    },
    imageCropper: {
      cropLabel: 'Zuschneiden',
      cancelLabel: 'Abbrechen',
      chooseLabel: 'Fertig',
    },
    signIn: {
      register: 'Registrieren',
      title: 'Bitte einloggen',
      loginPlaceholder: 'E-Mail-Adresse',
      passwordPlaceholder: 'Passwort',
      forgotPassword: 'Passwort vergessen?',
      loginBtn: 'Login',
      noAccount: 'Noch keinen Account?',
      description0: 'Bei Fragen können Sie sich gerne an',
      description1: 'oder',
      description2: 'wenden.',
      loginValidation: 'Die Angabe Ihrer E-Mail-Adresse ist nicht korrekt',
      passwordValidation: 'Das Passwort muss mindestens 6 Zeichen lang sein',
    },
    search: {
      name: 'Name',
      corps: CorpsName('GER').single,
      country: CountryName('GER'),
      city: 'Stadt',
      industry: IndustryName('GER'),
      company: 'Firma',
      profession: 'Beruf',
      hobby: 'Interessen',
      cultural: 'Kulturpaten',
      study: `${StudyName('GER').short}`,
      office: 'Niederlassung',
      degreeProgram: StudyName('GER').studiengang,
      university: StudyName('GER').university,
      position: `${FunctionName('GER')}`,
      status: 'Status',
      title: 'Suchergebnis für',
      filterTitle: 'Filter nach',
      postCode: 'Postleitzahl',
      global: 'Alles',
      activity: ActivityName('GER'),
    },
    resetPassword: {
      enterEmail: 'Bitte E-Mail-Adresse eingeben',
      inputPlaceholder: 'E-Mail-Adresse',
      sendBtn: 'Senden',
      cancelBtn: 'Abbrechen',
      message: 'Falls uns die E-Mail-Adresse bekannt ist, wurde dorthin ein Link geschickt.',
      desc: 'Du musst exakt die Mailadresse eingeben, welche in der App hinterlegt ist. Schaue ggf. in deinen Mails nach, welche das ist.',
    },
    password: {
      resetLabel: 'Geben Sie ein neues Passwort ein',
      inputLabel: 'Neues Passwort',
    },
    signUp: {
      createAccount: 'Account anlegen',
      inputPlaceholder: `Login Code Ihres ${CorpsName('GER').single}s`,
      sendBtn: 'Weiter',
      descFirst: 'Mit der Registrierung erklären Sie sich mit unseren ',
      descSecond: 'und bestätigen, dass Sie unsere gelesen haben',
      descThird: `Sollten Sie Fragen haben, können Sie uns gerne unter ${support_email} oder +49 170 49 805 kontaktieren.`,
      terms: 'terms and conditions',
      policy: 'privacy policy.',
      description: 'Hinweis',
      generatePassword: 'Passwort generieren',
      noCodeSuccessRegister:
        'Vielen Dank für Ihre Registrierung! Sie werden per Mail informiert, sobald Sie freigeschaltet wurden.',
    },
    signUpDefault: {
      withoutCode: 'Ohne Einladungscode',
      createAccount: 'Hier einfach registrieren',
      simpleRegister: 'Ohne Einladungscode',
      existingAccount: 'Account bereits vorhanden?',
      descr01: 'Ich stimme den',
      descr0: 'Mit der Registrierung stimmen Sie den',
      descr1: 'und der',
      descr2: 'zu.',
      guest: `Registrieren als ${guest_naming_singular}`,
      withCodeWithDescr: `Registrieren als ${member_naming_singular}`,
      withCode: 'Mit Einladungscode',
    },
    signUpManual: {
      title: 'Registrieren',
      photoDesc: 'Foto hinzufügen',
      genderInput: 'Geschlecht',
      firstName: 'Vorname',
      lastName: 'Nachname',
      club: CorpsName('GER').single,
      specify: 'bitte angeben',
      emailPlaceholder: 'E-Mail-Adresse',
      emailHint: 'Bitte langfristig genutzte Mailadresse verwenden.',
      repeatEmailPlaceholder: 'E-Mail-Adresse wiederholen',
      passwordPlaceholder: 'Passwort festlegen',
      repeatPasswordPlaceholder: 'Passwort wiederholen',
      postcode: 'Postleitzahl',
      place: 'Wohnort',
      country: 'Land',
      loginCode: 'Einladungscode',
      mobilePlaceholder: 'Mobil-Nr.',
      corps: SignUpCorpsName('GER'),
      searchCountry: 'Suche nach Land',
    },
    backToLogin: {
      desc: 'Account bereits vorhanden? ',
      login: 'Login',
    },
    backToSignUp: {
      desc: 'Noch keinen Account? ',
      register: 'Registrieren',
    },
    privacy: {
      desc: 'Ja, ich stimme den',
      btn: 'AGB',
      zu: 'zu',
      map: {
        title: 'Datenschutzhinweis',
        description:
          'Diese App wurde DSGVO-konform implementiert. Wir nutzen Google Maps nur zur Darstellung der Karte. Es werden keine personenbezogenen Daten übermittelt.\n\nZudem werden die Pins nur grob dargestellt, auf Basis der PLZ/Stadt aber ohne Straße/Nr. Damit ist ein Rückschluss auf die konkreten Adressen der Nutzer nicht möglich.',
      },
      restrictions: {
        overall: 'Diese Funktion ist nur für Mitglieder zugänglich.',
        overallWilkar: 'Diese Funktion ist aus Datenschutzgründen nicht zugänglich.',
        profile:
          'Nur Mitglieder können Nutzerprofile ansehen. Du kannst dem Nutzer aber eine private Nachricht schreiben.',
        groupchat: `Die ${KreiseName('GER').single} sind nur für Mitglieder zugänglich.`,
        groupchatWilkar: `Aus Datenschutzgründen die ${KreiseName('GER').single} sind nur für Mitglieder zugänglich.`,
        comments: 'Kommentare sind nur für Mitglieder einsehbar.',
        commentsWilkar: 'Aus Datenschutzgründen kommentare sind nur für Mitglieder einsehbar.',
        likes: 'Die Liste der Likes sind nur für Mitglieder einsehbar.',
        post: 'Nur Mitglieder können Beiträge verfassen.',
        participantsList: 'Die Teilnehmerliste ist nur für Mitglieder einsehbar.',
        commentsOnlyForMembers: 'Kommentare sind nur für Mitglieder einsehbar.',
        postsLimitation: 'Ältere Beiträge sind nur für Mitglieder einsehbar.',
      },
      settings: {
        guestPost: {
          title: `Verifizierte ${guest_naming_plural} können meine Beiträge sehen`,
          ask: 'Immer fragen',
          no: 'Nein',
          yes: 'Ja',
        },
      },
      guestModal: {
        title: `Dürfen validierte ${guest_naming_plural} diesen Beitrag sehen? Du kannst diese Sichtbarkeits-Einstellung für jeden einzelnen Beitrag im Nachhinein noch ändern.`,
        dontAskAgain:
          'Nicht erneut fragen (Du kannst diese Einstellung unter “Profil” → “Privatsphäre” auch wieder ändern).',
      },
      post: {
        ifShown: `Für ${guest_naming_plural} verbergen`,
        ifHidden: `Für ${guest_naming_plural} anzeigen`,
        ifShownModal: `Der Beitrag ist jetzt für ${guest_naming_plural} nicht mehr sichtbar.`,
        ifHiddenModal: `Der Beitrag ist jetzt für ${guest_naming_plural} sichtbar.`,
      },
    },
    searchModal: {
      landTitle: 'Wähle ein Land',
      corpsTitle: `${CorpsName('GER').single} Auswählen`,
      pleaseSelect: 'Bitte wählen',
    },
    btn: {
      sendBtn: 'Senden',
      cancelBtn: 'Abbrechen',
      select: 'Auswählen',
      cancelEventBtn: acceptEventName('GER').cancel,
      acceptBtn: acceptEventName('GER').accept,
      saveBtn: 'Speichern',
      clearBtn: 'Löschen',
      acceptReqBtn: 'Annehmen',
      declineReqBtn: 'Abbrechen',
      updateBtn: 'Aktualisieren',
      declineBtn: 'Ablehnen',
      yesUnderstood: 'Ja, verstanden',
      ok: 'OK',
      cancel2: 'Absagen',
    },
    validation: {
      email: 'Die Angabe Ihrer E-Mail-Adresse ist nicht korrekt',
      password: 'Das Passwort muss mindestens 6 Zeichen lang sein',
      repeatEmail: 'E-Mail und E-Mail-Wiederholung stimmen nicht überein.',
      repeatPassword: 'Passwort und -wiederholung stimmen nicht überein',
      required: 'Dieses Feld wird benötigt',
      matchPasswords: 'Altes und neues Passwort stimmen überein',
      dontMatchPasswords: 'Passwort und -wiederholung stimmen nicht überein.',
    },
    init: {
      title: 'Wird geladen',
      overlayLabel: 'Bitte Internetverbindung prüfen',
    },
    screenTitles: {
      postsTitle: `Neuigkeiten`,
      circlesTitle: KreiseName('GER').multi,
      searchTitle: 'Suche',
      profileTitle: ProfileName('GER'),
      eventTitle: CalendarName('GER'),
      eventDetailsTitle: 'Veranstaltungen',
      newEventTitle: 'Veranstaltung hinzufügen',
      contactsTitle: 'Kontakte',
      contactReqTitle: 'Kontaktanfragen',
      reqDetailsTitle: 'Kontaktanfragen',
      outReqTitle: 'Kontaktanfragen',
      messagesTitle: MessagesName('GER'),
      changePasswordTitle: 'Passwort ändern',
      appInfoTitle: 'Über',
      reportTitle: 'Benutzer melden',
      createKreiseTitle: 'Gruppenchat erstellen',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      user: 'Nutzer',
      mainTitleLabel: ProfileName('GER'),
      basisLabel: 'Basis',
      clubLabel: CorpsName('GER').single,
      clubLabelPlural: CorpsName('GER').multi,
      professionLabel: 'Beruf',
      studyLabel: StudyName('GER').short,
      uploadPhotoLabel: 'Foto aufnehmen',
      editPhotoLabel: 'Foto editieren',
      profileCompletedLabel: 'Profil',
      profileCompletedGLabel: 'vollständig',
      birthday: 'Geburtsdatum',
      functionLabel: `${FunctionName('GER')}`,
      dateOfJoinLabel: CorpsEditDate('GER'),
      positionDescLabel: 'Beruf',
      companyLabel: CompanyName('GER'),
      nameLabel: 'Name',
      websiteLabel: 'Webseite',
      fromLabel: 'Von',
      toLabel: 'Bis',
      delete: 'löschen',
      untilToday: 'Bis heute',
      degreeLabel: StudyName('ENG').abshchluss,
      miscellaneos: 'Sonstiges',
      hobbies: 'Interessen',
      cultural: 'Kulturpaten',
      positionLabel: 'Beruf',
      firmaLabel: 'Firma',
      industryLabel: IndustryName('GER'),
      areaLabel: 'Bereich',
      typeOfEmplLabel: 'Beschäftigungsart',
      activityLabel: ActivityName('GER'),
      careerStageLabel: 'Karrierestufe',
      activityDescLabel: 'Beschreibung der Tätigkeit',
      employmentPeriodLabel: 'Zeitraum',
      universityLabel: StudyName('GER').hochsule,
      courseLabel: StudyName('GER').studiengang,
      ageLabel: 'Alter',
      birthdayLabel: 'Geburtstag',
      editProfileLabel: 'Profil editieren',
      addContactLabel: 'Als Kontakt hinzufügen',
      sendMessageLabel: 'Nachricht senden',
      requestSentLabel: 'Kontaktanfrage bereits gesendet',
      sendRequestSuccessLabel: 'Anfrage erfolgreich gesendet',
      reportSentLabel: 'Benutzer bereits gemeldet',
      addReportLabel: 'Benutzer melden/blockieren',
      contactsLabel: 'Anzahl Kontakte',
      showBirthday: 'Kontakte sehen meinen Geburtstag',
      street: 'Straße + Nr. ',
      partner: 'Name Partner/in',
      mobileNumberLabel: 'Mobil-Nr.',
      handphoneNumberLabel: 'Handynummer',
      telephoneNumberLabel: 'Telefonnummer',
      leibbursch: 'Leibbursch',
      leibfuechse: 'Leibfüchse',
      bio: 'Über mich',
      addDocuments: 'Dokumente zu dir hinzufügen',
      image: 'Bild',
      video: 'Video',
      pdf: 'PDF',
      link: 'Link',
      guest: 'Gast',
      firstName: 'Vorname',
      lastName: 'Nachname',
      title: 'Titel',
      email: 'E-Mail',
      name: 'Vorname',
      lastname: 'Nachname',
      country: CountryName('GER'),
      wohnort: 'Stadt',
      postcode: 'PLZ',
      inReview: 'Bestätigung via Mail ausstehend',
    },
    calendar: {
      birthdayLabel: 'Geburtstag von',
      noEventsLabel: 'Für das ausgewählte Datum gibt es keine Ereignisse',
      allTabLabel: 'Alle',
      myTabLabel: 'Meine',
      clubsTabLabel: CorpsName('GER').single,
      subsTabLabel: 'Abonnements',
      subscribedTabLabel: 'Abonnierte',
      otherTabLabel: 'Andere',

      error: 'Startdatum/-zeit muss vor Enddatum/-zeit liegen.',
      filter: {
        all: 'Alle',
        invited: 'Eingeladen',
        confirmed: 'Zugesagt',
      },
    },
    eventLabels: {
      inviteTo: 'Einladung an',
      addEndDate: 'Enddatum/-zeit eingeben',
      eventLabel: 'Veranstalter',
      startLabel: 'Beginnt um',
      locationLabel: 'Ort',
      participantLabel: 'Teilnehmer',
      moreInfo: 'Weitere Informationen',
      commentsLabel: 'Kommentare',
      noCommentsLabel: 'Noch keine Kommentare',
      eventStartLabel: 'Beginn',
      eventEndLabel: 'Ende',
      descLabel: 'Beschreibung',
      creatorLabel: 'Erstellt von',
      titleLabel: 'Titel',
      deleteEventTitle: 'Veranstaltung löschen?',
      deleteEventLabel: 'Diese Veranstaltung löschen?',
      clubsTitle: `${CorpsName('GER').single}s`,
      gruppenchat: 'Gruppenchat(s) wählen',
      contactsTitle: 'Kontakte',
      delCommentLabel: 'Kommentar löschen?',
      sendInvite: 'Einladung senden an',
      selectCorps: 'Zielgruppe auswählen',
      externalGuest: 'Eingeladene externe Gäste',
      externalGuestPlaceholder: 'Mailadresse eingeben',
      attachmentLabel: 'Anhang',
      shareLinkLabel: 'Link teilen',
      linkLabel: `${guest_naming_singular} eintragen`,
      copyLinkLabel: 'Link kopieren',
      iCalDownload: 'Kalenderdatei',
      timezone: 'Zeitzone',
      visibility: 'Sichtbarkeit',
      errorDate: 'Datum liegt in der Vergangenheit',
      startAfterEndError: 'The end date must be after the start date',
      members: 'Teilnehmer',
      waitlist: 'Warteliste',
      onWaitlist: 'auf der Warteliste',
      leaveWaitlist: 'Platz freigeben?',
      leaveWaitlistDesc: `Von Warteliste entfernen?\n\n Wenn du dich später wieder auf die Warteliste setzt, landest du ggf. auf einer hinteren Position.`,
      limitAchieved: (limit: number) =>
        `Die maximale Teilnehmeranzahl von ${limit} ist erreicht. Du wurdest auf die Warteliste gesetzt.`,
      visibilityOptions: {
        allWithoutGuests: 'Mitglieder und auf Webseite',
        invited_member: 'Nur Mitglieder',
        verified_member: 'Alle Mitglieder',
        authorized_member: 'Mitglieder und verifizierte Gäste',
        all: 'Mitglieder, Gäste und auf Webseite',
      },
      modalEvent: {
        title: 'Veranstaltung erstellt',
        subTitle: 'Die Veranstaltung wurde erstellt.\n\n',
        content:
          'Je nach Nutzereinstellungen werden nun Push-/Mailbenachrichtigungen zur Veranstaltung versendet. \n\nFalls Sie das unter “Profil” → “Benachrichtigungen” eingestellt haben, werden Sie auch selbst eine Mailbenachrichtigung zur Veranstaltung erhalten.',
      },
      modalComment: {
        title: 'Wen benachrichtigen?',
        message: 'Wer soll über deinen Kommentar informiert werden?',
        optionAll: 'Alle Teilnehmer',
        optionNone: 'Nur Organisator(en)',
        button: 'Kommentar senden',
      },
      cancelModal: {
        title: 'Absagen oder löschen?',
        message: 'Veranstaltung absagen oder löschen?',
        optionAll: 'Absagen (Teilnehmer werden informiert)',
        optionNone: 'Löschen (Teilnehmer werden nicht informiert)',
      },
      updateEventModal: {
        title: 'Weitere Einladungen verschickt',
        label: 'Die weiteren Gruppen wurden eingeladen.',
        message:
          'Je nach Einstellungen werden jetzt Push-oder Mailbenachrichtigungen zur Veranstaltung an die weiteren Gruppen versendet.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/Mailadresse',
        enterLink: 'Link/Mailadresse o.ä. hinzufügen',
      },
      parts: {
        part: 'Programmpunkt',
        addPart: 'Programmpunkt hinzufügen',
        hint: 'Explanation',
        question: 'Frage',
        single: 'Nur eine Option wählbar',
        multi: 'Mehrere Optionen wählbar',
        freeText: 'Freitextantwort möglich',
        quiz: 'Quiz',
        questionnaire: 'Optionsabfrage',
        addQuestionnaire: 'Optionsabfrage hinzufügen',
        questionnaireType: 'Antwortmöglichkeiten',
        option: 'Option',
        addOption: 'Option hinzufügen',
        showMembers: 'Teilnehmer',
        youJoined: 'Du nimmst teil an:',
        totalToPay: 'Zu zahlende Gesamtsumme:',
        yourAnswer: 'Deine Antwort',
        visibleForCreators: 'Sichtbar nur für Event-Admins',
        allAnswers: 'Alle Antworten:',
        addUsers: 'Teilnehmer hinzufügen',
        isAnonym: 'Anonyme Umfrage',
        isAnonymQuiz: 'Anonymes Quiz',
      },
    },
    rrEventLabels: {
      title: 'Wiederholung',
      MO: 'Montag',
      TU: 'Dienstag',
      WE: 'Mittwoch',
      TH: 'Donnerstag',
      FR: 'Freitag',
      SA: 'Samstag',
      SU: 'Sonntag',
      YEARLY: { single: 'Jahr', regular: 'Jährlich' },
      MONTHLY: { single: 'Monat', regular: 'Monatlich' },
      WEEKLY: { single: 'Woche', regular: 'Wöchentlich' },
      DAILY: { single: 'Tag', regular: 'Täglich' },
      SINGLE: { single: 'Einmalig', regular: 'Einmalig' },
      CUSTOM: {
        single: 'Benutzerdefiniert...',
        regular: 'Benutzerdefiniert...',
      },
      freq: 'Frequenz',
      interval: 'Intervall',
      notRepeat: 'Einmalig',
      every: 'Jeden',
      month: 'monat',
      week: 'woche',
      last: 'letzten',
      on: 'am',
      everyMonthOn: 'Jeden Monat am',
      ofTheMonth: 'des Monats',
      editLabel: 'Welche Termine willst du ändern?',
      thisDate: 'Nur diesen Termin',
      thisDateAndFollowing: 'Diesen und alle folgenden',
      allDates: 'Alle Termine',
      after: 'Nach',
      time: 'Terminen',
      times: 'Terminen',
      neverEnds: 'Endet nie',
      until: 'bis zum',
      repeatedDefault: ({ frequency, freq }) =>
        `Wird jede${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} wiederholt`,
      repeatedMonthly: ({ day, interval }) =>
        `Wird jeden${interval == 1 ? '' : ` ${interval}.`} Monat am ${day.length > 2 ? day : `${day}.`} wiederholt.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }) =>
        `Wird jeden${bysetpos == -1 ? ' letzten' : ` ${bysetpos}.`} ${day} des${
          interval === 1 ? '' : ` ${interval}.`
        } Monats wiederholt.`,

      repeatedYearly: ({ interval }) => `Wird jedes${interval == 1 ? '' : ` ${interval}.`} Jahr wiederholt.`,
      repeatedWeekly: ({ interval, byweekday }) =>
        `Wird jede${interval != 1 ? ` ${interval}.` : ''} Woche${
          !!byweekday?.length
            ? ' am ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' und ' : '') +
              byweekday?.slice(-1)
            : ''
        } wiederholt.`,
      repeatedDaily: ({ interval }) => `Wird jeden${interval != 1 ? ` ${interval}.` : ''} Tag wiederholt.`,
    },
    requestsLabels: {
      receiveLabel: 'Empfangen',
      sentLabel: 'Gesendet',
    },
    post: {
      adTitle: 'Information/Anzeige',
      toKreise: 'an',
      comment: 'Kommentare',
      myPost: 'Ich',
      openPdf: 'Klicken zum Öffnen',
      deletePost: 'Diesen Beitrag löschen?',
      posted: 'Gepostet',
      commented: 'Kommentiert',
      contacts: 'Kontakte',
      sendToContacts: 'Nachricht an alle',
      enterText: 'Schreibe hier eine Nachricht',
      sendToCircle: `Nachricht an ${KreiseName('GER').singleArticle}`,
      sendComment: 'Kommentar eingeben',
      longMessage: 'Maximal 20.000 Zeichen',
      noComments: 'Noch keine Kommentare',
      deleteComment: 'Kommentar löschen?',
      deleteEventPost: 'Diesen Beitrag löschen? Die Veranstaltung wird dadurch nicht gelöscht.',
      editPostLabel: 'Beitrag bearbeiten',
      editingPostLabel: 'Bearbeitungsmodus',
      deletePostLabel: 'Beitrag löschen',
      editedAt: 'Bearbeitet',
      pinned: 'Angepinnt.',
      actions: {
        label: 'Aktionen posten',
        pin: {
          label: 'Beitrag anpinnen',
          once: 'Einmalig nach oben rücken',
          d3: '3 Tage',
          d5: '5 Tage',
          w1: '1 Woche',
          w2: '2 Wochen',
          unpin: 'Nicht mehr anpinnen',
        },
      },
    },
    circles: {
      circlesTitle: `Abonnierte ${KreiseName('GER').multi}`,
      noCirclesTitle: `Nicht abonnierte ${KreiseName('GER').multi}`,
      myPost: 'Ich',
      circlesInfoTitle: 'Info & Teilnehmer',
      circlesSubscribe: 'Abonnieren',
      unsubscribeAlert: 'Geschlossenen Gruppenchat unwiderruflich verlassen?',
      continueAlert: 'Verlassen',
      readOnlyKreise: `Dies ist ein "Nur-lese-${
        KreiseName('GER').single
      }". Nur Admins können hier Beiträge erstellen. Du kannst aber Kommentare zu den Beiträgen schreiben.`,
    },
    messages: {
      addMessageLabel: 'Nachricht eingeben',
      changesSaved: 'Änderungen gespeichert!',
      newConversation: 'Neue Konversation',
    },
    profileTabs: {
      basicTab: 'Basis',
      clubsTab: `${CorpsName('GER').single}`,
      miscellaneosTab: 'Interessen',
      professionTab: 'Beruf',
      studyTab: StudyName('GER')?.short,
      notificationsTab: 'Benachrichtigungen',
      privacy: 'Privatsphäre',
    },
    profile: {
      addPhotoLabel: 'Foto hinzufügen',
      genderInputLabel: 'Geschlecht',
      titleLabel: 'Titel',
      firstNameLabel: 'Vorname',
      lastNameLabel: 'Nachname',

      passwordLabel: 'Passwort festlegen',
      birthDateLabel: 'Geburtsdatum',

      changePasswordLabel: 'Passwort ändern',
      defaultCountryLabel: 'Deutschland',
      defaultGraduationLabel: 'Keine Angabe',
      defaultCorpsLabel: 'keine',
      street: 'Straße + Nr. ',

      partner: 'Name Partner/in',
      mobileNumberLabel: 'Mobil-Nr.',
      handphoneNumberLabel: 'Handynummer',
      telephoneNumberLabel: 'Telefonnummer',
    },
    profileKorp: {
      verband: CorpsName('GER').single,
      function: FunctionName('GER'),
      dateOfJoin: CorpsEditDate('GER'),
      beername: 'Biername',
    },
    profileClubs: {
      noUserLabel: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
      functionLabel: `${FunctionName('GER')}`,
      setClubLabel: `Bitte den Namen des ${CorpsName('GER').single} angeben.`,
      matchClubLabel: 'Das',
      matchClubContinueLabel: 'ist bereits im Profil vorhanden, bitte einmal überprüfen.',
      clubLabel: `${CorpsName('GER').single.toUpperCase()}`,
      clubCodeLabel: `LOGIN CODE IHRES ${CorpsName('GER').single.toUpperCase()}S`,
      entryTimeLabel: CorpsEditDate('GER'),
      deleteClubLabel: 'Wollen Sie Ihre Mitgliedschaft im zu',
      deleteClubContinueLabel: 'wirklich löschen?',
      selectVariant: 'Was möchtest du tun?',
      requestExistingCorp: 'Existierenden Eintrag hinzufügen',
      requestNewCorps: 'Neuen Eintrag anfragen',
      createCorp: 'Neuen Eintrag anlegen',
    },
    profileProfession: {
      jobTitle: 'Berufsbezeichnung',
      noUserLabel: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
      untilToday: 'Bis heute',
      positionTitleLabel: 'Beruf',
      itemNameLabel: 'Beruf',
      employmentLabel: 'Beschäftigungsart',
      activityLabel: ActivityName('GER'),
      careerStageLabel: 'Karrierestufe',
      activityDescLabel: 'Beschreibung der Tätigkeit',
      companyLabel: CompanyName('GER'),
      nameLabel: 'Name',
      industryLabel: IndustryName('GER'),
      areaLabel: 'Bereich',
      websiteLabel: 'Webseite des Unternehmens',
      employmentPeriodLabel: 'Zeitraum der Beschäftigung',
      fromLabel: 'Von',
      toLabel: 'Bis',
    },
    profileStudy: {
      noUserLabel: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
      universityLabel: StudyName('GER').hochsule,
      courseLabel: StudyName('GER').studiengang,
      degreeLabel: StudyName('GER').abshchluss,
    },
    profileOther: {
      noUserLabel: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
      hobbiesLabel: 'Interessen',
      culturalLabel: 'Kulturpaten',
      socialMediaPlattform: 'Social-Media-Plattform',
      socialMediaLabel: 'Social Media Links',
      socialMediaLink: 'Link',
      socialMediaError: 'Bitte vollständige URL eingeben',
    },
    profileNotifications: {
      noUserLabel: 'Es müssen erst alle Basis-Informationen ausgefüllt sein, bevor die App genutzt werden kann.',
      notificationsInfo:
        'Hier können Sie E-Mail-Benachrichtigungen für einzelne Aktivitäten aktivieren (Standard) oder für 3 Monate deaktivieren.',
      notificationsTitle: 'Push-Benachrichtigungen:',
      notificationsDescr0: 'Die Push-Benachrichtigungen können Sie in den ',
      notificationsDescr1: 'Smartphone-Systemeinstellungen',
      notificationsDescr2: ' vornehmen.',
      mailingTitle: 'E-Mail-Benachrichtigungen:',
      mailingDescr:
        'Die E-Mail-Benachrichtigungen für einzelne Aktivitäten aktivieren (Standard) oder für 1 Jahr deaktivieren.',
      allNotificationsLabel: 'Alle Benachrichtigungen',
      postsLabel: `Beiträge in ${KreiseName('GER').multi}`,
      messagesLabel: 'Private Nachrichten',
      commentsLabel: 'Kommentare zu eigenen Beiträgen',
      contactRequestsLabel: 'Kontaktanfragen',
      eventsLabel: 'Veranstaltungen',
      clubMemberLabel: 'Neu registrierte Mitglieder',
      enableLabel: 'An',
      disableToLabel: 'Aus bis',
      disableLabel: 'Aus',
      ownPosts: 'Meine Beiträge (Mail auch selbst erhalten)',
    },
    searchInput: {
      title: 'Suche',
      placeholder: 'Suche',
    },
    emptyList: {
      title: 'Hier sind (noch) keine Inhalte',
    },
    switchLanguage: {
      placeholder: 'Sprache/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'russian (русский)',
      SP: 'Spanish (Español)',
      title: 'Sprache wählen',
    },
    filePicker: {
      takePicLabel: 'Anhänge',
      uploadPicLabel: 'Foto aufnehmen',
      selectPicLabel: 'Foto/Video auswählen',
      selectDocLabel: 'Dokument auswählen',
    },
    datePicker: {
      selectDateLabel: 'Datum auswählen',
      confirmDateLabel: 'Bestätigen',
      selectTimeLabel: 'Zeit auswählen',
    },
    changePassword: {
      currentPasswordLabel: 'Aktuelles Passwort',
      newPasswordLabel: 'Neues Passwort',
      repeatPasswordLabel: 'Passwort wiederholen',
      changePasswordLabel: 'Passwort ändern',
    },
    appInfo: {
      appDescLabel:
        'Korponnect ist die App für Verbindungsstudenten. Austausch unter Korporierten, Organisation von Veranstaltungen sowie berufliches und privates Vernetzen – alles in einer App.',
      contactsLabel: InfoContactsName('GER'),
      infoLabel: 'Informationen',
      versionLabel: 'App-Version',
      agbLabel: 'AGB',
      dataProtectionLabel: 'Datenschutzerklärung',
      manualLabel: 'Anleitung',
      appTextLabel: 'Hier finden Sie unsere',
      appAndLabel: 'und eine',
      appMainTextLabel: `Sie wollen einen Kreis hinzufügen lassen oder haben eine andere Anregung? Sie wollen Ihr Profil löschen? Schreiben Sie uns einfach eine kurze E-Mail an ${support_email}.`,
      deleteFeedback: 'Grund für die Löschung / Feedback 🙂:',
      deleteUserTitle: 'Account löschen?',
      deleteUserDesc:
        'Hier können Sie Ihren Account unwiderruflich löschen.\b Übrigens: Sie können auch einfach die Benachrichtigungen ausschalten. Bearbeiten Sie die Einstellungen unter Profil -> Benachrichtigungen oder schreiben Sie uns eine E-Mail.',
      deleteUserAlert: 'Wollen Sie Ihren Account wirklich löschen?',
      webLabel: 'Web-Version',
    },
    infoMessages: {
      registartionSuccessLabel: 'Vielen Dank für die Registrierung!',
      manualRegSuccessLabel:
        'Vielen Dank für Ihre Registrierung. Sie werden in Kürze freigeschaltet und per E-Mail darüber informiert.',
      linkExpiredLabel: 'Link abgelaufen. Fordern Sie eine neue Passwortänderung an.',
      emailExistLabel: 'Sorry, die E-Mail ist nicht verfügbar.',
    },
    logoutAlert: {
      title: 'Ausloggen',
      text: 'Wollen Sie sie wirklich ausloggen? Sie müssen sich nicht ausloggen, sondern können die App einfach schließen oder minimieren.',
      confirm: 'Ja',
      dismiss: 'Abbrechen',
    },
    invalidInputs: {
      passwordChars: `Das Passwort muss mindestens ${passwordDigits(
        'GER',
      )} Zeichen enthalten, inkl. Klein-, Großbuchstaben, Ziffern und Sonderzeichen.`,
      fieldRequired: 'Dieses Feld wird benötigt.',
      emailsNotMatching: 'Bitte stellen Sie sicher, dass Ihre E-Mails übereinstimmen',
      emailInvalid: 'Die Angabe Ihrer E-Mail-Adresse ist nicht korrekt!',
      selectCorrectAnswer: 'Bitte die korrekte Antwort markieren.',
      corp: 'Bitte mindestens 1 Eintrag auswählen',
    },
    mentoring: {
      profile: {
        rules1: 'Ich stimme den',
        rules2: ' Werteverständnis ',
        rules3: '“Mentoring für Stipendiaten” zu.',
        menteeDescription:
          'Bitte wähle deine Mentoring-Region aus, sodass Du über vor-Ort-Veranstaltungen informiert werden kannst. HINWEIS: Die Auswahl kannst Du jederzeit ändern. Sie schränkt NICHT Deine Mentoren-Suche ein.',
        mentorDescription:
          'Bitte fülle Dein Mentoren-Profil aus, damit Dich Stipendiat/innen als Mentor finden können. HINWEIS: Mindestens 1 Region und min. 1 Themenfeld sind nötig.',
        mentoringRegions: 'Mentoring-Regionen',
        reigonsShort: 'Regionen',
        topics: 'Themenfelder',
        departments: 'Fachbereich',
        available: 'Ich bin verfügbar',
        unavailable: 'Ich bin derzeit nicht verfügbar',
        deleteAccount: 'Ment.-Profil löschen & Gruppe verlassen',
        deleteAccountDescr: 'Mentoring-Profil löschen und Mentoring-Gruppenchat verlassen?',
        uploadPdf: 'Mentoren-Profil (PDF)',
        myPdf: 'Mein Profil (PDF)',
        pdf: 'Mentorenprofil',
      },
      tabNames: {
        info: 'Info',
        news: 'News',
        profile: 'Profil',
        search: 'Suche & Anfrage',
        request: 'Mentorate',
        requestDetails: 'Mentoringanfrage',
        tabDeactivated: 'Tab deaktiviert. Bitte Mentoring-Profil ausfüllen.',
      },
      search: {
        description:
          'Um die Suche gezielt zu starten, empfehlen wir Dir, zunächst mit wenigen Auswahlfeldern zu beginnen und dann in einem neuen Suchlauf das Suchraster breiter anzulegen. Wenn Du in Deiner Region keinen geeigneten Mentor findest, dann weite die Suche auf eine benachbarte Mentoring-Region aus. Suchkriterien: 1 Region, 1 bis 3 Themenfelder. Alle Felder sind UND-verknüpft!',
        searchBtn: 'Suche',
      },
      requests: {
        mentorIncoming: 'Meine Mentoring-Anfragen',
        mentorCurrent: 'Meine Mentorate',
        menteeOutgoing: 'Meine Mentoring-Anfrage',
        menteeCurrent: 'Mein Mentorat',
        cancelMentoring: 'Mentorat beenden',
        sendRequest: 'Als Mentor anfragen',
        alreadyHaveMentor: 'Sie können nur einen Mentor haben',
        requestSuccess: 'Anfrage erfolgreich gesendet',
        requestRetracted: 'Anfrage erfolgreich zurückgezogen',
        cancelSendingRequest: 'Mentoring-Anfrage zurückziehen',
        approveRequest: 'Anfrage annehmen',
        rejectRequest: 'Anfrage ablehnen',
        invitaionText: 'Anfrage',
        rejectionText: 'Begründen Sie Ihre Ablehnung',
        sureRetractRequest: 'Willst du die Anfrage wirklich zurückziehen?',
      },
    },
    downloads: {
      root: 'Hauptverzeichnis',
      delete: 'Löschen',
      rename: 'Umbenennen',
      areYouSureDeleteFolder: 'Ordner wirklich löschen? Alle Inhalte werden gelöscht!',
      renameFolder: 'Ordner umbenennen',
      createNewFolder: 'Neuen Ordner erstellen',
      newFolderPlaceholder: 'Ordnernamen eingeben',
      create: 'Erstellen',
      areYouSureDeleteFile: '',
      downloadFile: 'Herunterladen',
      deleteFile: 'Vom Gerät löschen',
      openInFinder: 'Öffnen in Dateien',
      cancel: 'Abbrechen',
    },
    globalSearch: {
      postedBy: 'gepostet von',
      found: 'Gefunden',
      loadMore: 'Weitere laden',
      enterQuery: 'Gib irgendeinen Suchbegriff ein. Du kannst nach allem suchen (Name, Dokument, Event, Beitrag, …)',
      folder: 'Ordner',
      post: 'Beitrag',
      error: 'Gib mindestens 3 Zeichen ein',
    },
    kreiseSettings: {
      description: 'Beschreibung',
      push: 'Push-Benachrichtigungen',
      email: 'Mail-Benachrichtigungen',
      subscribers: 'Mitglieder',
      active: 'Aktiviert',
      oneYear: 'Stumm für 1 Jahr',
      forever: 'Stumm für immer',
      until: 'Bis',
      cantDeactive: 'Für diesen Gruppenchat ist diese Einstellung deaktiviert',
      SINGLE: 'Für jeden Beitrag',
      SUMMARY_ONCE: 'Zusammenfassung 1x pro Woche',
      SUMMARY_TWICE: 'Zusammenfassung 2x pro Woche',
      SUMMARY_DAY: 'Zusammenfassung 1x pro Tag',
      OFF: 'Aus',
      PUSH: 'Push-Benachrichtigung',
      EMAIL: 'Mail-Benachrichtigung',
      PUSH_AND_EMAIL: 'Push- und Mailbenachrichtigung',
      newUser: 'Benachrichtigung für neue Nutzer',
      statistic: 'Sende-Statistik',
      off: 'Aus',
      ownPosts: 'Sende-Statistik-Mail für deine Beiträge',
      deleteMemberDescr: 'entfernen?',
      deleteMemberBtn: 'Entfernen',
      retractAdminRights: 'Rechte entziehen',
      deleteAdminAndUser: 'Ganz entfernen',
      toDeleteKreiseBtn: 'Gruppenchat löschen',
      deleteKreiseDescr: `${KreiseName('GER').single} unwiderruflich löschen?`,
      deleteKreiseModalTitle: 'Mitglied entfernen?',
      addKreiseName: 'Name',
      addKreiseDescription: 'Beschreibung',
      addMembers: 'Mitglieder hinzufügen',
      createKreise: 'Gruppenchat erstellen',
      toKreiseEditMessage: 'Hinweis: Alle Änderungen an den Mitgliedern werden sofort umgesetzt.',
      kreiseCreator: 'Ersteller:in dieses Gruppenchats',
      kreiseDeleteMembersLeft: 'Der Gruppenchat kann erst gelöscht werden, wenn nur noch ein Mitglied darin ist.',
      kreiseDeleteMembersLeftTitle: 'Erst Mitglieder entfernen',
      suggestionInNewsfeed: {
        admin: 'Admin',
        member: 'Mitglieder',
        corp: `${CorpsName('GER').single} mitglieder`,
        corpAdmin: `${CorpsName('GER').single} admin`,
      },
    },
    surveys: {
      checkVotes: 'Ergebnisse anzeigen',
      chooseOptions: {
        single: 'Wähle eine Option aus.',
        multiple: 'Wähle mindestens eine Optionen aus.',
      },
      votesModalTitle: 'Ergebnisse',
      createModalTitle: 'Umfrage',
      noVotes: 'Es liegen keine Abstimmungen vor',
      question: 'Frage',
      askAQuestion: 'Eine Frage stellen',
      allowMultiple: 'Mehrere Antworten zulassen',
      votes: {
        single: 'Stimme',
        multiple: 'Stimmen',
      },
      option: 'Option',
      optionsLabel: 'Optionen',
      anonymus: 'Anonymus poll',
      create: 'Erstellen',
    },
  },
  {
    key: 'ENG',
    key2: 'en',
    responseHandler: {
      messageLabel: `Unknown error. Please contact ${support_email}.`,
      expiredLinkLabel: 'Link expired. Request a new password change.',
      emailExistLabel: 'Sorry, this email address is already registered.',
      profileUpdated: 'Profile updated',
      profileUpdatedCorpsError: `Profile updated! However, the following ${
        CorpsName('ENG').single
      } could not be added due to an incorrect login code:`,
      appNeedsUpdate: 'Please update the app in the App Store / Google Play.',
      maximalVideoSize: 'The maximum file size is 2 GB!',
    },
    report: {
      notBelongLabel: `The user no longer belongs to a ${CorpsName('ENG').single}`,
      incProfileLabel: 'The user makes incorrect profile offers',
      badPicLabel: "The user's profile picture violates the guidelines",
      badComLabel: "The user's comments violate the guidelines",
      spamLabel: 'The user sends spam in the chat',
      reasonsLabel: 'Reasons for reporting',
    },
    drawer: {
      news: 'News',
      kreise: KreiseName('ENG').multi,
      search: 'Search',
      calendar: CalendarName('ENG'),
      contacts: 'Contacts',
      documents: `${DocumentsName('ENG')}`,
      requests: 'Requests',
      messages: MessagesName('ENG'),
      profile: ProfileName('ENG'),
      logout: 'Logout',
      map: 'Map',
      mentoring: 'Mentoring',
    },
    imageCropper: {
      cropLabel: 'Crop',
      cancelLabel: 'Cancel',
      chooseLabel: 'Choose',
    },
    signIn: {
      register: 'Register',
      title: 'Please log in',
      loginPlaceholder: 'Mail adress',
      passwordPlaceholder: 'Password',
      forgotPassword: 'Forgot password?',
      loginBtn: 'Login',
      noAccount: 'No account yet?',
      description0: 'If you have any questions, please feel free to contact us at',
      description1: 'or',
      description2: '.',
      loginValidation: 'Your email address is incorrect',
      passwordValidation: 'The password must be at least 6 characters long',
    },
    search: {
      name: 'Name',
      corps: CorpsName('ENG').single,
      country: CountryName('ENG'),
      city: 'City',
      industry: IndustryName('ENG'),
      company: 'Company',
      profession: 'Profession',
      hobby: 'Interests',
      cultural: 'Cultural sponsor',
      study: 'Study',
      office: 'Office',
      degreeProgram: 'Degree program',
      university: 'University',
      position: FunctionName('ENG'),
      status: 'Status',
      title: 'Search result for',
      filterTitle: 'Filter by',
      postCode: 'Post code',
      global: 'All',
      activity: ActivityName('ENG'),
    },
    resetPassword: {
      enterEmail: 'Please enter your e-mail adress',
      inputPlaceholder: 'Mail adress',
      sendBtn: 'Send',
      cancelBtn: 'Cancel',
      message: 'If the e-mail address is known to us, a link was sent to it.',
      desc: 'You must enter the exact email address that is stored in the app. If necessary, check your mails to see which one it is. ',
    },
    password: {
      resetLabel: 'Enter a new password',
      inputLabel: 'Enter new password',
    },
    signUp: {
      createAccount: 'Create account',
      inputPlaceholder: `Login code of your ${CorpsName('ENG').single}'s`,
      sendBtn: 'Continue',
      descFirst: 'By registering you agree to our',
      descSecond: 'and confirm that you have read our',
      descThird: `If you have any questions, please feel free to contact us at ${support_email} or +49 170 49 805 94.`,
      terms: 'terms and conditions ',
      policy: 'privacy policy.',
      description: 'Note',
      generatePassword: 'Generate password',
      noCodeSuccessRegister:
        'Thank you for your registration! You will be informed by mail as soon as you have been activated.',
    },
    signUpDefault: {
      withoutCode: 'Without Code',
      createAccount: 'Simply register here',
      simpleRegister: 'Without Invitation Code',
      existingAccount: 'Account already exists?',
      descr01: 'I agree with the',
      descr0: 'By registering you agree to our',
      descr1: '(general terms) and confirm that you have read our',
      descr2: '(data privacy policy).',
      guest: 'Register as Guest',
      withCodeWithDescr: `Register as ${member_naming_singular}`,
      withCode: 'With Code',
    },
    signUpManual: {
      title: 'Registration',
      photoDesc: 'Add profile photo',
      genderInput: 'Gender',
      firstName: 'First name(s)',
      lastName: 'Last name',
      club: CorpsName('ENG').single,
      specify: 'please specify',
      emailPlaceholder: 'E-mail adress',
      emailHint: 'Please use long term used mail address.',
      repeatEmailPlaceholder: 'Repeat E-mail adress',
      passwordPlaceholder: 'Set password',
      repeatPasswordPlaceholder: 'Repeat password',
      postcode: 'Postcode',
      place: 'Place of residence',
      country: 'Country',
      loginCode: 'Invitation code',
      mobilePlaceholder: 'Mobile',
      corps: SignUpCorpsName('ENG'),
      searchCountry: 'Search for country',
    },
    backToLogin: {
      desc: 'You already have an account? ',
      login: 'Login',
    },
    backToSignUp: {
      desc: 'No account yet? ',
      register: 'Register',
    },
    privacy: {
      desc: 'Yes, I accept the',
      btn: 'general terms and conditions',
      zu: '',
      map: {
        title: 'Data protection notice',
        description:
          'This app has been implemented in compliance with GDPR. We only use Google Maps to display the map. No personal data is transmitted.\n\nIn addition, the pins are only displayed roughly, based on the postcode/city but without street/no. This means that it is not possible to draw conclusions about the specific addresses of the users.',
      },
      restrictions: {
        overall: 'This function is only accessible to members.',
        overallWilkar: 'This function is not accessible for data protection reasons.',

        profile: 'Only members can view user profiles. However, you can write a private message to users.',
        groupchat: `The ${KreiseName('ENG').single} are only accessible to members.`,
        groupchatWilkar: `For data protection reasons, the ${KreiseName('ENG').single} are only accessible to members.`,
        comments: 'Comments are only visible to members.',
        commentsWilkar: 'For data protection reasons, comments are only visible to members.',
        likes: 'The list of likes can only be viewed by members.',
        post: 'Only members can post articles.',
        participantsList: 'The list of participants can only be viewed by members.',
        commentsOnlyForMembers: 'Comments are only visible to members.',
        postsLimitation: 'Older posts can only be viewed by members.',
      },
      settings: {
        guestPost: {
          title: `Verified ${guest_naming_plural} can see my posts`,
          ask: 'Always ask',
          no: 'No',
          yes: 'Yes',
        },
      },
      guestModal: {
        title: 'Can guests see this post? You can also change this setting afterwards.',
        dontAskAgain: 'Do not ask again (you can also change this setting under "Profile" → "Privacy").',
      },
      post: {
        ifShown: `Hide for ${guest_naming_plural}`,
        ifHidden: `Show for ${guest_naming_plural}`,
        ifShownModal: `The post is now no longer visible for ${guest_naming_plural}.`,
        ifHiddenModal: `The post is now visible for ${guest_naming_plural}.`,
      },
    },
    searchModal: {
      landTitle: 'Choose a country',
      corpsTitle: `Select a ${CorpsName('ENG').single}`,
      pleaseSelect: 'Please select',
    },
    btn: {
      sendBtn: 'Send',
      cancelBtn: 'Cancel',
      select: 'Select',
      cancelEventBtn: acceptEventName('ENG').cancel,
      acceptBtn: acceptEventName('ENG').accept,
      saveBtn: 'Save',
      clearBtn: 'Delete',
      acceptReqBtn: 'Accept',
      declineReqBtn: 'Decline',
      updateBtn: 'Update',
      declineBtn: 'Decline',
      yesUnderstood: 'Ja, verstanden',

      ok: 'OK',
      cancel2: 'Cancel',
    },
    validation: {
      email: 'Your email address is incorrect',
      password: 'The password must be at least 6 characters long',
      repeatEmail: 'Email and retry email do not match',
      repeatPassword: 'Password and repeat do not match',
      required: 'This field is required',
      matchPasswords: 'Old and new password match',
      dontMatchPasswords: 'Password and repeat do not match',
    },
    init: {
      title: 'Loading',
      overlayLabel: 'Please check your internet connection',
    },
    screenTitles: {
      postsTitle: 'News',
      circlesTitle: KreiseName('ENG').multi,
      searchTitle: 'Search',
      profileTitle: ProfileName('ENG'),
      eventTitle: CalendarName('ENG'),
      eventDetailsTitle: 'Event',
      newEventTitle: 'Add Event',
      contactsTitle: 'Contacts',
      contactReqTitle: 'Contact requests',
      reqDetailsTitle: 'Request details',
      outReqTitle: 'Contact request',
      messagesTitle: MessagesName('ENG'),
      changePasswordTitle: 'Change password',
      appInfoTitle: 'Info',
      reportTitle: 'Report user',
      createKreiseTitle: 'Gruppenchat erstellen',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      user: 'Users',
      mainTitleLabel: ProfileName('ENG'),
      basisLabel: 'Basic',
      clubLabel: CorpsName('ENG').single,
      clubLabelPlural: CorpsName('ENG').multi,
      professionLabel: 'Profession',
      studyLabel: StudyName('ENG').short,
      uploadPhotoLabel: 'Upload photo',
      editPhotoLabel: 'Edit photo',
      profileCompletedLabel: 'Profile completed to',
      profileCompletedGLabel: '',
      birthday: 'Date of Birth',
      functionLabel: 'Function',
      dateOfJoinLabel: 'Date of Loin',
      positionDescLabel: 'Profession',
      companyLabel: CompanyName('ENG'),
      nameLabel: 'Name',
      websiteLabel: 'Website',
      fromLabel: 'From',
      toLabel: 'To',
      delete: 'delete',
      untilToday: 'Until today',
      degreeLabel: StudyName('ENG').abshchluss,
      miscellaneos: 'Miscellaneos',
      hobbies: 'Interests',
      cultural: 'Cultural sponsors',
      positionLabel: 'Position',
      typeOfEmplLabel: 'Type of employment',
      activityLabel: ActivityName('ENG'),
      careerStageLabel: 'Career stage',
      activityDescLabel: 'Description of the activity',
      industryLabel: IndustryName('ENG'),
      areaLabel: 'Area',
      employmentPeriodLabel: 'Period of employment',
      universityLabel: 'University',
      courseLabel: 'Course of studies',
      firmaLabel: 'Firma',

      ageLabel: 'Age',
      birthdayLabel: 'Birthday',
      editProfileLabel: 'Edit profile',
      addContactLabel: 'Add to contacts',
      sendMessageLabel: 'Send message',
      requestSentLabel: 'Send friend request',
      sendRequestSuccessLabel: 'Request sent successfully',
      reportSentLabel: 'User already reported',
      addReportLabel: 'Send report',
      contactsLabel: 'Contacts',
      showBirthday: 'Contacts see my birthday',
      street: 'Street + Nr. ',

      partner: 'Partner name',
      mobileNumberLabel: 'Mobile number',
      handphoneNumberLabel: 'Cell phone number',
      telephoneNumberLabel: 'Phone number',
      leibbursch: 'Mentor',
      leibfuechse: 'Mentee',

      bio: 'About me',
      addDocuments: 'Add documents',
      image: 'Bild',
      video: 'Video',
      pdf: 'PDF',
      link: 'Link',
      guest: 'Guest',
      firstName: 'First name',
      lastName: 'Surname',
      title: 'Title',
      email: 'E-Mail',
      name: 'First name',
      lastname: 'Surname',
      country: CountryName('ENG'),
      wohnort: 'City',
      postcode: 'ZIP CODE',
      inReview: 'Confirmation via mail pending',
    },
    calendar: {
      birthdayLabel: 'Birthday of',
      noEventsLabel: 'There are no events for the selected date',
      allTabLabel: 'All',
      myTabLabel: 'My events',
      clubsTabLabel: `${CorpsName('ENG').single}`,
      subsTabLabel: 'Subscriptions',
      subscribedTabLabel: 'Subscribed',
      otherTabLabel: 'Others',

      error: 'Start date/time must be before end date/time.',
      filter: {
        all: 'All',
        invited: 'Invited',
        confirmed: 'Confirmed',
      },
    },
    eventLabels: {
      inviteTo: 'Invite to',
      addEndDate: 'Enter end date/time',
      eventLabel: 'Event',
      startLabel: 'Starts at',
      locationLabel: 'Location',
      participantLabel: 'Participants',
      moreInfo: 'More information',
      commentsLabel: 'Comments',
      noCommentsLabel: 'No comments yet',
      eventStartLabel: 'Start',
      eventEndLabel: 'End',
      descLabel: 'Description',
      creatorLabel: 'Created by',
      titleLabel: 'Title',
      deleteEventTitle: 'Delete event?',
      deleteEventLabel: 'Delete this event?',
      clubsTitle: CorpsName('ENG').single,
      gruppenchat: 'Select group(s) chat',
      contactsTitle: 'Contacts',
      delCommentLabel: 'Delete this comment?',
      sendInvite: 'Send invitation to',
      selectCorps: 'Select target audience',
      externalGuest: 'Invite external guests',
      externalGuestPlaceholder: 'Enter mail address',
      attachmentLabel: 'Attachment',
      shareLinkLabel: 'Share link',
      linkLabel: 'Register Guest',
      copyLinkLabel: 'Copy link',
      iCalDownload: 'Calendar file',
      timezone: 'Time zone',
      visibility: 'Visibility',
      errorDate: 'Date is in the past',
      startAfterEndError: 'The end date must be after the start date',
      members: 'Members',
      waitlist: 'Waitlist',
      onWaitlist: 'auf der Warteliste',
      leaveWaitlist: 'Platz freigeben?',
      leaveWaitlistDesc: `Von Warteliste entfernen?\n\n Wenn du dich später wieder auf die Warteliste setzt, landest du ggf. auf einer hinteren Position.`,
      limitAchieved: (limit: number) =>
        `Die maximale Teilnehmeranzahl von ${limit} ist erreicht. Du wurdest auf die Warteliste gesetzt.`,
      visibilityOptions: {
        allWithoutGuests: 'Mitglieder und auf Webseite',
        invited_member: 'Invited members only',
        verified_member: 'All members',
        authorized_member: 'All members and guests',
        all: 'Members, guests and on website',
      },
      modalEvent: {
        title: 'Event created',
        subTitle: 'The event was created.\n\n',
        content:
          'Depending on the user settings, push/mail notifications will now be sent for the event. \n\nIf you have set this under "Profile" → "Notifications", you will also receive a mail notification about the event yourself.',
      },
      modalComment: {
        title: 'Notify whom?',
        message: 'Who should be informed about your comment?',
        optionAll: 'All participants',
        optionNone: 'Organizer(s) only',
        button: 'Send comment',
      },
      cancelModal: {
        title: 'Cancel or delete?',
        message: 'Cancel or delete event?',
        optionAll: 'Cancel (participants will be informed)',
        optionNone: 'Delete (attendees will not be informed)',
      },
      updateEventModal: {
        title: 'Further invitations sent out',
        label: 'The other groups were invited.',
        message:
          'Depending on the settings, push notifications or email notifications about the event are now sent to the other groups.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/Mailadresse',
        enterLink: 'Add link/mail address or similar',
      },
      parts: {
        part: 'Part',
        addPart: 'Add program point',
        hint: 'Explanation',
        question: 'Question',
        single: 'Only one option selectable',
        multi: 'Several options available',
        freeText: 'Free text answer',
        quiz: 'Quiz',
        questionnaire: 'Questionnaire',
        addQuestionnaire: 'Add Questionnaire',
        questionnaireType: 'Answer options',
        option: 'Option',
        addOption: 'Add option',
        showMembers: 'Participants',
        youJoined: 'You take part in:',
        totalToPay: 'Total amount to be paid:',

        yourAnswer: 'Your answer',
        visibleForCreators: 'Visible only for creators',
        allAnswers: 'All answers:',
        addUsers: 'Add users',
        isAnonym: 'Anonymous voting',
        isAnonymQuiz: 'Anonymes Quiz',
      },
    },
    rrEventLabels: {
      title: 'Repetition',
      MO: 'Mondat',
      TU: 'Tuesday',
      WE: 'Wednesday',
      TH: 'Thursday',
      FR: 'Friday',
      SA: 'Saturday',
      SU: 'Sunday',
      YEARLY: { single: 'Year', regular: 'Annually' },
      MONTHLY: { single: 'month', regular: 'monthly' },
      WEEKLY: { single: 'week', regular: 'weekly' },
      DAILY: { single: 'Day', regular: 'daily' },
      SINGLE: { single: 'Unique', regular: 'Unique' },
      CUSTOM: {
        single: 'User-defined...',
        regular: 'User-defined...',
      },
      freq: 'Frequency',
      interval: 'Interval',
      notRepeat: 'Unique',
      every: 'Every',
      month: 'month',
      week: 'week',
      last: 'last',
      on: 'on',
      everyMonthOn: 'Every month on',
      ofTheMonth: 'of the month',
      editLabel: 'Which dates do you want to change?',
      thisDate: 'This date only',
      thisDateAndFollowing: 'This and all subsequent',
      allDates: 'All dates',
      after: 'After',
      time: 'time',
      times: 'times',
      neverEnds: 'Never ends',
      until: 'until the',
      repeatedDefault: ({ frequency, freq }) =>
        `Wird jede${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} wiederholt`,
      repeatedMonthly: ({ day, interval }) =>
        `Wird jeden${interval == 1 ? '' : ` ${interval}.`} Monat am ${day.length > 2 ? day : `${day}.`} wiederholt.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }) =>
        `Wird jeden ${bysetpos == -1 ? ' letzten' : `${bysetpos}.`} ${day} des ${
          interval === 1 ? '' : `${interval}.`
        } Monats wiederholt.`,

      repeatedYearly: ({ interval }) => `Wird jedes${interval == 1 ? '' : ` ${interval}.`} Jahr wiederholt.`,
      repeatedWeekly: ({ interval, byweekday }) =>
        `Wird jede${interval != 1 ? ` ${interval}.` : ''} Woche${
          !!byweekday?.length
            ? ' am ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' und ' : '') +
              byweekday?.slice(-1)
            : ''
        } wiederholt.`,
      repeatedDaily: ({ interval }) => `Wird jeden${interval != 1 ? ` ${interval}.` : ''} Tag wiederholt.`,
    },
    requestsLabels: {
      receiveLabel: 'Received',
      sentLabel: 'Sent',
    },
    post: {
      adTitle: 'Information/Advertisement',
      toKreise: 'to',
      comment: 'Comments',
      myPost: 'Me',
      openPdf: 'Click to open',
      deletePost: 'Delete this post?',
      posted: 'Posted',
      commented: 'Commented',
      contacts: 'Contacts',
      sendToContacts: 'Message to all',
      enterText: 'Write a message here',
      sendToCircle: `Enter message in this ${KreiseName('ENG').single}`,
      sendComment: 'Enter comment',
      longMessage: '20,000 characters maximum',
      noComments: 'No comments yet',
      deleteComment: 'Delete comment?',
      deleteEventPost: 'Delete this post? This will not delete the event.',
      editPostLabel: 'Edit post',
      editingPostLabel: 'Editing mode',
      deletePostLabel: 'Delete post',
      editedAt: 'Edited',
      pinned: 'Pinned',
      actions: {
        label: 'Post actions',
        pin: {
          label: 'Pin post',
          once: 'Move up once',
          d3: '3 days',
          d5: '5 days',
          w1: '1 week',
          w2: '2 weeks',
          unpin: 'Unpin',
        },
      },
    },
    circles: {
      circlesTitle: `Subscribed ${KreiseName('ENG').multi}`,
      noCirclesTitle: `Unsubscribed ${KreiseName('ENG').multi}`,
      myPost: 'Me',
      circlesInfoTitle: 'Info & Members',
      circlesSubscribe: 'Subscribe',
      unsubscribeAlert: 'Leave closed group chat irrevocably?',
      continueAlert: 'Leave',
      readOnlyKreise: `This is a "read-only ${
        KreiseName('ENG').single
      }". Only admins can post here. You can post comments on the posts though.`,
    },
    messages: {
      addMessageLabel: 'Enter message',
      changesSaved: 'Changes saved!',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: 'Basic',
      clubsTab: CorpsName('ENG').single,
      miscellaneosTab: 'Interests',
      professionTab: 'Profession',
      studyTab: StudyName('ENG')?.short,
      notificationsTab: 'Notifications',
      privacy: 'Privacy',
    },
    profile: {
      addPhotoLabel: 'Add profile photo',
      genderInputLabel: 'Gender',
      titleLabel: 'Title',
      firstNameLabel: 'First name(s)',
      lastNameLabel: 'Last name',

      passwordLabel: 'Set password',
      birthDateLabel: 'Date of birth',

      changePasswordLabel: 'Change password',
      defaultCountryLabel: 'Germany',
      defaultGraduationLabel: 'No information',
    },
    profileKorp: {
      verband: CorpsName('ENG').single,
      function: FunctionName('ENG'),
      dateOfJoin: CorpsEditDate('ENG'),
      beername: 'Biername',
    },
    profileClubs: {
      noUserLabel: 'All basic information must be filled in before the app can be used.',
      functionLabel: 'Function',
      setClubLabel: `Please indicate the name of the ${CorpsName('ENG').single}.`,
      matchClubLabel: 'The',
      matchClubContinueLabel: 'already exists in the profile, please check again.',
      clubLabel: CorpsName('ENG').single.toUpperCase(),
      clubCodeLabel: `LOGIN CODE OF YOUR ${CorpsName('ENG').single.toUpperCase()}`,
      entryTimeLabel: CorpsEditDate('ENG'),
      deleteClubLabel: 'Are you sure you want to delete your membership in',
      deleteClubContinueLabel: '?',
      selectVariant: 'Select variant',
      requestExistingCorp: 'Request existing corp',
      requestNewCorps: 'Request new corps',
      createCorp: 'Create',
    },
    profileProfession: {
      jobTitle: 'Job title',
      noUserLabel: 'All basic information must be filled in before the app can be used.',
      untilToday: 'Until today',
      positionTitleLabel: 'Position',
      itemNameLabel: 'Profession',
      employmentLabel: 'Employment type',
      activityLabel: ActivityName('ENG'),
      careerStageLabel: 'Career stage',
      activityDescLabel: 'Description of the activity',
      companyLabel: CompanyName('ENG'),
      nameLabel: 'Name',
      industryLabel: IndustryName('ENG'),
      areaLabel: 'Area',
      websiteLabel: 'Website',
      employmentPeriodLabel: 'Period of employment',
      fromLabel: 'From',
      toLabel: 'To',
    },
    profileStudy: {
      noUserLabel: 'All basic information must be filled in before the app can be used.',
      universityLabel: StudyName('ENG').hochsule,
      courseLabel: StudyName('ENG').studiengang,
      degreeLabel: StudyName('ENG').abshchluss,
    },
    profileOther: {
      noUserLabel: 'All basic information must be filled in before the app can be used.',
      hobbiesLabel: 'Interests',
      culturalLabel: 'Cultural sponsors',
      socialMediaPlattform: 'Social-Media-Plattform',
      socialMediaLabel: 'Social Media Links',
      socialMediaLink: 'Link',
      socialMediaError: 'Please enter full URL',
    },
    profileNotifications: {
      noUserLabel: 'All basic information must be filled in before the app can be used.',
      notificationsTitle: 'Push notifications:',
      notificationsDescr0: 'You can set the push notifications in the ',
      notificationsDescr1: 'smartphone system settings.',
      notificationsDescr2: '',
      mailingTitle: 'Email notifications:',
      mailingDescr: 'Activate email notifications for individual activities (default) or deactivate them for 1 year.',
      allNotificationsLabel: 'All notifications',
      postsLabel: `Posts in ${KreiseName('ENG').multi}`,
      messagesLabel: 'Private messages',
      commentsLabel: 'Comments on own contributions',
      contactRequestsLabel: 'Contact requests',
      eventsLabel: 'Events',
      clubMemberLabel: 'New registered members',
      enableLabel: 'On',
      disableToLabel: 'Off until',
      disableLabel: 'Off',
      ownPosts: 'My contributions (mail also received by myself)',
    },
    searchInput: {
      title: 'Search',
      placeholder: 'Search',
    },
    emptyList: {
      title: 'Here are no contents (yet)',
    },
    switchLanguage: {
      placeholder: 'Language/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'russian (русский)',
      SP: 'Spanish (Español)',
      title: 'Choose language',
    },
    filePicker: {
      takePicLabel: 'Attachments',
      uploadPicLabel: 'Upload photo',
      selectPicLabel: 'Select photo or video',
      selectDocLabel: 'Select document',
    },
    datePicker: {
      selectDateLabel: 'Select date',
      confirmDateLabel: 'Confirm date',
      selectTimeLabel: 'Select time',
    },
    changePassword: {
      currentPasswordLabel: 'Current password',
      newPasswordLabel: 'New password',
      repeatPasswordLabel: 'Repeat password',
      changePasswordLabel: 'Change password',
    },
    appInfo: {
      appDescLabel:
        'Korponnect is the app for fraternity students. Exchange among corporates, organization of events as well as professional and private networking - all in one app.',
      contactsLabel: InfoContactsName('ENG'),
      infoLabel: 'Information',
      versionLabel: 'App Version',
      agbLabel: 'AGB',
      dataProtectionLabel: 'Data protection declaration',
      manualLabel: 'Manual',
      appTextLabel: 'You can find us here',
      appAndLabel: 'and a',
      appMainTextLabel: `Do you want to add a circle or do you have another suggestion? Do you want to delete your profile? Just write us a short email to ${support_email}.`,
      deleteFeedback: ' Reason for account deletion / feedback 🙂:',
      deleteUserTitle: 'Delete account?',
      deleteUserDesc:
        'Here you can irrevocably delete your account.\b By the way: You can also simply turn off the notifications. Edit the settings under profile -> Notifications or write us an email. ',
      deleteUserAlert: 'All your data will be deleted forever, continue?',
      webLabel: 'Web-Version',
    },
    infoMessages: {
      registartionSuccessLabel: 'Thank you for registering!',
      manualRegSuccessLabel: 'Thank you for registering! The profile will be activated shortly.',
      linkExpiredLabel: 'Link expired. Request a new password change.',
      emailExistLabel: 'Sorry, the email is not available.',
    },
    logoutAlert: {
      title: 'Logout',
      text: "Do you really want to log them out? You don't have to log out, you can simply close or minimize the app.",
      confirm: 'Yes',
      dismiss: 'No',
    },
    invalidInputs: {
      passwordChars: `Password must contain minimum ${passwordDigits(
        'ENG',
      )} characters, at least one letter, one number and one special character.`,
      fieldRequired: 'This field is required.',
      emailsNotMatching: 'Please make sure that your emails match',
      emailInvalid: 'The information of your e-mail address is not correct!',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: 'Please select at least 1 entry',
    },
    mentoring: {
      profile: {
        rules1: 'I agree with the',
        rules2: ' Understanding of values ',
        rules3: '"Mentoring for Scholars" to.',
        menteeDescription:
          'Please select your mentoring region so that you can be informed about on-site events. NOTE: You can change your selection at any time. It does NOT limit your mentor search.',
        mentorDescription:
          'Please fill out your mentor profile so that scholarship holders can find you as a mentor. NOTE: At least 1 region and min. 1 subject area are required.',
        mentoringRegions: 'Mentoring Regions',
        reigonsShort: 'Regions',
        topics: 'Topics',
        departments: 'Department',
        available: 'I am available',
        unavailable: 'I am currently not available',
        deleteAccount: 'Delete ment. profile & leave group',
        deleteAccountDescr: 'Delete mentor profile and leave mentor group chat?',
        uploadPdf: 'Mentor profile (PDF)',
        myPdf: 'My profile (PDF)',
        pdf: 'Mentor Profile',
      },
      tabNames: {
        info: 'Info',
        news: 'News',
        profile: 'Profile',
        search: 'Search & Request',
        request: 'Mentorates',
        requestDetails: 'Mentoring request',
        tabDeactivated: 'Tab deactivated. Please fill out mentoring profile.',
      },
      search: {
        description:
          'To start a targeted search, we recommend that you begin with a few selection fields and then broaden the search grid in a new search run. If you do not find a suitable mentor in your region, then expand the search to a neighboring mentoring region. Search criteria: 1 region, 1 to 3 topic fields',
        searchBtn: 'Search',
      },
      requests: {
        mentorIncoming: 'My mentoring requests',
        mentorCurrent: 'My mentorships',
        menteeOutgoing: 'My mentoring request',
        menteeCurrent: 'My mentorship',
        cancelMentoring: 'End mentorship',
        sendRequest: 'Request as mentor',
        alreadyHaveMentor: 'You can have only one mentor',
        requestSuccess: 'Request sent successfully',
        requestRetracted: 'Request withdrawn successfully',
        cancelSendingRequest: 'Cancel mentoring request',
        approveRequest: 'Accept request',
        rejectRequest: 'Reject mentee',
        invitaionText: 'Request',
        rejectionText: 'Give reasons for your rejection',
        sureRetractRequest: 'Really withdraw request?',
      },
    },
    downloads: {
      root: 'Home',
      delete: 'Delete',
      rename: 'Rename',
      areYouSureDeleteFolder: 'Delete folder really? All contents will be deleted!',
      renameFolder: 'Rename folder',
      createNewFolder: 'Create new folder',
      newFolderPlaceholder: 'Enter folder name',
      create: 'Create',
      areYouSureDeleteFile: '',
      downloadFile: 'Download',
      deleteFile: 'Delete from device',
      openInFinder: 'Open in Files',
      cancel: 'Cancel',
    },
    globalSearch: {
      postedBy: 'posted by',
      found: 'Found',
      loadMore: 'Load more',
      enterQuery: 'Enter any search term. You can search for anything (name, document, event, post, ...)',
      folder: 'Folder',
      post: 'Post',
      error: 'Enter at least 3 characters',
    },
    kreiseSettings: {
      description: 'Description',
      push: 'Push notifications',
      email: 'Mail notifications',
      subscribers: 'Members',
      active: 'Activated',
      oneYear: 'Silent for 1 year',
      forever: 'Silent forever',
      until: 'Until',
      cantDeactive: 'This setting is deactivated for this group chat',
      SINGLE: 'For each contribution',
      SUMMARY_ONCE: 'Summary 1x per week',
      SUMMARY_TWICE: 'Summary 2x per week',
      SUMMARY_DAY: 'Summary 1x per day',
      OFF: 'Off',
      PUSH: 'Push notification',
      EMAIL: 'Mail notification',
      PUSH_AND_EMAIL: 'Push and email notifications',
      newUser: 'Notification for new users',
      statistic: 'Send statistics',
      off: 'Off',
      ownPosts: 'Send statistics mail for your contributions',
      deleteMemberDescr: 'Remove?',
      deleteMemberBtn: 'Remove',
      retractAdminRights: 'Withdraw rights',
      deleteAdminAndUser: 'Remove completely',
      toDeleteKreiseBtn: `Delete ${KreiseName('GER')}`,
      deleteKreiseDescr: `Delete irrevocably ${KreiseName('GER').single}?`,
      deleteKreiseModalTitle: 'Remove member?',
      addKreiseName: 'Name',
      addKreiseDescription: 'Description',
      addMembers: 'Add members',
      createKreise: `Create ${KreiseName('GER')}`,
      toKreiseEditMessage: 'Note: All changes to the members will be implemented immediately.',
      kreiseCreator: 'Creator:in this group chat',
      kreiseDeleteMembersLeft: 'Kreise can be deleted only if there is only one member left',
      kreiseDeleteMembersLeftTitle: 'Remove members first',
      suggestionInNewsfeed: {
        admin: 'Admin',
        member: 'Members',
        corp: `${CorpsName('GER').single} member`,
        corpAdmin: `${CorpsName('GER').single} admin`,
      },
    },
    surveys: {
      checkVotes: 'View results',
      chooseOptions: {
        single: 'Choose one option',
        multiple: 'Choose multiple options',
      },
      votesModalTitle: 'Votes',
      createModalTitle: 'Poll',
      noVotes: 'There are no votes',
      question: 'Question',
      askAQuestion: 'Ask a question',
      allowMultiple: 'Allow multiple answers',
      votes: {
        single: 'Vote',
        multiple: 'Votes',
      },
      option: 'Option',
      optionsLabel: 'Poll options',
      anonymus: 'Anonymus poll',
      create: 'Create',
    },
  },
  {
    key: 'ITA',
    key2: 'it',
    responseHandler: {
      messageLabel: `Errore sconosciuto. Si prega di contattare ${support_email}.`,
      expiredLinkLabel: 'Il collegamento è scaduto. Richiedere la modifica di una nuova password.',
      emailExistLabel: 'Spiacenti, questo indirizzo e-mail è già registrato.',
      profileUpdated: 'Profilo aggiornato',
      profileUpdatedCorpsError: `Profilo aggiornato! Tuttavia, il seguente ${
        CorpsName('ITA').single
      } non ha potuto essere aggiunto a causa di un codice di accesso errato:`,
      appNeedsUpdate: "Aggiornare l'applicazione nell'App Store / Google Play.",
      maximalVideoSize: 'La dimensione massima del file è di 2 GB!',
    },
    report: {
      notBelongLabel: `L'utente non appartiene più ad un ${CorpsName('ITA').single}`,
      incProfileLabel: "L'utente fa offerte di profilo non corrette",
      badPicLabel: "L'immagine del profilo dell'utente viola le linee guida",
      badComLabel: "I commenti dell'utente violano le linee guida",
      spamLabel: 'The user sends spam in the chat',
      reasonsLabel: "L'utente invia spam nella chat",
    },
    drawer: {
      news: 'Notizie',
      kreise: KreiseName('ITA').multi,
      search: 'Ricerca',
      calendar: CalendarName('ITA'),
      contacts: 'Contatti',
      documents: `${DocumentsName('ITA')}`,
      requests: 'Richieste',
      messages: MessagesName('ITA'),
      profile: ProfileName('ITA'),
      logout: 'Logout',
      map: 'Mappa',
      mentoring: 'Mentoring',
    },
    imageCropper: {
      cropLabel: 'Ritaglio',
      cancelLabel: 'Annulla',
      chooseLabel: 'Scegliere',
    },
    signIn: {
      register: 'Registra',
      title: 'Accedere prego',
      loginPlaceholder: 'Indirizzo email',
      passwordPlaceholder: "Parola d'ordine",
      forgotPassword: 'Ha dimenticato la password?',
      loginBtn: 'Accesso',
      noAccount: 'Non hai ancora un conto?',
      description0: 'Se avete domande, non esitate a contattarci a',
      description1: 'o',
      description2: '.',
      loginValidation: 'Il tuo indirizzo e-mail non è corretto',
      passwordValidation: 'La password deve essere lunga almeno 6 caratteri',
    },
    search: {
      name: 'Cognome',
      corps: CorpsName('ITA').single,
      country: CountryName('ITA'),
      city: 'Città',
      industry: IndustryName('ITA'),
      company: 'Company',
      profession: 'Lavoro',
      hobby: 'Interessi',
      cultural: 'Sponsor culturali',
      study: 'Formazione scolastica',
      office: 'Ufficio',
      degreeProgram: 'Programma di laurea',
      university: 'Università',
      position: FunctionName('ITA'),
      status: 'Status',
      title: 'Risultato della ricerca per:',
      filterTitle: 'Filtra dopo',
      global: 'Tutti',
      activity: ActivityName('ITA'),
    },
    resetPassword: {
      enterEmail: 'Inserisci il tuo indirizzo email',
      inputPlaceholder: 'Indirizzo email',
      sendBtn: 'Spedire',
      cancelBtn: 'Abortire',
      message: "Se conosciamo l'indirizzo e-mail, è stato inviato un collegamento lì.",
      desc: 'È necessario inserire l`indirizzo e-mail esatto memorizzato nell`app. Se necessario, controllate le vostre e-mail per verificare quale sia.',
    },
    password: {
      resetLabel: 'Inserisci una nuova password',
      inputLabel: 'Inserisci una nuova password',
    },
    signUp: {
      createAccount: 'Creare un profilo',
      inputPlaceholder: `Il codice di accesso del tuo ${CorpsName('ITA').single}`,
      sendBtn: 'Continua',
      descFirst: 'Registrandoti, accetti il nostro ',
      descSecondo: 'e confermare che avete letto il nostro',
      descThird: `Se avete domande, non esitate a contattarci a ${support_email} o +49 170 49 805`,
      terms: 'termini e condizioni',
      policy: 'politica sulla privacy.',
      description: 'Nota',
      generatePassword: 'Generate password',
      noCodeSuccessRegister:
        'Grazie per la vostra registrazione! Sarete informati via e-mail non appena sarete stati attivati.',
    },
    signUpDefault: {
      withoutCode: 'Senza codice',
      createAccount: 'Basta registrarsi qui',
      withCode: "Con codice d'invito",
      simpleRegister: "Senza codice d'invito",
      existingAccount: 'Conto già disponibile?',
      descr01: "Sono d'accordo con il",
      descr0: "Registrandosi, l'utente accetta il nostro",
      descr1: 'e confermate di aver letto e compreso il nostro.',
      descr2: 'Se avete domande.',
      guest: 'Registrieren als Gast',
      withCodeWithDescr: `Registrieren als ${member_naming_singular}`,
    },
    signUpManual: {
      title: 'Registrare',
      photoDesc: 'Aggiungere una foto',
      genderInput: 'Genere',
      firstName: 'Nome di battesimo',
      lastName: 'Cognome',
      club: CorpsName('ITA').single,
      specify: 'per favore specificare',
      emailPlaceholder: 'E-mail',
      emailHint: "Utilizzate l'indirizzo e-mail che utilizzate da tempo.",
      repeatEmailPlaceholder: 'Conferma E-mail',
      passwordPlaceholder: 'Per impostare una password',
      repeatPasswordPlaceholder: 'Ripeti la password',
      postcode: 'Codice postale',
      place: 'Residenza',
      country: 'Nazione',
      loginCode: "Codice d'invito",
      mobilePlaceholder: 'Mobile (non visibile nel profilo)',
      corps: SignUpCorpsName('ITA'),
      searchCountry: 'Suche nach Land',
    },
    backToLogin: {
      desc: 'Hai già un account? ',
      login: 'Accesso',
    },
    backToSignUp: {
      desc: 'Non hai ancora un conto? ',
      register: 'Registra',
    },
    privacy: {
      desc: 'Sì, accetto i ',
      btn: 'termini e le condizioni',
      zu: '',
      map: {
        title: 'Avviso di protezione dei dati',
        description:
          'Questa applicazione è stata implementata in conformità con il GDPR. Per la visualizzazione della mappa utilizziamo esclusivamente Google Maps. Inoltre, le puntine sono visualizzate solo in modo approssimativo, in base al codice postale/città ma senza via/numero civico. Ciò significa che non è possibile trarre conclusioni sugli indirizzi specifici degli utenti.',
      },
      restrictions: {
        overall: 'Diese Funktion ist nur für Mitglieder zugänglich.',
        overallWilkar: 'Diese Funktion ist aus Datenschutzgründen nicht zugänglich.',

        profile:
          'Nur Mitglieder können Nutzerprofile ansehen. Du kannst dem Nutzer aber eine private Nachricht schreiben.',
        groupchat: `Die ${KreiseName('GER').single} sind nur für Mitglieder zugänglich.`,
        groupchatWilkar: `Aus Datenschutzgründen die ${KreiseName('GER').single} sind nur für Mitglieder zugänglich.`,
        comments: 'Kommentare sind nur für Mitglieder einsehbar.',
        commentsWilkar: 'Aus Datenschutzgründen kommentare sind nur für Mitglieder einsehbar.',
        likes: 'Die Liste der Likes sind nur für Mitglieder einsehbar.',
        post: 'Nur Mitglieder können Beiträge verfassen.',
        participantsList: 'Die Teilnehmerliste ist nur für Mitglieder einsehbar.',
        commentsOnlyForMembers: 'Kommentare sind nur für Mitglieder einsehbar.',
        postsLimitation: 'Ältere Beiträge sind nur für Mitglieder einsehbar.',
      },
      settings: {
        guestPost: {
          title: 'Verifizierte Gäste können meine Beiträge sehen',
          ask: 'Immer fragen',
          no: 'Nein',
          yes: 'Ja',
        },
      },
      guestModal: {
        title: 'Dürfen Gäste diesen Beitrag sehen? Du kannst diese Einstellung im Nachhinein auch noch ändern.',
        dontAskAgain:
          'Nicht erneut fragen (Du kannst diese Einstellung unter “Profil” → “Privatsphäre” auch wieder ändern).',
      },
      post: {
        ifShown: `Für ${guest_naming_plural} verbergen`,
        ifHidden: `Für ${guest_naming_plural} anzeigen`,
        ifShownModal: `Der Beitrag ist jetzt für ${guest_naming_plural} nicht mehr sichtbar.`,
        ifHiddenModal: `Der Beitrag ist jetzt für ${guest_naming_plural} sichtbar.`,
      },
    },
    searchModal: {
      landTitle: 'Scegliere un nazione',
      corpsTitle: `Seleziona il ${CorpsName('ITA').single}`,
      pleaseSelect: 'Selezionare',
    },
    btn: {
      sendBtn: 'Spedire',
      cancelBtn: 'Abortire',
      select: 'Selezionare',
      cancelEventBtn: acceptEventName('ITA').cancel,
      acceptBtn: acceptEventName('ITA').accept,
      saveBtn: 'Salva',
      clearBtn: 'Cancella',
      acceptReqBtn: 'Confermare',
      declineReqBtn: 'Diminuire',
      updateBtn: 'Aggiorna',
      declineBtn: 'Declino',
      yesUnderstood: 'Ja, verstanden',

      ok: 'OK',
      cancel2: 'Abortire',
    },
    validation: {
      email: 'Il tuo indirizzo e-mail non è corretto',
      password: 'La password deve essere lunga almeno 6 caratteri',
      repeatEmail: "L'e-mail e la ripetizione dell'e-mail non corrispondono",
      repeatPassword: 'Password e ripetizione non corrispondono',
      required: 'Questo campo è obbligatorio',
      matchPasswords: 'La vecchia e la nuova password corrispondono',
      dontMatchPasswords: 'Password e ripetizione non corrispondono',
    },
    init: {
      title: 'Caricamento',
      overlayLabel: 'Si prega di controllare la connessione internet',
    },
    screenTitles: {
      postsTitle: 'Notizie',
      circlesTitle: KreiseName('ITA').multi,
      searchTitle: 'Ricerca',
      profileTitle: ProfileName('ITA'),
      eventTitle: CalendarName('ITA'),
      eventDetailsTitle: 'Evento',
      newEventTitle: 'Aggiungi evento',
      contactsTitle: 'Contatti',
      contactReqTitle: 'Richieste di contatto',
      reqDetailsTitle: 'Dettagli della richiesta',
      outReqTitle: 'Richiesta di contatto',
      messagesTitle: MessagesName('ITA'),
      changePasswordTitle: 'Cambiare la password',
      appInfoTitle: 'Info',
      reportTitle: 'Segnala utente',
      createKreiseTitle: 'Gruppenchat erstellen',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      user: 'Users',
      mainTitleLabel: ProfileName('ITA'),
      basisLabel: 'Base',
      clubLabel: CorpsName('ITA').single,
      clubLabelPlural: CorpsName('ITA').multi,
      professionLabel: 'Lavoro',
      studyLabel: StudyName('ITA').short,
      uploadPhotoLabel: 'Carica una foto',
      editPhotoLabel: 'Modifica foto',
      profileCompletedLabel: 'Profilo',
      profileCompletedGLabel: 'completato',
      birthday: 'Data di nascita',
      functionLabel: 'Funzione',
      dateOfJoinLabel: 'Tempo di adesione',
      positionDescLabel: 'Profession',
      companyLabel: CompanyName('ITA'),
      nameLabel: 'Cognome',
      websiteLabel: 'Sito web aziendale',
      fromLabel: 'A partire dal',
      toLabel: 'Per',
      delete: 'cancellare',
      untilToday: 'Fino ad oggi',
      degreeLabel: StudyName('ITA').abshchluss,
      miscellaneos: 'Altro',
      hobbies: 'Interessi',
      cultural: 'Sponsor culturali',
      positionLabel: 'Posizione',
      typeOfEmplLabel: 'Tipo di impiego',
      activityLabel: ActivityName('ITA'),
      careerStageLabel: 'Livello di carriera',
      activityDescLabel: 'Descrizione del lavoro',
      industryLabel: IndustryName('ITA'),
      areaLabel: 'La zona',
      employmentPeriodLabel: 'Periodo di impiego',
      universityLabel: 'Università',
      courseLabel: 'Corso di studio',
      firmaLabel: 'Firma',

      ageLabel: 'Età',
      birthdayLabel: 'Compleanno',
      editProfileLabel: 'Modifica profilo',
      addContactLabel: 'Aggiungi ai contatti',
      sendMessageLabel: 'Invia messaggio',
      requestSentLabel: 'Invia richiesta di amicizia',
      sendRequestSuccessLabel: 'Richiesta inviata con successo',
      reportSentLabel: 'Utente già segnalato',
      addReportLabel: 'Inviare il rapporto',
      contactsLabel: 'Contatti',
      showBirthday: 'I contatti vedono il mio compleanno',
      street: 'Via + Nc. ',

      partner: 'Nome del partner',
      mobileNumberLabel: 'Numero di cellulare',
      handphoneNumberLabel: 'Numero di telefono cellulare',
      telephoneNumberLabel: 'Numero di telefono',
      leibbursch: 'Mentore',
      leibfuechse: 'Mentee',

      bio: 'Über mich',
      addDocuments: 'Dokumente zu dir hinzufügen',
      image: 'Bild',
      video: 'Video',
      pdf: 'PDF',
      link: 'Link',
      guest: 'Ospite',
      firstName: 'Nome',
      lastName: 'Cognome',
      title: 'Titolo',
      email: 'E-Mail',
      name: 'Nome',
      lastname: 'Cognome',
      country: CountryName('ITA'),
      wohnort: 'Città',
      postcode: 'CAP',
      inReview: 'Confirmation via mail pending',
    },
    calendar: {
      birthdayLabel: 'Compleanno del',
      noEventsLabel: 'Non ci sono eventi per la data selezionata',
      allTabLabel: 'Tutti',
      myTabLabel: 'I miei eventi',
      clubsTabLabel: `${CorpsName('ITA').single}`,
      subsTabLabel: 'Abbonamenti',
      subscribedTabLabel: 'Iscritto',
      otherTabLabel: 'Altri',

      error: 'The end date must be later than the start date',
      filter: {
        all: 'All',
        invited: 'Invited',
        confirmed: 'Confirmed',
      },
    },
    eventLabels: {
      inviteTo: 'Invito a',
      addEndDate: 'Inserire la data e l`ora di fine',
      eventLabel: 'Evento',
      startLabel: 'Inizia a',
      locationLabel: 'Posizione',
      participantLabel: 'Partecipanti',
      moreInfo: 'Ulteriori informazioni',
      commentsLabel: 'Commenti',
      noCommentsLabel: 'Non ci sono ancora commenti',
      eventStartLabel: 'Iniziare',
      eventEndLabel: 'Fine',
      descLabel: 'Descrizione',
      creatorLabel: 'Creato da',
      titleLabel: 'Titolo',
      deleteEventTitle: 'Cancellare un evento?',
      deleteEventLabel: 'Cancellare questo evento?',
      clubsTitle: CorpsName('ITA').single,
      gruppenchat: 'Selezionare la chat di gruppo',
      contactsTitle: 'Contatti',
      delCommentLabel: 'Cancellare questo commento?',
      sendInvite: "Inviare l'invito a",
      selectCorps: 'Selezionare il pubblico di riferimento',
      externalGuest: 'Ospiti esterni invitati',
      externalGuestPlaceholder: 'Inserire l`indirizzo di posta elettronica',
      attachmentLabel: 'Allegato',
      shareLinkLabel: 'Condividi il link',
      linkLabel: "Registrazione dell'ospite",
      copyLinkLabel: 'Copiare il link',
      iCalDownload: 'File calendario',
      timezone: 'Fuso orario',
      visibility: 'Sichtbarkeit',
      errorDate: 'La data è nel passato',
      startAfterEndError: 'La data di fine deve essere successiva a quella di inizio',
      members: 'Members',
      waitlist: 'Warteliste',
      onWaitlist: 'auf der Warteliste',
      leaveWaitlist: 'Platz freigeben?',
      leaveWaitlistDesc: `Von Warteliste entfernen?\n\n Wenn du dich später wieder auf die Warteliste setzt, landest du ggf. auf einer hinteren Position.`,
      limitAchieved: (limit: number) =>
        `Die maximale Teilnehmeranzahl von ${limit} ist erreicht. Du wurdest auf die Warteliste gesetzt.`,
      visibilityOptions: {
        allWithoutGuests: 'Mitglieder und auf Webseite',
        invited_member: 'Nur Mitglieder',
        verified_member: 'Alle Mitglieder',
        authorized_member: 'Mitglieder und verifizierte Gäste',
        all: 'Mitglieder, Gäste und auf Webseite',
      },
      modalEvent: {
        title: 'Evento creato',
        subTitle: 'L`evento è stato creato.\n\n',
        content:
          'A seconda delle impostazioni dell`utente, verranno inviate notifiche push/mail per l`evento. \n\nSe è stato impostato in "Profilo" → "Notifiche", l`utente riceverà anche una notifica via e-mail dell`evento.',
      },
      modalComment: {
        title: 'Chi lo comunica?',
        message: 'Chi deve essere informato del suo commento?',
        optionAll: 'Tutti i partecipanti',
        optionNone: 'Solo organizzatore/i',
        button: 'Invia un commento',
      },
      cancelModal: {
        title: 'Cancellare o eliminare?',
        message: 'Cancellare o eliminare l`evento?',
        optionAll: 'Annullare (i partecipanti saranno informati)',
        optionNone: 'Cancellare (i partecipanti non saranno informati)',
      },
      updateEventModal: {
        title: 'Invio di ulteriori inviti',
        label: 'Gli altri gruppi sono stati invitati.',
        message:
          'A seconda delle impostazioni, le notifiche push o le notifiche e-mail sull`evento vengono ora inviate agli altri gruppi.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/Mailadresse',
        enterLink: 'Aggiungi link/indirizzo e-mail o simili',
      },
      parts: {
        part: 'Part',
        addPart: 'Add program point',
        hint: 'Explanation',
        question: 'Question',
        single: 'Only one option selectable',
        multi: 'Several options available',
        freeText: 'Free text answer',
        quiz: 'Quiz',
        questionnaire: 'Questionnaire',
        addQuestionnaire: 'Add Questionnaire',
        questionnaireType: 'Answer options',
        option: 'Option',
        addOption: 'Add option',
        showMembers: 'Participants',
        youJoined: 'You take part in:',
        totalToPay: 'Total amount to be paid:',

        yourAnswer: 'Your answer',
        visibleForCreators: 'Visible only for creators',
        allAnswers: 'All answers:',
        addUsers: 'Add users',
        isAnonym: 'Anonymous voting',
        isAnonymQuiz: 'Anonymes Quiz',
      },
    },
    rrEventLabels: {
      title: 'Wiederholung',
      MO: 'Montag',
      TU: 'Dienstag',
      WE: 'Mittwoch',
      TH: 'Donnerstag',
      FR: 'Freitag',
      SA: 'Samstag',
      SU: 'Sonntag',
      YEARLY: { single: 'Jahr', regular: 'Jährlich' },
      MONTHLY: { single: 'Monat', regular: 'Monatlich' },
      WEEKLY: { single: 'Woche', regular: 'Wöchentlich' },
      DAILY: { single: 'Tag', regular: 'Täglich' },
      SINGLE: { single: 'Einmalig', regular: 'Einmalig' },
      CUSTOM: {
        single: 'Benutzerdefiniert...',
        regular: 'Benutzerdefiniert...',
      },
      freq: 'Frequenz',
      interval: 'Intervall',
      notRepeat: 'Einmalig',
      every: 'Jeden',
      month: 'monat',
      week: 'woche',
      last: 'letzten',
      on: 'am',
      everyMonthOn: 'Jeden Monat am',
      ofTheMonth: 'des Monats',
      editLabel: 'Welche Termine willst du ändern?',
      thisDate: 'Nur diesen Termin',
      thisDateAndFollowing: 'Diesen und alle folgenden',
      allDates: 'Alle Termine',
      after: 'After',
      time: 'time',
      times: 'times',
      neverEnds: 'Never ends',
      until: 'until the',
      repeatedDefault: ({ frequency, freq }) =>
        `Wird jede${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} wiederholt`,
      repeatedMonthly: ({ day, interval }) =>
        `Wird jeden${interval == 1 ? '' : ` ${interval}.`} Monat am ${day.length > 2 ? day : `${day}.`} wiederholt.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }) =>
        `Wird jeden ${bysetpos == -1 ? ' letzten' : `${bysetpos}.`} ${day} des ${
          interval === 1 ? '' : `${interval}.`
        } Monats wiederholt.`,

      repeatedYearly: ({ interval }) => `Wird jede ${interval == 1 ? '' : `${interval}.`} Jahr wiederholt.`,
      repeatedWeekly: ({ interval, byweekday }) =>
        `Wird jede${interval != 1 ? ` ${interval}.` : ''} Woche${
          !!byweekday?.length
            ? ' am ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' und ' : '') +
              byweekday?.slice(-1)
            : ''
        } wiederholt.`,
      repeatedDaily: ({ interval }) => `Wird jeden${interval != 1 ? ` ${interval}.` : ''} Tag wiederholt.`,
    },
    requestsLabels: {
      receiveLabel: 'Ricevuto',
      sentLabel: 'Inviato a',
    },
    post: {
      adTitle: 'Information/Advertisement',
      toKreise: 'a',
      comment: 'Commenti',
      myPost: 'Me',
      openPdf: 'Clicca per aprire',
      deletePost: 'Cancella questo post?',
      posted: 'Pubblicato',
      commented: 'Commentato',
      contacts: 'Contatti',
      sendToContacts: 'Messaggio a tutti',
      enterText: 'Schreibe hier eine Nachricht',
      sendToCircle: `Inserire il messaggio in questo ${KreiseName('ITA').single}`,
      sendComment: 'Inserisci il tuo commento',
      longMessage: '20.000 caratteri al massimo',
      noComments: 'Non ci sono ancora commenti',
      deleteComment: 'Cancellare il commento?',
      deleteEventPost: "Cancellare questo post? Questa operazione non cancella l'evento.",
      editPostLabel: 'Edit post',
      editingPostLabel: 'Editing mode',
      deletePostLabel: 'Delete post',
      editedAt: 'Edited',
      pinned: 'Angepinnt.',
      actions: {
        label: 'Aktionen posten',
        pin: {
          label: 'Beitrag anpinnen',
          once: 'Einmalig nach oben rücken',
          d3: '3 Tage',
          d5: '5 Tage',
          w1: '1 Woche',
          w2: '2 Wochen',
          unpin: 'Nicht mehr anpinnen',
        },
      },
    },
    circles: {
      circlesTitle: `${KreiseName('ITA').multi} Cerchi`,
      noCirclesTitle: `${KreiseName('ITA').multi} non cerchi`,
      myPost: 'Me',
      circlesInfoTitle: 'Info & Membri',
      circlesSubscribe: 'Abbonarsi a',
      unsubscribeAlert: 'Lasciare irrevocabilmente la chat di gruppo chiusa?',
      continueAlert: 'Lasciare',
      readOnlyKreise: `Questa è una chat di ${
        KreiseName('ITA').single
      } di sola lettura. Solo gli amministratori possono postare qui. È tuttavia possibile scrivere commenti sui messaggi".`,
    },
    messages: {
      addMessageLabel: 'Inserire il messaggio',
      changesSaved: 'Changes saved!',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: 'Base',
      clubsTab: CorpsName('ITA').single,
      miscellaneosTab: 'Altro',
      professionTab: 'lavoro',
      studyTab: StudyName('ITA')?.short,
      notificationsTab: 'Notifiche',
      privacy: 'Privatsphäre',
    },
    profile: {
      addPhotoLabel: 'Aggiungere una foto',
      genderInputLabel: 'Genere',
      titleLabel: 'Titel',
      firstNameLabel: 'Nome di battesimo',
      lastNameLabel: 'Cognome',

      passwordLabel: 'Per impostare una password',
      birthDateLabel: 'Data di nascita',

      changePasswordLabel: 'Cambiare la password',
      defaultCountryLabel: 'Germania',
      defaultGraduationLabel: 'Nessuna informazione',
    },
    profileKorp: {
      verband: CorpsName('ITA').single,
      function: FunctionName('ITA'),
      dateOfJoin: CorpsEditDate('ITA'),
      beername: 'Biername',
    },
    profileClubs: {
      noUserLabel: "Tutte le informazioni di base devono essere compilate prima di poter utilizzare l'app.",
      functionLabel: 'Funzione',
      setClubLabel: `Indicare il nome del ${CorpsName('ITA').single}.`,
      matchClubLabel: 'Il',
      matchClubContinueLabel: 'esiste già nel profilo, si prega di controllare di nuovo.',
      clubLabel: CorpsName('ITA').single.toUpperCase(),
      clubCodeLabel: `Il codice di accesso del tuo ${CorpsName('ITA').single.toUpperCase()}`,
      entryTimeLabel: CorpsEditDate('ITA'),
      deleteClubLabel: 'Sei sicuro di voler cancellare la tua iscrizione a',
      deleteClubContinueLabel: '?',
      selectVariant: 'Selezionare la variante',
      requestExistingCorp: 'Richiesta di un`azienda esistente',
      requestNewCorps: 'Richiesta di un nuovo corpo',
      createCorp: 'Creare',
    },
    profileProfession: {
      jobTitle: 'Titolo del lavoro',
      noUserLabel: "Tutte le informazioni di base devono essere compilate prima di poter utilizzare l'app.",
      untilToday: 'Fino ad oggi',
      positionTitleLabel: 'Posizione',
      itemNameLabel: 'Profession',
      employmentLabel: 'Tipo di impiego',
      activityLabel: ActivityName('ITA'),
      careerStageLabel: 'Livello di carriera',
      activityDescLabel: 'Descrizione del lavoro',
      companyLabel: CompanyName('ITA'),
      nameLabel: 'Cognome',
      industryLabel: IndustryName('ITA'),
      areaLabel: 'La zona',
      websiteLabel: 'Sito web aziendale',
      employmentPeriodLabel: 'Periodo di impiego',
      fromLabel: 'A partire dal',
      toLabel: 'Per',
    },
    profileStudy: {
      noUserLabel: "Tutte le informazioni di base devono essere compilate prima di poter utilizzare l'app.",
      universityLabel: StudyName('ITA').hochsule,
      courseLabel: StudyName('ITA').studiengang,
      degreeLabel: StudyName('ITA').abshchluss,
    },
    profileOther: {
      noUserLabel: "Tutte le informazioni di base devono essere compilate prima di poter utilizzare l'app.",
      hobbiesLabel: 'Interessi',
      culturalLabel: 'Sponsor culturali',
      socialMediaPlattform: 'Social-Media-Plattform',
      socialMediaLabel: 'Social Media Links',
      socialMediaLink: 'Link',
      socialMediaError: 'Inserire l`URL completo',
    },
    profileNotifications: {
      noUserLabel: "Tutte le informazioni di base devono essere compilate prima di poter utilizzare l'app.",
      notificationsInfo:
        'Qui puoi attivare le notifiche via email per le singole attività (default) o disattivarle per 3 mesi.',
      notificationsTitle: 'Notifiche push:',
      notificationsDescr0: 'È possibile impostare le notifiche push nelle impostazioni di ',
      notificationsDescr1: 'sistema dello smartphone.',
      notificationsDescr2: '',
      mailingTitle: 'Notifiche via e-mail:',
      mailingDescr:
        'Qui è possibile attivare le notifiche via e-mail per le singole attività (impostazione predefinita) o disattivarle per 1 anno.',
      allNotificationsLabel: 'Tutte le notifiche',
      postsLabel: `Messaggi nelle ${KreiseName('ITA').multi}`,
      messagesLabel: 'Messaggi privati',
      commentsLabel: 'Commenti sui propri contributi',
      contactRequestsLabel: 'Richieste di contatto',
      eventsLabel: 'Eventi',
      clubMemberLabel: 'Nuovi membri registrati',
      enableLabel: 'Su',
      disableToLabel: 'Spento fino a quando',
      disableLabel: 'Da',
      ownPosts: 'Meine Beiträge (Mail auch selbst erhalten)',
    },
    searchInput: {
      title: 'Ricerca',
      placeholder: 'Ricerca',
    },
    emptyList: {
      title: 'Qui non ci sono contenuti (ancora)',
    },
    switchLanguage: {
      placeholder: 'Lingua/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'russian (русский)',
      SP: 'Spanish (Español)',
      title: 'Scegliere la lingua',
    },
    filePicker: {
      takePicLabel: 'Scatta la foto',
      uploadPicLabel: 'Carica foto',
      selectPicLabel: 'Seleziona la foto/video',
      selectDocLabel: 'Seleziona il documento',
    },
    datePicker: {
      selectDateLabel: 'Seleziona la data',
      confirmDateLabel: 'Confermare la data',
      selectTimeLabel: "Seleziona l'ora",
    },
    changePassword: {
      currentPasswordLabel: 'Password attuale',
      newPasswordLabel: 'Nuova password',
      repeatPasswordLabel: 'Ripetere la password',
      changePasswordLabel: 'Cambiare la password',
    },
    appInfo: {
      appDescLabel:
        "Korponnect è l'app per gli studenti della confraternita. Scambio tra aziende, organizzazione di eventi e networking professionale e privato: tutto in un'unica app.",
      contactsLabel: InfoContactsName('ITA'),
      infoLabel: 'Informazioni',
      versionLabel: "Versione dell'app",
      agbLabel: 'AGB',
      dataProtectionLabel: 'Dichiarazione di protezione dei dati',
      manualLabel: 'Manuale',
      appTextLabel: 'Potete trovarci qui',
      appAndLabel: 'e un',
      appMainTextLabel: `Vuoi aggiungere una cerchia o hai un altro suggerimento? Vuoi eliminare il tuo profilo? Scrivici una breve email a ${support_email}.`,
      deleteFeedback: 'Motivo dell`eliminazione dell`account / feedback 🙂',
      deleteUserTitle: `Eliminare l'account?`,
      deleteUserDesc: `Qui puoi eliminare irrevocabilmente il tuo account.\b A proposito: puoi anche semplicemente disattivare le notifiche.Modifica le impostazioni in profilo -> Notifiche o scrivici un'e -mail.`,
      deleteUserAlert: 'Tutti i tuoi dati verranno eliminati per sempre, continua?',
      webLabel: 'Versione web',
    },
    infoMessages: {
      registartionSuccessLabel: 'Grazie per esserti registrato!',
      manualRegSuccessLabel: 'Grazie per esserti registrato! Il profilo sarà attivato a breve.',
      linkExpiredLabel: 'Il collegamento è scaduto. Richiedere la modifica di una nuova password.',
      emailExistLabel: "Spiacenti, l'e-mail non è disponibile.",
    },
    logoutAlert: {
      title: 'Disconnettersi',
      text: "Volete davvero disconnettervi? Non è necessario disconnettersi, è sufficiente chiudere o ridurre a icona l'applicazione.",
      confirm: 'Si',
      dismiss: 'No',
    },
    invalidInputs: {
      passwordChars: `La password deve contenere almeno ${passwordDigits(
        'ITA',
      )} caratteri, di cui almeno una lettera, un numero e un carattere speciale.`,
      fieldRequired: 'Questo campo è obbligatorio.',
      emailsNotMatching: 'Assicuratevi che le vostre e-mail corrispondano',
      emailInvalid: "L'indirizzo e-mail non è corretto!",
      selectCorrectAnswer: 'You need to select correct answer',
      corp: 'Selezionare almeno 1 voce',
    },
    mentoring: {
      profile: {
        rules1: "Sono d'accordo con il",
        rules2: ' Comprensione dei valori ',
        rules3: '"Mentoring for Scholars" a.',
        menteeDescription:
          'Selezionate la vostra regione di tutoraggio per essere informati sugli eventi in loco. NOTA: è possibile modificare la selezione in qualsiasi momento. NON limita la ricerca di mentori.',
        mentorDescription:
          'Compilate il vostro profilo di mentore in modo che i borsisti possano trovarvi come mentori. NOTA: sono richieste almeno 1 regione e almeno 1 area tematica.',
        mentoringRegions: 'Regioni di mentoring',
        reigonsShort: 'Regioni',
        topics: 'Argomenti',
        departments: 'Dipartimento',
        available: 'Sono disponibile',
        unavailable: 'Al momento non sono disponibile',
        deleteAccount: 'Cancellare il profilo Ment. e lasciare il gruppo',
        deleteAccountDescr: 'Cancellare il profilo del mentore e lasciare la chat del gruppo mentori?',
        uploadPdf: 'Profilo del mentore (PDF)',
        myPdf: 'Il mio profilo (PDF)',
        pdf: 'Mentor Profile',
      },
      tabNames: {
        info: 'Info',
        news: 'Notizie',
        profile: 'Profilo',
        search: 'Ricerca e richiesta',
        request: 'Mentore',
        requestDetails: 'Richiesta di mentoring',
        tabDeactivated: 'Scheda disattivata. Compilare il profilo di mentore.',
      },
      search: {
        description:
          'Per avviare la ricerca in modo mirato, si consiglia di iniziare con alcuni campi di selezione e poi ampliare la griglia di ricerca in una nuova ricerca. Se non trovate un mentore adatto nella vostra regione, allargate la ricerca a una regione vicina. Criteri di ricerca: 1 regione, da 1 a 3 campi tematici',
        searchBtn: 'Ricerca',
      },
      requests: {
        mentorIncoming: 'Le mie richieste di mentoring',
        mentorCurrent: 'I miei tutoraggi',
        menteeOutgoing: 'La mia richiesta di mentoring',
        menteeCurrent: 'Il mio tutoraggio',
        cancelMentoring: 'Fine del tutoraggio',
        sendRequest: 'Richiesta come mentore',
        alreadyHaveMentor: 'Si può avere un solo mentore',
        requestSuccess: 'Richiesta inviata con successo',
        requestRetracted: 'Anfrage erfolgreich zurückgezogen',
        cancelSendingRequest: 'Annulla la richiesta del mentore',
        approveRequest: 'Accettare la richiesta',
        rejectRequest: 'Rifiutare il mentee',
        invitaionText: 'Richiesta',
        rejectionText: 'Motivare il rifiuto',
        sureRetractRequest: 'Ritirare davvero la richiesta?',
      },
    },
    downloads: {
      root: 'Casa',
      delete: 'Elimina',
      rename: 'Rinomina',
      areYouSureDeleteFolder: 'Eliminare davvero la cartella? Tutti i contenuti saranno cancellati!',
      renameFolder: 'Rinomina cartella',
      createNewFolder: 'Crea nuova cartella',
      newFolderPlaceholder: 'Inserisci il nome della cartella',
      create: 'Crea',
      areYouSureDeleteFile: '',
      downloadFile: 'Scarica',
      deleteFile: 'Elimina dal dispositivo',
      openInFinder: 'Apri in Finder',
      cancel: 'Annulla',
    },
    globalSearch: {
      postedBy: 'Pubblicato da',
      found: 'Trovato',
      loadMore: 'Carica altro',
      enterQuery:
        'Inserisci qualsiasi termine di ricerca. Puoi cercare qualsiasi cosa (nome, documento, evento, post, ...)',
      folder: 'Cartella',
      post: 'Post',
      error: 'Inserisci almeno 3 caratteri',
    },
    kreiseSettings: {
      description: 'Beschreibung',
      push: 'Push-Benachrichtigungen',
      email: 'Mail-Benachrichtigungen',
      subscribers: 'Mitglieder',
      active: 'Aktiviert',
      oneYear: 'Stumm für 1 Jahr',
      forever: 'Stumm für immer',
      until: 'Bis',
      cantDeactive: 'Für diesen Gruppenchat ist diese Einstellung deaktiviert',
      SINGLE: 'Für jeden Beitrag',
      SUMMARY_ONCE: 'Zusammenfassung 1x pro Woche',
      SUMMARY_TWICE: 'Zusammenfassung 2x pro Woche',
      SUMMARY_DAY: 'Zusammenfassung 1x pro Tag',
      OFF: 'Aus',
      PUSH: 'Push-Benachrichtigung',
      EMAIL: 'Mail-Benachrichtigung',
      PUSH_AND_EMAIL: 'Push- und Mailbenachrichtigung',
      newUser: 'Benachrichtigung für neue Nutzer',
      statistic: 'Sende-Statistik',
      off: 'Aus',
      ownPosts: 'Sende-Statistik-Mail für deine Beiträge',
      deleteMemberDescr: 'entfernen?',
      deleteMemberBtn: 'Entfernen',
      retractAdminRights: 'Rechte entziehen',
      deleteAdminAndUser: 'Ganz entfernen',
      toDeleteKreiseBtn: 'Gruppenchat löschen',
      deleteKreiseDescr: `${KreiseName('GER').single} unwiderruflich löschen?`,
      deleteKreiseModalTitle: 'Mitglied entfernen?',
      addKreiseName: 'Name',
      addKreiseDescription: 'Beschreibung',
      addMembers: 'Mitglieder hinzufügen',
      createKreise: 'Gruppenchat erstellen',
      toKreiseEditMessage: 'Hinweis: Alle Änderungen an den Mitgliedern werden sofort umgesetzt.',
      kreiseCreator: 'Ersteller:in dieses Gruppenchats',
      kreiseDeleteMembersLeft: 'Kreise can be deleted only if there is only one member left',
      kreiseDeleteMembersLeftTitle: 'Remove members first',
      suggestionInNewsfeed: {
        admin: 'Admin',
        member: 'Mitglieder',
        corp: `${CorpsName('GER').single} mitglieder`,
        corpAdmin: `${CorpsName('GER').single} admin`,
      },
    },
    surveys: {
      checkVotes: 'View results',
      chooseOptions: {
        single: 'Choose one option',
        multiple: 'Choose multiple options',
      },
      votesModalTitle: 'Votes',
      createModalTitle: 'Poll',
      noVotes: 'There are no votes',
      question: 'Question',
      askAQuestion: 'Ask a question',
      allowMultiple: 'Allow multiple answers',
      votes: {
        single: 'Vote',
        multiple: 'Votes',
      },
      option: 'Option',
      optionsLabel: 'Poll options',
      anonymus: 'Anonymus poll',
      create: 'Create',
    },
  },
  {
    key: 'FRA',
    key2: 'fr',
    responseHandler: {
      messageLabel: `Erreur inconnue. Veuillez contacter ${support_email}.`,
      expiredLinkLabel: 'Lien expiré. Demander un nouveau changement de mot de passe.',
      emailExistLabel: 'Désolé, cette adresse électronique est déjà enregistrée.',
      profileUpdated: 'Profil actualisé',
      profileUpdatedCorpsError: `Profil mis à jour ! Cependant, le ${
        CorpsName('FRA').single
      } suivant n'a pas pu être ajouté en raison d'un code de connexion incorrect :`,
      appNeedsUpdate: "Veuillez mettre à jour l'application dans l'App Store / Google Play.",
      maximalVideoSize: 'La taille maximale des fichiers est de 2 Go !',
    },
    report: {
      notBelongLabel: `L'utilisateur n'appartient plus à un ${CorpsName('FRA').single}`,
      incProfileLabel: "L'utilisateur fait des offres de profil incorrectes",
      badPicLabel: "La photo de profil de l'utilisateur enfreint les directives",
      badComLabel: "Les commentaires de l'utilisateur enfreignent les lignes directrices",
      spamLabel: "L'utilisateur envoie du spam dans le chat",
      reasonsLabel: 'Raisons de la déclaration',
    },
    drawer: {
      news: 'Nouvelles',
      kreise: KreiseName('FRA').multi,
      search: 'Recherchez',
      documents: `${DocumentsName('FRA')}`,
      calendar: CalendarName('FRA'),
      contacts: 'Contacts',
      requests: 'Demandes',
      messages: MessagesName('FRA'),
      profile: ProfileName('FRA'),
      logout: 'Déconnexion',
      map: 'Carte',
      mentoring: 'Mentorat',
    },
    imageCropper: {
      cropLabel: 'Culture',
      cancelLabel: 'Annuler',
      chooseLabel: 'Choisir',
    },
    signIn: {
      register: 'Registre',
      title: 'Veuillez vous connecter',
      loginPlaceholder: 'Adresse e-mail',
      passwordPlaceholder: 'Mot de passe',
      forgotPassword: 'Oublié votre mot de passe ?',
      loginBtn: 'Connexion',
      noAccount: 'Pas encore de compte ?',
      description0: "Si vous avez des questions, n'hésitez pas à nous contacter à l'adresse",
      description1: 'ou au',
      description2: '.',
      loginValidation: 'Votre adresse électronique est incorrecte',
      passwordValidation: 'Le mot de passe doit comporter au moins 6 caractères',
    },
    search: {
      name: 'Nom',
      corps: CorpsName('FRA').single,
      country: CountryName('FRA'),
      city: 'Ville',
      industry: IndustryName('FRA'),
      company: 'Société',
      profession: 'Profession',
      hobby: 'Hobby',
      cultural: 'Sponsor culturel',
      study: 'Étude',
      office: 'Bureau',
      degreeProgram: 'Programme de diplôme',
      university: 'Université',
      position: FunctionName('FRA'),
      status: 'Statut',
      title: 'Résultat de la recherche pour',
      filterTitle: 'Filtrer par',
      global: 'Tout',
      activity: ActivityName('FRA'),
    },
    resetPassword: {
      enterEmail: 'Veuillez entrer votre adresse électronique',
      inputPlaceholder: 'Adresse e-mail',
      sendBtn: 'Envoyer',
      cancelBtn: 'Annuler',
      message: "Si l'adresse électronique nous est connue, un lien lui a été envoyé.",
      desc: 'Tu dois saisir exactement l`adresse e-mail qui est enregistrée dans l`application. Vérifie éventuellement dans tes e-mails de quelle adresse il s`agit.',
    },
    password: {
      resetLabel: 'Entrez un nouveau mot de passe',
      inputLabel: 'Entrez un nouveau mot de passe',
    },
    signUp: {
      createAccount: 'Créer un compte',
      inputPlaceholder: `Code de connexion de votre ${CorpsName('FRA').single}`,
      sendBtn: 'Continuer',
      descFirst: 'En vous inscrivant, vous acceptez notre ',
      descSecond: 'et confirmez que vous avez lu notre',
      descThird: `Si vous avez des questions, n'hésitez pas à nous contacter à l'adresse ${support_email} ou au +49 170 49 805`,
      terms: 'conditions générales',
      policy: 'politique de confidentialité.',
      description: 'Remarque',
      generatePassword: 'Generate password',
      noCodeSuccessRegister:
        'Nous vous remercions de votre inscription ! Vous serez informé(e) par e-mail dès que vous aurez été activé(e).',
    },
    signUpDefault: {
      withoutCode: 'Sans code',
      createAccount: "Il suffit de s'inscrire ici",
      withCode: "Avec code d'invitation",
      simpleRegister: "Sans code d'invitation",
      existingAccount: 'Compte déjà disponible ?',
      descr01: "Je suis d'accord avec le",
      descr0: "En s'inscrivant, l'utilisateur accepte notre",
      descr1: 'et confirmez que vous avez lu et compris notre. ',
      descr2: '',
      guest: 'Registrieren als Gast',
      withCodeWithDescr: `Registrieren als ${member_naming_singular}`,
    },

    signUpManual: {
      title: 'Inscription',
      photoDesc: 'Ajouter une photo de profil',
      genderInput: 'Genre',
      firstName: 'Prénom(s)',
      lastName: 'Nom de famille',
      club: CorpsName('FRA').single,
      specify: 'veuillez préciser',
      emailPlaceholder: "l'adresse électronique",
      emailHint: "Veuillez utiliser l'adresse e-mail utilisée à long terme.",
      repeatEmailPlaceholder: "Répéter l'adresse E-mail",
      passwordPlaceholder: 'Définir le mot de passe',
      repeatPasswordPlaceholder: 'Répéter le mot de passe',
      postcode: 'Code postal',
      place: 'Lieu de résidence',
      country: 'Pays',
      loginCode: "Code d'invitation",
      mobilePlaceholder: 'Mobile (non visible dans le profil)',
      corps: SignUpCorpsName('FRA'),
      searchCountry: 'Suche nach Land',
    },
    backToLogin: {
      desc: 'Vous avez déjà un compte ? ',
      login: 'Login',
    },
    backToSignUp: {
      desc: 'Pas encore de compte? ',
      register: 'Registre',
    },
    privacy: {
      desc: "Oui, j'accepte la",
      btn: 'conditions générales',
      zu: '',
      map: {
        title: "Note d'information sur la protection des données",
        description:
          "Cette application a été implémentée en conformité avec le RGPD. Nous utilisons Google Maps uniquement pour la représentation de la carte. Aucune donnée personnelle n'est transmise.\n\nEn outre, les épingles ne sont représentées que grossièrement, sur la base du code postal/de la ville mais sans la rue/le numéro. Il n'est donc pas possible d'en déduire les adresses concrètes des utilisateurs.",
      },
      restrictions: {
        overall: 'Diese Funktion ist nur für Mitglieder zugänglich.',
        overallWilkar: 'Diese Funktion ist aus Datenschutzgründen nicht zugänglich.',

        profile:
          'Nur Mitglieder können Nutzerprofile ansehen. Du kannst dem Nutzer aber eine private Nachricht schreiben.',
        groupchat: `Die ${KreiseName('GER').single} sind nur für Mitglieder zugänglich.`,
        groupchatWilkar: `Aus Datenschutzgründen die ${KreiseName('GER').single} sind nur für Mitglieder zugänglich.`,
        comments: 'Kommentare sind nur für Mitglieder einsehbar.',
        commentsWilkar: 'Aus Datenschutzgründen kommentare sind nur für Mitglieder einsehbar.',
        likes: 'Die Liste der Likes sind nur für Mitglieder einsehbar.',
        post: 'Nur Mitglieder können Beiträge verfassen.',
        participantsList: 'Die Teilnehmerliste ist nur für Mitglieder einsehbar.',
        commentsOnlyForMembers: 'Kommentare sind nur für Mitglieder einsehbar.',
        postsLimitation: 'Ältere Beiträge sind nur für Mitglieder einsehbar.',
      },
      settings: {
        guestPost: {
          title: 'Verifizierte Gäste können meine Beiträge sehen',
          ask: 'Immer fragen',
          no: 'Nein',
          yes: 'Ja',
        },
      },
      guestModal: {
        title: 'Dürfen Gäste diesen Beitrag sehen? Du kannst diese Einstellung im Nachhinein auch noch ändern.',
        dontAskAgain:
          'Nicht erneut fragen (Du kannst diese Einstellung unter “Profil” → “Privatsphäre” auch wieder ändern).',
      },
      post: {
        ifShown: `Für ${guest_naming_plural} verbergen`,
        ifHidden: `Für ${guest_naming_plural} anzeigen`,
        ifShownModal: `Der Beitrag ist jetzt für ${guest_naming_plural} nicht mehr sichtbar.`,
        ifHiddenModal: `Der Beitrag ist jetzt für ${guest_naming_plural} sichtbar.`,
      },
    },
    searchModal: {
      landTitle: 'Choisissez un pays',
      corpsTitle: `Sélectionner un ${CorpsName('FRA').single}`,
      pleaseSelect: 'Veuillez choisir',
    },
    btn: {
      sendBtn: 'Envoyer',
      cancelBtn: 'Annuler',
      select: 'Sélectionnez',
      cancelEventBtn: acceptEventName('FRA').cancel,
      acceptBtn: acceptEventName('FRA').accept,
      saveBtn: 'Sauvegarder',
      clearBtn: 'Clair',
      acceptReqBtn: 'Confirmer',
      declineReqBtn: 'Déclin',
      updateBtn: 'Mise à jour',
      declineBtn: 'Refuser',
      yesUnderstood: 'Ja, verstanden',

      ok: 'OK',
      cancel2: 'Annuler',
    },
    validation: {
      email: 'Votre adresse électronique est incorrecte',
      password: 'Le mot de passe doit comporter au moins 6 caractères',
      repeatEmail: 'Le courriel et le courriel de relance ne correspondent pas',
      repeatPassword: 'Le mot de passe et la répétition ne correspondent pas',
      required: 'Ce champ est obligatoire',
      matchPasswords: "Correspondance entre l'ancien et le nouveau mot de passe",
      dontMatchPasswords: 'Le mot de passe et la répétition ne correspondent pas',
    },
    init: {
      title: 'Chargement',
      overlayLabel: 'Veuillez vérifier votre connexion Internet',
    },
    screenTitles: {
      postsTitle: 'Nouvelles',
      circlesTitle: KreiseName('FRA').multi,
      searchTitle: 'Recherchez',
      profileTitle: ProfileName('FRA'),
      eventTitle: CalendarName('FRA'),
      eventDetailsTitle: 'Événement',
      newEventTitle: 'Ajouter un événement',
      contactsTitle: 'Contacts',
      contactReqTitle: 'Demandes de contact',
      reqDetailsTitle: 'Demande de renseignements',
      outReqTitle: 'Demande de contact',
      messagesTitle: MessagesName('FRA'),
      changePasswordTitle: 'Changer de mot de passe',
      appInfoTitle: 'Info',
      reportTitle: 'Signaler un utilisateur',
      createKreiseTitle: 'Gruppenchat erstellen',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      user: 'Users',
      mainTitleLabel: ProfileName('FRA'),
      basisLabel: 'De base',
      clubLabel: CorpsName('FRA').single,
      clubLabelPlural: CorpsName('FRA').multi,
      professionLabel: 'Profession',
      studyLabel: StudyName('FRA').short,
      uploadPhotoLabel: 'Télécharger une photo',
      editPhotoLabel: 'Modifier la photo',
      profileCompletedLabel: 'Modifier la photo',
      profileCompletedGLabel: '',
      birthday: 'Date de naissance',
      functionLabel: 'Fonction',
      dateOfJoinLabel: 'Date de la pièce',
      positionDescLabel: 'Profession',
      companyLabel: CompanyName('FRA'),
      nameLabel: 'Nom',
      websiteLabel: 'Site web',
      fromLabel: 'Tiré de',
      toLabel: 'à',
      delete: 'supprimer',
      untilToday: "Jusqu'à aujourd'hui",
      degreeLabel: StudyName('FRA').abshchluss,
      miscellaneos: 'Miscellaneos',
      hobbies: 'Hobbies',
      cultural: 'Parrainage culturel',
      positionLabel: 'Position',
      typeOfEmplLabel: "Type d'emploi",
      activityLabel: ActivityName('FRA'),
      careerStageLabel: 'Étape de la carrière',
      activityDescLabel: "Description de l'activité",
      industryLabel: IndustryName('FRA'),
      areaLabel: 'Domaine',
      employmentPeriodLabel: "Période d'emploi",
      universityLabel: 'Université',
      courseLabel: "Cours d'études",
      firmaLabel: 'Firma',

      ageLabel: 'Âge',
      birthdayLabel: 'Anniversaire',
      editProfileLabel: 'Modifier le profil',
      addContactLabel: 'Ajouter aux contacts',
      sendMessageLabel: 'Envoyer un message',
      requestSentLabel: "Envoyer une demande d'ami",
      sendRequestSuccessLabel: 'Demande envoyée avec succès',
      reportSentLabel: 'Utilisateur déjà signalé',
      addReportLabel: 'Envoyer le rapport',
      street: 'Rue + Nr. ',

      partner: 'Nom du partenaire',
      mobileNumberLabel: 'Numéro de téléphone mobile',
      handphoneNumberLabel: 'Numéro de téléphone portable',
      telephoneNumberLabel: 'Numéro de téléphone',
      leibbursch: 'Mentor',
      leibfuechse: 'Mentee',

      contactsLabel: 'Contacts',
      showBirthday: 'Contacts Voir mon anniversaire',
      bio: 'Über mich',
      addDocuments: 'Dokumente zu dir hinzufügen',
      image: 'Bild',
      video: 'Video',
      pdf: 'PDF',
      link: 'Link',
      guest: 'Invité',
      firstName: 'Prénom',
      lastName: 'Nom de famille',
      title: 'Titre',
      email: 'E-Mail',
      name: 'Prénom',
      lastname: 'Nom de famille',
      country: CountryName('FRA'),
      wohnort: 'Ville',
      postcode: 'Code postal',
      inReview: 'Confirmation via mail pending',
    },
    calendar: {
      birthdayLabel: 'Anniversaire du',
      noEventsLabel: "Il n'y a pas d'événements pour la date sélectionnée",
      allTabLabel: 'Tous',
      myTabLabel: 'Mes événements',
      clubsTabLabel: `${CorpsName('FRA').single}`,
      subsTabLabel: 'Abonnements',
      subscribedTabLabel: 'Inscrit',
      otherTabLabel: 'Autres',

      error: 'La date de fin doit être postérieure à la date de début.',
      filter: {
        all: 'All',
        invited: 'Invited',
        confirmed: 'Confirmed',
      },
    },
    eventLabels: {
      inviteTo: 'Invitation à',
      addEndDate: 'Saisir la date/l`heure de fin',
      eventLabel: 'Événement',
      startLabel: 'Commence à',
      locationLabel: 'Lieu',
      participantLabel: 'Participants',
      moreInfo: 'Plus d`informations',
      commentsLabel: 'Commentaires',
      noCommentsLabel: 'Pas encore de commentaires',
      eventStartLabel: 'Démarrer',
      eventEndLabel: 'Fin',
      descLabel: 'Description',
      creatorLabel: 'Créé par',
      titleLabel: 'Titre',
      deleteEventTitle: 'Supprimer l`événement ?',
      deleteEventLabel: 'Supprimer cet événement?',
      clubsTitle: CorpsName('FRA').single,
      gruppenchat: 'Sélectionner le chat de groupe',
      contactsTitle: 'Contacts',
      delCommentLabel: 'Supprimer ce commentaire?',
      sendInvite: "Envoyez l'invitation à",
      selectCorps: 'Sélectionner le public cible',
      externalGuest: 'Invités externes',
      externalGuestPlaceholder: 'Saisir l`adresse e-mail',
      attachmentLabel: 'Pièce jointe',
      shareLinkLabel: 'Lien de partage',
      linkLabel: 'Inscrire un invité',
      copyLinkLabel: 'Copier le lien',
      iCalDownload: 'Fichier calendrier',
      timezone: 'Fuseau horaire',
      visibility: 'Sichtbarkeit',
      errorDate: 'La date se situe dans le passé',
      startAfterEndError: 'La date de fin doit être postérieure à la date de début',
      members: 'Members',
      waitlist: 'Warteliste',
      onWaitlist: 'auf der Warteliste',
      leaveWaitlist: 'Platz freigeben?',
      leaveWaitlistDesc: `Von Warteliste entfernen?\n\n Wenn du dich später wieder auf die Warteliste setzt, landest du ggf. auf einer hinteren Position.`,

      limitAchieved: (limit: number) =>
        `Die maximale Teilnehmeranzahl von ${limit} ist erreicht. Du wurdest auf die Warteliste gesetzt.`,
      visibilityOptions: {
        allWithoutGuests: 'Mitglieder und auf Webseite',
        invited_member: 'Nur Mitglieder',
        verified_member: 'Alle Mitglieder',
        authorized_member: 'Mitglieder und verifizierte Gäste',
        all: 'Mitglieder, Gäste und auf Webseite',
      },
      modalEvent: {
        title: 'Événement créé',
        subTitle: 'L`événement a été créé.\n\n',
        content:
          'Selon les paramètres de l`utilisateur, des notifications push/mail sont envoyées pour l`événement. \n\nSi vous l`avez configuré sous "Profil" → "Notifications", vous recevrez également une notification par e-mail de l`événement.',
      },
      modalComment: {
        title: 'Qui avertir ?',
        message: 'Qui doit être informé de ton commentaire ?',
        optionAll: 'Tous les participants',
        optionNone: 'Organisateur(s) uniquement',
        button: 'Envoyer un commentaire',
      },
      cancelModal: {
        title: 'Annuler ou supprimer ?',
        message: 'Annuler ou supprimer un événement ?',
        optionAll: 'Annuler (les participants sont informés)',
        optionNone: 'Supprimer (les participants ne sont pas informés)',
      },
      updateEventModal: {
        title: 'Envoi d`autres invitations',
        label: 'Les autres groupes ont été invités.',
        message:
          'En fonction des paramètres, des notifications push ou des notifications par e-mail concernant l`événement sont désormais envoyées aux autres groupes.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/Mailadresse',
        enterLink: 'Ajouter un lien/une adresse e-mail ou autre',
      },
      parts: {
        part: 'Part',
        addPart: 'Add program point',
        hint: 'Explanation',
        question: 'Question',
        single: 'Only one option selectable',
        multi: 'Several options available',
        freeText: 'Free text answer',
        quiz: 'Quiz',
        questionnaire: 'Questionnaire',
        addQuestionnaire: 'Add Questionnaire',
        questionnaireType: 'Answer options',
        option: 'Option',
        addOption: 'Add option',
        showMembers: 'Participants',
        youJoined: 'You take part in:',
        totalToPay: 'Total amount to be paid:',

        yourAnswer: 'Your answer',
        visibleForCreators: 'Visible only for creators',
        allAnswers: 'All answers:',
        addUsers: 'Add users',
        isAnonym: 'Anonymous voting',
        isAnonymQuiz: 'Anonymes Quiz',
      },
    },
    rrEventLabels: {
      title: 'Wiederholung',
      MO: 'Montag',
      TU: 'Dienstag',
      WE: 'Mittwoch',
      TH: 'Donnerstag',
      FR: 'Freitag',
      SA: 'Samstag',
      SU: 'Sonntag',
      YEARLY: { single: 'Jahr', regular: 'Jährlich' },
      MONTHLY: { single: 'Monat', regular: 'Monatlich' },
      WEEKLY: { single: 'Woche', regular: 'Wöchentlich' },
      DAILY: { single: 'Tag', regular: 'Täglich' },
      SINGLE: { single: 'Einmalig', regular: 'Einmalig' },
      CUSTOM: {
        single: 'Benutzerdefiniert...',
        regular: 'Benutzerdefiniert...',
      },
      freq: 'Frequenz',
      interval: 'Intervall',
      notRepeat: 'Einmalig',
      every: 'Jeden',
      month: 'monat',
      week: 'woche',
      last: 'letzten',
      on: 'am',
      everyMonthOn: 'Jeden Monat am',
      ofTheMonth: 'des Monats',
      editLabel: 'Welche Termine willst du ändern?',
      thisDate: 'Nur diesen Termin',
      thisDateAndFollowing: 'Diesen und alle folgenden',
      allDates: 'Alle Termine',
      after: 'After',
      time: 'time',
      times: 'times',
      neverEnds: 'Never ends',
      until: 'until the',
      repeatedDefault: ({ frequency, freq }) =>
        `Wird jede${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} wiederholt`,
      repeatedMonthly: ({ day, interval }) =>
        `Wird jeden${interval == 1 ? '' : ` ${interval}.`} Monat am ${day.length > 2 ? day : `${day}.`} wiederholt.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }) =>
        `Wird jeden ${bysetpos == -1 ? ' letzten' : `${bysetpos}.`} ${day} des ${
          interval === 1 ? '' : `${interval}.`
        } Monats wiederholt.`,

      repeatedYearly: ({ interval }) => `Wird jede ${interval == 1 ? '' : `${interval}.`} Jahr wiederholt.`,
      repeatedWeekly: ({ interval, byweekday }) =>
        `Wird jede${interval != 1 ? ` ${interval}.` : ''} Woche${
          !!byweekday?.length
            ? ' am ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' und ' : '') +
              byweekday?.slice(-1)
            : ''
        } wiederholt.`,
      repeatedDaily: ({ interval }) => `Wird jeden${interval != 1 ? ` ${interval}.` : ''} Tag wiederholt.`,
    },
    requestsLabels: {
      receiveLabel: 'Reçu',
      sentLabel: 'Envoyé',
    },
    post: {
      adTitle: 'Information/Advertisement',
      toKreise: 'à',
      comment: 'Commentaires',
      myPost: 'Moi',
      openPdf: 'Cliquez pour ouvrir',
      deletePost: 'Supprimer ce poste?',
      posted: 'Publié sur',
      commented: 'Commenté',
      contacts: 'Contacts',
      sendToContacts: 'Message à tous:',
      enterText: 'Schreibe hier eine Nachricht',
      sendToCircle: `Entrez le message dans ce ${KreiseName('FRA').single}`,
      sendComment: 'Entrez un commentaire',
      longMessage: '20,000 characters maximum',
      noComments: 'Pas encore de commentaires',
      deleteComment: 'Supprimer le commentaire?',
      deleteEventPost: "Supprimer ce message ? Cela ne supprime pas l'événement.",
      editPostLabel: 'Edit post',
      editingPostLabel: 'Editing mode',
      deletePostLabel: 'Delete post',
      editedAt: 'Edited',
      pinned: 'Angepinnt.',
      actions: {
        label: 'Aktionen posten',
        pin: {
          label: 'Beitrag anpinnen',
          once: 'Einmalig nach oben rücken',
          d3: '3 Tage',
          d5: '5 Tage',
          w1: '1 Woche',
          w2: '2 Wochen',
          unpin: 'Nicht mehr anpinnen',
        },
      },
    },
    circles: {
      circlesTitle: `${KreiseName('FRA').multi} souscrits`,
      noCirclesTitle: `${KreiseName('FRA').multi} désabonnés`,
      myPost: 'Moi',
      circlesInfoTitle: 'Info & Membres',
      circlesSubscribe: "S'abonner",
      unsubscribeAlert: 'Quitter irrévocablement la discussion de groupe fermée ?',
      continueAlert: 'Laissez',
      readOnlyKreise: `Il s'agit d'un "chat de ${
        KreiseName('FRA').single
      } en lecture seule". Seuls les admins peuvent y créer des messages. Tu peux toutefois écrire des commentaires sur les messages.`,
    },
    messages: {
      addMessageLabel: 'Entrez le message',
      changesSaved: 'Changes saved!',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: 'De base',
      clubsTab: CorpsName('FRA').single,
      miscellaneosTab: 'Intérêts',
      professionTab: 'Profession',
      studyTab: StudyName('FRA')?.short,
      notificationsTab: 'Notifications',
      privacy: 'Privatsphäre',
    },
    profile: {
      addPhotoLabel: 'Ajouter une photo de profil',
      genderInputLabel: 'Genre',
      titleLabel: 'Titel',
      firstNameLabel: 'Prénom(s)',
      lastNameLabel: 'Nom de famille',

      passwordLabel: 'Définir le mot de passe',
      birthDateLabel: 'Date de naissance',

      changePasswordLabel: 'Changer de mot de passe',
      defaultCountryLabel: 'Allemagne',
      defaultGraduationLabel: 'Aucune information',
    },
    profileKorp: {
      verband: CorpsName('FRA').single,
      function: FunctionName('FRA'),
      dateOfJoin: CorpsEditDate('FRA'),
      beername: 'Biername',
    },
    profileClubs: {
      noUserLabel: 'All basic information must be filled in before the app can be used.',
      functionLabel: 'Function',
      setClubLabel: `Please indicate the name of the ${CorpsName('FRA').single}.`,
      matchClubLabel: 'The',
      matchClubContinueLabel: 'already exists in the profile, please check again.',
      clubLabel: CorpsName('FRA').single.toUpperCase(),
      clubCodeLabel: `LOGIN CODE OF YOUR ${CorpsName('FRA').single.toUpperCase()}`,
      entryTimeLabel: CorpsEditDate('FRA'),
      deleteClubLabel: 'Are you sure you want to delete your membership in',
      deleteClubContinueLabel: '?',
      selectVariant: 'Sélectionner une variante',
      requestExistingCorp: 'Demande de corp existant',
      requestNewCorps: 'Demander un nouveau corps',
      createCorp: 'Créer',
    },
    profileProfession: {
      jobTitle: 'Titre de la profession',
      noUserLabel:
        "Toutes les informations de base doivent être remplies avant que l'application puisse être utilisée.",
      untilToday: "Jusqu'à aujourd'hui",
      positionTitleLabel: 'Position',
      itemNameLabel: 'Profession',
      employmentLabel: "Type d'emploi",
      activityLabel: ActivityName('FRA'),
      careerStageLabel: 'Étape de la carrière',
      activityDescLabel: "Description de l'activité",
      companyLabel: CompanyName('FRA'),
      nameLabel: 'Nom',
      industryLabel: IndustryName('FRA'),
      areaLabel: 'Domaine',
      websiteLabel: 'Site web',
      employmentPeriodLabel: "Période d'emploi",
      fromLabel: 'Tiré de',
      toLabel: 'à',
    },
    profileStudy: {
      noUserLabel:
        "Toutes les informations de base doivent être remplies avant que l'application puisse être utilisée.",
      universityLabel: StudyName('FRA').hochsule,
      courseLabel: StudyName('FRA').studiengang,
      degreeLabel: StudyName('FRA').abshchluss,
    },
    profileOther: {
      noUserLabel:
        "Toutes les informations de base doivent être remplies avant que l'application puisse être utilisée.",
      hobbiesLabel: 'Hobbies',
      culturalLabel: 'Parrainage culturel',
      socialMediaPlattform: 'Plate-forme de médias sociaux',
      socialMediaLabel: 'Liens avec les médias sociaux',
      socialMediaLink: 'Liens',
      socialMediaError: 'Veuillez saisir l`URL complète',
    },
    profileNotifications: {
      noUserLabel:
        "Toutes les informations de base doivent être remplies avant que l'application puisse être utilisée.",
      notificationsInfo:
        'Ici, vous pouvez activer les notifications par courriel pour les activités individuelles (par défaut) ou les désactiver pendant 3 mois.',
      notificationsTitle: 'Notifications push:',
      notificationsDescr0: 'Vous pouvez configurer les notifications push dans les ',
      notificationsDescr1: 'paramètres système du smartphone.',
      notificationsDescr2: '',
      mailingTitle: 'Notifications par e-mail:',
      mailingDescr:
        'Ici, vous pouvez activer les notifications par e-mail pour des activités individuelles (par défaut) ou les désactiver pendant 1 an.',
      allNotificationsLabel: 'Toutes les notifications',
      postsLabel: `Messages dans ${KreiseName('FRA').multi}`,
      messagesLabel: 'Messages privés',
      commentsLabel: 'Commentaires sur les contributions propres',
      contactRequestsLabel: 'Demandes de contact',
      eventsLabel: 'Événements',
      clubMemberLabel: 'Nouveaux membres enregistrés',
      enableLabel: 'Sur',
      disableToLabel: "Arrêt jusqu'à",
      disableLabel: 'De',
      ownPosts: 'Meine Beiträge (Mail auch selbst erhalten)',
    },
    searchInput: {
      title: 'Recherchez',
      placeholder: 'Recherchez',
    },
    emptyList: {
      title: "Il n'y a pas (encore) de contenu ici",
    },
    switchLanguage: {
      placeholder: 'Langue/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'russian (русский)',
      SP: 'Spanish (Español)',
      title: 'Choisissez une langue',
    },
    filePicker: {
      takePicLabel: 'Prendre une photo',
      uploadPicLabel: 'Télécharger une photo',
      selectPicLabel: 'Sélectionnez une photo/video',
      selectDocLabel: 'Sélectionner un document',
    },
    datePicker: {
      selectDateLabel: 'Sélectionnez une date',
      confirmDateLabel: 'Confirmer la date',
      selectTimeLabel: "Sélectionnez l'heure",
    },
    changePassword: {
      currentPasswordLabel: 'Mot de passe actuel',
      newPasswordLabel: 'Nouveau mot de passe',
      repeatPasswordLabel: 'Répéter le mot de passe',
      changePasswordLabel: 'Changer de mot de passe',
    },
    appInfo: {
      appDescLabel:
        "Korponnect est l'application pour les étudiants de la fraternité. Échange entre entreprises, organisation d'événements ainsi que réseautage professionnel et privé - le tout dans une seule application.",
      contactsLabel: InfoContactsName('FRA'),
      infoLabel: 'Informations',
      versionLabel: "Version de l'application",
      agbLabel: 'AGB',
      dataProtectionLabel: 'Déclaration sur la protection des données',
      manualLabel: 'Manuel',
      appTextLabel: 'Vous pouvez nous trouver ici',
      appAndLabel: 'et un',
      appMainTextLabel: `Souhaitez-vous ajouter un cercle ou avez-vous une autre suggestion? Voulez-vous supprimer votre profil ? Écrivez-nous simplement un court e-mail à ${support_email}.`,
      deleteFeedback: 'Raison de la suppression du compte / retour d`information 🙂',
      deleteUserTitle: 'Supprimer le compte?',
      deleteUserDesc:
        'Ici, vous pouvez supprimer irrévocablement votre compte.\b au fait: vous pouvez également désactiver les notifications.Modifiez les paramètres sous Profil -> Notifications ou écrivez-nous un e-mail.',
      deleteUserAlert: 'Toutes vos données seront supprimées pour toujours, continuer?',
      webLabel: 'Version Web',
    },
    infoMessages: {
      registartionSuccessLabel: 'Merci de vous inscrire!',
      manualRegSuccessLabel: 'Merci de vous inscrire ! Le profil sera activé prochainement.',
      linkExpiredLabel: 'Lien expiré. Demander un nouveau changement de mot de passe.',
      emailExistLabel: "Désolé, l'e-mail n'est pas disponible.",
    },
    logoutAlert: {
      title: 'Se déconnecter',
      text: "Vous voulez vraiment la déconnecter ? Vous n'êtes pas obligé de vous déconnecter, il vous suffit de fermer ou de réduire l'application.",
      confirm: 'Oui',
      dismiss: 'Non',
    },
    invalidInputs: {
      passwordChars: `Le mot de passe doit contenir au moins ${passwordDigits(
        'FRA',
      )} caractères, dont au moins une lettre, un chiffre et un caractère spécial.`,
      fieldRequired: 'Ce champ est obligatoire.',
      emailsNotMatching: 'Veuillez vous assurer que vos emails correspondent',
      emailInvalid: "L'indication de votre adresse e-mail n'est pas correcte !",
      selectCorrectAnswer: 'You need to select correct answer',
      corp: 'Veuillez sélectionner au moins 1 entrée',
    },
    mentoring: {
      profile: {
        rules1: "Je suis d'accord avec le",
        rules2: ' Compréhension des valeurs ',
        rules3: '"Mentorat pour les boursiers".',
        menteeDescription:
          "Veuillez sélectionner votre région de mentoring afin d'être informé(e) des événements locaux. REMARQUE : tu peux modifier ta sélection à tout moment. Elle ne limite PAS ta recherche de mentor.",
        mentorDescription:
          'Veuillez remplir votre profil de mentor afin que les boursiers puissent vous trouver comme mentor. REMARQUE : Au moins 1 région et au moins 1 domaine thématique sont nécessaires.',
        mentoringRegions: 'Régions de mentoring',
        reigonsShort: 'Régions',
        topics: 'Champs thématiques',
        departments: 'Département',
        available: 'Je suis disponible',
        unavailable: 'Je ne suis pas disponible pour le moment',
        deleteAccount: 'Supprimer le profil Ment. & quitter le groupe',
        deleteAccountDescr: 'Supprimer le profil du mentor et quitter le chat du groupe de mentors ?',
        uploadPdf: 'Profil du mentor (PDF)',
        myPdf: 'Mon profil (PDF)',
        pdf: 'Mentor Profile',
      },
      tabNames: {
        info: 'Info',
        news: 'Actualités',
        profile: 'Profil',
        search: 'Recherche & demande',
        request: 'Mentorat',
        requestDetails: 'Demande de mentoring',
        tabDeactivated: 'Onglet désactivé. Veuillez remplir le profil de mentoring.',
      },
      search: {
        description:
          "Pour bien cibler ta recherche, nous te conseillons de commencer avec peu de champs de sélection, puis d'élargir la grille de recherche lors d'une nouvelle recherche. Si tu ne trouves pas de mentor approprié dans ta région, élargis la recherche à une région de mentoring voisine. Critères de recherche : 1 région, 1 à 3 champs thématiques",
        searchBtn: 'Recherche',
      },
      requests: {
        mentorIncoming: 'Mes demandes de mentoring',
        mentorCurrent: 'Mes mentorats',
        menteeOutgoing: 'Ma demande de mentoring',
        menteeCurrent: 'Mon mentorat',
        cancelMentoring: 'Terminer le mentorat',
        sendRequest: 'Demander à être mentor',
        alreadyHaveMentor: "Vous ne pouvez avoir qu'un seul mentor",
        requestSuccess: 'Demande envoyée avec succès',
        requestRetracted: 'Demande retirée avec succès',
        cancelSendingRequest: 'Annuler la demande de mentorat',
        approveRequest: 'Accepter la demande',
        rejectRequest: 'Refuser un mentee',
        invitaionText: 'Demande',
        rejectionText: 'Justifiez votre refus',
        sureRetractRequest: 'Retirer vraiment la demande ?',
      },
    },
    downloads: {
      root: 'Accueil',
      delete: 'Supprimer',
      rename: 'Renommer',
      areYouSureDeleteFolder: 'Voulez-vous vraiment supprimer le dossier ? Tous les contenus seront supprimés !',
      renameFolder: 'Renommer le dossier',
      createNewFolder: 'Créer un nouveau dossier',
      newFolderPlaceholder: 'Entrez le nom du dossier',
      create: 'Créer',
      areYouSureDeleteFile: '',
      downloadFile: 'Télécharger',
      deleteFile: 'Supprimer du périphérique',
      openInFinder: 'Ouvrir dans le Finder',
      cancel: 'Annuler',
    },
    globalSearch: {
      postedBy: 'Publié par',
      found: 'Trouvé',
      loadMore: 'Charger plus',
      enterQuery:
        "Saisissez n'importe quel terme de recherche. Vous pouvez rechercher n'importe quoi (nom, document, événement, publication, ...)",
      folder: 'Dossier',
      post: 'Publication',
      error: 'Saisissez au moins 3 caractères',
    },
    kreiseSettings: {
      description: 'Beschreibung',
      push: 'Push-Benachrichtigungen',
      email: 'Mail-Benachrichtigungen',
      subscribers: 'Mitglieder',
      active: 'Aktiviert',
      oneYear: 'Stumm für 1 Jahr',
      forever: 'Stumm für immer',
      until: 'Bis',
      cantDeactive: 'Für diesen Gruppenchat ist diese Einstellung deaktiviert',
      SINGLE: 'Für jeden Beitrag',
      SUMMARY_ONCE: 'Zusammenfassung 1x pro Woche',
      SUMMARY_TWICE: 'Zusammenfassung 2x pro Woche',
      SUMMARY_DAY: 'Zusammenfassung 1x pro Tag',
      OFF: 'Aus',
      PUSH: 'Push-Benachrichtigung',
      EMAIL: 'Mail-Benachrichtigung',
      PUSH_AND_EMAIL: 'Push- und Mailbenachrichtigung',
      newUser: 'Benachrichtigung für neue Nutzer',
      statistic: 'Sende-Statistik',
      off: 'Aus',
      ownPosts: 'Sende-Statistik-Mail für deine Beiträge',
      deleteMemberDescr: 'entfernen?',
      deleteMemberBtn: 'Entfernen',
      retractAdminRights: 'Rechte entziehen',
      deleteAdminAndUser: 'Ganz entfernen',
      toDeleteKreiseBtn: 'Gruppenchat löschen',
      deleteKreiseDescr: `${KreiseName('GER').single} unwiderruflich löschen?`,
      deleteKreiseModalTitle: 'Mitglied entfernen?',
      addKreiseName: 'Name',
      addKreiseDescription: 'Beschreibung',
      addMembers: 'Mitglieder hinzufügen',
      createKreise: 'Gruppenchat erstellen',
      toKreiseEditMessage: 'Hinweis: Alle Änderungen an den Mitgliedern werden sofort umgesetzt.',
      kreiseCreator: 'Ersteller:in dieses Gruppenchats',
      kreiseDeleteMembersLeft: 'Kreise can be deleted only if there is only one member left',
      kreiseDeleteMembersLeftTitle: 'Remove members first',
      suggestionInNewsfeed: {
        admin: 'Admin',
        member: 'Mitglieder',
        corp: `${CorpsName('GER').single} mitglieder`,
        corpAdmin: `${CorpsName('GER').single} admin`,
      },
    },
    surveys: {
      checkVotes: 'View results',
      chooseOptions: {
        single: 'Choose one option',
        multiple: 'Choose multiple options',
      },
      votesModalTitle: 'Votes',
      createModalTitle: 'Poll',
      noVotes: 'There are no votes',
      question: 'Question',
      askAQuestion: 'Ask a question',
      allowMultiple: 'Allow multiple answers',
      votes: {
        single: 'Vote',
        multiple: 'Votes',
      },
      option: 'Option',
      optionsLabel: 'Poll options',
      anonymus: 'Anonymus poll',
      create: 'Create',
    },
  },
  {
    key: 'HNG',
    key2: 'hu',
    responseHandler: {
      messageLabel: `Ismeretlen hiba. Kérjük, lépjen kapcsolatba az ${support_email} címmel.`,
      expiredLinkLabel: 'A link lejárt. Kérjen új jelszót.',
      emailExistLabel: 'Sajnáljuk, ez az e-mail cím már regisztrálva van.',
      profileUpdated: 'Profil aktualizálva',
      profileUpdatedCorpsError: `Profil frissítve! Azonban a következő ${
        CorpsName('GER').single
      } nem tudott hozzáadni egy hibás bejelentkezési kód miatt:`,
      appNeedsUpdate: 'Kérjük, frissítse az alkalmazást az App Store-ban / Google Play-ben.',
      maximalVideoSize: 'A maximális fájlméret 2 GB!',
    },
    report: {
      notBelongLabel: 'A felhasználó már nem tartozik egy hadtesthez',
      incProfileLabel: 'A felhasználó hibás profilajánlatokat tesz',
      badPicLabel: 'A felhasználó profilképe sérti az irányelveket',
      badComLabel: 'A felhasználó megjegyzései sértik az irányelveket',
      spamLabel: 'A felhasználó spamet küld a csevegésben',
      reasonsLabel: 'A jelentés oka',
    },
    drawer: {
      news: 'Üzenetek',
      kreise: KreiseName('HNG').multi,
      documents: `${DocumentsName('HNG')}`,
      search: 'Keresés',
      calendar: CalendarName('HNG'),
      contacts: 'Névjegyek',
      requests: 'Kérések',
      messages: MessagesName('HNG'),
      profile: ProfileName('HNG'),
      logout: 'Kijelentkezés',
      map: 'Térkép',
      mentoring: 'Mentorálás',
    },
    imageCropper: {
      cropLabel: 'Kép kivágása',
      cancelLabel: 'Megszünteti',
      chooseLabel: 'Befejezett',
    },
    signIn: {
      register: 'Regisztráció',
      title: 'Kérjük jelentkezz be',
      loginPlaceholder: 'Levelezési cím',
      passwordPlaceholder: 'Jelszó',
      forgotPassword: 'Elfelejtetted a jelszavad?',
      loginBtn: 'Belépés',
      noAccount: 'Még nincs fiókod?',
      description0: 'Ha bármilyen kérdése van, kérjük, forduljon hozzánk bizalommal a',
      description1: 'vagy a',
      description2: 'e-mail címen.',
      loginValidation: 'Az email címed helytelen',
      passwordValidation: 'A jelszónak legalább 6 karakter hosszúnak kell lennie',
    },
    search: {
      name: 'Név',
      corps: CorpsName('HNG').single,
      country: CountryName('HNG'),
      city: 'Város',
      industry: IndustryName('HNG'),
      company: 'Cég',
      profession: 'Szakma',
      hobby: 'Hobbi',
      cultural: 'Kulturális szponzor',
      study: 'Tanulmány',
      office: 'Hivatal',
      degreeProgram: 'Szak',
      university: 'Egyetem',
      position: FunctionName('HNG'),
      status: 'Állapot',
      title: 'Keresési eredmény a következőre',
      filterTitle: 'Szűrés vlami alapján',
      global: 'Minden',
      activity: ActivityName('HNG'),
    },
    resetPassword: {
      enterEmail: 'Kérjük, add meg e-mail címed',
      inputPlaceholder: 'Levelezési cím',
      sendBtn: 'Küld',
      cancelBtn: 'Megszünteti',
      message: 'Ha az e-mail cím ismert számunkra, linket küldtek rá.',
      desc: 'Meg kell adnia az alkalmazásban tárolt pontos e-mail címet. Ha szükséges, ellenőrizze az e-mailjeit, hogy melyik az.',
    },
    password: {
      resetLabel: 'Adjon meg egy új jelszót',
      inputLabel: 'Adjon meg új jelszót',
    },
    signUp: {
      createAccount: 'Fiók létrehozása',
      inputPlaceholder: `Klubod bejelentkezési ${CorpsName('HNG').single}`,
      sendBtn: 'Folytatás',
      descFirst: 'A regisztrációval Ön elfogadja a ',
      descSecond: 'és megerősíti, hogy elolvasta a',
      descThird: `Ha bármilyen kérdése van, kérjük, forduljon hozzánk bizalommal a ${support_email} vagy a +49 170 49 805 e-mail címen`,
      terms: 'feltételek és kikötések',
      policy: 'adatvédelmi politika.',
      description: 'Megjegyzés',
      generatePassword: 'Generate password',
      noCodeSuccessRegister: 'Köszönjük a regisztrációt! E-mailben értesítjük Önt, amint aktiválódott.',
    },
    signUpDefault: {
      withoutCode: 'Kód nélkül',
      createAccount: 'Csak regisztráljon itt',
      withCode: 'Meghívó kóddal',
      simpleRegister: 'Meghívó kód nélkül',
      existingAccount: 'Számla már elérhető?',
      descr01: 'Ich stimme den',
      descr0: 'A regisztrációval a felhasználó beleegyezik a következőkbe',
      descr1: 'és megerősíti, hogy elolvasta és megértette a.',
      descr2: '',
      guest: 'Registrieren als Gast',
      withCodeWithDescr: `Registrieren als ${member_naming_singular}`,
    },
    signUpManual: {
      title: 'Bejegyzés',
      photoDesc: 'Profilfotó hozzáadása',
      genderInput: 'Nem',
      firstName: 'Keresztnév (nevek)',
      lastName: 'Vezetéknév',
      club: CorpsName('HNG').single,
      specify: 'kérem pontosítsa',
      emailPlaceholder: 'E-mail',
      emailHint: 'Kérjük, használja azt az e-mail címet, amelyet már régóta használ.',
      repeatEmailPlaceholder: 'Ismételd meg az E-mail címet',
      passwordPlaceholder: 'Állítsd be a jelszót',
      repeatPasswordPlaceholder: 'Jelszó újra',
      postcode: 'Irányítószám',
      place: 'Lakóhely',
      country: 'Ország',
      loginCode: 'Meghívó kód',
      mobilePlaceholder: 'Mobil (nem látható a profilban)',
      corps: SignUpCorpsName('HNG'),
      searchCountry: 'Suche nach Land',
    },
    backToLogin: {
      desc: 'Már van fiókja? ',
      login: 'Belépés',
    },
    backToSignUp: {
      desc: 'Még nincs fiókod? ',
      register: 'Regisztráció',
    },
    privacy: {
      desc: 'Igen, elfogadom a',
      btn: 'általános szerződési feltételek',
      zu: '',
      map: {
        title: 'Adatvédelmi tájékoztató',
        description:
          'Ez az alkalmazás a GDPR-nak megfelelően került bevezetésre. A térkép megjelenítéséhez kizárólag a Google Térképet használjuk. Személyes adatok nem kerülnek továbbításra.\n\nAzonkívül a kitűzőket csak hozzávetőlegesen, az irányítószám/város alapján, de utca/szám nélkül jelenítjük meg. Ez azt jelenti, hogy nem lehet következtetéseket levonni a felhasználók konkrét címeire vonatkozóan.',
      },
      restrictions: {
        overall: 'Diese Funktion ist nur für Mitglieder zugänglich.',
        overallWilkar: 'Diese Funktion ist aus Datenschutzgründen nicht zugänglich.',

        profile:
          'Nur Mitglieder können Nutzerprofile ansehen. Du kannst dem Nutzer aber eine private Nachricht schreiben.',
        groupchat: `Die ${KreiseName('GER').single} sind nur für Mitglieder zugänglich.`,
        groupchatWilkar: `Aus Datenschutzgründen die ${KreiseName('GER').single} sind nur für Mitglieder zugänglich.`,
        comments: 'Kommentare sind nur für Mitglieder einsehbar.',
        commentsWilkar: 'Aus Datenschutzgründen kommentare sind nur für Mitglieder einsehbar.',
        likes: 'Die Liste der Likes sind nur für Mitglieder einsehbar.',
        post: 'Nur Mitglieder können Beiträge verfassen.',
        participantsList: 'Die Teilnehmerliste ist nur für Mitglieder einsehbar.',
        commentsOnlyForMembers: 'Kommentare sind nur für Mitglieder einsehbar.',
        postsLimitation: 'Ältere Beiträge sind nur für Mitglieder einsehbar.',
      },
      settings: {
        guestPost: {
          title: 'Verifizierte Gäste können meine Beiträge sehen',
          ask: 'Immer fragen',
          no: 'Nein',
          yes: 'Ja',
        },
      },
      guestModal: {
        title: 'Dürfen Gäste diesen Beitrag sehen? Du kannst diese Einstellung im Nachhinein auch noch ändern.',
        dontAskAgain:
          'Nicht erneut fragen (Du kannst diese Einstellung unter “Profil” → “Privatsphäre” auch wieder ändern).',
      },
      post: {
        ifShown: `Für ${guest_naming_plural} verbergen`,
        ifHidden: `Für ${guest_naming_plural} anzeigen`,
        ifShownModal: `Der Beitrag ist jetzt für ${guest_naming_plural} nicht mehr sichtbar.`,
        ifHiddenModal: `Der Beitrag ist jetzt für ${guest_naming_plural} sichtbar.`,
      },
    },
    searchModal: {
      landTitle: 'Válassz egy országot',
      corpsTitle: `Válassza a ${CorpsName('HNG').single} lehetőséget`,
      pleaseSelect: 'Kérjük, válasszon',
    },
    btn: {
      sendBtn: 'Küld',
      cancelBtn: 'Megszünteti',
      select: 'Válassza a lehetőséget',
      cancelEventBtn: acceptEventName('HNG').cancel,
      acceptBtn: acceptEventName('HNG').accept,
      saveBtn: 'Megment',
      clearBtn: 'Törlés',
      acceptReqBtn: 'Megerősíteni',
      declineReqBtn: 'Hanyatlás',
      updateBtn: 'Frissítés',
      declineBtn: 'Hanyatlás',
      yesUnderstood: 'Ja, verstanden',

      ok: 'Oké',
      cancel2: 'Megszünteti',
    },
    validation: {
      email: 'Az email címed helytelen',
      password: 'A jelszónak legalább 6 karakter hosszúnak kell lennie',
      repeatEmail: 'Az e-mail és az újrapróbálkozás nem egyezik',
      repeatPassword: 'A jelszó és az ismétlés nem egyezik',
      required: 'Ez a mező kötelező',
      matchPasswords: 'Régi és új jelszó egyezik',
      dontMatchPasswords: 'A jelszó és az ismétlés nem egyezik',
    },
    init: {
      title: 'Betöltés',
      overlayLabel: 'Kérjük, ellenőrizze internetkapcsolatát',
    },
    screenTitles: {
      postsTitle: 'Üzenetek',
      circlesTitle: KreiseName('HNG').multi,
      searchTitle: 'Keresés',
      profileTitle: ProfileName('HNG'),
      eventTitle: CalendarName('HNG'),
      eventDetailsTitle: 'Esemény',
      newEventTitle: 'Esemény hozzáadása',
      contactsTitle: 'Névjegyek',
      contactReqTitle: 'Kapcsolattartási kérelmek',
      reqDetailsTitle: 'Kérjen részleteket',
      outReqTitle: 'Kapcsolatfelkérés',
      messagesTitle: MessagesName('HNG'),
      changePasswordTitle: 'Jelszó módosítása',
      appInfoTitle: 'Info',
      reportTitle: 'Felhasználó bejelentése',
      createKreiseTitle: 'Gruppenchat erstellen',
      eventParticipants: 'Teilnehmer',
      guest: 'Vendég',
    },
    profileDetails: {
      user: 'Users',
      mainTitleLabel: ProfileName('HNG'),
      basisLabel: 'Alapvető',
      clubLabel: CorpsName('HNG').single,
      clubLabelPlural: CorpsName('HNG').multi,
      professionLabel: 'Szakma',
      studyLabel: StudyName('HNG').short,
      uploadPhotoLabel: 'Fénykép feltöltése',
      editPhotoLabel: 'Fénykép szerkesztése',
      profileCompletedLabel: 'A profil elkészült',
      profileCompletedGLabel: '',
      birthday: 'Születési dátum',
      functionLabel: 'Funkció',
      dateOfJoinLabel: 'Az ágyék dátuma',
      positionDescLabel: 'Profession',
      companyLabel: CompanyName('HNG'),
      nameLabel: 'Név',
      websiteLabel: 'Weboldal',
      fromLabel: 'tól',
      toLabel: 'ig',
      delete: 'töröl',
      untilToday: 'A mai napig',
      degreeLabel: StudyName('HNG').abshchluss,
      miscellaneos: 'Vegyes',
      hobbies: 'Hobbi',
      cultural: 'Kulturális támogatás',
      positionLabel: 'Beosztás',
      typeOfEmplLabel: 'Foglalkoztatás típusa',
      activityLabel: ActivityName('HNG'),
      careerStageLabel: 'Karrier szakasz',
      activityDescLabel: 'A tevékenység leírása',
      industryLabel: IndustryName('HNG'),
      areaLabel: 'Szakterület',
      employmentPeriodLabel: 'A foglalkoztatás időszaka',
      universityLabel: 'Egyetem',
      courseLabel: 'Tanulmányok menete',
      firmaLabel: 'Firma',

      ageLabel: 'Kor',
      birthdayLabel: 'Születésnap',
      partner: 'Partner neve',
      editProfileLabel: 'Profil szerkesztése',
      addContactLabel: 'Hozzáadás a kapcsolatokhoz',
      sendMessageLabel: 'Üzenet küldése',
      requestSentLabel: 'Küldj barátkérést',
      sendRequestSuccessLabel: 'Sikeresen elküldött kérés',
      reportSentLabel: 'A felhasználó már jelentett',
      addReportLabel: 'Jelentés küldése',
      contactsLabel: 'Névjegyek',
      showBirthday: 'A kapcsolatok lásd a születésnapomat',
      street: 'Utca + Nr. ',

      mobileNumberLabel: 'Mobilszám',
      handphoneNumberLabel: 'Mobiltelefonszám',
      telephoneNumberLabel: 'Telefonszám',
      leibbursch: 'Mentor',
      leibfuechse: 'Mentee',

      bio: 'Über mich',
      addDocuments: 'Dokumente zu dir hinzufügen',
      image: 'Bild',
      video: 'Video',
      pdf: 'PDF',
      link: 'Link',
      firstName: 'Keresztnév',
      lastName: 'Vezetéknév',
      title: 'Cím',
      email: 'E-Mail',
      name: 'Keresztnév',
      lastname: 'Vezetéknév',
      country: CountryName('HNG'),
      wohnort: 'Város',
      postcode: 'Irányítószám',
      inReview: 'Confirmation via mail pending',
    },
    calendar: {
      birthdayLabel: 'Születésnapja a',
      noEventsLabel: 'Nincsenek események a kiválasztott dátumra',
      allTabLabel: 'Minden',
      myTabLabel: 'Eseményeim eseményeim',
      clubsTabLabel: `${CorpsName('HNG').single}`,
      subsTabLabel: 'Feliratkozások',
      subscribedTabLabel: 'Feliratkozott',
      otherTabLabel: 'Mások',

      error: 'The end date must be later than the start date',
      filter: {
        all: 'All',
        invited: 'Invited',
        confirmed: 'Confirmed',
      },
    },
    eventLabels: {
      inviteTo: 'Meghívó a',
      addEndDate: 'Adja meg a végdátumot/időpontot',
      eventLabel: 'Esemény',
      startLabel: 'Kezdődik',
      locationLabel: 'Elhelyezkedés',
      participantLabel: 'Résztvevők',
      moreInfo: 'További információk',
      commentsLabel: 'Hozzászólások',
      noCommentsLabel: 'Még nincsenek hozzászólások',
      eventStartLabel: 'Rajt',
      eventEndLabel: 'Vége',
      descLabel: 'Leírás',
      creatorLabel: 'Készítette',
      titleLabel: 'Cím',
      deleteEventTitle: 'Esemény törlése?',
      deleteEventLabel: 'Törli ezt az eseményt?',
      clubsTitle: CorpsName('HNG').single,
      gruppenchat: 'Csoportos csevegés kiválasztása',
      contactsTitle: 'Névjegyek',
      delCommentLabel: 'Törli ezt a megjegyzést?',
      sendInvite: 'Küldjön meghívót a következő címre',
      selectCorps: 'Célközönség kiválasztása',
      externalGuest: 'Meghívott külső vendégek',
      externalGuestPlaceholder: 'Adja meg a levelezési címet',
      attachmentLabel: 'Mellékletek',
      shareLinkLabel: 'Megosztás link',
      linkLabel: 'Vendég regisztrálása',
      copyLinkLabel: 'Link másolása',
      iCalDownload: 'Naptár fájl',
      timezone: 'Időzóna',
      visibility: 'Sichtbarkeit',
      errorDate: 'A dátum a múltban van',
      startAfterEndError: 'A végdátumnak később kell lennie, mint a kezdet',
      members: 'Members',
      waitlist: 'Warteliste',
      onWaitlist: 'auf der Warteliste',
      leaveWaitlist: 'Platz freigeben?',
      leaveWaitlistDesc: `Von Warteliste entfernen?\n\n Wenn du dich später wieder auf die Warteliste setzt, landest du ggf. auf einer hinteren Position.`,
      limitAchieved: (limit: number) =>
        `Die maximale Teilnehmeranzahl von ${limit} ist erreicht. Du wurdest auf die Warteliste gesetzt.`,
      visibilityOptions: {
        allWithoutGuests: 'Mitglieder und auf Webseite',
        invited_member: 'Nur Mitglieder',
        verified_member: 'Alle Mitglieder',
        authorized_member: 'Mitglieder und verifizierte Gäste',
        all: 'Mitglieder, Gäste und auf Webseite',
      },
      modalEvent: {
        title: 'Létrehozott esemény',
        subTitle: 'Az eseményt létrehozták.\n\n',
        content:
          'A felhasználói beállításoktól függően mostantól push/mail értesítések kerülnek kiküldésre az eseményről. \n\nHa ezt a "Profil" → "Értesítések" menüpontban állította be, akkor maga is kap e-mail értesítést az eseményről.',
      },
      modalComment: {
        title: 'Kit kell figyelmeztetni?',
        message: 'Kit kell tájékoztatni a megjegyzéséről?',
        optionAll: 'Minden résztvevő',
        optionNone: 'Csak a szervező(k)',
        button: 'Megjegyzés küldése',
      },
      cancelModal: {
        title: 'Törlés vagy törlés?',
        message: 'Esemény törlése vagy törlése?',
        optionAll: 'Törlés (a résztvevőket értesítjük)',
        optionNone: 'Törlés (a résztvevők nem kapnak értesítést)',
      },
      updateEventModal: {
        title: 'Egyéb meghívók küldése',
        label: 'A többi csoportot meghívták.',
        message:
          'En fonction des paramètres, des notifications push ou des notifications par e-mail concernant l`événement sont désormais envoyées aux autres groupes.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/Mailadresse',
        enterLink: 'Link/email cím vagy hasonló hozzáadása',
      },
      parts: {
        part: 'Part',
        addPart: 'Add program point',
        hint: 'Explanation',
        question: 'Question',
        single: 'Only one option selectable',
        multi: 'Several options available',
        freeText: 'Free text answer',
        quiz: 'Quiz',
        questionnaire: 'Questionnaire',
        addQuestionnaire: 'Add Questionnaire',
        questionnaireType: 'Answer options',
        option: 'Option',
        addOption: 'Add option',
        showMembers: 'Participants',
        youJoined: 'You take part in:',
        totalToPay: 'Total amount to be paid:',

        yourAnswer: 'Your answer',
        visibleForCreators: 'Visible only for creators',
        allAnswers: 'All answers:',
        addUsers: 'Add users',
        isAnonym: 'Anonymous voting',
        isAnonymQuiz: 'Anonymes Quiz',
      },
    },
    rrEventLabels: {
      title: 'Wiederholung',
      MO: 'Montag',
      TU: 'Dienstag',
      WE: 'Mittwoch',
      TH: 'Donnerstag',
      FR: 'Freitag',
      SA: 'Samstag',
      SU: 'Sonntag',
      YEARLY: 'Jahr',
      MONTHLY: 'Monat',
      WEEKLY: 'Woche',
      DAILY: 'Tag',
      SINGLE: 'inmalig',
      CUSTOM: 'Benutzerdefiniert...',
      freq: 'Frequenz',
      interval: 'Intervall',
      notRepeat: 'Einmalig',
      every: 'Jeden',
      month: 'monat',
      week: 'woche',
      last: 'letzten',
      on: 'am',
      everyMonthOn: 'Jeden Monat am',
      ofTheMonth: 'des Monats',
      editLabel: 'Welche Termine willst du ändern?',
      thisDate: 'Nur diesen Termin',
      thisDateAndFollowing: 'Diesen und alle folgenden',
      allDates: 'Alle Termine',
      after: 'After',
      time: 'time',
      times: 'times',
      neverEnds: 'Endet nie',
      until: 'until the',
      repeatedDefault: ({ frequency, freq }) =>
        `Wird jede${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} wiederholt`,
      repeatedMonthly: ({ day, interval }) =>
        `Wird jeden${interval == 1 ? '' : ` ${interval}.`} Monat am ${day.length > 2 ? day : `${day}.`} wiederholt.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }) =>
        `Wird jeden ${bysetpos == -1 ? ' letzten' : `${bysetpos}.`} ${day} des ${
          interval === 1 ? '' : `${interval}.`
        } Monats wiederholt.`,

      repeatedYearly: ({ interval }) => `Wird jede ${interval == 1 ? '' : `${interval}.`} Jahr wiederholt.`,
      repeatedWeekly: ({ interval, byweekday }) =>
        `Wird jede${interval != 1 ? ` ${interval}.` : ''} Woche${
          !!byweekday?.length
            ? ' am ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' und ' : '') +
              byweekday?.slice(-1)
            : ''
        } wiederholt.`,
      repeatedDaily: ({ interval }) => `Wird jeden${interval != 1 ? ` ${interval}.` : ''} Tag wiederholt.`,
    },
    requestsLabels: {
      receiveLabel: 'Megkapta',
      sentLabel: 'Küldött',
    },
    post: {
      adTitle: 'Information/Advertisement',
      toKreise: 'ig',
      comment: 'Hozzászólások',
      myPost: 'Nekem',
      openPdf: 'Kattintson a megnyitáshoz',
      deletePost: 'Törölje ezt a bejegyzést?',
      posted: 'Feladva',
      commented: 'Hozzászólt',
      contacts: 'Névjegyek',
      sendToContacts: 'Üzenet mindenkinek',
      enterText: 'Schreibe hier eine Nachricht',
      sendToCircle: `Írja be az üzenetet ebbe a ${KreiseName('HNG').single}`,
      sendComment: 'Írja be a megjegyzést',
      longMessage: 'Legfeljebb 20 000 karakter',
      noComments: 'Még nincsenek hozzászólások',
      deleteComment: 'Törli a megjegyzést?',
      deleteEventPost: 'Törölni ezt a hozzászólást? Ez nem törli az eseményt.',
      editPostLabel: 'Edit post',
      editingPostLabel: 'Editing mode',
      deletePostLabel: 'Delete post',
      editedAt: 'Edited',
      pinned: 'Angepinnt.',
      actions: {
        label: 'Aktionen posten',
        pin: {
          label: 'Beitrag anpinnen',
          once: 'Einmalig nach oben rücken',
          d3: '3 Tage',
          d5: '5 Tage',
          w1: '1 Woche',
          w2: '2 Wochen',
          unpin: 'Nicht mehr anpinnen',
        },
      },
    },
    circles: {
      circlesTitle: `Feliratkozott ${KreiseName('HNG').multi}`,
      noCirclesTitle: `Leiratkozott ${KreiseName('HNG').multi}`,
      myPost: 'Nekem',
      circlesInfoTitle: 'Info és tagok',
      circlesSubscribe: 'Iratkozz fel',
      unsubscribeAlert: 'Visszavonhatatlanul kilépni a zárt csoportos megbeszélésből?',
      continueAlert: 'Hagyja',
      readOnlyKreise: `Ez egy "csak olvasható ${
        KreiseName('HNG').single
      }". Csak az adminok írhatnak ide. A hozzászólásokhoz azonban lehet kommenteket írni.`,
    },
    messages: {
      addMessageLabel: 'Írja be az üzenetet',
      changesSaved: 'Changes saved!',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: 'Alapvető',
      clubsTab: CorpsName('HNG').single,
      miscellaneosTab: 'Másféle',
      professionTab: 'Szakma',
      studyTab: StudyName('HNG')?.short,
      notificationsTab: 'Értesítések',
      privacy: 'Privatsphäre',
    },
    profile: {
      addPhotoLabel: 'Profilfotó hozzáadása',
      genderInputLabel: 'Nem',
      titleLabel: 'Titel',
      firstNameLabel: 'Keresztnév (nevek)',
      lastNameLabel: 'Vezetéknév',

      passwordLabel: 'Állítsd be a jelszót',
      birthDateLabel: 'Születési dátum',

      changePasswordLabel: 'Jelszó módosítása',
      defaultCountryLabel: 'Németország',
      defaultGraduationLabel: 'Nincs információ',
    },
    profileKorp: {
      verband: CorpsName('HNG').single,
      function: FunctionName('HNG'),
      dateOfJoin: CorpsEditDate('HNG'),
      beername: 'Biername',
    },
    profileClubs: {
      noUserLabel: 'Az alkalmazás használatához minden alapvető információt ki kell tölteni.',
      functionLabel: 'Funkció',
      setClubLabel: `Kérjük, adja meg a ${CorpsName('HNG').single} nevét.`,
      matchClubLabel: 'Az',
      matchClubContinueLabel: 'már létezik a profilban, ellenőrizze újra.',
      clubLabel: CorpsName('HNG').single.toUpperCase(),
      clubCodeLabel: `KLUBJÁNAK BEJELENTKEZÉSI ${CorpsName('HNG').single.toUpperCase()}`,
      entryTimeLabel: CorpsEditDate('HNG'),
      deleteClubLabel: 'Biztosan törli a (z) domain tagságát',
      deleteClubContinueLabel: '?',
      selectVariant: 'Változat kiválasztása',
      requestExistingCorp: 'Meglévő corp kérése',
      requestNewCorps: 'Új alakulat kérése',
      createCorp: 'Hozzon létre',
    },
    profileProfession: {
      jobTitle: 'Munkakör',
      noUserLabel: 'Az alkalmazás használatához minden alapvető információt ki kell tölteni.',
      untilToday: 'A mai napig',
      positionTitleLabel: 'Beosztás',
      itemNameLabel: 'Profession',
      employmentLabel: 'Foglalkoztatási típus',
      activityLabel: ActivityName('HNG'),
      careerStageLabel: 'Karrier szakasz',
      activityDescLabel: 'A tevékenység leírása',
      companyLabel: CompanyName('HNG'),
      nameLabel: 'Név',
      industryLabel: IndustryName('HNG'),
      areaLabel: 'Szakterület',
      websiteLabel: 'Weboldal',
      employmentPeriodLabel: 'A foglalkoztatás időszaka',
      fromLabel: 'tól',
      toLabel: 'ig',
    },
    profileStudy: {
      noUserLabel: 'Az alkalmazás használatához minden alapvető információt ki kell tölteni.',
      universityLabel: StudyName('HNG').hochsule,
      courseLabel: StudyName('HNG').studiengang,
      degreeLabel: StudyName('HNG').abshchluss,
    },
    profileOther: {
      noUserLabel: 'Az alkalmazás használatához minden alapvető információt ki kell tölteni.',
      hobbiesLabel: 'Hobbik',
      culturalLabel: 'Kulturális támogatás',
      socialMediaPlattform: 'Social-Media-Plattform',
      socialMediaLabel: 'Social Media Links',
      socialMediaLink: 'Link',
      socialMediaError: 'Kérjük, adja meg a teljes URL-t',
    },
    profileNotifications: {
      noUserLabel: 'Az alkalmazás használatához minden alapvető információt ki kell tölteni.',
      notificationsInfo:
        'Itt aktiválhatja az e-mail értesítéseket az egyes tevékenységekhez (alapértelmezett), vagy kikapcsolhatja őket 3 hónapra.',
      notificationsTitle: 'Push értesítések:',
      notificationsDescr0: 'A push-értesítéseket az ',
      notificationsDescr1: 'okostelefon rendszerbeállításaiban',
      notificationsDescr2: ' állíthatja be.',
      mailingTitle: 'E-mail értesítések:',
      mailingDescr:
        'Itt aktiválhatja az e-mail értesítéseket az egyes tevékenységekhez (alapértelmezett), vagy kikapcsolhatja őket 1 évre..',
      allNotificationsLabel: 'Minden értesítés',
      postsLabel: `Hozzászólások ${KreiseName('HNG').multi}`,
      messagesLabel: 'Privát üzenetek',
      commentsLabel: 'Észrevételek a saját hozzájárulásokhoz',
      contactRequestsLabel: 'Kapcsolatfelvételi kérelmek',
      eventsLabel: 'Események',
      clubMemberLabel: 'Új regisztrált tagok',
      enableLabel: 'A oldalon',
      disableToLabel: 'Kikapcsolva, amíg',
      disableLabel: 'A címről',
      ownPosts: 'Meine Beiträge (Mail auch selbst erhalten)',
    },
    searchInput: {
      title: 'Keresés',
      placeholder: 'Keresés',
    },
    emptyList: {
      title: 'Itt nincs tartalom (még)',
    },
    switchLanguage: {
      placeholder: 'Nyelv/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'russian (русский)',
      SP: 'Spanish (Español)',
      title: 'Válassz nyelvet',
    },
    filePicker: {
      takePicLabel: 'Fotót készíteni',
      uploadPicLabel: 'Fénykép feltöltése',
      selectPicLabel: 'Fotó/video kiválasztása',
      selectDocLabel: 'Válassza ki a dokumentumot',
    },
    datePicker: {
      selectDateLabel: 'Válassza ki a dátumot',
      confirmDateLabel: 'Erősítse meg a dátumot',
      selectTimeLabel: 'Válassza ki az időt',
    },
    changePassword: {
      currentPasswordLabel: 'Jelenlegi jelszó',
      newPasswordLabel: 'Új jelszó',
      repeatPasswordLabel: 'Jelszó újra',
      changePasswordLabel: 'Jelszó módosítása',
    },
    appInfo: {
      appDescLabel:
        'Csevegés a klub barátokkal, rendezvények szervezése, szakmai és személyes kapcsolatépítés. Együtt a humánus világért - mindez egy alkalmazásban.',
      contactsLabel: InfoContactsName('HNG'),
      infoLabel: 'Információ',
      versionLabel: 'App verzió',
      agbLabel: 'AGB',
      dataProtectionLabel: 'Adatvédelmi nyilatkozat',
      manualLabel: 'Kézikönyv',
      appTextLabel: 'Itt találhat meg minket',
      appAndLabel: 'és a',
      appMainTextLabel: `Szeretne egyetemet vagy kerületet hozzáadni? Van más kérése vagy javaslata? Törli a profilját? Csak írjon nekünk egy rövid e-mailt az ${support_email}.`,
      deleteFeedback: 'A fiók törlésének oka / visszajelzés 🙂',
      deleteUserTitle: 'Fiók törlése?',
      deleteUserDesc:
        'Itt visszavonhatatlanul törölheti fiókját.\b egyébként: egyszerűen kikapcsolhatja az értesítéseket.Szerkessze a beállításokat a Profil -> Értesítések alatt, vagy írjon nekünk egy e -mailt.',
      deleteUserAlert: 'Az összes adata örökre törlődik, folytassa?',
      webLabel: 'Web-verzió',
    },
    infoMessages: {
      registartionSuccessLabel: 'Köszönjük a regisztrációját!',
      manualRegSuccessLabel: 'Köszönjük a regisztrációját! A profil hamarosan aktiválódik.',
      linkExpiredLabel: 'A link lejárt. Kérjen új jelszót.',
      emailExistLabel: 'Sajnáljuk, az e-mail nem érhető el.',
    },
    logoutAlert: {
      title: 'Logout',
      text: 'Tényleg ki akarja őket jelenteni? Nem kell kijelentkeznie, egyszerűen bezárhatja vagy minimalizálhatja az alkalmazást.',
      confirm: 'Yes',
      dismiss: 'No',
    },
    invalidInputs: {
      passwordChars: `A jelszónak legalább ${passwordDigits(
        'HNG',
      )} karaktert kell tartalmaznia, legalább egy betűt, egy számot és egy speciális karaktert.`,
      fieldRequired: 'Ez a mező kötelező.',
      emailsNotMatching: 'Kérjük, győződjön meg róla, hogy az e-mail címei megegyeznek',
      emailInvalid: 'Az e-mail címe nem helyes!',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: 'Kérjük, válasszon ki legalább 1 bejegyzést',
    },
    mentoring: {
      profile: {
        rules1: 'Ich stimme den',
        rules2: ' Werteverständnis ',
        rules3: '“Mentoring für Stipendiaten” zu.',
        menteeDescription:
          'Bitte wähle deine Mentoring-Region aus, sodass Du über vor-Ort-Veranstaltungen informiert werden kannst. HINWEIS: Die Auswahl kannst Du jederzeit ändern. Sie schränkt NICHT Deine Mentoren-Suche ein.',
        mentorDescription:
          'Bitte fülle Dein Mentoren-Profil aus, damit Dich Stipendiat/innen als Mentor finden können. HINWEIS: Mindestens 1 Region und min. 1 Themenfeld sind nötig.',
        mentoringRegions: 'Mentoring-Regionen',
        reigonsShort: 'Regionen',
        topics: 'Themenfelder',
        departments: 'Fachbereich',
        available: 'Ich bin verfügbar',
        unavailable: 'Ich bin derzeit nicht verfügbar',
        deleteAccount: 'Ment.-Profil löschen & Gruppe verlassen',
        deleteAccountDescr: 'Mentorenprofil löschen und Mentoren-Gruppenchat verlassen?',
        uploadPdf: 'Profil du mentor (PDF)',
        myPdf: 'Mon profil (PDF)',
        pdf: 'Mentor Profile',
      },
      tabNames: {
        info: 'Info',
        news: 'News',
        profile: 'Profil',
        search: 'Suche & Anfrage',
        request: 'Mentorate',
        requestDetails: 'Mentoringanfrage',
        tabDeactivated: 'Tab deaktiviert. Bitte Mentoring-Profil ausfüllen.',
      },
      search: {
        description:
          'Um die Suche gezielt zu starten, empfehlen wir Dir, zunächst mit wenigen Auswahlfeldern zu beginnen und dann in einem neuen Suchlauf das Suchraster breiter anzulegen. Wenn Du in Deiner Region keinen geeigneten Mentor findest, dann weite die Suche auf eine benachbarte Mentoring-Region aus. Suchkriterien: 1 Region, 1 bis 3 Themenfelder. Alle Felder sind UND-verknüpft!',
        searchBtn: 'Suche',
      },
      requests: {
        mentorIncoming: 'Meine Mentoring-Anfragen',
        mentorCurrent: 'Meine Mentorate',
        menteeOutgoing: 'Meine Mentoring-Anfrage',
        menteeCurrent: 'Mein Mentorat',
        cancelMentoring: 'Mentorat beenden',
        sendRequest: 'Als Mentor anfragen',
        alreadyHaveMentor: 'Sie können nur einen Mentor haben',
        requestSuccess: 'Anfrage erfolgreich gesendet',
        requestRetracted: 'Anfrage erfolgreich zurückgezogen',
        cancelSendingRequest: 'Mentorenanfrage abbrechen',
        approveRequest: 'Anfrage annehmen',
        rejectRequest: 'Anfrage ablehnen',
        invitaionText: 'Anfrage',
        rejectionText: 'Begründen Sie Ihre Ablehnung',
        sureRetractRequest: 'Anfrage wirklich zurückziehen?',
      },
    },
    downloads: {
      root: 'Kezdőlap',
      delete: 'Törlés',
      rename: 'Átnevezés',
      areYouSureDeleteFolder: 'Biztosan törölni szeretné a mappát? Minden tartalom törlődni fog!',
      renameFolder: 'Mappa átnevezése',
      createNewFolder: 'Új mappa létrehozása',
      newFolderPlaceholder: 'Adja meg a mappa nevét',
      create: 'Létrehozás',
      areYouSureDeleteFile: '',
      downloadFile: 'Letöltés',
      deleteFile: 'Törlés az eszközről',
      openInFinder: 'Megnyitás a Finderben',
      cancel: 'Mégse',
    },
    globalSearch: {
      postedBy: 'Közzétette',
      found: 'Találat',
      loadMore: 'További betöltése',
      enterQuery: 'Adjon meg bármilyen keresési kifejezést. Keresés bármi (név, dokumentum, esemény, bejegyzés, ...)',
      folder: 'Mappa',
      post: 'Bejegyzés',
      error: 'Legalább 3 karaktert adjon meg',
    },
    kreiseSettings: {
      description: 'Beschreibung',
      push: 'Push-Benachrichtigungen',
      email: 'Mail-Benachrichtigungen',
      subscribers: 'Mitglieder',
      active: 'Aktiviert',
      oneYear: 'Stumm für 1 Jahr',
      forever: 'Stumm für immer',
      until: 'Bis',
      cantDeactive: 'Für diesen Gruppenchat ist diese Einstellung deaktiviert',
      SINGLE: 'Für jeden Beitrag',
      SUMMARY_ONCE: 'Zusammenfassung 1x pro Woche',
      SUMMARY_TWICE: 'Zusammenfassung 2x pro Woche',
      SUMMARY_DAY: 'Zusammenfassung 1x pro Tag',
      OFF: 'Aus',
      PUSH: 'Push-Benachrichtigung',
      EMAIL: 'Mail-Benachrichtigung',
      PUSH_AND_EMAIL: 'Push- und Mailbenachrichtigung',
      newUser: 'Benachrichtigung für neue Nutzer',
      statistic: 'Sende-Statistik',
      off: 'Aus',
      ownPosts: 'Sende-Statistik-Mail für deine Beiträge',
      deleteMemberDescr: 'entfernen?',
      deleteMemberBtn: 'Entfernen',
      retractAdminRights: 'Rechte entziehen',
      deleteAdminAndUser: 'Ganz entfernen',
      toDeleteKreiseBtn: 'Gruppenchat löschen',
      deleteKreiseDescr: `${KreiseName('GER').single} unwiderruflich löschen?`,
      deleteKreiseModalTitle: 'Mitglied entfernen?',
      addKreiseName: 'Name',
      addKreiseDescription: 'Beschreibung',
      addMembers: 'Mitglieder hinzufügen',
      createKreise: 'Gruppenchat erstellen',
      toKreiseEditMessage: 'Hinweis: Alle Änderungen an den Mitgliedern werden sofort umgesetzt.',
      kreiseCreator: 'Ersteller:in dieses Gruppenchats',
      kreiseDeleteMembersLeft: 'Kreise can be deleted only if there is only one member left',
      kreiseDeleteMembersLeftTitle: 'Remove members first',
      suggestionInNewsfeed: {
        admin: 'Admin',
        member: 'Mitglieder',
        corp: `${CorpsName('GER').single} mitglieder`,
        corpAdmin: `${CorpsName('GER').single} admin`,
      },
    },
    surveys: {
      checkVotes: 'View results',
      chooseOptions: {
        single: 'Choose one option',
        multiple: 'Choose multiple options',
      },
      votesModalTitle: 'Votes',
      createModalTitle: 'Poll',
      noVotes: 'There are no votes',
      question: 'Question',
      askAQuestion: 'Ask a question',
      allowMultiple: 'Allow multiple answers',
      votes: {
        single: 'Vote',
        multiple: 'Votes',
      },
      option: 'Option',
      optionsLabel: 'Poll options',
      anonymus: 'Anonymus poll',
      create: 'Create',
    },
  },
  {
    key: 'SP', //spanish language
    key2: 'es',
    responseHandler: {
      messageLabel: 'Error desconocido. Póngase en contacto con info@connact.app',
      expiredLinkLabel: 'Enlace caducado. Solicitar un nuevo cambio de contraseña.',
      emailExistLabel: 'Lo sentimos, esta dirección de correo electrónico ya está registrada.',
      profileUpdated: 'Perfil actualizado',
      profileUpdatedCorpsError: `Perfil actualizado Las siguientes ${
        CorpsName('SP').single
      } no se ha podido añadir debido a un código de acceso incorrecto:`,
      appNeedsUpdate: 'Actualiza la aplicación en App Store / Google Play.',
      maximalVideoSize: 'El tamaño máximo de los archivos es de 2 GB.',
    },
    report: {
      notBelongLabel: 'El usuario ya no es miembro.',
      incProfileLabel: 'El usuario hace falsas ofertas de perfil',
      badPicLabel: 'La foto de perfil del usuario infringe las directrices',
      badComLabel: 'Las declaraciones del usuario infringen las directrices',
      spamLabel: 'El usuario envía spam en el chat',
      reasonsLabel: 'Motivos de la notificación',
    },
    drawer: {
      news: `Últimas noticias`,
      kreise: KreiseName('SP').multi,
      search: 'Buscar en',
      calendar: CalendarName('SP'),
      documents: `${DocumentsName('SP')}`,
      contacts: 'Contactos',
      requests: 'Contacto',
      messages: MessagesName('SP'),
      profile: ProfileName('SP'),
      logout: 'Cerrar sesión',
      map: 'Mapa',
      mentoring: 'Tutoría',
    },
    imageCropper: {
      cropLabel: 'Cultivos',
      cancelLabel: 'Cancelar',
      chooseLabel: 'Listo',
    },
    signIn: {
      register: 'Regístrese en',
      title: 'Conéctese',
      loginPlaceholder: 'Correo electrónico',
      passwordPlaceholder: 'contraseña',
      forgotPassword: '¿Ha olvidado su contraseña?',
      loginBtn: 'Inicio de sesión',
      noAccount: '¿Aún no tiene cuenta?',
      description0: 'Si tiene alguna pregunta, póngase en contacto con',
      description1: 'o',
      description2: 'vuelta.',
      loginValidation: 'Su dirección de correo electrónico no es correcta',
      passwordValidation: 'La contraseña debe tener al menos 6 caracteres',
    },
    search: {
      name: 'Nombre',
      corps: CorpsName('SP').single,
      country: CountryName('SP'),
      city: 'Ciudad',
      industry: IndustryName('SP'),
      company: 'Empresa',
      profession: 'Profesión',
      hobby: 'Intereses',
      cultural: 'Patrocinadores culturales',
      study: `${StudyName('SP').short}`,
      office: 'Sucursal',
      degreeProgram: StudyName('SP').studiengang,
      university: StudyName('SP').university,
      position: `${FunctionName('SP')}`,
      status: 'Estado',
      title: 'Resultado de la búsqueda de',
      filterTitle: 'Filtrar por',
      postCode: 'Código postal',
      global: 'Todo',
      activity: ActivityName('SP'),
    },
    resetPassword: {
      enterEmail: 'Introduzca su dirección de correo electrónico',
      inputPlaceholder: 'Correo electrónico',
      sendBtn: 'Enviar',
      cancelBtn: 'Cancelar',
      message: 'Si conocemos la dirección de correo electrónico, se ha enviado un enlace a la misma.',
      desc: 'Debes introducir la dirección de correo electrónico exacta que está almacenada en la aplicación. Comprueba tus correos electrónicos para ver cuál es.',
    },
    password: {
      resetLabel: 'Introduzca una nueva contraseña',
      inputLabel: 'Nueva contraseña',
    },
    signUp: {
      createAccount: 'Crear una cuenta',
      inputPlaceholder: `Código de inicio de sesión de su ${CorpsName('SP').single}s`,
      sendBtn: 'Más información en',
      descFirst: 'Al registrarse, acepta nuestros términos y condiciones ',
      descSecond: 'y confirme que ha leído nuestro',
      descThird: `Si tiene alguna pregunta, no dude en ponerse en contacto con nosotros en ${support_email} o +49 170 49 805.`,
      terms: 'condiciones generales',
      policy: 'política de privacidad.',
      description: 'Nota',
      generatePassword: 'Generar contraseña',
      noCodeSuccessRegister:
        'Gracias por registrarse. Se le informará por correo electrónico en cuanto se le haya activado.',
    },
    signUpDefault: {
      withoutCode: 'Sin código',
      createAccount: 'Sólo tiene que inscribirse aquí',
      withCode: 'Con código de invitación',
      simpleRegister: 'Sin código de invitación',
      existingAccount: 'Ya tiene una cuenta',
      descr01: 'Estoy de acuerdo con el',
      descr0: 'Al registrarse, acepta la',
      descr1: 'y el',
      descr2: 'a.',
      guest: `Registrarse como ${guest_naming_singular}`,
      withCodeWithDescr: `Registrieren als ${member_naming_singular}`,
    },
    signUpManual: {
      title: 'Regístrese en',
      photoDesc: 'Añadir foto',
      genderInput: 'Género',
      firstName: 'Nombre',
      lastName: 'Apellido',
      club: CorpsName('SP').single,
      specify: 'especifique',
      emailPlaceholder: 'Correo electrónico',
      emailHint: 'Utilice una dirección de correo electrónico de larga duración.',
      repeatEmailPlaceholder: 'E-Mail-Repetir dirección',
      passwordPlaceholder: 'Establecer contraseña',
      repeatPasswordPlaceholder: 'Repetir contraseña',
      postcode: 'Código postal',
      place: 'Lugar de residencia',
      country: 'País',
      loginCode: 'Código de invitación',
      mobilePlaceholder: 'Móvil no. (no visible en el perfil)',
      corps: SignUpCorpsName('SP'),
      searchCountry: 'Suche nach Land',
    },
    backToLogin: {
      desc: '¿Ya tiene una cuenta? ',
      login: 'Login',
    },
    backToSignUp: {
      desc: '¿Aún no tiene cuenta? ',
      register: 'Regístrese en',
    },
    privacy: {
      desc: 'Sí, acepto la',
      btn: 'Condiciones',
      zu: 'a',
      map: {
        title: 'Protección de datos',
        description:
          'Esta aplicación se ha implementado de conformidad con el GDPR. Solo utilizamos Google Maps para mostrar el mapa. No se transmite ningún dato personal. Además, las chinchetas solo se muestran de forma aproximada, basándose en el código postal/ciudad pero sin calle/no. Esto significa que no es posible sacar conclusiones sobre las direcciones concretas de los usuarios.',
      },
      restrictions: {
        overall: 'Esta función sólo es accesible a los afiliados.',
        overallWilkar: 'Esta función no es accesible por razones de protección de datos.',
        profile:
          'Sólo los miembros pueden ver los perfiles de los usuarios. Sin embargo, puedes escribir un mensaje privado al usuario.',
        groupchat: `Die ${KreiseName('SP').single} sólo son accesibles para los miembros.`,
        groupchatWilkar: `Por motivos de protección de datos ${
          KreiseName('SP').single
        } sólo son accesibles a los afiliados.`,
        comments: 'Los comentarios sólo son visibles para los miembros.',
        commentsWilkar: 'Por razones de protección de datos, los comentarios sólo pueden ser vistos por los miembros.',
        likes: 'La lista de "Me gusta" sólo la pueden ver los miembros.',
        post: 'Sólo los miembros pueden publicar artículos.',
        participantsList: 'La lista de participantes sólo puede ser consultada por los miembros.',
        commentsOnlyForMembers: 'Los comentarios sólo son visibles para los miembros.',
        postsLimitation: 'Los mensajes antiguos sólo pueden ser vistos por los miembros.',
      },
      settings: {
        guestPost: {
          title: `Verificado ${guest_naming_plural} puede ver mis mensajes`,
          ask: 'Pregunte siempre',
          no: 'No',
          yes: 'Sí',
        },
      },
      guestModal: {
        title: `Mayo validado ${guest_naming_plural} ver este post? Posteriormente, puede cambiar esta configuración de visibilidad para cada entrada individual.`,
        dontAskAgain:
          'No vuelvas a preguntar (Puede cambiar esta configuración en "Perfil". → Cambiar de nuevo "Privacidad).',
      },
      post: {
        ifShown: `Para ${guest_naming_plural} ocultar`,
        ifHidden: `Para ${guest_naming_plural} Mostrar`,
        ifShownModal: `La contribución ya está disponible para ${guest_naming_plural} ya no es visible.`,
        ifHiddenModal: `La contribución ya está disponible para ${guest_naming_plural} visible.`,
      },
    },
    searchModal: {
      landTitle: 'Elija un país',
      corpsTitle: `${CorpsName('SP').single} Seleccione`,
      pleaseSelect: 'Seleccione una opción',
    },
    btn: {
      sendBtn: 'Enviar',
      cancelBtn: 'Cancelar',
      select: 'Seleccione',
      cancelEventBtn: acceptEventName('SP').cancel,
      acceptBtn: acceptEventName('SP').accept,
      saveBtn: 'Guardar',
      clearBtn: 'Borrar',
      acceptReqBtn: 'Acepte',
      declineReqBtn: 'Cancelar',
      updateBtn: 'Actualizar',
      declineBtn: 'Rechazar',
      yesUnderstood: 'Ja, verstanden',

      ok: 'OK',
    },
    validation: {
      email: 'Su dirección de correo electrónico no es correcta',
      password: 'La contraseña debe tener al menos 6 caracteres',
      repeatEmail: 'El correo electrónico y la repetición de correo electrónico no coinciden.',
      repeatPassword: 'La contraseña y la repetición de contraseña no coinciden',
      required: 'Este campo es obligatoriot',
      matchPasswords: 'Coincidencia de contraseña antigua y nueva',
      dontMatchPasswords: 'La contraseña y la repetición de contraseña no coinciden.',
    },
    init: {
      title: 'Cargando',
      overlayLabel: 'Compruebe su conexión a Internet',
    },
    screenTitles: {
      postsTitle: `Últimas noticias`,
      circlesTitle: KreiseName('SP').multi,
      searchTitle: 'Buscar en',
      profileTitle: ProfileName('SP'),
      eventTitle: CalendarName('SP'),
      eventDetailsTitle: 'eventos',
      newEventTitle: 'Añadir evento',
      contactsTitle: 'Contactos',
      contactReqTitle: 'Contacto',
      reqDetailsTitle: 'Contacto',
      outReqTitle: 'Contacto',
      messagesTitle: MessagesName('SP'),
      changePasswordTitle: 'Cambiar contraseña',
      appInfoTitle: 'Acerca de',
      reportTitle: 'Denunciar usuario',
      createKreiseTitle: 'Crear chat de grupo',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      user: 'Usuarios',
      mainTitleLabel: ProfileName('SP'),
      basisLabel: 'Base',
      clubLabel: CorpsName('SP').single,
      clubLabelPlural: CorpsName('GER').multi,
      professionLabel: 'Profesión',
      studyLabel: StudyName('SP').short,
      uploadPhotoLabel: 'Haz una foto',
      editPhotoLabel: 'Editar foto',
      profileCompletedLabel: 'Perfil',
      profileCompletedGLabel: 'completa',
      birthday: 'Fecha de nacimiento',
      functionLabel: `${FunctionName('SP')}`,
      dateOfJoinLabel: CorpsEditDate('SP'),
      positionDescLabel: 'Profesión',
      companyLabel: CompanyName('SP'),
      nameLabel: 'Nombre',
      websiteLabel: 'Página web',
      fromLabel: 'En',
      toLabel: 'Hasta',
      delete: 'Borrar',
      untilToday: 'Hasta hoy',
      degreeLabel: StudyName('ENG').abshchluss,
      miscellaneos: 'Varios',
      hobbies: 'Intereses',
      cultural: 'Patrocinadores culturales',

      positionLabel: 'Profesión',
      firmaLabel: 'Empresa',
      industryLabel: IndustryName('SP'),
      areaLabel: 'Gama',
      typeOfEmplLabel: 'Tipo de empleo',
      activityLabel: ActivityName('SP'),
      careerStageLabel: 'Nivel profesional',
      activityDescLabel: 'Descripción de la actividad',
      employmentPeriodLabel: 'Periodo',
      universityLabel: StudyName('SP').hochsule,
      courseLabel: StudyName('SP').studiengang,
      ageLabel: 'Edad',
      birthdayLabel: 'Cumpleaños',
      editProfileLabel: 'Editar perfil',
      addContactLabel: 'Añadir como contacto',
      sendMessageLabel: 'Enviar mensaje',
      requestSentLabel: 'Solicitud de contacto ya enviada',
      sendRequestSuccessLabel: 'Consulta enviada correctamente',
      reportSentLabel: 'Usuario ya informado',
      addReportLabel: 'Denunciar/bloquear usuario',
      contactsLabel: 'Número de contactos',
      showBirthday: 'Contactos ver mi cumpleaños',
      street: 'Calle + Nº ',

      partner: 'Nombre del socio',
      mobileNumberLabel: 'Móvil no.',
      handphoneNumberLabel: 'Número de teléfono móvil',
      telephoneNumberLabel: 'Número de teléfono',

      leibbursch: 'Leibbursch',
      leibfuechse: 'Leibfüchse',
      bio: 'Sobre mí',
      addDocuments: 'Añadirle documentos',
      image: 'Fotografía',
      video: 'Vídeo',
      pdf: 'PDF',
      link: 'Link',
      firstName: 'Nombre de pila',
      lastName: 'Apellidos',
      title: 'Título',
      email: 'E-Mail',
      name: 'Nombre',
      lastname: 'Apellido',
      country: CountryName('SP'),
      wohnort: 'Ciudad',
      postcode: 'CÓDIGO POSTAL',
      inReview: 'Confirmation via mail pending',
    },
    calendar: {
      birthdayLabel: 'Cumpleaños de',
      noEventsLabel: 'No hay eventos para la fecha seleccionada',
      allTabLabel: 'Todos',
      myTabLabel: 'Mi',
      clubsTabLabel: CorpsName('SP').single,
      subsTabLabel: 'Suscripciones',
      subscribedTabLabel: 'Abonados',
      otherTabLabel: 'Otros',

      error: 'La fecha/hora inicial debe ser anterior a la fecha/hora final.',
      filter: {
        all: 'Todos',
        invited: 'Invitado',
        confirmed: 'Prometido',
      },
    },
    eventLabels: {
      inviteTo: 'Invitación a',
      addEndDate: 'Introduzca la fecha/hora de finalización',
      eventLabel: 'Organizador',
      startLabel: 'Comienza en',
      locationLabel: 'Lugar',
      participantLabel: 'Participantes',
      moreInfo: 'Para más información',
      commentsLabel: 'Comentarios',
      noCommentsLabel: 'Aún no hay comentarios',
      eventStartLabel: 'Inicio',
      eventEndLabel: 'Fin',
      descLabel: 'Descripción de la',
      creatorLabel: 'Creado por',
      titleLabel: 'Título',
      deleteEventTitle: '¿Borrar evento?',
      deleteEventLabel: '¿Suprimir este evento?',
      clubsTitle: `${CorpsName('SP').single}s`,
      gruppenchat: 'Seleccionar chat de grupo',
      contactsTitle: 'Contactos',
      delCommentLabel: '¿Borrar comentario?',
      sendInvite: 'Enviar invitación a',
      selectCorps: 'Seleccionar el grupo destinatario',
      externalGuest: 'Invitados externos',
      externalGuestPlaceholder: 'Introduzca su dirección de correo electrónico',
      attachmentLabel: 'Anexo',
      shareLinkLabel: 'Compartir enlace',
      linkLabel: `${guest_naming_singular} entre en`,
      copyLinkLabel: 'Copiar enlace',
      iCalDownload: 'Calendario',
      timezone: 'Huso horario',
      visibility: 'Visibilidad',
      errorDate: 'La fecha está en el pasado',
      startAfterEndError: 'La fecha de finalización debe ser posterior a la de inicio',
      members: 'Members',
      waitlist: 'Warteliste',
      onWaitlist: 'auf der Warteliste',
      leaveWaitlist: 'Platz freigeben?',
      leaveWaitlistDesc: `Von Warteliste entfernen?\n\n Wenn du dich später wieder auf die Warteliste setzt, landest du ggf. auf einer hinteren Position.`,
      limitAchieved: (limit: number) =>
        `Die maximale Teilnehmeranzahl von ${limit} ist erreicht. Du wurdest auf die Warteliste gesetzt.`,
      visibilityOptions: {
        allWithoutGuests: 'Mitglieder und auf Webseite',
        invited_member: 'Sólo miembros invitados',
        verified_member: 'Todos los miembros',
        authorized_member: 'Todos los socios e invitados',
        all: 'Miembros, invitados y en el sitio web',
      },
      modalEvent: {
        title: 'Evento creado',
        subTitle: 'El acontecimiento se creó.\n\n',
        content:
          'Dependiendo de la configuración del usuario, ahora se enviarán notificaciones push/notificaciones por correo electrónico sobre el evento. Si lo ha configurado en "Perfil" → "Notificaciones", también recibirá una notificación por correo electrónico sobre el evento.',
      },
      modalComment: {
        title: '¿A quién avisar?',
        message: 'Quién debe ser informado de su comentario?',
        optionAll: 'Todos los participantes',
        optionNone: 'Sólo organizador(es)',
        button: 'Enviar comentario',
      },
      cancelModal: {
        title: '¿Cancelar o eliminar?',
        message: '¿Cancelar o eliminar un evento?',
        optionAll: 'Cancelaciones (se informará a los participantes)',
        optionNone: 'Suprimir (no se informa a los participantes)',
      },
      updateEventModal: {
        title: 'Envío de nuevas invitaciones',
        label: 'Los demás grupos fueron invitados.',
        message:
          'Dependiendo de la configuración, ahora se envían notificaciones push o por correo electrónico sobre el evento a los demás grupos.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/Mailadresse',
        enterLink: 'Añadir enlace/dirección de correo electrónico o similar',
      },
      parts: {
        part: 'Part',
        addPart: 'Add program point',
        hint: 'Explanation',
        question: 'Question',
        single: 'Only one option selectable',
        multi: 'Several options available',
        freeText: 'Free text answer',
        quiz: 'Quiz',
        questionnaire: 'Questionnaire',
        addQuestionnaire: 'Add Questionnaire',
        questionnaireType: 'Answer options',
        option: 'Option',
        addOption: 'Add option',
        showMembers: 'Participants',
        youJoined: 'You take part in:',
        totalToPay: 'Total amount to be paid:',

        yourAnswer: 'Your answer',
        visibleForCreators: 'Visible only for creators',
        allAnswers: 'All answers:',
        addUsers: 'Add users',
        isAnonym: 'Anonymous voting',
        isAnonymQuiz: 'Anonymes Quiz',
      },
    },
    rrEventLabels: {
      title: 'Repetición',
      MO: 'Montag',
      TU: 'Lunes',
      WE: 'Miércoles',
      TH: 'Jueves',
      FR: 'Viernes',
      SA: 'Sábado',
      SU: 'Domingo',
      YEARLY: { single: 'Año', regular: 'Anualmente' },
      MONTHLY: { single: 'mes', regular: 'Mensualmente' },
      WEEKLY: { single: 'semana', regular: 'Semanal' },
      DAILY: { single: 'Día', regular: 'Diario' },
      SINGLE: { single: 'Único', regular: 'Único' },
      CUSTOM: {
        single: 'Definido por el usuario...',
        regular: 'Definido por el usuario...',
      },
      freq: 'Frecuencia',
      interval: 'Intervalo',
      notRepeat: 'Único',
      every: 'Cada',
      month: 'mes',
      week: 'semana',
      last: 'último',
      on: 'en',
      everyMonthOn: 'Todos los meses en',
      ofTheMonth: 'del mes',
      editLabel: '¿Qué fechas desea cambiar?',
      thisDate: 'Esta fecha sólo',
      thisDateAndFollowing: 'Ésta y todas las',
      allDates: 'Todas las fechas',
      after: 'En',
      time: 'Citas',
      times: 'Terminen',
      neverEnds: 'Nunca termina',
      until: 'hasta',
      repeatedDefault: ({ frequency, freq }) =>
        `¿Es cada${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} repetido`,
      repeatedMonthly: ({ day, interval }) =>
        `Será cada${interval == 1 ? '' : ` ${interval}.`} Mes en ${day.length > 2 ? day : `${day}.`} repetido.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }) =>
        `Será cada${bysetpos == -1 ? ' último' : ` ${bysetpos}.`} ${day} de la${
          interval === 1 ? '' : ` ${interval}.`
        } Mes repetido.`,

      repeatedYearly: ({ interval }) => `Si cada${interval == 1 ? '' : ` ${interval}.`} Año repetido.`,
      repeatedWeekly: ({ interval, byweekday }) =>
        `¿Es cada${interval != 1 ? ` ${interval}.` : ''} semana${
          !!byweekday?.length
            ? ' am ' + byweekday?.slice(0, -1).join(', ') + (byweekday?.length > 1 ? ' y ' : '') + byweekday?.slice(-1)
            : ''
        } repetido.`,
      repeatedDaily: ({ interval }) => `Será cada${interval != 1 ? ` ${interval}.` : ''} Día repetido.`,
    },
    requestsLabels: {
      receiveLabel: 'Reciba',
      sentLabel: 'Enviado',
    },
    post: {
      adTitle: 'Información/visualización',
      toKreise: 'en',
      comment: 'Comentarios',
      myPost: 'I',
      openPdf: 'Haga clic para abrir',
      deletePost: '¿Borrar este post?',
      posted: 'Publicado en',
      commented: 'Comentado',
      contacts: 'Contactos',
      sendToContacts: 'Mensaje a todos',
      enterText: 'Escriba aquí un mensaje',
      sendToCircle: `Mensaje para ${KreiseName('SP').singleArticle}`,
      sendComment: 'Escriba un comentario',
      longMessage: 'Máximo 20.000 caracteres',
      noComments: 'Aún no hay comentarios',
      deleteComment: '¿Borrar comentario?',
      deleteEventPost: '¿Borrar este mensaje? Esto no borrará el evento.',
      editPostLabel: 'Editar entrada',
      editingPostLabel: 'Modo edición',
      deletePostLabel: 'Suprimir puesto',
      editedAt: 'Procesado',
      pinned: 'Clavado.',
      actions: {
        label: 'Acciones postales',
        pin: {
          label: 'Pin post',
          once: 'Sube una vez',
          d3: '3 días',
          d5: '5 días',
          w1: '1 semana',
          w2: '2 semanas',
          unpin: 'No fijar más',
        },
      },
    },
    circles: {
      circlesTitle: `Abonados ${KreiseName('SP').multi}`,
      noCirclesTitle: `No suscrito ${KreiseName('SP').multi}`,
      myPost: 'I',
      circlesInfoTitle: 'Información y participantes',
      circlesSubscribe: 'Suscríbase a',
      unsubscribeAlert: '¿Dejar irrevocablemente un chat de grupo cerrado?',
      continueAlert: 'Abandono',
      readOnlyKreise: `Se trata de un "sólo lectura-${
        KreiseName('SP').single
      }". Sólo los administradores pueden crear mensajes aquí. Sin embargo, puedes escribir comentarios en las entradas.`,
    },
    messages: {
      addMessageLabel: 'Introducir mensaje',
      changesSaved: 'Cambios guardados.',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: 'Base',
      clubsTab: `${CorpsName('SP').single}`,
      miscellaneosTab: 'Intereses',
      professionTab: 'Profesión',
      studyTab: StudyName('SP')?.short,
      notificationsTab: 'Notificaciones',
      privacy: 'Privacidad',
    },
    profile: {
      addPhotoLabel: 'Añadir foto',
      genderInputLabel: 'Género',
      titleLabel: 'Título',
      firstNameLabel: 'Nombre',
      lastNameLabel: 'Apellido',

      passwordLabel: 'Establecer contraseña',
      birthDateLabel: 'Fecha de nacimiento',

      changePasswordLabel: 'Cambiar contraseña',
      defaultCountryLabel: 'Alemania',
      defaultGraduationLabel: 'No especificado',
      defaultCorpsLabel: 'ninguno',
      street: 'Calle + No. ',

      partner: 'Nombre del socio',
      mobileNumberLabel: 'Nº de móvil',
      handphoneNumberLabel: 'Número de teléfono móvil',
      telephoneNumberLabel: 'Número de teléfono',
    },
    profileKorp: {
      verband: CorpsName('SP').single,
      function: FunctionName('SP'),
      dateOfJoin: CorpsEditDate('SP'),
      beername: 'Nombre de la cerveza',
    },
    profileClubs: {
      noUserLabel: 'Para poder utilizar la aplicación, primero hay que rellenar toda la información básica..',
      functionLabel: `${FunctionName('GER')}`,
      setClubLabel: `Introduzca el nombre del ${CorpsName('GER').single} especifique.`,
      matchClubLabel: 'En',
      matchClubContinueLabel: 'ya existe en el perfil, compruébelo una vez.',
      clubLabel: `${CorpsName('SP').single.toUpperCase()}`,
      clubCodeLabel: `CÓDIGO DE ACCESO DE SU ${CorpsName('SP').single.toUpperCase()}S`,
      entryTimeLabel: CorpsEditDate('SP'),
      deleteClubLabel: '¿Desea ampliar su afiliación a la',
      deleteClubContinueLabel: '¿Borrar de verdad?',
      selectVariant: '¿Qué le gustaría hacer?',
      requestExistingCorp: 'Añadir entrada existente',
      requestNewCorps: 'Solicitar nueva entrada',
      createCorp: 'Crear una nueva entrada',
    },
    profileProfession: {
      jobTitle: 'Puesto',
      noUserLabel: 'Antes de poder utilizar la aplicación, hay que rellenar toda la información básica.',
      untilToday: 'Hasta hoy',
      positionTitleLabel: 'Profesión',
      itemNameLabel: 'Profesión',
      employmentLabel: 'Tipo de empleo',
      activityLabel: ActivityName('SP'),
      careerStageLabel: 'Nivel profesional',
      activityDescLabel: 'Descripción de la actividad',
      companyLabel: CompanyName('SP'),
      nameLabel: 'Nombre',
      industryLabel: IndustryName('SP'),
      areaLabel: 'Gama',
      websiteLabel: 'Sitio web de la empresa',
      employmentPeriodLabel: 'Período de empleo',
      fromLabel: 'En',
      toLabel: 'Hasta',
    },
    profileStudy: {
      noUserLabel: 'Antes de poder utilizar la aplicación, hay que rellenar toda la información básica.',
      universityLabel: StudyName('SP').hochsule,
      courseLabel: StudyName('SP').studiengang,
      degreeLabel: StudyName('SP').abshchluss,
    },
    profileOther: {
      noUserLabel: 'Antes de poder utilizar la aplicación, hay que rellenar toda la información básica.',
      hobbiesLabel: 'Intereses',
      culturalLabel: 'Patrocinadores culturales',
      socialMediaPlattform: 'Plataforma de medios sociales',
      socialMediaLabel: 'Enlaces a redes sociales',
      socialMediaLink: 'Link',
      socialMediaError: 'Introduzca la URL completa',
    },
    profileNotifications: {
      noUserLabel: 'Para poder utilizar la aplicación, primero hay que rellenar toda la información básica..',
      notificationsInfo:
        'Aquí puede activar las notificaciones por correo electrónico para actividades individuales (por defecto) o desactivarlas durante 3 meses.',
      notificationsTitle: 'Notificaciones push:',
      notificationsDescr0: 'Puede configurar las notificaciones push en ',
      notificationsDescr1: 'Ajustes del sistema del smartphone',
      notificationsDescr2: ' escriba a.',
      mailingTitle: 'Notificaciones por correo electrónico:',
      mailingDescr:
        'Aquí puede activar las notificaciones por correo electrónico para actividades individuales (por defecto) o desactivarlas durante 1 año.',
      allNotificationsLabel: 'Todas las notificaciones',
      postsLabel: `Contribuciones en ${KreiseName('SP').multi}`,
      messagesLabel: 'Mensajes privados',
      commentsLabel: 'Comentarios sobre las "contribuciones propias',
      contactRequestsLabel: 'Contacto',
      eventsLabel: 'eventos',
      clubMemberLabel: 'Nuevos miembros registrados',
      enableLabel: 'An',
      disableToLabel: 'Aus bis',
      disableLabel: 'Aus',
      ownPosts: 'Mis contribuciones (correo recibido también por mí)',
    },
    searchInput: {
      title: 'Buscar en',
      placeholder: 'Buscar en',
    },
    emptyList: {
      title: 'No hay contenido aquí (todavía)',
    },
    switchLanguage: {
      placeholder: 'Idioma/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'russian (русский)',
      SP: 'Spanish (Español)',
      title: 'Seleccionar idioma',
    },
    filePicker: {
      takePicLabel: 'Archivos adjuntos',
      uploadPicLabel: 'Haz una foto',
      selectPicLabel: 'Seleccionar foto/vídeo',
      selectDocLabel: 'Seleccionar documento',
    },
    datePicker: {
      selectDateLabel: 'Seleccione la fecha',
      confirmDateLabel: 'Confirme',
      selectTimeLabel: 'Seleccionar hora',
    },
    changePassword: {
      currentPasswordLabel: 'Contraseña actual',
      newPasswordLabel: 'Nueva contraseña',
      repeatPasswordLabel: 'Repetir contraseña',
      changePasswordLabel: 'Cambiar contraseña',
    },
    appInfo: {
      appDescLabel:
        'Korponnect es la aplicación para estudiantes de fraternidades. Intercambio entre los miembros, organización de eventos y creación de redes profesionales y privadas: todo en una sola aplicación.',
      contactsLabel: InfoContactsName('SP'),
      infoLabel: 'Información sobre',
      versionLabel: 'Versión de la aplicación',
      agbLabel: 'GTC',
      dataProtectionLabel: 'Política de privacidad',
      manualLabel: 'Instrucciones',
      appTextLabel: 'Aquí encontrará nuestro',
      appAndLabel: 'y un',
      appMainTextLabel: `¿Quieres añadir un círculo o tienes otra sugerencia? ¿Quiere borrar su perfil? Envíenos un breve correo electrónico a ${support_email}.`,
      deleteFeedback: 'Motivo de la eliminación / comentario 🙂:',
      deleteUserTitle: '¿Borrar cuenta?',
      deleteUserDesc:
        'Aquí puedes eliminar tu cuenta de forma irrevocable.\b Por cierto: también puedes desactivar las notificaciones de forma sencilla. Edita la configuración en Perfil -> Notificaciones o envíanos un correo electrónico.',
      deleteUserAlert: '¿De verdad quieres borrar tu cuenta?',
      webLabel: 'Versión web',
    },
    infoMessages: {
      registartionSuccessLabel: 'Gracias por inscribirse.',
      manualRegSuccessLabel:
        'Gracias por registrarse. En breve se le activará y se le informará por correo electrónico.',
      linkExpiredLabel: 'Enlace caducado. Solicitar un nuevo cambio de contraseña.',
      emailExistLabel: 'Lo sentimos, el correo electrónico no está disponible.',
    },
    logoutAlert: {
      title: 'Cerrar sesión',
      text: '¿De verdad quieres cerrar la sesión? No tienes que cerrar la sesión, puedes simplemente cerrar o minimizar la aplicación.',
      confirm: 'Sí',
      dismiss: 'Cancelar',
    },
    invalidInputs: {
      passwordChars: `La contraseña debe ser como mínimo ${passwordDigits(
        'SP',
      )} caracteres, incluyendo al menos una letra, un número y un carácter especial.`,
      fieldRequired: 'Este campo es obligatorio.',
      emailsNotMatching: 'Asegúrese de que sus correos electrónicos coinciden',
      emailInvalid: 'Su dirección de correo electrónico no es correcta.',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: 'Seleccione al menos 1 entrada',
    },
    mentoring: {
      profile: {
        rules1: 'Estoy de acuerdo con el',
        rules2: ' Comprensión de los valores ',
        rules3: '“Tutoría para becarios” a.',
        menteeDescription:
          'Seleccione su región de tutoría para que se le pueda informar sobre los eventos in situ. NOTA: Puede cambiar su selección en cualquier momento. NO limita su búsqueda de mentores.',
        mentorDescription:
          'Por favor, rellene su perfil de mentor para que los becarios puedan encontrarle como mentor. NOTA: Se requiere al menos 1 región y al menos 1 área temática.',
        mentoringRegions: 'Regiones de tutoría.',
        reigonsShort: 'Regiones',
        topics: 'Áreas temáticas',
        departments: 'Departamento',
        available: 'Estoy disponible',
        unavailable: 'Actualmente no estoy disponible',
        deleteAccount: 'Eliminar el perfil del tutor y abandonar el grupo',
        deleteAccountDescr: '¿Borrar el perfil de tutor y abandonar el chat del grupo de tutoría?',
        uploadPdf: 'Perfil del tutor (PDF)',
        myPdf: 'Mi perfil (PDF)',
        pdf: 'Perfil del tutor',
      },
      tabNames: {
        info: 'Información',
        news: 'Noticias',
        profile: 'Perfil',
        search: 'Búsqueda y consulta',
        request: 'Mentoriza',
        requestDetails: 'Solicitud de tutoría',
        tabDeactivated: 'Pestaña desactivada. Rellene su perfil de tutor.',
      },
      search: {
        description:
          'Para iniciar la búsqueda de forma selectiva, le recomendamos que comience con unos pocos campos de selección y, a continuación, amplíe la parrilla de búsqueda en una nueva búsqueda. Si no encuentra un mentor adecuado en su región, amplíe la búsqueda a una región de mentores vecina. Criterios de búsqueda: 1 región, de 1 a 3 áreas temáticas. Todos los campos están vinculados por AND.',
        searchBtn: 'Suche',
      },
      requests: {
        mentorIncoming: 'Mis consultas sobre tutoría',
        mentorCurrent: 'Mis mentorados',
        menteeOutgoing: 'Mi solicitud de tutoría',
        menteeCurrent: 'Mi tutoría',
        cancelMentoring: 'Fin de la tutoría',
        sendRequest: 'Solicitud como mentor',
        alreadyHaveMentor: 'Sólo puedes tener un mentor',
        requestSuccess: 'Consulta enviada correctamente',
        requestRetracted: 'Consulta retirada con éxito',
        cancelSendingRequest: 'Retirar la solicitud de tutoría',
        approveRequest: 'Aceptar solicitud',
        rejectRequest: 'Rechazar solicitud',
        invitaionText: 'Consulta',
        rejectionText: 'Razone su rechazo',
        sureRetractRequest: '¿De verdad quiere retirar la consulta?',
      },
    },

    downloads: {
      root: 'Inicio',
      delete: 'Eliminar',
      rename: 'Renombrar',
      areYouSureDeleteFolder: '¿Seguro que quieres eliminar la carpeta? ¡Se eliminarán todos los contenidos!',
      renameFolder: 'Renombrar carpeta',
      createNewFolder: 'Crear nueva carpeta',
      newFolderPlaceholder: 'Introduce el nombre de la carpeta',
      create: 'Crear',
      areYouSureDeleteFile: '',
      downloadFile: 'Descargar',
      deleteFile: 'Eliminar del dispositivo',
      openInFinder: 'Abrir en el Finder',
      cancel: 'Cancelar',
    },
    globalSearch: {
      postedBy: 'Publicado por',
      found: 'Encontrado',
      loadMore: 'Cargar más',
      enterQuery:
        'Introduce cualquier término de búsqueda. Puedes buscar cualquier cosa (nombre, documento, evento, publicación, ...)',
      folder: 'Carpeta',
      post: 'Publicación',
      error: 'Introduce al menos 3 caracteres',
    },
    kreiseSettings: {
      description: 'Descripción de la',
      push: 'Notificaciones push',
      email: 'Notificaciones por correo',
      subscribers: 'Miembros',
      active: 'Activado',
      oneYear: 'Silencio durante 1 año',
      forever: 'Silencio para siempre',
      until: 'Hasta',
      cantDeactive: 'Esta configuración está desactivada para este chat de grupo',
      SINGLE: 'Para cada contribución',
      SUMMARY_ONCE: 'Resumen 1 vez por semana',
      SUMMARY_TWICE: 'Resumen 2x por semana',
      SUMMARY_DAY: 'Resumen 1 vez al día',
      OFF: 'En',
      PUSH: 'Push-Benachrichtigung',
      EMAIL: 'Notificación por correo',
      PUSH_AND_EMAIL: 'Notificaciones push y por correo electrónico',
      newUser: 'Notificación para nuevos usuarios',
      statistic: 'Estadísticas de transmisiónk',
      off: 'En',
      ownPosts: 'Enviar correo estadístico para sus contribuciones',
      deleteMemberDescr: '¿Quitar?',
      deleteMemberBtn: 'Eliminar',
      retractAdminRights: 'Retirar derechos',
      deleteAdminAndUser: 'Quitar completamente',
      toDeleteKreiseBtn: 'Borrar chat de grupo',
      deleteKreiseDescr: `${KreiseName('SP').single} ¿borrar irrevocablemente?`,
      deleteKreiseModalTitle: '¿Retirar a un miembro?',
      addKreiseName: 'Nombre',
      addKreiseDescription: 'Descripción de la',
      addMembers: 'Añadir miembros',
      createKreise: 'Crear chat de grupo',
      toKreiseEditMessage: 'Nota: Todos los cambios de los miembros se aplicarán inmediatamente.',
      kreiseCreator: 'Creador:en este chat de grupo',
      kreiseDeleteMembersLeft: 'El chat de grupo sólo puede borrarse cuando sólo queda un miembro.',
      kreiseDeleteMembersLeftTitle: 'Eliminar primero a los miembro',
      suggestionInNewsfeed: {
        admin: 'Admin',
        member: 'Miembros',
        corp: `${CorpsName('SP').single} miembros`,
        corpAdmin: `${CorpsName('SP').single} admin`,
      },
    },
    surveys: {
      checkVotes: 'Resultados',
      chooseOptions: {
        single: 'Selecciona una opción.',
        multiple: 'Seleccione al menos una opción.',
      },
      votesModalTitle: 'Resultados',
      createModalTitle: 'Encuesta',
      noVotes: 'No se han emitido votos',
      question: 'Pregunta',
      askAQuestion: 'Formule una pregunta',
      allowMultiple: 'Permitir varias respuestas',
      votes: {
        single: 'Voz',
        multiple: 'Voces',
      },
      option: 'Opción',
      optionsLabel: 'Opciones',
      anonymus: 'Anonymus poll',
      create: 'Cree',
    },
  },
  {
    key: 'KOR', //korean language /koreanisch
    key2: 'ko',
    responseHandler: {
      messageLabel: '알 수 없는 오류입니다. info@connact.app 으로 문의하세요.',
      expiredLinkLabel: '링크가 만료되었습니다. 새 비밀번호 변경 요청하기.',
      emailExistLabel: '죄송합니다. 이 이메일 주소는 이미 등록되어 있습니다.',
      profileUpdated: '프로필 업데이트',
      profileUpdatedCorpsError: `프로필이 업데이트되었습니다! 다음 사항 ${
        CorpsName('KOR').single
      } 로그인 코드가 잘못되어 추가할 수 없습니다:`,
      appNeedsUpdate: '앱 스토어/구글 플레이에서 앱을 업데이트하세요.',
      maximalVideoSize: '최대 파일 크기는 2GB입니다!',
    },
    report: {
      notBelongLabel: '사용자가 더 이상 회원이 아닙니다..',
      incProfileLabel: '사용자가 허위 프로필 제안을 하는 경우',
      badPicLabel: '사용자의 프로필 사진이 가이드라인을 위반합니다.',
      badComLabel: '사용자의 진술이 가이드라인을 위반하는 경우',
      spamLabel: '사용자가 채팅에서 스팸을 보내는 경우',
      reasonsLabel: '알림 이유',
    },
    drawer: {
      news: `최신 뉴스`,
      kreise: KreiseName('KOR').multi,
      search: '검색',
      calendar: CalendarName('KOR'),
      documents: `${DocumentsName('KOR')}`,
      contacts: '연락처',
      requests: '연락처 문의',
      messages: MessagesName('KOR'),
      profile: ProfileName('KOR'),
      logout: '로그아웃',
      map: '지도',
      mentoring: '멘토링',
    },
    imageCropper: {
      cropLabel: '자르기',
      cancelLabel: '취소',
      chooseLabel: '완료',
    },
    signIn: {
      register: '등록하기',
      title: '로그인하세요',
      loginPlaceholder: '이메일 주소',
      passwordPlaceholder: '비밀번호',
      forgotPassword: '비밀번호를 잊어버리셨나요?',
      loginBtn: '로그인',
      noAccount: '아직 계정이 없으신가요?',
      description0: '궁금한 점이 있으면 다음 연락처로 문의하세요.',
      description1: '또는',
      description2: '턴.',
      loginValidation: '이메일 주소가 올바르지 않습니다.',
      passwordValidation: '비밀번호는 6자 이상이어야 합니다.',
    },
    search: {
      name: '이름',
      corps: CorpsName('KOR').single,
      country: CountryName('KOR'),
      city: '도시',
      industry: IndustryName('KOR'),
      company: '회사',
      profession: '직업',
      hobby: '관심 분야',
      cultural: '문화 스폰서',
      study: `${StudyName('KOR').short}`,
      office: '지사 사무실',
      degreeProgram: StudyName('KOR').studiengang,
      university: StudyName('KOR').university,
      position: `${FunctionName('KOR')}`,
      status: '상태',
      title: '검색 결과',
      filterTitle: '필터링 기준',
      postCode: '우편번호',
      global: '모든 것',
      activity: ActivityName('KOR'),
    },
    resetPassword: {
      enterEmail: '이메일 주소를 입력하세요.',
      inputPlaceholder: '이메일 주소',
      sendBtn: '보내기',
      cancelBtn: '취소',
      message: '이메일 주소를 알고 있는 경우 해당 주소로 링크가 전송되었습니다.',
      desc: '앱에 저장된 정확한 이메일 주소를 입력해야 합니다. 어떤 이메일인지 확인하려면 이메일을 확인하세요.',
    },
    password: {
      resetLabel: '새 비밀번호 입력',
      inputLabel: '새 비밀번호',
    },
    signUp: {
      createAccount: '계정 만들기',
      inputPlaceholder: `로그인 코드 ${CorpsName('KOR').single}s`,
      sendBtn: '추가',
      descFirst: '등록하면 이용약관에 동의하는 것으로 간주됩니다. ',
      descSecond: 'u를 읽었는지 확인합니다.',
      descThird: '궁금한 점이 있으면 info@connact.app 또는 +49 170 49 805로 문의하시기 바랍니다.',
      terms: '이용 약관',
      policy: '개인정보 보호정책.',
      description: '참고',
      generatePassword: '비밀번호 생성',
      noCodeSuccessRegister: '등록해 주셔서 감사합니다! 활성화되는 즉시 이메일로 알려드립니다.',
    },
    signUpDefault: {
      withoutCode: '코드 없이',
      createAccount: '여기에서 간단히 등록하세요.',
      withCode: '초대 코드 사용',
      simpleRegister: '초대 코드 없이',
      existingAccount: '이미 계정이 있으신가요?',
      descr01: '본인은',
      descr0: '등록하면 다음 사항에 동의하는 것으로 간주됩니다.',
      descr1: '및',
      descr2: '에.',
      guest: `다음 계정으로 등록 ${guest_naming_singular}`,
      withCodeWithDescr: `다음 계정으로 등록 ${member_naming_singular}`,
    },
    signUpManual: {
      title: '등록하기',
      photoDesc: '사진 추가',
      genderInput: '성별',
      firstName: '이름',
      lastName: '성',
      club: CorpsName('KOR').single,
      specify: '지정하십시오',
      emailPlaceholder: '이메일 주소',
      emailHint: '장기 이메일 주소를 사용해 주세요.',
      repeatEmailPlaceholder: '이메일 주소를 반복합니다.',
      passwordPlaceholder: '이메일 주소를 반복합니다.',
      repeatPasswordPlaceholder: '비밀번호 반복',
      postcode: '우편번호:,',
      place: '거주지',
      country: '국가',
      loginCode: '초대 코드',
      mobilePlaceholder: '휴대폰 번호(프로필에 표시되지 않음)',
      corps: SignUpCorpsName('KOR'),
      searchCountry: 'Suche nach Land',
    },
    backToLogin: {
      desc: '이미 계정이 있으신가요? ',
      login: '로그인',
    },
    backToSignUp: {
      desc: '아직 계정이 없으신가요? ',
      register: '등록하기',
    },
    privacy: {
      desc: '예, 본인은',
      btn: 'GTC',
      zu: '에',
      map: {
        title: '데이터 보호 고지',
        description:
          '이 앱은 GDPR을 준수하여 구현되었습니다. 지도 표시에는 Google 지도만 사용합니다. 또한 핀은 우편번호/도시를 기준으로 대략적으로만 표시되며 거리/번호는 표시되지 않습니다. 따라서 사용자의 특정 주소에 대한 결론을 도출할 수 없습니다.',
      },
      restrictions: {
        overall: '이 기능은 회원만 액세스할 수 있습니다.',
        overallWilkar: '이 기능은 데이터 보호상의 이유로 액세스할 수 없습니다.',
        profile: '회원만 사용자 프로필을 볼 수 있습니다. 하지만 사용자에게 비공개 메시지를 작성할 수는 있습니다.',
        groupchat: `그리고 ${KreiseName('KOR').single} 는 회원만 액세스할 수 있습니다.`,
        groupchatWilkar: `데이터 보호를 위해 ${KreiseName('KOR').single} 회원만 액세스할 수 있습니다..`,
        comments: '댓글은 회원에게만 표시됩니다.',
        commentsWilkar: '데이터 보호를 위해 댓글은 회원만 볼 수 있습니다.',
        likes: '좋아요 목록은 회원만 볼 수 있습니다.',
        post: '회원만 글을 게시할 수 있습니다.',
        participantsList: '참가자 목록은 회원만 볼 수 있습니다.',
        commentsOnlyForMembers: '댓글은 회원에게만 표시됩니다.',
        postsLimitation: '이전 게시물은 회원만 볼 수 있습니다.',
      },
      settings: {
        guestPost: {
          title: `확인됨 ${guest_naming_plural} 내 게시물을 볼 수 있습니다`,
          ask: '항상 물어보세요',
          no: '아니요',
          yes: '예',
        },
      },
      guestModal: {
        title: `유효성 검사 가능 ${guest_naming_plural} 이 게시물을 보시나요? 나중에 각 개별 게시물에 대해 이 공개 설정을 변경할 수 있습니다.`,
        dontAskAgain: '다시 묻지 마세요(“프로필“ → “개인정보“에서 이 설정을 변경할 수도 있습니다).',
      },
      post: {
        ifShown: `쇼${guest_naming_plural} 숨기기`,
        ifHidden: `쇼 ${guest_naming_plural} 보여주기`,
        ifShownModal: `기여금은 이제 다음 용도로 사용할 수 있습니다. ${guest_naming_plural} 더 이상 표시되지 않음.`,
        ifHiddenModal: `기여금은 이제 다음 용도로 사용할 수 있습니다. ${guest_naming_plural} 표시.`,
      },
    },
    searchModal: {
      landTitle: '국가 선택',
      corpsTitle: `${CorpsName('GER').single} 선택`,
      pleaseSelect: '선택하세요',
    },
    btn: {
      sendBtn: '보내기',
      cancelBtn: '취소',
      select: '선택',
      cancelEventBtn: acceptEventName('KOR').cancel,
      acceptBtn: acceptEventName('KOR').accept,
      saveBtn: '저장',
      clearBtn: '삭제',
      acceptReqBtn: '수락',
      declineReqBtn: '취소',
      updateBtn: '새로 고침',
      declineBtn: '거부',
      yesUnderstood: 'Ja, verstanden',

      ok: '확인',
    },
    validation: {
      email: '이메일 주소가 올바르지 않습니다.',
      password: '비밀번호는 6자 이상이어야 합니다.',
      repeatEmail: '이메일과 이메일 반복이 일치하지 않습니다.',
      repeatPassword: '비밀번호와 비밀번호 반복이 일치하지 않음',
      required: '이 필드는 필수 입력 사항입니다',
      matchPasswords: '이전 비밀번호와 새 비밀번호 일치',
      dontMatchPasswords: '비밀번호와 비밀번호 반복이 일치하지 않습니다.',
    },
    init: {
      title: '로드 중',
      overlayLabel: '인터넷 연결 상태를 확인하세요.',
    },
    screenTitles: {
      postsTitle: `최신 뉴스`,
      circlesTitle: KreiseName('KOR').multi,
      searchTitle: '검색',
      profileTitle: ProfileName('KOR'),
      eventTitle: CalendarName('KOR'),
      eventDetailsTitle: '이벤트',
      newEventTitle: '이벤트 추가',
      contactsTitle: '연락처',
      contactReqTitle: '연락처 문의',
      reqDetailsTitle: '연락처 문의',
      outReqTitle: '연락처 문의',
      messagesTitle: MessagesName('KOR'),
      changePasswordTitle: '비밀번호 변경',
      appInfoTitle: '정보',
      reportTitle: '사용자 신고',
      createKreiseTitle: '그룹 채팅 만들기',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      user: '사용자',
      mainTitleLabel: ProfileName('KOR'),
      basisLabel: '기본',
      clubLabel: CorpsName('KOR').single,
      clubLabelPlural: CorpsName('KOR').multi,
      professionLabel: '직업',
      studyLabel: StudyName('KOR').short,
      uploadPhotoLabel: '사진 찍기',
      editPhotoLabel: '사진 편집',
      profileCompletedLabel: '프로필',
      profileCompletedGLabel: '완료',
      birthday: '생년월일',
      functionLabel: `${FunctionName('KOR')}`,
      dateOfJoinLabel: CorpsEditDate('KOR'),
      positionDescLabel: '직업',
      companyLabel: CompanyName('KOR'),
      nameLabel: '이름',
      websiteLabel: '웹사이트',
      fromLabel: '에서',
      toLabel: '까지',
      delete: '삭제',
      untilToday: '오늘까지',
      degreeLabel: StudyName('KOR').abshchluss,
      miscellaneos: '기타',
      hobbies: '관심 분야',
      cultural: '문화 스폰서',

      positionLabel: '직업',
      firmaLabel: '회사',
      industryLabel: IndustryName('KOR'),
      areaLabel: '범위',
      typeOfEmplLabel: '고용 유형',
      activityLabel: ActivityName('KOR'),
      careerStageLabel: '경력 수준',
      activityDescLabel: '활동 설명',
      employmentPeriodLabel: '기간',
      universityLabel: StudyName('KOR').hochsule,
      courseLabel: StudyName('KOR').studiengang,
      ageLabel: '나이',
      birthdayLabel: '생일',
      editProfileLabel: '프로필 수정',
      addContactLabel: '연락처로 추가',
      sendMessageLabel: '메시지 보내기',
      requestSentLabel: '이미 보낸 연락처 요청',
      sendRequestSuccessLabel: '문의가 성공적으로 전송되었습니다.',
      reportSentLabel: '이미 신고한 사용자',
      addReportLabel: '사용자 신고/차단',
      contactsLabel: '연락처 수',
      showBirthday: '연락처에서 내 생일 보기',
      street: '거리 + 아니요. ',

      partner: '파트너 이름',
      mobileNumberLabel: '모바일 없음.',
      handphoneNumberLabel: '휴대폰 번호',
      telephoneNumberLabel: '전화 번호r',

      leibbursch: '바디 폭스',
      leibfuechse: '바디 폭스',
      bio: '내 소개',
      addDocuments: '문서 추가',
      image: '사진',
      video: '비디오',
      pdf: 'PDF',
      link: '링크',
      firstName: '이름',
      lastName: '성',
      title: '제목',
      email: '이메일',
      name: '이름',
      lastname: '성',
      country: CountryName('KOR'),
      wohnort: '도시',
      postcode: '포스트코드',
      inReview: 'Confirmation via mail pending',
    },
    calendar: {
      birthdayLabel: '생일',
      noEventsLabel: '선택한 날짜에 이벤트가 없습니다.',
      allTabLabel: '모두',
      myTabLabel: '마이',
      clubsTabLabel: CorpsName('KOR').single,
      subsTabLabel: '구독',
      subscribedTabLabel: '구독자',
      otherTabLabel: '기타',

      error: '시작 날짜/시간은 종료 날짜/시간 이전이어야 합니다.',
      filter: {
        all: '모두.',
        invited: '초대됨',
        confirmed: '약속',
      },
    },
    eventLabels: {
      inviteTo: '초대',
      addEndDate: '종료 날짜/시간 입력',
      eventLabel: '주최자',
      startLabel: '다음에서 시작',
      locationLabel: '장소',
      participantLabel: '참가자',
      moreInfo: '추가 정보',
      commentsLabel: '댓글',
      noCommentsLabel: '아직 댓글이 없습니다',
      eventStartLabel: '시작',
      eventEndLabel: '종료',
      descLabel: '설명',
      creatorLabel: '만든 사람',
      titleLabel: 'Titel',
      deleteEventTitle: '이벤트 삭제?',
      deleteEventLabel: '이 이벤트를 삭제하시겠습니까?',
      clubsTitle: `${CorpsName('KOR').single}s`,
      gruppenchat: '그룹 채팅 선택',
      contactsTitle: '연락처',
      delCommentLabel: '댓글을 삭제하시겠습니까?',
      sendInvite: '다음 주소로 초대 보내기',
      selectCorps: '대상 그룹 선택',
      externalGuest: '외부 게스트 초대',
      externalGuestPlaceholder: '이메일 주소 입력',
      attachmentLabel: '부록',
      shareLinkLabel: '공유 링크',
      linkLabel: `${guest_naming_singular} 입력`,
      copyLinkLabel: '링크 복사',
      iCalDownload: '캘린더 파일',
      timezone: '시간대',
      visibility: '가시성',
      errorDate: '날짜가 과거입니다.',
      startAfterEndError: '종료 날짜가 시작 날짜보다 늦어야 합니다.',
      members: 'Members',
      waitlist: 'Warteliste',
      onWaitlist: 'auf der Warteliste',
      leaveWaitlist: 'Platz freigeben?',
      leaveWaitlistDesc: `Von Warteliste entfernen?\n\n Wenn du dich später wieder auf die Warteliste setzt, landest du ggf. auf einer hinteren Position.`,
      limitAchieved: (limit: number) =>
        `Die maximale Teilnehmeranzahl von ${limit} ist erreicht. Du wurdest auf die Warteliste gesetzt.`,
      visibilityOptions: {
        allWithoutGuests: 'Mitglieder und auf Webseite',
        invited_member: '초대된 회원만',
        verified_member: '모든 회원',
        authorized_member: '모든 회원 및 게스트',
        all: '회원, 게스트 및 웹사이트',
      },
      modalEvent: {
        title: '이벤트 생성',
        subTitle: '이벤트가 생성되었습니다.\n\n',
        content:
          "이제 사용자 설정에 따라 이벤트에 대한 푸시 알림/이메일 알림이 전송됩니다. \n\n'프로필' → '알림'에서 설정한 경우 이벤트에 대한 이메일 알림도 직접 받게 됩니다.",
      },
      modalComment: {
        title: '누구에게 알려야 하나요?',
        message: '내 댓글에 대해 누구에게 알려야 하나요?',
        optionAll: '모든 참가자',
        optionNone: '주최자 전용(en)',
        button: '댓글 보내기',
      },
      cancelModal: {
        title: '취소 또는 삭제?',
        message: '이벤트를 취소하거나 삭제하시겠습니까?',
        optionAll: '취소 (참가자에게 알림)',
        optionNone: 'Löschen (Teilnehmer werden nicht informiert)',
      },
      updateEventModal: {
        title: '추가 초대장 발송',
        label: '다른 그룹은 초대되었습니다',
        message: '설정에 따라 이제 이벤트에 대한 푸시 알림 또는 이메일 알림이 다른 그룹으로 전송됩니다.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/Mailadresse',
        enterLink: '링크/이메일 주소 또는 유사 주소 추가',
      },
      parts: {
        part: 'Part',
        addPart: 'Add program point',
        hint: 'Explanation',
        question: 'Question',
        single: 'Only one option selectable',
        multi: 'Several options available',
        freeText: 'Free text answer',
        quiz: 'Quiz',
        questionnaire: 'Questionnaire',
        addQuestionnaire: 'Add Questionnaire',
        questionnaireType: 'Answer options',
        option: 'Option',
        addOption: 'Add option',
        showMembers: 'Participants',
        youJoined: 'You take part in:',
        totalToPay: 'Total amount to be paid:',

        yourAnswer: 'Your answer',
        visibleForCreators: 'Visible only for creators',
        allAnswers: 'All answers:',
        addUsers: 'Add users',
        isAnonym: 'Anonymous voting',
        isAnonymQuiz: 'Anonymes Quiz',
      },
    },
    rrEventLabels: {
      title: '반복',
      MO: '월요일',
      TU: '화요일',
      WE: '수요일',
      TH: '목요일',
      FR: '금요일',
      SA: '토요일',
      SU: '일요일',
      YEARLY: { single: '연도', regular: '매년' },
      MONTHLY: { single: '월', regular: '월간' },
      WEEKLY: { single: '주', regular: '주간' },
      DAILY: { single: '일', regular: '매일' },
      SINGLE: { single: '고유', regular: '고유' },
      CUSTOM: {
        single: '사용자 지정...',
        regular: '사용자 지정...',
      },
      freq: '빈도',
      interval: '간격',
      notRepeat: '고유',
      every: '모든',
      month: '월',
      week: '주',
      last: '마지막',
      on: 'on',
      everyMonthOn: '매월',
      ofTheMonth: '이달의',
      editLabel: '어떤 날짜를 변경하시겠습니까?',
      thisDate: '이 날짜만 해당',
      thisDateAndFollowing: '이것과 그 이후의 모든',
      allDates: '모든 날짜',
      after: '이후',
      time: '약속',
      times: '약속',
      neverEnds: '끝이 없습니다',
      until: '까지',
      repeatedDefault: ({ frequency, freq }) => `모든${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} 반복`,
      repeatedMonthly: ({ day, interval }) =>
        `모든${interval == 1 ? '' : ` ${interval}.`} 월별 ${day.length > 2 ? day : `${day}.`} 반복.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }) =>
        `Will every${bysetpos == -1 ? ' letzten' : ` ${bysetpos}.`} ${day} 의${
          interval === 1 ? '' : ` ${interval}.`
        } 월 반복.`,

      repeatedYearly: ({ interval }) => `각${interval == 1 ? '' : ` ${interval}.`} 매년 반복됩니다.`,
      repeatedWeekly: ({ interval, byweekday }) =>
        `모든${interval != 1 ? ` ${interval}.` : ''} 주${
          !!byweekday?.length
            ? ' on ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' 그리고 ' : '') +
              byweekday?.slice(-1)
            : ''
        } 반복.`,
      repeatedDaily: ({ interval }) => `모든${interval != 1 ? ` ${interval}.` : ''} 하루가 반복됩니다.`,
    },
    requestsLabels: {
      receiveLabel: '수신',
      sentLabel: '보낸',
    },
    post: {
      adTitle: '정보/디스플레이',
      toKreise: 'on',
      comment: 'on',
      myPost: 'I',
      openPdf: '클릭하여 열기',
      deletePost: '이 게시물을 삭제하시겠습니까?',
      posted: '게시됨',
      commented: '댓글 달기',
      contacts: '연락처',
      sendToContacts: '모두에게 보내는 메시지',
      enterText: '여기에 메시지 작성',
      sendToCircle: `대상 메시지 ${KreiseName('KOR').singleArticle}`,
      sendComment: '댓글 입력',
      longMessage: '최대 20,000자',
      noComments: '아직 댓글이 없습니다',
      deleteComment: '댓글을 삭제하시겠습니까?',
      deleteEventPost: '이 게시물을 삭제하시겠습니까? 이벤트는 삭제되지 않습니다.',
      editPostLabel: '게시물 수정',
      editingPostLabel: '편집 모드',
      deletePostLabel: '게시물 삭제',
      editedAt: '처리됨',
      pinned: '고정됨.',
      actions: {
        label: '작업 게시',
        pin: {
          label: '핀 포스트',
          once: '한 번 위로 이동',
          d3: '3일',
          d5: '5일',
          w1: '1주',
          w2: '2주',
          unpin: '더 이상 고정하지 마세요.',
        },
      },
    },
    circles: {
      circlesTitle: `구독자 ${KreiseName('KOR').multi}`,
      noCirclesTitle: `구독하지 않음 ${KreiseName('KOR').multi}`,
      myPost: 'I',
      circlesInfoTitle: '정보 및 참가자',
      circlesSubscribe: '구독',
      unsubscribeAlert: '비공개 그룹 채팅을 취소할 수 없나요?',
      continueAlert: 'Verlassen',
      readOnlyKreise: `이것은 '읽기 전용'입니다.-${
        KreiseName('KOR').single
      }". 관리자만 이곳에서 글을 작성할 수 있습니다. 그러나 게시물에 댓글을 작성할 수 있습니다..`,
    },
    messages: {
      addMessageLabel: '메시지 입력',
      changesSaved: '변경 사항이 저장되었습니다!',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: '기본',
      clubsTab: `${CorpsName('KOR').single}`,
      miscellaneosTab: '관심 분야',
      professionTab: '직업',
      studyTab: StudyName('KOR')?.short,
      notificationsTab: '알림',
      privacy: '개인 정보 보호',
    },
    profile: {
      addPhotoLabel: '사진 추가',
      genderInputLabel: '성별',
      titleLabel: '제목',
      firstNameLabel: '이름',
      lastNameLabel: '성',

      passwordLabel: '비밀번호 설정',
      birthDateLabel: '생년월일',

      changePasswordLabel: '비밀번호 변경',
      defaultCountryLabel: '독일',
      defaultGraduationLabel: '지정되지 않음',
      defaultCorpsLabel: '없음',
      street: '거리 + 아니요. ',

      partner: '파트너 이름',
      mobileNumberLabel: '휴대폰 번호',
      handphoneNumberLabel: '휴대폰 번호',
      telephoneNumberLabel: '전화 번호',
    },
    profileKorp: {
      verband: CorpsName('KOR').single,
      function: FunctionName('KOR'),
      dateOfJoin: CorpsEditDate('KOR'),
      beername: '맥주 이름',
    },
    profileClubs: {
      noUserLabel: '앱을 사용하려면 먼저 모든 기본 정보를 입력해야 합니다.',
      functionLabel: `${FunctionName('KOR')}`,
      setClubLabel: `이름을 입력하세요. ${CorpsName('KOR').single} 지정.`,
      matchClubLabel: '그리고',
      matchClubContinueLabel: '프로필에 이미 존재하는 경우 한 번만 확인해 주세요.',
      clubLabel: `${CorpsName('KOR').single.toUpperCase()}`,
      clubCodeLabel: `로그인 코드 ${CorpsName('KOR').single.toUpperCase()}S`,
      entryTimeLabel: CorpsEditDate('KOR'),
      deleteClubLabel: '멤버십을 연장하시겠습니까?',
      deleteClubContinueLabel: '정말 삭제할까요?',
      selectVariant: '어떻게 하시겠습니까?',
      requestExistingCorp: '기존 항목 추가',
      requestNewCorps: '새 항목 요청',
      createCorp: '새 항목 만들기',
    },
    profileProfession: {
      jobTitle: '직책',
      noUserLabel: '앱을 사용하려면 먼저 모든 기본 정보를 입력해야 합니다.',
      untilToday: '오늘까지',
      positionTitleLabel: '직업',
      itemNameLabel: '직업',
      employmentLabel: '고용 유형',
      activityLabel: ActivityName('KOR'),
      careerStageLabel: '경력 수준',
      activityDescLabel: '활동 설명',
      companyLabel: CompanyName('KOR'),
      nameLabel: '이름',
      industryLabel: IndustryName('KOR'),
      areaLabel: '범위h',
      websiteLabel: '회사 웹사이트',
      employmentPeriodLabel: '고용 기간',
      fromLabel: '에서',
      toLabel: '까지',
    },
    profileStudy: {
      noUserLabel: '앱을 사용하려면 먼저 모든 기본 정보를 입력해야 합니다.',
      universityLabel: StudyName('KOR').hochsule,
      courseLabel: StudyName('KOR').studiengang,
      degreeLabel: StudyName('KOR').abshchluss,
    },
    profileOther: {
      noUserLabel: '앱을 사용하려면 먼저 모든 기본 정보를 입력해야 합니다.',
      hobbiesLabel: '관심 분야',
      culturalLabel: '문화 스폰서',
      socialMediaPlattform: '소셜 미디어 플랫폼',
      socialMediaLabel: '소셜 미디어 링크',
      socialMediaLink: '링크',
      socialMediaError: '전체 URL을 입력하세요.',
    },
    profileNotifications: {
      noUserLabel: '앱을 사용하려면 먼저 모든 기본 정보를 입력해야 합니다.',
      notificationsInfo:
        '여기에서 개별 활동에 대한 이메일 알림을 활성화하거나(기본값) 3개월 동안 비활성화할 수 있습니다.',
      notificationsTitle: '푸시 알림:',
      notificationsDescr0: '푸시 알림을 설정할 수 있습니다. ',
      notificationsDescr1: '스마트폰 시스템 설정',
      notificationsDescr2: ' make.',
      mailingTitle: '이메일 알림:',
      mailingDescr: '여기에서 개별 활동에 대한 이메일 알림을 활성화(기본값)하거나 1년 동안 비활성화할 수 있습니다.',
      allNotificationsLabel: '모든 알림',
      postsLabel: `기여 분야 ${KreiseName('KOR').multi}`,
      messagesLabel: '비공개 메시지',
      commentsLabel: '자신의 기여에 대한 댓글',
      contactRequestsLabel: '연락처 문의',
      eventsLabel: '이벤트',
      clubMemberLabel: '신규 등록한 회원',
      enableLabel: 'To',
      disableToLabel: '다음까지 꺼짐',
      disableLabel: '에서',
      ownPosts: '내 기여(내가 직접 받은 메일도 포함)',
    },
    searchInput: {
      title: '검색',
      placeholder: '검색',
    },
    emptyList: {
      title: '여기에 콘텐츠가 없습니다(아직).',
    },
    switchLanguage: {
      placeholder: '언어/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'russian (русский)',
      SP: 'Spanish (Español)',
      title: '언어 선택',
    },
    filePicker: {
      takePicLabel: '첨부 파일',
      uploadPicLabel: '사진 찍기',
      selectPicLabel: '사진/비디오 선택',
      selectDocLabel: '문서 선택',
    },
    datePicker: {
      selectDateLabel: '날짜 선택',
      confirmDateLabel: '확인',
      selectTimeLabel: 'Zeit auswählen',
    },
    changePassword: {
      currentPasswordLabel: '현재 비밀번호',
      newPasswordLabel: '새 비밀번호',
      repeatPasswordLabel: '비밀번호 반복',
      changePasswordLabel: '비밀번호 변경',
    },
    appInfo: {
      appDescLabel:
        'Korponnect는 동아리 학생들을 위한 앱입니다. 기업 간 교류, 이벤트 조직, 전문 및 개인 네트워킹을 하나의 앱에서 모두 가능합니다.',
      contactsLabel: InfoContactsName('KOR'),
      infoLabel: '다음에 대한 정보',
      versionLabel: '앱 버전',
      agbLabel: 'GTC',
      dataProtectionLabel: '개인정보 보호정책',
      manualLabel: '지침',
      appTextLabel: '여기에서',
      appAndLabel: '및',
      appMainTextLabel: `동그라미를 추가하거나 다른 제안을 하시겠습니까? 프로필을 삭제하시겠습니까? ${support_email} 으로 간단한 이메일을 보내주세요.`,
      deleteFeedback: '삭제 사유/피드백 🙂:',
      deleteUserTitle: '계정 삭제?',
      deleteUserDesc:
        '여기에서 계정을 취소할 수 있습니다.\b 참고: 알림을 간단히 끌 수도 있습니다. 프로필 -> 알림에서 설정을 수정하거나 이메일을 보내주세요.',
      deleteUserAlert: '정말 계정을 삭제하시겠습니까?',
      webLabel: '웹 버전',
    },
    infoMessages: {
      registartionSuccessLabel: '등록해 주셔서 감사합니다!',
      manualRegSuccessLabel: '등록해 주셔서 감사합니다. 곧 활성화되며 이메일로 안내해 드립니다.',
      linkExpiredLabel: '링크가 만료되었습니다. 새 비밀번호 변경을 요청하세요.',
      emailExistLabel: '죄송합니다. 이메일을 사용할 수 없습니다.',
    },
    logoutAlert: {
      title: '로그아웃',
      text: '정말 로그아웃하시겠습니까? 로그아웃할 필요 없이 앱을 닫거나 최소화하기만 하면 됩니다.',
      confirm: '예',
      dismiss: '취소',
    },
    invalidInputs: {
      passwordChars: `비밀번호는 다음 중 하나 이상이어야 합니다. ${passwordDigits(
        'KOR',
      )} 문자 하나, 숫자 하나, 특수 문자 1개를 포함한 문자 수입니다.`,
      fieldRequired: '이 필드는 필수 입력 사항입니다..',
      emailsNotMatching: '이메일이 일치하는지 확인하세요.',
      emailInvalid: '이메일 주소가 올바르지 않습니다!',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: '1개 이상의 항목을 선택해 주세요.',
    },
    mentoring: {
      profile: {
        rules1: '본인은',
        rules2: ' 가치에 대한 이해 ',
        rules3: '“장학금 소지자를 위한 멘토링".',
        menteeDescription:
          '멘토링 지역을 선택하면 현장 이벤트에 대한 정보를 받을 수 있습니다. 참고: 언제든지 선택 지역을 변경할 수 있습니다. 멘토 검색을 제한하지 않습니다..',
        mentorDescription:
          '장학금 수혜자가 멘토로 여러분을 찾을 수 있도록 멘토 프로필을 작성해 주세요. 참고: 최소 1개 지역과 최소 1개 주제 영역이 필요합니다.',
        mentoringRegions: '멘토링 지역',
        reigonsShort: '지역',
        topics: '주제 영역',
        departments: '부서',
        available: '사용 가능',
        unavailable: '현재 부재중입니다.',
        deleteAccount: '멘션 프로필 삭제 및 그룹 탈퇴',
        deleteAccountDescr: '멘토링 프로필을 삭제하고 멘토링 그룹 채팅에서 나가시겠습니까?',
        uploadPdf: '멘토 프로필 (PDF)',
        myPdf: '내 프로필 (PDF)',
        pdf: '멘토 프로필',
      },
      tabNames: {
        info: '정보',
        news: '뉴스',
        profile: '프로필',
        search: '검색 및 문의',
        request: '멘토링',
        requestDetails: '멘토링 문의',
        tabDeactivated: '탭이 비활성화되었습니다. 멘토링 프로필을 작성해 주세요.',
      },
      search: {
        description:
          '타겟팅된 방식으로 검색을 시작하려면 몇 가지 선택 필드로 시작한 다음 새 검색 실행에서 검색 그리드를 넓히는 것이 좋습니다. 해당 지역에서 적합한 멘토를 찾을 수 없다면 인근 멘토링 지역으로 검색 범위를 확장하세요. 검색 기준: 지역 1개, 주제 분야 1~3개. 모든 필드는 AND로 연결됩니다!',
        searchBtn: '검색',
      },
      requests: {
        mentorIncoming: '멘토링 문의',
        mentorCurrent: '내 멘토',
        menteeOutgoing: '멘토링 문의',
        menteeCurrent: '나의 멘토링',
        cancelMentoring: '멘토링 종료',
        sendRequest: '멘토링 종료',
        alreadyHaveMentor: '멘토는 한 명만 가질 수 있습니다.',
        requestSuccess: '문의가 성공적으로 전송되었습니다.',
        requestRetracted: '문의가 성공적으로 철회되었습니다.',
        cancelSendingRequest: '멘토링 요청 철회하기',
        approveRequest: '문의 수락',
        rejectRequest: '요청 거부',
        invitaionText: '문의',
        rejectionText: '거부 사유 제공',
        sureRetractRequest: '정말 문의를 철회하시겠습니까?',
      },
    },
    downloads: {
      root: '기본 디렉토리',
      delete: '삭제',
      rename: '이름 바꾸기',
      areYouSureDeleteFolder: '정말 폴더를 삭제하시겠습니까? 모든 콘텐츠가 삭제됩니다!',
      renameFolder: '폴더 이름 바꾸기',
      createNewFolder: '새 폴더 만들기',
      newFolderPlaceholder: '폴더 이름 입력',
      create: '만들기',
      areYouSureDeleteFile: '',
      downloadFile: '다운로드',
      deleteFile: '기기에서 삭제',
      openInFinder: '파일로 열기',
      cancel: '취소',
    },
    globalSearch: {
      postedBy: '게시자',
      found: '발견됨',
      loadMore: '더 보기',
      enterQuery: '검색어를 입력합니다. 이름, 문서, 이벤트, 게시물 등 무엇이든 검색할 수 있습니다.',
      folder: '폴더',
      post: '기여',
      error: '3자 이상 입력',
    },
    kreiseSettings: {
      description: '설명',
      push: '푸시 알림',
      email: '메일 알림',
      subscribers: '회원',
      active: '활성화',
      oneYear: '1년간 침묵',
      forever: '영원히 침묵',
      until: '까지',
      cantDeactive: '이 그룹 채팅에 대해서는 이 설정이 비활성화되어 있습니다.',
      SINGLE: '각 기여에 대해',
      SUMMARY_ONCE: '주당 1회 요약',
      SUMMARY_TWICE: '주당 2회 요약',
      SUMMARY_DAY: '하루 1회 요약',
      OFF: '에서',
      PUSH: '푸시 알림',
      EMAIL: '메일 알림',
      PUSH_AND_EMAIL: '푸시 및 이메일 알림',
      newUser: '신규 사용자를 위한 알림',
      statistic: '전송 통계',
      off: '에서',
      ownPosts: '기여에 대한 통계 메일 보내기',
      deleteMemberDescr: '제거하시겠습니까?',
      deleteMemberBtn: '제거',
      retractAdminRights: '권한 철회',
      deleteAdminAndUser: '완전히 제거',
      toDeleteKreiseBtn: '그룹 채팅 삭제',
      deleteKreiseDescr: `${KreiseName('KOR').single} 취소할 수 없게 삭제할 수 있나요?`,
      deleteKreiseModalTitle: '회원을 제거하시겠습니까?',
      addKreiseName: '이름',
      addKreiseDescription: '이름',
      addMembers: '멤버 추가',
      createKreise: '그룹 채팅 만들기',
      toKreiseEditMessage: '참고: 멤버에 대한 모든 변경 사항은 즉시 적용됩니다.',
      kreiseCreator: '크리에이터:이 그룹 채팅에서',
      kreiseDeleteMembersLeft: '그룹 채팅은 멤버가 한 명만 남은 경우에만 삭제할 수 있습니다.',
      kreiseDeleteMembersLeftTitle: '먼저 멤버 제거',
      suggestionInNewsfeed: {
        admin: '관리자',
        member: '회원',
        corp: `${CorpsName('KOR').single} 멤버`,
        corpAdmin: `${CorpsName('KOR').single} 관리자`,
      },
    },
    surveys: {
      checkVotes: '결과 표시',
      chooseOptions: {
        single: '옵션 선택.',
        multiple: '하나 이상의 옵션을 선택합니다.',
      },
      votesModalTitle: '결과',
      createModalTitle: '설문 조사',
      noVotes: '투표가 진행되지 않았습니다.',
      question: '질문',
      askAQuestion: '질문하기',
      allowMultiple: '복수 답변 허용',
      votes: {
        single: '음성',
        multiple: '음성',
      },
      option: '옵션',
      optionsLabel: '옵션',
      anonymus: 'Anonymus poll',
      create: '만들기',
    },
  },
  {
    key: 'JAP', //japan language /japanisch
    key2: 'ja',
    responseHandler: {
      messageLabel: '不明なエラー。info@connact.app までご連絡ください。',
      expiredLinkLabel: 'リンク切れです。新しいパスワードの変更をリクエストする.',
      emailExistLabel: 'このメールアドレスはすでに登録されています。.',
      profileUpdated: 'プロフィール更新',
      profileUpdatedCorpsError: `プロフィールを更新しました！以下 ${
        CorpsName('JAP').single
      } ログインコードが正しくないため、追加できませんでした：`,
      appNeedsUpdate: 'App Store / Google Playでアプリをアップデートしてください。',
      maximalVideoSize: '最大ファイルサイズは2GB！',
    },
    report: {
      notBelongLabel: 'ユーザーはメンバーではなくなりました。',
      incProfileLabel: '虚偽のプロフィールを提示',
      badPicLabel: 'プロフィール写真がガイドラインに違反している。',
      badComLabel: 'ユーザーの発言がガイドラインに違反している',
      spamLabel: 'ユーザーがチャットでスパムを送信',
      reasonsLabel: '通告の理由',
    },
    drawer: {
      news: `最新ニュース`,
      kreise: KreiseName('JAP').multi,
      search: '検索',
      calendar: CalendarName('JAP'),
      documents: `${DocumentsName('JAP')}`,
      contacts: 'コンタクト',
      requests: 'お問い合わせ',
      messages: MessagesName('JAP'),
      profile: ProfileName('JAP'),
      logout: 'ログアウト',
      map: '地図',
      mentoring: 'メンタリング',
    },
    imageCropper: {
      cropLabel: '作物',
      cancelLabel: 'キャンセル',
      chooseLabel: '終了',
    },
    signIn: {
      register: '登録',
      title: 'ログインしてください',
      loginPlaceholder: 'Eメールアドレス',
      passwordPlaceholder: 'パスワード',
      forgotPassword: 'パスワードをお忘れですか？',
      loginBtn: 'Login',
      noAccount: 'アカウントはまだ？',
      description0: 'ご不明な点がございましたら、下記までお問い合わせください。',
      description1: 'または',
      description2: 'ターン。',
      loginValidation: 'Eメールアドレスが正しくありません。',
      passwordValidation: 'パスワードは6文字以上でなければなりません。',
    },
    search: {
      name: '名称',
      corps: CorpsName('JAP').single,
      country: CountryName('JAP'),
      city: '都市',
      industry: IndustryName('JAP'),
      company: '会社概要',
      profession: '職業',
      hobby: '趣味',
      cultural: 'Kulturpaten',
      study: `${StudyName('JAP').short}`,
      office: '支店',
      degreeProgram: StudyName('JAP').studiengang,
      university: StudyName('JAP').university,
      position: `${FunctionName('JAP')}`,
      status: 'ステータス',
      title: '検索結果',
      filterTitle: 'フィルター',
      postCode: '郵便番号',
      global: 'すべて',
      activity: ActivityName('JAP'),
    },
    resetPassword: {
      enterEmail: 'Eメールアドレスを入力してください。',
      inputPlaceholder: 'Eメールアドレス',
      sendBtn: '送信',
      cancelBtn: 'キャンセル',
      message: 'メールアドレスがわかっている場合は、リンクが送信されている。',
      desc: 'アプリに保存されているメールアドレスを正確に入力してください。それがどれなのか、メールをチェックしてください。',
    },
    password: {
      resetLabel: '新しいパスワードを入力してください。',
      inputLabel: '新しいパスワード',
    },
    signUp: {
      createAccount: 'アカウント作成',
      inputPlaceholder: `ログインコード ${CorpsName('JAP').single}s`,
      sendBtn: 'さらに',
      descFirst: '登録することで、利用規約に同意したものとみなされます。 ',
      descSecond: 'をお読みいただき',
      descThird: 'ご質問がございましたら、info@connact.app または +49 170 49 805 までお気軽にお問い合わせください。',
      terms: '諸条件',
      policy: 'プライバシーポリシー.',
      description: 'ヒント',
      generatePassword: 'パスワードの生成',
      noCodeSuccessRegister: 'ご登録ありがとうございます！登録が有効になり次第、Eメールにてお知らせいたします。',
    },
    signUpDefault: {
      withoutCode: 'コードなし',
      createAccount: '登録はこちらから',
      withCode: '招待コード付き',
      simpleRegister: '招待コードなし',
      existingAccount: 'すでにアカウントをお持ちですか？',
      descr01: 'に同意する。',
      descr0: '登録することにより、以下の事項に同意したものとみなされます。',
      descr1: 'そして',
      descr2: 'に。',
      guest: `登録 ${guest_naming_singular}`,
      withCodeWithDescr: `Rとして登録する。 ${member_naming_singular}`,
    },
    signUpManual: {
      title: '登録',
      photoDesc: '写真を追加',
      genderInput: '性別',
      firstName: '名前',
      lastName: '苗字',
      club: CorpsName('JAP').single,
      specify: '指定してください',
      emailPlaceholder: 'Eメールアドレス',
      emailHint: 'メールアドレスは長期的に使用できるものをご使用ください。',
      repeatEmailPlaceholder: 'リピートEメールアドレス',
      passwordPlaceholder: 'パスワード設定',
      repeatPasswordPlaceholder: 'リピートパスワード',
      postcode: '郵便番号',
      place: '居住地',
      country: '国名',
      loginCode: '招待コード',
      mobilePlaceholder: '携帯番号（プロフィールでは表示されません）',
      corps: SignUpCorpsName('JAP'),
      searchCountry: 'Suche nach Land',
    },
    backToLogin: {
      desc: 'すでにアカウントをお持ちですか？',
      login: 'ログイン ',
    },
    backToSignUp: {
      desc: 'アカウントはまだ？ ',
      register: '登録',
    },
    privacy: {
      desc: 'はい、同意します。',
      btn: 'GTC',
      zu: 'への',
      map: {
        title: 'データ保護に関するお知らせ',
        description:
          'このアプリはGDPRに準拠して実装されています。地図の表示にはGoogleマップのみを使用しています。個人情報は一切送信されません。\n\nまた、ピンは郵便番号や市区町村をもとに大まかに表示されるだけで、番地は表示されません。',
      },
      restrictions: {
        overall: 'この機能は会員のみが利用できる。',
        overallWilkar: 'データ保護のため、この機能にはアクセスできません。.',
        profile:
          'ユーザーのプロフィールを閲覧できるのはメンバーのみです。ただし、そのユーザーにプライベートメッセージを書き込むことはできます。',
        groupchat: `について ${KreiseName('JAP').single} 会員のみアクセス可能.`,
        groupchatWilkar: `データ保護の観点から ${KreiseName('JAP').single} にアクセスできるのは会員のみである。`,
        comments: 'コメントは会員のみ閲覧可能.',
        commentsWilkar: 'データ保護の観点から、コメントは会員のみが閲覧できます。',
        likes: 'いいね！」のリストは会員のみが閲覧できます。',
        post: '記事を投稿できるのは会員のみです。',
        participantsList: '参加者リストは会員のみ閲覧可能。',
        commentsOnlyForMembers: 'コメントは会員のみに表示されます。',
        postsLimitation: '古い投稿は会員のみ閲覧可能です。',
      },
      settings: {
        guestPost: {
          title: `V洗練された ${guest_naming_plural} 私の投稿を見ることができる`,
          ask: '常に尋ねる',
          no: 'いいえ',
          yes: 'はい',
        },
      },
      guestModal: {
        title: `5月有効 ${guest_naming_plural} この投稿が見えますか？この表示設定は、後で個々の投稿ごとに変更することができます。`,
        dontAskAgain: '再度質問しない（この設定は「プロフィール」→「プライバシー」で再度変更することもできます）.',
      },
      post: {
        ifShown: `について ${guest_naming_plural} 隠す`,
        ifHidden: `について ${guest_naming_plural} ショー`,
        ifShownModal: `この寄付は現在、以下の目的で利用可能である。 ${guest_naming_plural} もう見えない。`,
        ifHiddenModal: `この寄付は現在、以下の目的で利用可能である ${guest_naming_plural} 目に見える.`,
      },
    },
    searchModal: {
      landTitle: '国を選ぶ',
      corpsTitle: `${CorpsName('JAP').single} 選択`,
      pleaseSelect: '選択してください',
    },
    btn: {
      sendBtn: '送信',
      cancelBtn: 'キャンセル',
      select: '選択',
      cancelEventBtn: acceptEventName('JAP').cancel,
      acceptBtn: acceptEventName('JAP').accept,
      saveBtn: 'セーブ',
      clearBtn: '削除',
      acceptReqBtn: '受け入れる',
      declineReqBtn: 'キャンセル',
      updateBtn: 'リフレッシュ',
      declineBtn: '却下',
      yesUnderstood: 'Ja, verstanden',

      ok: 'OK',
    },
    validation: {
      email: 'Eメールアドレスが正しくありません。',
      password: 'パスワードは6文字以上でなければなりません。',
      repeatEmail: 'EメールとEメールの繰り返しは一致しない。',
      repeatPassword: 'パスワードとパスワードの繰り返しが一致しない',
      required: 'このフィールドは必須です',
      matchPasswords: '新旧パスワードの一致',
      dontMatchPasswords: 'パスワードとパスワードの繰り返しが一致しない。',
    },
    init: {
      title: 'ローディング',
      overlayLabel: 'インターネット接続をご確認ください',
    },
    screenTitles: {
      postsTitle: `最新ニュース`,
      circlesTitle: KreiseName('JAP').multi,
      searchTitle: 'Suche',
      profileTitle: ProfileName('JAP'),
      eventTitle: CalendarName('JAP'),
      eventDetailsTitle: 'イベント',
      newEventTitle: 'イベント追加',
      contactsTitle: '連絡先',
      contactReqTitle: '連絡先',
      reqDetailsTitle: '連絡先',
      outReqTitle: '連絡先',
      messagesTitle: MessagesName('JAP'),
      changePasswordTitle: 'パスワードの変更',
      appInfoTitle: 'について',
      reportTitle: '報告ユーザー',
      createKreiseTitle: 'グループチャットの作成',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      user: 'ユーザー',
      mainTitleLabel: ProfileName('JAP'),
      basisLabel: 'ベース',
      clubLabel: CorpsName('JAP').single,
      clubLabelPlural: CorpsName('GER').multi,
      professionLabel: '職業',
      studyLabel: StudyName('JAP').short,
      uploadPhotoLabel: '写真を撮る',
      editPhotoLabel: '写真を編集する',
      profileCompletedLabel: 'プロフィール',
      profileCompletedGLabel: '完全',
      birthday: '生年月日',
      functionLabel: `${FunctionName('JAP')}`,
      dateOfJoinLabel: CorpsEditDate('JAP'),
      positionDescLabel: '職業',
      companyLabel: CompanyName('JAP'),
      nameLabel: '名称',
      websiteLabel: 'ウェブサイト',
      fromLabel: 'より',
      toLabel: 'まで',
      delete: '削除',
      untilToday: '今日まで',
      degreeLabel: StudyName('JAP').abshchluss,
      miscellaneos: 'その他',
      hobbies: '趣味',
      cultural: '文化スポンサー',

      positionLabel: '職業',
      firmaLabel: '会社概要',
      industryLabel: IndustryName('JAP'),
      areaLabel: 'レンジ',
      typeOfEmplLabel: '雇用形態',
      activityLabel: ActivityName('JAP'),
      careerStageLabel: 'キャリアレベル',
      activityDescLabel: '活動内容',
      employmentPeriodLabel: '期間',
      universityLabel: StudyName('JAP').hochsule,
      courseLabel: StudyName('JAP').studiengang,
      ageLabel: '年齢',
      birthdayLabel: '誕生日',
      editProfileLabel: 'プロフィール編集',
      addContactLabel: '連絡先として追加',
      sendMessageLabel: 'メッセージを送る',
      requestSentLabel: 'コンタクトリクエストは送信済み',
      sendRequestSuccessLabel: 'お問い合わせは正常に送信されました',
      reportSentLabel: 'すでに報告済みのユーザー',
      addReportLabel: 'ユーザーへの報告/ブロック',
      contactsLabel: 'コンタクト数',
      showBirthday: 'コンタクトは私の誕生日を見る',
      street: 'ストリート＋No. ',

      partner: 'パートナーの名前',
      mobileNumberLabel: '携帯番号',
      handphoneNumberLabel: '携帯電話番号',
      telephoneNumberLabel: '電話番号',

      leibbursch: 'ライプバーシュ',
      leibfuechse: 'Leibfüchse',
      bio: '私について',
      addDocuments: 'ドキュメントを追加する',
      image: '写真',
      video: 'ビデオ',
      pdf: 'PDF',
      link: 'リンク',
      firstName: '名',
      lastName: '姓',
      title: '役職名',
      email: '電子メール',
      name: '名前',
      lastname: '苗字',
      country: CountryName('JAP'),
      wohnort: '都市',
      postcode: '郵便番号',
      inReview: 'Confirmation via mail pending',
    },
    calendar: {
      birthdayLabel: '誕生日',
      noEventsLabel: '選択された日付のイベントはありません。',
      allTabLabel: 'すべて',
      myTabLabel: '私の',
      clubsTabLabel: CorpsName('JAP').single,
      subsTabLabel: 'サブスクリプ',
      subscribedTabLabel: '購読者',
      otherTabLabel: 'その他',

      error: '開始日時は終了日時より前でなければならない。',
      filter: {
        all: 'すべて',
        invited: '招待',
        confirmed: '約束',
      },
    },
    eventLabels: {
      inviteTo: '招待状',
      addEndDate: '終了日時を入力',
      eventLabel: 'オーガナイザー',
      startLabel: 'でスタート',
      locationLabel: '場所',
      participantLabel: '参加者',
      moreInfo: '詳細情報',
      commentsLabel: 'コメント',
      noCommentsLabel: 'コメントはまだない',
      eventStartLabel: 'スタート',
      eventEndLabel: '終了',
      descLabel: '概要',
      creatorLabel: '作成者',
      titleLabel: 'タイトル',
      deleteEventTitle: 'イベントを削除しますか？',
      deleteEventLabel: 'このイベントを削除する?',
      clubsTitle: `${CorpsName('JAP').single}s`,
      gruppenchat: 'グループチャットを選択',
      contactsTitle: '連絡先',
      delCommentLabel: 'コメントを削除しますか？',
      sendInvite: '招待状送付先',
      selectCorps: 'ターゲット・グループを選ぶ',
      externalGuest: '外部招待客',
      externalGuestPlaceholder: 'Eメールアドレスを入力してください。',
      attachmentLabel: '付録',
      shareLinkLabel: 'シェアリンク',
      linkLabel: `${guest_naming_singular} 入る`,
      copyLinkLabel: 'リンクをコピーする',
      iCalDownload: 'カレンダーファイル',
      timezone: 'タイムゾーン',
      visibility: '視認性',
      errorDate: '日付は過去',
      startAfterEndError: '終了日は開始日よりも遅くなければならない',
      members: 'Members',
      waitlist: 'Warteliste',
      onWaitlist: 'auf der Warteliste',
      leaveWaitlist: 'Platz freigeben?',
      leaveWaitlistDesc: `Von Warteliste entfernen?\n\n Wenn du dich später wieder auf die Warteliste setzt, landest du ggf. auf einer hinteren Position.`,
      limitAchieved: (limit: number) =>
        `Die maximale Teilnehmeranzahl von ${limit} ist erreicht. Du wurdest auf die Warteliste gesetzt.`,
      visibilityOptions: {
        allWithoutGuests: 'Mitglieder und auf Webseite',
        invited_member: '招待された会員のみ',
        verified_member: '全員',
        authorized_member: '全メンバーとゲスト',
        all: '会員、ゲスト、ウェブサイト',
      },
      modalEvent: {
        title: 'イベント作成',
        subTitle: 'このイベントは.\n\n',
        content:
          'ユーザー設定により、イベントのプッシュ通知/メール通知が送信されるようになります。Profile」→「Notifications」で設定した場合は、自分自身にもイベントに関するメール通知が届きます。',
      },
      modalComment: {
        title: '通知先?',
        message: 'あなたのコメントを誰に知らせるべきか？',
        optionAll: '参加者全員',
        optionNone: '主催者のみ(en)',
        button: 'コメントを送信',
      },
      cancelModal: {
        title: 'キャンセルまたは削除?',
        message: 'イベントのキャンセルまたは削除?',
        optionAll: 'キャンセルについて (参加者に通知)',
        optionNone: '削除（参加者には知らされない）',
      },
      updateEventModal: {
        title: 'さらに招待状を送付',
        label: '他のグループも招待された。',
        message: '設定に応じて、イベントに関するプッシュ通知またはEメール通知が他のグループに送信されます。',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/Mailadresse',
        enterLink: 'リンク/メールアドレスなどを追加',
      },
      parts: {
        part: 'Part',
        addPart: 'Add program point',
        hint: 'Explanation',
        question: 'Question',
        single: 'Only one option selectable',
        multi: 'Several options available',
        freeText: 'Free text answer',
        quiz: 'Quiz',
        questionnaire: 'Questionnaire',
        addQuestionnaire: 'Add Questionnaire',
        questionnaireType: 'Answer options',
        option: 'Option',
        addOption: 'Add option',
        showMembers: 'Participants',
        youJoined: 'You take part in:',
        totalToPay: 'Total amount to be paid:',

        yourAnswer: 'Your answer',
        visibleForCreators: 'Visible only for creators',
        allAnswers: 'All answers:',
        addUsers: 'Add users',
        isAnonym: 'Anonymous voting',
        isAnonymQuiz: 'Anonymes Quiz',
      },
    },
    rrEventLabels: {
      title: '繰り返し',
      MO: '月曜日',
      TU: '火曜日',
      WE: '水曜日',
      TH: '木曜日',
      FR: '金曜日',
      SA: '土曜日',
      SU: '日曜日',
      YEARLY: { single: '年', regular: '毎年' },
      MONTHLY: { single: '月', regular: '毎月' },
      WEEKLY: { single: 'ウィークリー', regular: 'ウィークリー' },
      DAILY: { single: '日', regular: '毎日' },
      SINGLE: { single: 'ユニーク', regular: 'ユニーク' },
      CUSTOM: {
        single: 'カスタマイズ',
        regular: 'カスタマイズ...',
      },
      freq: '頻度',
      interval: 'インターバル',
      notRepeat: 'ユニーク',
      every: 'すべての',
      month: '月',
      week: '週間',
      last: '最後',
      on: 'オン',
      everyMonthOn: '毎月',
      ofTheMonth: '月',
      editLabel: 'どの日程を変更しますか？',
      thisDate: 'この日のみ',
      thisDateAndFollowing: 'これ以降もすべて',
      allDates: '全日程',
      after: 'その後',
      time: 'アポイントメント',
      times: 'アポイントメント',
      neverEnds: '終わりはない',
      until: 'まで',
      repeatedDefault: ({ frequency, freq }) =>
        `すべての${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} 繰り返し`,
      repeatedMonthly: ({ day, interval }) =>
        `毎回${interval == 1 ? '' : ` ${interval}.`} 月 ${day.length > 2 ? day : `${day}.`} を繰り返した。`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }) =>
        `毎回${bysetpos == -1 ? ' 最後' : ` ${bysetpos}.`} ${day} の${
          interval === 1 ? '' : ` ${interval}.`
        } 月が繰り返される。`,

      repeatedYearly: ({ interval }) => `もしそれぞれが${interval == 1 ? '' : ` ${interval}.`} 繰り返し年.`,
      repeatedWeekly: ({ interval, byweekday }) =>
        `すべての${interval != 1 ? ` ${interval}.` : ''} 週間${
          !!byweekday?.length
            ? ' am ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' そして ' : '') +
              byweekday?.slice(-1)
            : ''
        } 繰り返し.`,
      repeatedDaily: ({ interval }) => `毎回${interval != 1 ? ` ${interval}.` : ''} 連日.`,
    },
    requestsLabels: {
      receiveLabel: '受け取る',
      sentLabel: '送信済み',
    },
    post: {
      adTitle: 'インフォメーション/ディスプレイ',
      toKreise: 'オン',
      comment: 'コメント',
      myPost: 'I',
      openPdf: 'クリックして開く',
      deletePost: 'クリックして開く',
      posted: '投稿',
      commented: 'コメント',
      contacts: '連絡先',
      sendToContacts: '皆さんへのメッセージ',
      enterText: 'ここにメッセージを書く',
      sendToCircle: `メッセージ ${KreiseName('JAP').singleArticle}`,
      sendComment: 'コメントを入力',
      longMessage: '最大20,000文字',
      noComments: 'コメントはまだない',
      deleteComment: 'コメント削除?',
      deleteEventPost: 'この投稿を削除しますか？これはイベントを削除しません.',
      editPostLabel: '投稿を編集する',
      editingPostLabel: '編集モード',
      deletePostLabel: '投稿を削除する',
      editedAt: '加工済み',
      pinned: 'ピン留め.',
      actions: {
        label: 'ポストアクション',
        pin: {
          label: 'ピンポスト',
          once: '一度繰り上がる',
          d3: '3日',
          d5: '5日',
          w1: '1週間',
          w2: '2週間',
          unpin: 'これ以上ピンを打つな',
        },
      },
    },
    circles: {
      circlesTitle: `購読者 ${KreiseName('JAP').multi}`,
      noCirclesTitle: `未登録 ${KreiseName('JAP').multi}`,
      myPost: 'I',
      circlesInfoTitle: '情報＆参加者',
      circlesSubscribe: 'サブスクライブ',
      unsubscribeAlert: 'クローズド・グループ・チャットを取り消すことはできない？',
      continueAlert: '放棄',
      readOnlyKreise: `これは "読むだけ-${
        KreiseName('JAP').single
      }". ここで投稿を作成できるのは管理者のみです。ただし、投稿にコメントを書き込むことはできます。.`,
    },
    messages: {
      addMessageLabel: 'メッセージを入力',
      changesSaved: '変更が保存された！',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: 'ベース',
      clubsTab: `${CorpsName('JAP').single}`,
      miscellaneosTab: '趣味',
      professionTab: '職業',
      studyTab: StudyName('JAP')?.short,
      notificationsTab: 'お知らせ',
      privacy: 'プライバシー',
    },
    profile: {
      addPhotoLabel: '写真を追加',
      genderInputLabel: '性別',
      titleLabel: 'タイトル',
      firstNameLabel: '名前',
      lastNameLabel: '苗字',

      passwordLabel: 'パスワードの設定',
      birthDateLabel: '生年月日',

      changePasswordLabel: 'パスワードの変更',
      defaultCountryLabel: 'ドイツ',
      defaultGraduationLabel: '特になし',
      defaultCorpsLabel: 'なし',
      street: 'ストリート＋No. ',

      partner: 'パートナー名',
      mobileNumberLabel: '携帯番号',
      handphoneNumberLabel: '携帯電話番号',
      telephoneNumberLabel: '電話番号',
    },
    profileKorp: {
      verband: CorpsName('JAP').single,
      function: FunctionName('JAP'),
      dateOfJoin: CorpsEditDate('JAP'),
      beername: 'ビール名',
    },
    profileClubs: {
      noUserLabel: 'アプリを使用する前に、まずすべての基本情報を入力しなければならない。',
      functionLabel: `${FunctionName('JAP')}`,
      setClubLabel: `の名前を入力してください。 ${CorpsName('JAP').single} 指定する.`,
      matchClubLabel: 'について',
      matchClubContinueLabel: 'すでにプロフィールに存在しますので、一度ご確認ください。',
      clubLabel: `${CorpsName('JAP').single.toUpperCase()}`,
      clubCodeLabel: `ログインコード ${CorpsName('JAP').single.toUpperCase()}S`,
      entryTimeLabel: CorpsEditDate('JAP'),
      deleteClubLabel: '会員資格の延長をご希望ですか？',
      deleteClubContinueLabel: '本当に削除するのか？',
      selectVariant: 'どうしたい？',
      requestExistingCorp: '既存のエントリーを追加する',
      requestNewCorps: '新規エントリーのリクエスト',
      createCorp: '新規エントリーの作成',
    },
    profileProfession: {
      jobTitle: '役職名',
      noUserLabel: 'アプリを使用する前に、まずすべての基本情報を入力しなければならない。',
      untilToday: '今日まで',
      positionTitleLabel: '職業',
      itemNameLabel: '職業',
      employmentLabel: '雇用形態',
      activityLabel: ActivityName('JAP'),
      careerStageLabel: 'キャリアレベル',
      activityDescLabel: '活動内容',
      companyLabel: CompanyName('JAP'),
      nameLabel: '名称',
      industryLabel: IndustryName('JAP'),
      areaLabel: 'レンジ',
      websiteLabel: 'ウェブサイト',
      employmentPeriodLabel: '雇用期間',
      fromLabel: 'より',
      toLabel: 'まで',
    },
    profileStudy: {
      noUserLabel: 'アプリを使用する前に、すべての基本情報を入力する必要があります。.',
      universityLabel: StudyName('JAP').hochsule,
      courseLabel: StudyName('JAP').studiengang,
      degreeLabel: StudyName('JAP').abshchluss,
    },
    profileOther: {
      noUserLabel: 'アプリを使用する前に、まずすべての基本情報を入力しなければならない。',
      hobbiesLabel: '趣味',
      culturalLabel: '文化スポンサー',
      socialMediaPlattform: 'ソーシャルメディア・プラットフォーム',
      socialMediaLabel: 'ソーシャルメディア・リンク',
      socialMediaLink: 'リンク',
      socialMediaError: '完全なURLを入力してください',
    },
    profileNotifications: {
      noUserLabel: 'アプリを使用する前に、まずすべての基本情報を入力しなければならない。',
      notificationsInfo:
        'ここでは、個々のアクティビティに対するEメール通知を有効にするか（デフォルト）、または3ヶ月間無効にすることができます。',
      notificationsTitle: 'プッシュ通知:',
      notificationsDescr0: 'プッシュ通知は ',
      notificationsDescr1: 'スマートフォンのシステム設定',
      notificationsDescr2: ' 作る.',
      mailingTitle: '電子メールによる通知：',
      mailingDescr:
        'ここでは、個々のアクティビティに対するEメール通知を有効にしたり（デフォルト）、1年間無効にしたりすることができます。',
      allNotificationsLabel: 'すべての通知',
      postsLabel: `貢献 ${KreiseName('JAP').multi}`,
      messagesLabel: 'プライベートメッセージ',
      commentsLabel: '自身の貢献に対するコメント',
      contactRequestsLabel: 'お問い合わせ',
      eventsLabel: 'イベント',
      clubMemberLabel: '新規会員登録',
      enableLabel: 'へ',
      disableToLabel: 'までオフ',
      disableLabel: 'より',
      ownPosts: '私の投稿（郵便物も自分で受け取った）',
    },
    searchInput: {
      title: '検索',
      placeholder: '検索',
    },
    emptyList: {
      title: 'ここには（まだ）コンテンツはない',
    },
    switchLanguage: {
      placeholder: '言語/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'russian (русский)',
      SP: 'Spanish (Español)',
      title: '言語を選択',
    },
    filePicker: {
      takePicLabel: 'アタッチメント',
      uploadPicLabel: '写真を撮る',
      selectPicLabel: 'F音/ビデオを選択',
      selectDocLabel: 'ドキュメントを選択',
    },
    datePicker: {
      selectDateLabel: '日付を選択',
      confirmDateLabel: '確認',
      selectTimeLabel: '時間を選択',
    },
    changePassword: {
      currentPasswordLabel: '現在のパスワード',
      newPasswordLabel: '新しいパスワード',
      repeatPasswordLabel: 'リピートパスワード',
      changePasswordLabel: 'パスワードの変更',
    },
    appInfo: {
      appDescLabel:
        'Korponnectは、クラブ活動をしている学生のためのアプリです。企業間の交流、イベントの開催、プロフェッショナルでプライベートなネットワーキングなど、すべてが1つのアプリに集約されています。',
      contactsLabel: InfoContactsName('JAP'),
      infoLabel: 'インフォメーションn',
      versionLabel: 'アプリ版',
      agbLabel: 'GTC',
      dataProtectionLabel: 'プライバシーポリシー',
      manualLabel: '使用方法',
      appTextLabel: 'ここでは、私たちの',
      appAndLabel: 'そして',
      appMainTextLabel: 'サークルを追加しますか？プロフィールを削除したいですか？info@connact.app。',
      deleteFeedback: '削除理由／ご意見 🙂:',
      deleteUserTitle: 'アカウントを削除しますか？',
      deleteUserDesc:
        'アカウントを削除することもできます。プロフィール]->[通知]で設定を編集するか、私たちにメールを送ってください。',
      deleteUserAlert: '本当にアカウントを削除したいのですか？',
      webLabel: 'ウェブ版',
    },
    infoMessages: {
      registartionSuccessLabel: 'ご登録ありがとうございました。!',
      manualRegSuccessLabel: 'ご登録ありがとうございます。まもなく登録が完了し、Eメールにてお知らせいたします。',
      linkExpiredLabel: 'リンクの有効期限が切れています。新しいパスワードの変更をリクエストしてください。',
      emailExistLabel: '申し訳ございませんが、Eメールはご利用いただけません。.',
    },
    logoutAlert: {
      title: 'ログアウト',
      text: '本当にログアウトしますか？ログアウトする必要はありません。アプリを閉じるか最小化するだけです。',
      confirm: 'はい',
      dismiss: 'キャンセル',
    },
    invalidInputs: {
      passwordChars: `パスワードは少なくとも ${passwordDigits(
        'JAP',
      )} 文字（少なくとも1つの文字、1つの数字、1つの特殊文字を含む）。`,
      fieldRequired: 'このフィールドは必須です：.',
      emailsNotMatching: 'あなたの電子メールが一致することを確認してください',
      emailInvalid: 'メールアドレスが正しくありません！',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: '少なくとも1つのエントリーを選択してください。',
    },
    mentoring: {
      profile: {
        rules1: 'に同意する。',
        rules2: ' 価値観の理解 ',
        rules3: '“奨学金保持者のためのメンタリング」。',
        menteeDescription:
          'メンター地域を選択すると、現地で開催されるイベントについてお知らせすることができます。注：選択はいつでも変更できます。メンター探しを制限するものではありません。',
        mentorDescription:
          '奨学生があなたをメンターとして見つけることができるよう、メンター・プロフィールを記入してください。注：少なくとも1つの地域と少なくとも1つの分野が必要です。',
        mentoringRegions: 'メンタリング地域',
        reigonsShort: '地域',
        topics: '対象分野',
        departments: '部門',
        available: '私は利用可能である',
        unavailable: '現在欠席中',
        deleteAccount: 'メンターのプロフィールを削除し、グループから離脱する',
        deleteAccountDescr: 'メンター・プロフィールを削除し、メンター・グループ・チャットから退会しますか？',
        uploadPdf: 'メンター・プロフィール (PDF)',
        myPdf: 'プロフィール (PDF)',
        pdf: 'メンター・プロフィール',
      },
      tabNames: {
        info: 'インフォメーション',
        news: 'ニュース',
        profile: 'プロフィール',
        search: '検索・お問い合わせ',
        request: 'メンター',
        requestDetails: 'メンタリングに関するお問い合わせ',
        tabDeactivated: 'タブが無効になりました。メンタープロフィールを入力してください。',
      },
      search: {
        description:
          '的を絞って検索を開始するために、いくつかの選択フィールドから始め、新しい検索実行で検索グリッドを広げることをお勧めします。お住まいの地域で適切な指導者が見つからない場合は、近隣の指導地域に検索を広げてください。検索条件：1地域、1～3分野。すべてのフィールドはANDリンクされています！',
        searchBtn: '検索',
      },
      requests: {
        mentorIncoming: '私の指導に関するお問い合わせ',
        mentorCurrent: '私のメンターたち',
        menteeOutgoing: 'メンタリングに関するお問い合わせ',
        menteeCurrent: '私のメンターシップ',
        cancelMentoring: 'メンタリング終了',
        sendRequest: 'メンターとしての依頼',
        alreadyHaveMentor: 'メンターは一人しか持てない',
        requestSuccess: 'お問い合わせは正常に送信されました',
        requestRetracted: 'お問い合わせの取り下げに成功',
        cancelSendingRequest: 'メンタリング要請の取り下げ',
        approveRequest: '問い合わせを受け付ける',
        rejectRequest: 'リクエスト拒否',
        invitaionText: 'お問い合わせ',
        rejectionText: '不採用の理由を述べる',
        sureRetractRequest: '本当に問い合わせを取り下げたいのか？',
      },
    },
    downloads: {
      root: 'メインディレクトリ',
      delete: '削除',
      rename: '名前変更',
      areYouSureDeleteFolder: '本当にフォルダを削除しますか？すべてのコンテンツが削除されます！',
      renameFolder: 'フォルダ名の変更',
      createNewFolder: '新しいフォルダを作成する',
      newFolderPlaceholder: 'フォルダ名を入力',
      create: '作成',
      areYouSureDeleteFile: '',
      downloadFile: 'ダウンロード',
      deleteFile: 'デバイスから削除',
      openInFinder: 'ファイルで開く',
      cancel: 'キャンセル',
    },
    globalSearch: {
      postedBy: '投稿者',
      found: '発見',
      loadMore: 'もっと読む',
      enterQuery: '検索用語を入力してください。何でも検索できます(名前、文書、イベント、投稿、...)',
      folder: 'フォルダ',
      post: '貢献',
      error: '3文字以上',
    },
    kreiseSettings: {
      description: '概要',
      push: 'プッシュ通知',
      email: 'メール通知',
      subscribers: 'メンバー',
      active: '活性化',
      oneYear: '1年間沈黙',
      forever: 'サイレント・フォーエバー',
      until: 'まで',
      cantDeactive: 'この設定は、このグループチャットでは無効になっています。',
      SINGLE: '各寄稿について',
      SUMMARY_ONCE: 'サマリー 週1回',
      SUMMARY_TWICE: 'サマリー 週2回',
      SUMMARY_DAY: 'サマリー 1日1回',
      OFF: 'より',
      PUSH: 'プッシュ通知',
      EMAIL: 'メール通知',
      PUSH_AND_EMAIL: 'プッシュ通知とEメール通知',
      newUser: '新規ユーザーへの通知',
      statistic: '送信統計',
      off: 'より',
      ownPosts: 'ご寄付の統計メールを送る',
      deleteMemberDescr: '削除?',
      deleteMemberBtn: '削除',
      retractAdminRights: '権利の撤回',
      deleteAdminAndUser: '完全に取り除く',
      toDeleteKreiseBtn: 'グループチャットの削除',
      deleteKreiseDescr: `${KreiseName('JAP').single} 取り返しのつかない削除？`,
      deleteKreiseModalTitle: 'メンバーを外す？',
      addKreiseName: '名称',
      addKreiseDescription: '概要',
      addMembers: 'メンバーを追加する',
      createKreise: 'グループチャットの作成',
      toKreiseEditMessage: '注：メンバーの変更はすべて直ちに実施されます',
      kreiseCreator: '作成者：このグループチャットで',
      kreiseDeleteMembersLeft: 'グループチャットを削除できるのは、メンバーが1人だけになったときだけです。',
      kreiseDeleteMembersLeftTitle: 'まずメンバーから外す',
      suggestionInNewsfeed: {
        admin: '管理者',
        member: 'メンバー',
        corp: `${CorpsName('JAP').single} メンバー`,
        corpAdmin: `${CorpsName('JAP').single} 管理者`,
      },
    },
    surveys: {
      checkVotes: 'ショーの結果',
      chooseOptions: {
        single: 'オプションを選択してください。',
        multiple: '少なくとも1つのオプションを選択する。',
      },
      votesModalTitle: '結果',
      createModalTitle: 'アンケート',
      noVotes: '投票は行われていない',
      question: '質問',
      askAQuestion: '質問する',
      allowMultiple: '複数回答を許可する',
      votes: {
        single: '声',
        multiple: '声',
      },
      option: 'オプション',
      optionsLabel: 'オプション',
      anonymus: 'Anonymus poll',
      create: '作成',
    },
  },
  {
    key: 'CIN', //chinese language / chinesisch
    key2: 'zh',
    responseHandler: {
      messageLabel: '未知错误。请联系 info@connact.app',
      expiredLinkLabel: '链接已过期。申请更改新密码.',
      emailExistLabel: '抱歉，该电子邮件地址已注册。',
      profileUpdated: '简介已更新',
      profileUpdatedCorpsError: `简介已更新！以下内容 ${CorpsName('CIN').single} 由于登录代码不正确，无法添加：`,
      appNeedsUpdate: '请在 App Store / Google Play 更新应用程序。',
      maximalVideoSize: '最大文件大小为 2 GB！',
    },
    report: {
      notBelongLabel: '用户不再是会员。',
      incProfileLabel: '用户提供虚假个人资料',
      badPicLabel: '用户的个人资料图片违反了指导原则',
      badComLabel: '用户的发言违反了指导原则',
      spamLabel: '用户在聊天中发送垃圾邮件',
      reasonsLabel: '发出通知的原因',
    },
    drawer: {
      news: `最新消息`,
      kreise: KreiseName('CIN').multi,
      search: '搜索',
      calendar: CalendarName('CIN'),
      documents: `${DocumentsName('CIN')}`,
      contacts: '联系方式',
      requests: '联系查询',
      messages: MessagesName('CIN'),
      profile: ProfileName('CIN'),
      logout: '退出登录',
      map: '地图',
      mentoring: '指导',
    },
    imageCropper: {
      cropLabel: '农作物',
      cancelLabel: '取消',
      chooseLabel: '准备就绪',
    },
    signIn: {
      register: '注册',
      title: '请登录',
      loginPlaceholder: '电子邮件地址',
      passwordPlaceholder: '暗号',
      forgotPassword: '忘记密码？',
      loginBtn: '登录',
      noAccount: '尚无账户?',
      description0: '如有任何问题，请联系',
      description1: '或',
      description2: '转捩.',
      loginValidation: '您的电子邮件地址不正确',
      passwordValidation: '密码长度必须至少为 6 个字符。',
    },
    search: {
      name: '名称',
      corps: CorpsName('CIN').single,
      country: CountryName('CIN'),
      city: '城市',
      industry: IndustryName('CIN'),
      company: '公司名称',
      profession: '专业',
      hobby: '兴趣爱好',
      cultural: '文化赞助商',
      study: `${StudyName('CIN').short}`,
      office: '分支机构',
      degreeProgram: StudyName('CIN').studiengang,
      university: StudyName('CIN').university,
      position: `${FunctionName('CIN')}`,
      status: '现状',
      title: '搜索结果',
      filterTitle: '筛选方式',
      postCode: '邮编',
      global: '一切',
      activity: ActivityName('CIN'),
    },
    resetPassword: {
      enterEmail: '请输入您的电子邮件地址',
      inputPlaceholder: '电子邮件地址',
      sendBtn: '发送',
      cancelBtn: '取消',
      message: '如果我们知道电子邮件地址，就会向其发送链接。',
      desc: '您必须输入应用程序中存储的准确电子邮件地址。请检查您的电子邮件，看看是哪个。',
    },
    password: {
      resetLabel: '输入新密码',
      inputLabel: '新密码',
    },
    signUp: {
      createAccount: '创建账户',
      inputPlaceholder: `您的 ${CorpsName('CIN').single}s`,
      sendBtn: 'Weiter',
      descFirst: '注册即表示您同意我们的条款和条件 ',
      descSecond: '并确认您已阅读我们的',
      descThird: '如有任何疑问，请随时通过 info@connact.app 或 +49 170 49 805 与我们联系。',
      terms: '条款和条件。',
      policy: '隐私政策。.',
      description: '备注',
      generatePassword: '生成密码',
      noCodeSuccessRegister: '感谢您的注册！一旦激活，我们将通过电子邮件通知您',
    },
    signUpDefault: {
      withoutCode: '无代码',
      createAccount: '只需在此注册',
      withCode: '带邀请码',
      simpleRegister: '无邀请码',
      existingAccount: '已有账户？',
      descr01: '我同意',
      descr0: '注册即表示您同意',
      descr1: '和',
      descr2: '至.',
      guest: `注册为 ${guest_naming_singular}`,
      withCodeWithDescr: `注册为s ${member_naming_singular}`,
    },
    signUpManual: {
      title: '注册',
      photoDesc: '添加照片',
      genderInput: '性别',
      firstName: '姓名',
      lastName: '姓氏',
      club: CorpsName('CIN').single,
      specify: '请注明',
      emailPlaceholder: '电子邮件地址',
      emailHint: '请使用长期使用的邮件地址.',
      repeatEmailPlaceholder: '重复电子邮件地址',
      passwordPlaceholder: '设置密码',
      repeatPasswordPlaceholder: '重复密码',
      postcode: '邮编',
      place: '居住地',
      country: '国家',
      loginCode: '邀请码',
      mobilePlaceholder: '手机号码（在个人资料中不可见）',
      corps: SignUpCorpsName('CIN'),
      searchCountry: 'Suche nach Land',
    },
    backToLogin: {
      desc: '已有账户？ ',
      login: '登录',
    },
    backToSignUp: {
      desc: '还没有账户？ ',
      register: '注册',
    },
    privacy: {
      desc: '是的，我同意',
      btn: '通用技术委员会',
      zu: '至',
      map: {
        title: '数据保护通知',
        description:
          '本应用程序的实施符合 GDPR。我们仅使用谷歌地图来显示地图。不传输任何个人数据。\n\n此外，图钉仅根据邮编/城市粗略显示，但不包括街道/编号。',
      },
      restrictions: {
        overall: '该功能仅对以下成员开放.',
        overallWilkar: '出于数据保护原因，该功能不可用。',
        profile: '只有会员才能查看用户资料。不过，您可以给用户写私人信息.',
        groupchat: `"(《世界人权宣言》) ${KreiseName('CIN').single} 只有会员才能访问.`,
        groupchatWilkar: `出于数据保护的原因 ${KreiseName('CIN').single} 只有会员才能访问.`,
        comments: '只有会员才能看到评论.',
        commentsWilkar: '出于数据保护的原因，评论只能由会员查看。.',
        likes: '只有会员才能查看 "喜欢 "列表。',
        post: '只有会员才能撰写帖子.',
        participantsList: '与会者名单仅供会员查看。',
        commentsOnlyForMembers: '只有会员才能看到评论。',
        postsLimitation: '较早的帖子只能由会员查看。',
      },
      settings: {
        guestPost: {
          title: `已验证 ${guest_naming_plural} 可以看到我的帖子`,
          ask: '一定要问',
          no: '没有',
          yes: '是',
        },
      },
      guestModal: {
        title: `可以验证e ${guest_naming_plural} 看到这篇文章？您可以在之后更改每个帖子的可见性设置.`,
        dontAskAgain: '请勿再次询问（您也可以在 "个人资料"→"隐私 "下更改此设置）。',
      },
      post: {
        ifShown: `对于 ${guest_naming_plural} 隐藏`,
        ifHidden: `对于 ${guest_naming_plural} 展览`,
        ifShownModal: `捐款现可用于 ${guest_naming_plural} 不可见.`,
        ifHiddenModal: `捐款现可用于 ${guest_naming_plural} 看得见.`,
      },
    },
    searchModal: {
      landTitle: '选择国家',
      corpsTitle: `${CorpsName('CIN').single} 选择`,
      pleaseSelect: 'Bitte wählen',
    },
    btn: {
      sendBtn: '发送',
      cancelBtn: '取消',
      select: '选择',
      cancelEventBtn: acceptEventName('CIN').cancel,
      acceptBtn: acceptEventName('CIN').accept,
      saveBtn: '节省',
      clearBtn: '删除',
      acceptReqBtn: '接受',
      declineReqBtn: '取消',
      updateBtn: '刷新',
      declineBtn: '拒绝',
      yesUnderstood: 'Ja, verstanden',

      ok: '好的',
    },
    validation: {
      email: '您的电子邮件地址不正确',
      password: '密码长度至少为 6 个字符',
      repeatEmail: '电子邮件和电子邮件重复不匹配。',
      repeatPassword: '密码和密码重复不匹配',
      required: '该字段为必填字段',
      matchPasswords: '新旧密码匹配',
      dontMatchPasswords: '密码和密码重复不匹配。',
    },
    init: {
      title: 'Wird geladen',
      overlayLabel: 'Bitte Internetverbindung prüfen',
    },
    screenTitles: {
      postsTitle: `最新消息`,
      circlesTitle: KreiseName('CIN').multi,
      searchTitle: '搜索',
      profileTitle: ProfileName('CIN'),
      eventTitle: CalendarName('CIN'),
      eventDetailsTitle: '活动',
      newEventTitle: '添加事件',
      contactsTitle: '联系方式',
      contactReqTitle: '联系查询',
      reqDetailsTitle: '联系查询',
      outReqTitle: '联系查询',
      messagesTitle: MessagesName('CIN'),
      changePasswordTitle: '更改密码',
      appInfoTitle: '关于',
      reportTitle: '报告用户',
      createKreiseTitle: '创建群组聊天',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      user: '用户',
      mainTitleLabel: ProfileName('CIN'),
      basisLabel: '基地',
      clubLabel: CorpsName('CIN').single,
      clubLabelPlural: CorpsName('GER').multi,
      professionLabel: '专业',
      studyLabel: StudyName('CIN').short,
      uploadPhotoLabel: '拍摄照片',
      editPhotoLabel: '编辑照片',
      profileCompletedLabel: '简介',
      profileCompletedGLabel: '完整',
      birthday: '出生日期',
      functionLabel: `${FunctionName('CIN')}`,
      dateOfJoinLabel: CorpsEditDate('CIN'),
      positionDescLabel: '专业',
      companyLabel: CompanyName('CIN'),
      nameLabel: '名称',
      websiteLabel: '网站',
      fromLabel: '来自',
      toLabel: '直到',
      delete: '删除',
      untilToday: '直到今天',
      degreeLabel: StudyName('CIN').abshchluss,
      miscellaneos: '杂项',
      hobbies: '兴趣爱好',
      cultural: '文化赞助商',

      positionLabel: '专业',
      firmaLabel: '公司名称',
      industryLabel: IndustryName('CIN'),
      areaLabel: '范围',
      typeOfEmplLabel: '就业类型',
      activityLabel: ActivityName('CIN'),
      careerStageLabel: '职业水平',
      activityDescLabel: '活动说明',
      employmentPeriodLabel: '期间',
      universityLabel: StudyName('CIN').hochsule,
      courseLabel: StudyName('CIN').studiengang,
      ageLabel: '年龄',
      birthdayLabel: '生日',
      editProfileLabel: '编辑个人资料',
      addContactLabel: '添加为联系人',
      sendMessageLabel: '发送信息',
      requestSentLabel: '已发送联系请求t',
      sendRequestSuccessLabel: '查询已成功发送',
      reportSentLabel: '用户已报告',
      addReportLabel: '报告/阻止用户',
      contactsLabel: '联系次数',
      showBirthday: '联系人看到我的生日',
      street: '街道 + 编号 ',

      partner: '合作伙伴名称',
      mobileNumberLabel: '手机号码.',
      handphoneNumberLabel: '手机号码',
      telephoneNumberLabel: '电话号码',

      leibbursch: 'Leibbursch',
      leibfuechse: 'Leibfüchse',
      bio: '关于我',
      addDocuments: '为您添加文件',
      image: '图片',
      video: '视频',
      pdf: 'PDF',
      link: '链接',
      firstName: '名',
      lastName: '姓',
      title: '职称',
      email: 'E-Mail',
      name: '姓名',
      lastname: '姓氏',
      country: CountryName('CIN'),
      wohnort: '城市',
      postcode: '邮政编码',
      inReview: 'Confirmation via mail pending',
    },
    calendar: {
      birthdayLabel: '的生日',
      noEventsLabel: '所选日期没有活动',
      allTabLabel: '全部',
      myTabLabel: '我的',
      clubsTabLabel: CorpsName('CIN').single,
      subsTabLabel: '订阅',
      subscribedTabLabel: '订阅者',
      otherTabLabel: '其他',

      error: '开始日期/时间必须在结束日期/时间之前。',
      filter: {
        all: '全部',
        invited: '特邀',
        confirmed: '承诺',
      },
    },
    eventLabels: {
      inviteTo: '邀请',
      addEndDate: '输入结束日期/时间',
      eventLabel: '组织者',
      startLabel: '起价',
      locationLabel: '地点',
      participantLabel: '与会者',
      moreInfo: '更多信息',
      commentsLabel: '评论',
      noCommentsLabel: '尚无评论',
      eventStartLabel: '开始',
      eventEndLabel: '结束',
      descLabel: '说明',
      creatorLabel: '创建者',
      titleLabel: '标题',
      deleteEventTitle: '删除事件？',
      deleteEventLabel: '删除此事件？',
      clubsTitle: `${CorpsName('CIN').single}s`,
      gruppenchat: '选择群组聊天',
      contactsTitle: '联系方式',
      delCommentLabel: '删除评论?',
      sendInvite: '将邀请函寄往',
      selectCorps: '选择目标群体',
      externalGuest: '受邀外宾',
      externalGuestPlaceholder: '输入您的电子邮件地址',
      attachmentLabel: '附录',
      shareLinkLabel: '分享链接',
      linkLabel: `${guest_naming_singular} 走进`,
      copyLinkLabel: '复制链接',
      iCalDownload: '日历文件',
      timezone: '时区',
      visibility: '可见性',
      errorDate: '日期已过去',
      startAfterEndError: '结束日期必须晚于开始日期',
      members: 'Members',
      waitlist: 'Warteliste',
      onWaitlist: 'auf der Warteliste',
      leaveWaitlist: 'Platz freigeben?',
      leaveWaitlistDesc: `Von Warteliste entfernen?\n\n Wenn du dich später wieder auf die Warteliste setzt, landest du ggf. auf einer hinteren Position.`,
      limitAchieved: (limit: number) =>
        `Die maximale Teilnehmeranzahl von ${limit} ist erreicht. Du wurdest auf die Warteliste gesetzt.`,
      visibilityOptions: {
        allWithoutGuests: 'Mitglieder und auf Webseite',
        invited_member: '仅限受邀成员',
        verified_member: '所有成员',
        authorized_member: '所有成员和来宾',
        all: '会员、来宾和网站',
      },
      modalEvent: {
        title: '创建的活动',
        subTitle: '活动应运而生。\n\n',
        content:
          '根据用户设置，现在将发送事件的推送通知/电子邮件通知。如果您在 "个人资料"→"通知 "下进行了设置，您自己也会收到有关活动的电子邮件通知。',
      },
      modalComment: {
        title: '通知谁？',
        message: '您的意见应告知谁？',
        optionAll: '所有与会者',
        optionNone: '仅主办单位',
        button: '发送评论',
      },
      cancelModal: {
        title: '取消还是删除？',
        message: '取消或删除事件？',
        optionAll: '取消（将通知与会者）',
        optionNone: '删除（参与者不知情）',
      },
      updateEventModal: {
        title: '发出更多邀请',
        label: '邀请其他小组.',
        message: '根据设置，有关事件的推送通知或电子邮件通知现在会发送到其他组。',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/Mailadresse',
        enterLink: '添加链接/电子邮件地址或类似内容',
      },
      parts: {
        part: 'Part',
        addPart: 'Add program point',
        hint: 'Explanation',
        question: 'Question',
        single: 'Only one option selectable',
        multi: 'Several options available',
        freeText: 'Free text answer',
        quiz: 'Quiz',
        questionnaire: 'Questionnaire',
        addQuestionnaire: 'Add Questionnaire',
        questionnaireType: 'Answer options',
        option: 'Option',
        addOption: 'Add option',
        showMembers: 'Participants',
        youJoined: 'You take part in:',
        totalToPay: 'Total amount to be paid:',

        yourAnswer: 'Your answer',
        visibleForCreators: 'Visible only for creators',
        allAnswers: 'All answers:',
        addUsers: 'Add users',
        isAnonym: 'Anonymous voting',
        isAnonymQuiz: 'Anonymes Quiz',
      },
    },
    rrEventLabels: {
      title: '重复',
      MO: '星期一',
      TU: '星期二',
      WE: '星期三',
      TH: '星期四',
      FR: '星期五',
      SA: '星期六',
      SU: '星期日',
      YEARLY: { single: '年份', regular: '每年' },
      MONTHLY: { single: '月', regular: '每月' },
      WEEKLY: { single: '周', regular: '周' },
      DAILY: { single: '日', regular: '日' },
      SINGLE: { single: '独特性', regular: '独特性' },
      CUSTOM: {
        single: '定制...',
        regular: '定制...',
      },
      freq: '频率',
      interval: '间隔',
      notRepeat: '独特性',
      every: '每一个',
      month: '月',
      week: '周',
      last: '最后一个',
      on: '关于',
      everyMonthOn: '每月在',
      ofTheMonth: '每月',
      editLabel: '您想更改哪些日期',
      thisDate: '此日期仅限',
      thisDateAndFollowing: '本文件及其后所有',
      allDates: '所有日期',
      after: '之后',
      time: '日期',
      times: '日期',
      neverEnds: '永无止境',
      until: '直到',
      repeatedDefault: ({ frequency, freq }) => `是否每个${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} 连串`,
      repeatedMonthly: ({ day, interval }) =>
        `将每${interval == 1 ? '' : ` ${interval}.`} 月 ${day.length > 2 ? day : `${day}.`} 连串.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }) =>
        `将每${bysetpos == -1 ? ' 最后一个' : ` ${bysetpos}.`} ${day} 的${
          interval === 1 ? '' : ` ${interval}.`
        } 重复月份.`,

      repeatedYearly: ({ interval }) => `如果每个${interval == 1 ? '' : ` ${interval}.`} 重复年份.`,
      repeatedWeekly: ({ interval, byweekday }) =>
        `是否每个${interval != 1 ? ` ${interval}.` : ''} 周${
          !!byweekday?.length
            ? ' 关于 ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' 和 ' : '') +
              byweekday?.slice(-1)
            : ''
        } 连串.`,
      repeatedDaily: ({ interval }) => `将每${interval != 1 ? ` ${interval}.` : ''} 日复一日.`,
    },
    requestsLabels: {
      receiveLabel: '接收',
      sentLabel: '发送',
    },
    post: {
      adTitle: '信息/显示屏',
      toKreise: '关于',
      comment: '评论',
      myPost: 'I',
      openPdf: '点击打开',
      deletePost: '删除本帖？',
      posted: '已发布',
      commented: '有评论',
      contacts: '联系方式',
      sendToContacts: '给所有人的信息',
      enterText: '在此留言',
      sendToCircle: `留言至 ${KreiseName('CIN').singleArticle}`,
      sendComment: '输入评论',
      longMessage: '最多 20,000 个字符',
      noComments: '尚无评论',
      deleteComment: '删除评论？',
      deleteEventPost: '删除这个帖子？这不会删除事件。',
      editPostLabel: '编辑职位',
      editingPostLabel: '编辑模式',
      deletePostLabel: '删除帖子',
      editedAt: '已处理',
      pinned: '已钉.',
      actions: {
        label: '职位行动',
        pin: {
          label: '针柱',
          once: '向上移动一次',
          d3: '3 天',
          d5: '5 天',
          w1: '1 周',
          w2: '2 周',
          unpin: '不要再用别针固定',
        },
      },
    },
    circles: {
      circlesTitle: `订阅者 ${KreiseName('CIN').multi}`,
      noCirclesTitle: `Nicht abonnierte ${KreiseName('CIN').multi}`,
      myPost: 'I',
      circlesInfoTitle: '信息与参与者',
      circlesSubscribe: '订阅',
      unsubscribeAlert: '不可撤销地离开封闭群聊？',
      continueAlert: '放弃',
      readOnlyKreise: `这是一个 "只读-${
        KreiseName('CIN').single
      }". 只有管理员才能在此创建帖子。不过，您可以在帖子上写评论。`,
    },
    messages: {
      addMessageLabel: '输入信息',
      changesSaved: '已保存更改！',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: '基地',
      clubsTab: `${CorpsName('CIN').single}`,
      miscellaneosTab: '兴趣爱好',
      professionTab: '专业',
      studyTab: StudyName('CIN')?.short,
      notificationsTab: '通知',
      privacy: '隐私权',
    },
    profile: {
      addPhotoLabel: '添加照片',
      genderInputLabel: '性别',
      titleLabel: '标题',
      firstNameLabel: '姓名',
      lastNameLabel: '姓氏',

      passwordLabel: '设置密码',
      birthDateLabel: '出生日期',

      changePasswordLabel: '更改密码',
      defaultCountryLabel: '德国',
      defaultGraduationLabel: '未说明',
      defaultCorpsLabel: '无',
      street: '街道 + 编号. ',

      partner: '合作伙伴名称',
      mobileNumberLabel: '手机号码.',
      handphoneNumberLabel: '手机号码',
      telephoneNumberLabel: '电话号码',
    },
    profileKorp: {
      verband: CorpsName('CIN').single,
      function: FunctionName('CIN'),
      dateOfJoin: CorpsEditDate('CIN'),
      beername: 'Biername',
    },
    profileClubs: {
      noUserLabel: '在使用应用程序之前，必须先填写所有基本信息。',
      functionLabel: `${FunctionName('CIN')}`,
      setClubLabel: `请输入 ${CorpsName('CIN').single} 具体说明.`,
      matchClubLabel: '"《世界人权宣言》',
      matchClubContinueLabel: '已存在于个人资料中，请检查一次.',
      clubLabel: `${CorpsName('CIN').single.toUpperCase()}`,
      clubCodeLabel: `您的 ${CorpsName('CIN').single.toUpperCase()}S`,
      entryTimeLabel: CorpsEditDate('CIN'),
      deleteClubLabel: '您是否愿意延长您在',
      deleteClubContinueLabel: '真的删除？',
      selectVariant: '你想做什么？',
      requestExistingCorp: '添加现有条目',
      requestNewCorps: '申请新条目',
      createCorp: '创建新条目',
    },
    profileProfession: {
      jobTitle: '职位名称',
      noUserLabel: '在使用应用程序之前，必须先填写所有基本信息。',
      untilToday: '直到今天',
      positionTitleLabel: '专业',
      itemNameLabel: '专业',
      employmentLabel: '就业类型',
      activityLabel: ActivityName('CIN'),
      careerStageLabel: '职业水平',
      activityDescLabel: '活动说明',
      companyLabel: CompanyName('CIN'),
      nameLabel: '名称',
      industryLabel: IndustryName('CIN'),
      areaLabel: '范围',
      websiteLabel: '公司网站',
      employmentPeriodLabel: '就业期限',
      fromLabel: '来自',
      toLabel: '直到',
    },
    profileStudy: {
      noUserLabel: '在使用应用程序之前，必须先填写所有基本信息',
      universityLabel: StudyName('CIN').hochsule,
      courseLabel: StudyName('CIN').studiengang,
      degreeLabel: StudyName('CIN').abshchluss,
    },
    profileOther: {
      noUserLabel: '在使用应用程序之前，必须先填写所有基本信息。',
      hobbiesLabel: '兴趣爱好',
      culturalLabel: '文化赞助商',
      socialMediaPlattform: '社交媒体平台',
      socialMediaLabel: '社交媒体链接',
      socialMediaLink: '链接',
      socialMediaError: '请输入完整的 URL',
    },
    profileNotifications: {
      noUserLabel: '在使用应用程序之前，必须先填写所有基本信息。',
      notificationsInfo: '在这里，您可以激活单个活动的电子邮件通知（默认）或在 3 个月内停用。',
      notificationsTitle: '推送通知：',
      notificationsDescr0: '您可以在 ',
      notificationsDescr1: '智能手机系统设置',
      notificationsDescr2: ' 生产.',
      mailingTitle: '电子邮件通知：',
      mailingDescr: '在这里，您可以激活单个活动的电子邮件通知（默认），也可以在 1 年内停用',
      allNotificationsLabel: '所有通知',
      postsLabel: `在 ${KreiseName('CIN').multi}`,
      messagesLabel: '私人信息',
      commentsLabel: '对自己贡献的评论',
      contactRequestsLabel: '联系查询',
      eventsLabel: '活动',
      clubMemberLabel: '新注册会员',
      enableLabel: '至',
      disableToLabel: '关闭，直至',
      disableLabel: '来自',
      ownPosts: '我的捐款（我自己也收到的邮件）',
    },
    searchInput: {
      title: '搜索',
      placeholder: '搜索',
    },
    emptyList: {
      title: '搜索',
    },
    switchLanguage: {
      placeholder: '语言/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'russian (русский)',
      SP: 'Spanish (Español)',
      title: '选择语言',
    },
    filePicker: {
      takePicLabel: '附件',
      uploadPicLabel: '拍摄照片',
      selectPicLabel: '选择照片/视频',
      selectDocLabel: '选择文件',
    },
    datePicker: {
      selectDateLabel: '选择日期',
      confirmDateLabel: '选择日期',
      selectTimeLabel: '选择时间',
    },
    changePassword: {
      currentPasswordLabel: '当前密码',
      newPasswordLabel: '新密码',
      repeatPasswordLabel: '重复密码',
      changePasswordLabel: '更改密码',
    },
    appInfo: {
      appDescLabel:
        'Korponnect 是专为兄弟会学生设计的应用程序。企业间的交流、活动的组织以及专业和私人网络--一切尽在一个应用程序中。',
      contactsLabel: InfoContactsName('CIN'),
      infoLabel: '相关信息',
      versionLabel: '应用程序版本',
      agbLabel: '通用技术委员会',
      dataProtectionLabel: '隐私政策',
      manualLabel: '说明',
      appTextLabel: '在这里，您可以找到我们的',
      appAndLabel: '和一个',
      appMainTextLabel: `您想添加一个圈子或有其他建议？您想删除您的个人资料吗？只需发送简短电子邮件至 ${support_email}。`,
      deleteFeedback: '删除原因/反馈 🙂:',
      deleteUserTitle: '删除账户？',
      deleteUserDesc: '顺便说一句：您也可以直接关闭通知。请在 "个人资料"->"通知 "下编辑设置，或给我们发送电子邮件。',
      deleteUserAlert: '您真的想删除您的账户吗？',
      webLabel: '网络版',
    },
    infoMessages: {
      registartionSuccessLabel: '感谢您的注册！',
      manualRegSuccessLabel: '感谢您的注册。您将很快被激活并收到电子邮件通知',
      linkExpiredLabel: '链接已过期。请求更改新密码。',
      emailExistLabel: '抱歉，电子邮件无法使用。',
    },
    logoutAlert: {
      title: '退出登录',
      text: '您真的想注销吗？您不必注销，只需关闭或最小化应用程序即可。',
      confirm: '是',
      dismiss: '取消',
    },
    invalidInputs: {
      passwordChars: `密码必须至少为 ${passwordDigits('CIN')} 字符，包括至少一个字母、一个数字和一个特殊字符。`,
      fieldRequired: '该字段为必填字段。',
      emailsNotMatching: '请确保您的电子邮件与以下内容一致',
      emailInvalid: '您的电子邮件地址不正确！',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: '请至少选择一个参赛项目',
    },
    mentoring: {
      profile: {
        rules1: '我同意',
        rules2: ' 对价值观的理解 ',
        rules3: '“为奖学金获得者提供指导"。',
        menteeDescription:
          '请选择您的指导地区，以便了解现场活动。注意：您可以随时更改您的选择。这不会限制您的导师搜索。',
        mentorDescription:
          '请填写您的导师简介，以便奖学金获得者找到您作为导师。注：至少需要 1 个地区和至少 1 个学科领域.',
        mentoringRegions: '指导地区',
        reigonsShort: '地区',
        topics: '学科领域',
        departments: '部门',
        available: '我可以',
        unavailable: '我目前没有时间',
        deleteAccount: '删除导师档案并离开小组',
        deleteAccountDescr: '删除指导简介并离开指导群聊？',
        uploadPdf: '导师简介 (PDF)',
        myPdf: '我的简介 (PDF)',
        pdf: 'Mentorenprofil',
      },
      tabNames: {
        info: '信息',
        news: '新闻',
        profile: '新闻',
        search: '搜索与查询e',
        request: '导师',
        requestDetails: '辅导咨询',
        tabDeactivated: '标签已停用。请填写您的导师简介',
      },
      search: {
        description:
          '为了有针对性地开始搜索，我们建议您从几个选择字段开始，然后在新的搜索运行中扩大搜索网格。如果在您所在的地区找不到合适的导师，请将搜索范围扩大到邻近的导师地区。搜索标准：1 个地区，1 至 3 个主题领域。所有字段都是 AND 链接！',
        searchBtn: '搜索',
      },
      requests: {
        mentorIncoming: '我的指导咨询',
        mentorCurrent: '我的导师',
        menteeOutgoing: '我的辅导咨询',
        menteeCurrent: '我的导师',
        cancelMentoring: '结束辅导',
        sendRequest: '请求担任导师',
        alreadyHaveMentor: '你只能有一位导师',
        requestSuccess: '查询已成功发送',
        requestRetracted: '成功撤回查询',
        cancelSendingRequest: '撤回指导申请',
        approveRequest: '接受查询',
        rejectRequest: '拒绝请求',
        invitaionText: '查询',
        rejectionText: '说明拒绝的理由',
        sureRetractRequest: '你真的想撤回调查吗？',
      },
    },
    downloads: {
      root: '主目录',
      delete: '删除',
      rename: '重新命名',
      areYouSureDeleteFolder: '真的要删除文件夹？所有内容都会被删除！',
      renameFolder: '重命名文件夹',
      createNewFolder: '创建新文件夹',
      newFolderPlaceholder: '输入文件夹名称',
      create: '创建',
      areYouSureDeleteFile: '',
      downloadFile: '下载',
      deleteFile: '从设备中删除',
      openInFinder: '在文件中打开',
      cancel: '取消',
    },
    globalSearch: {
      postedBy: '由',
      found: '取消',
      loadMore: '取消',
      enterQuery: '已找到',
      folder: '加载更多 [...] ',
      post: '捐款',
      error: '输入至少 3 个字符',
    },
    kreiseSettings: {
      description: '说明',
      push: '推送通知',
      email: '邮件通知',
      subscribers: '成员',
      active: '已激活',
      oneYear: '静默 1 年',
      forever: '永远沉默',
      until: '直到',
      cantDeactive: '此群组聊天停用此设置',
      SINGLE: '此群组聊天停用此设置',
      SUMMARY_ONCE: '此群组聊天停用此设置',
      SUMMARY_TWICE: '每周 2 次总结',
      SUMMARY_DAY: '摘要 每天 1 次',
      OFF: '来自',
      PUSH: '推送通知',
      EMAIL: '邮件通知',
      PUSH_AND_EMAIL: '推送和电子邮件通知',
      newUser: '新用户通知',
      statistic: '传输统计',
      off: '来自',
      ownPosts: '发送捐款统计邮件',
      deleteMemberDescr: '移除?',
      deleteMemberBtn: '移除',
      retractAdminRights: '移除',
      deleteAdminAndUser: '移除',
      toDeleteKreiseBtn: '完全移除',
      deleteKreiseDescr: `${KreiseName('CIN').single} 不可撤销地删除？`,
      deleteKreiseModalTitle: '不可撤销地删除？',
      addKreiseName: '名称',
      addKreiseDescription: '说明',
      addMembers: '说明',
      createKreise: '删除成员？',
      toKreiseEditMessage: '删除成员？',
      kreiseCreator: '删除成员？',
      kreiseDeleteMembersLeft: '注意：对成员的所有更改都将立即执行。',
      kreiseDeleteMembersLeftTitle: '先删除成员',
      suggestionInNewsfeed: {
        admin: '管理员',
        member: '成员',
        corp: `${CorpsName('CIN').single} 成员`,
        corpAdmin: `${CorpsName('CIN').single} 管理`,
      },
    },
    surveys: {
      checkVotes: '显示结果',
      chooseOptions: {
        single: '选择一个选项.',
        multiple: '至少选择一个选项。',
      },
      votesModalTitle: '成果',
      createModalTitle: '调查',
      noVotes: '没有投票',
      question: '问题',
      askAQuestion: '提问',
      allowMultiple: '允许多个答案',
      votes: {
        single: '声音',
        multiple: '声音',
      },
      option: '选项',
      optionsLabel: '选项',
      anonymus: 'Anonymus poll',
      create: '创建',
    },
  },
  {
    key: 'POL', //polish /polnish language / polnisch
    key2: 'pl',
    responseHandler: {
      messageLabel: 'Nieznany błąd. Prosimy o kontakt pod adresem info@connact.app',
      expiredLinkLabel: 'Link wygasł. Poproś o zmianę hasła.',
      emailExistLabel: 'Przepraszamy, ten adres e-mail jest już zarejestrowany.',
      profileUpdated: 'Profil zaktualizowany',
      profileUpdatedCorpsError: `Profil zaktualizowany! Poniżej ${
        CorpsName('POL').single
      } nie mógł zostać dodany z powodu nieprawidłowego kodu logowania:`,
      appNeedsUpdate: 'Prosimy o aktualizację aplikacji w App Store / Google Play.',
      maximalVideoSize: 'Maksymalny rozmiar pliku to 2 GB',
    },
    report: {
      notBelongLabel: 'Użytkownik nie jest już członkiem.',
      incProfileLabel: 'Użytkownik składa fałszywe oferty profilowe.',
      badPicLabel: 'Zdjęcie profilowe użytkownika narusza wytyczne',
      badComLabel: 'Wypowiedzi użytkownika naruszają wytyczne',
      spamLabel: 'Użytkownik wysyła spam na czacie',
      reasonsLabel: 'Powody powiadomienia',
    },
    drawer: {
      news: `Najnowsze wiadomości`,
      kreise: KreiseName('POL').multi,
      search: 'Wyszukiwanie',
      calendar: CalendarName('POL'),
      documents: `${DocumentsName('POL')}`,
      contacts: 'Kontakty',
      requests: 'Zapytania kontaktowe',
      messages: MessagesName('POL'),
      profile: ProfileName('POL'),
      logout: 'Wylogowanie',
      map: 'Mapa',
      mentoring: 'Mentoring',
    },
    imageCropper: {
      cropLabel: 'Uprawa',
      cancelLabel: 'Anuluj',
      chooseLabel: 'Gotowy',
    },
    signIn: {
      register: 'Rejestr',
      title: 'Zaloguj się',
      loginPlaceholder: 'Adres e-mail',
      passwordPlaceholder: 'hasło',
      forgotPassword: 'Zapomniałeś hasła?',
      loginBtn: 'Logowanie',
      noAccount: 'Nie masz jeszcze konta?',
      description0: 'W razie jakichkolwiek pytań prosimy o kontakt',
      description1: 'lub',
      description2: 'obrót.',
      loginValidation: 'Twój adres e-mail jest nieprawidłowy',
      passwordValidation: 'Hasło musi składać się z co najmniej 6 znaków',
    },
    search: {
      name: 'Nazwa',
      corps: CorpsName('POL').single,
      country: CountryName('POL'),
      city: 'Miasto',
      industry: IndustryName('POL'),
      company: 'Firma',
      profession: 'Zawód',
      hobby: 'Zainteresowania',
      cultural: 'Sponsorzy kultury',
      study: `${StudyName('POL').short}`,
      office: 'Oddział',
      degreeProgram: StudyName('POL').studiengang,
      university: StudyName('POL').university,
      position: `${FunctionName('POL')}`,
      status: 'Status',
      title: 'Wynik wyszukiwania dla',
      filterTitle: 'Filtruj według',
      postCode: 'Kod pocztowy',
      global: 'Wszystko',
      activity: ActivityName('POL'),
    },
    resetPassword: {
      enterEmail: 'Wprowadź swój adres e-mail',
      inputPlaceholder: 'Adres e-mail',
      sendBtn: 'Wyślij',
      cancelBtn: 'Anuluj',
      message: 'Anuluj',
      desc: 'Należy wprowadzić dokładny adres e-mail zapisany w aplikacji. Sprawdź swoje wiadomości e-mail, aby zobaczyć, który to adres',
    },
    password: {
      resetLabel: 'Wprowadź nowe hasło',
      inputLabel: 'Nowe hasło',
    },
    signUp: {
      createAccount: 'Utwórz konto',
      inputPlaceholder: `Kod logowania użytkownika ${CorpsName('POL').single}s`,
      sendBtn: 'Dalej',
      descFirst: 'Rejestrując się, wyrażasz zgodę na nasze zasady i warunki ',
      descSecond: 'i potwierdzić zapoznanie się z naszymi',
      descThird: 'W razie jakichkolwiek pytań prosimy o kontakt pod adresem info@connact.app lub +49 170 49 805.',
      terms: 'zasady i warunki.',
      policy: 'polityka prywatności.',
      description: 'Wskazówka',
      generatePassword: 'Generowanie hasła',
      noCodeSuccessRegister:
        'Dziękujemy za rejestrację! Zostaniesz poinformowany e-mailem, gdy tylko zostaniesz aktywowany.',
    },
    signUpDefault: {
      withoutCode: 'Bez kodu',
      createAccount: 'Wystarczy zarejestrować się tutaj',
      withCode: 'Z kodem zaproszenia',
      simpleRegister: 'Bez kodu zaproszenia',
      existingAccount: 'Masz już konto?',
      descr01: 'Zgadzam się z',
      descr0: 'Rejestrując się, użytkownik wyraża zgodę na',
      descr1: 'i',
      descr2: 'do.',
      guest: `Zarejestruj się jako ${guest_naming_singular}`,
      withCodeWithDescr: `Zarejestruj się jako ${member_naming_singular}`,
    },
    signUpManual: {
      title: 'Rejestr',
      photoDesc: 'Dodaj zdjęcie',
      genderInput: 'Płeć',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      club: CorpsName('POL').single,
      specify: 'należy określić',
      emailPlaceholder: 'Adres e-mail',
      emailHint: 'Prosimy o używanie długoterminowego adresu e-mail.',
      repeatEmailPlaceholder: 'Powtórzony adres e-mail',
      passwordPlaceholder: 'Ustaw hasło',
      repeatPasswordPlaceholder: 'Powtórz hasło',
      postcode: 'Kod pocztowy',
      place: 'Miejsce zamieszkania',
      country: 'Kraj',
      loginCode: 'Kod zaproszenia',
      mobilePlaceholder: 'Numer telefonu komórkowego (niewidoczny w profilu)',
      corps: SignUpCorpsName('POL'),
      searchCountry: 'Suche nach Land',
    },
    backToLogin: {
      desc: 'Masz już konto? ',
      login: 'Logowanie',
    },
    backToSignUp: {
      desc: 'Nie masz jeszcze konta? ',
      register: 'Rejestr',
    },
    privacy: {
      desc: 'Tak, zgadzam się na',
      btn: 'GTC',
      zu: 'do',
      map: {
        title: 'Informacja o ochronie danych',
        description:
          'Ta aplikacja została wdrożona zgodnie z RODO. Do wyświetlania mapy używamy wyłącznie Google Maps. Żadne dane osobowe nie są przesyłane.\n\nPonadto pinezki są wyświetlane tylko w przybliżeniu, na podstawie kodu pocztowego / miasta, ale bez ulicy / nr. Oznacza to, że nie jest możliwe wyciągnięcie wniosków na temat konkretnych adresów użytkowników.',
      },
      restrictions: {
        overall: 'Ta funkcja jest dostępna tylko dla członków.',
        overallWilkar: 'Ta funkcja jest niedostępna ze względu na ochronę danych.',
        profile:
          'Tylko członkowie mogą przeglądać profile użytkowników. Możesz jednak napisać prywatną wiadomość do użytkownika.',
        groupchat: `The ${KreiseName('POL').single} są dostępne tylko dla członków.`,
        groupchatWilkar: `Ze względu na ochronę danych ${KreiseName('POL').single} są dostępne tylko dla członków.`,
        comments: 'Komentarze są widoczne tylko dla członków.',
        commentsWilkar:
          'Ze względu na ochronę danych osobowych komentarze mogą być przeglądane wyłącznie przez członków.',
        likes: 'Listę polubień mogą przeglądać tylko członkowie.',
        post: 'Tylko członkowie mogą publikować artykuły.',
        participantsList: 'Lista uczestników może być przeglądana tylko przez członków.',
        commentsOnlyForMembers: 'Komentarze są widoczne tylko dla użytkowników.',
        postsLimitation: 'Starsze posty mogą być przeglądane tylko przez użytkowników.',
      },
      settings: {
        guestPost: {
          title: `Zweryfikowano ${guest_naming_plural} może zobaczyć moje posty`,
          ask: 'Zawsze pytaj',
          no: 'Nie',
          yes: 'Tak',
        },
      },
      guestModal: {
        title: `Maj zatwierdzony ${guest_naming_plural} zobaczyć ten post? Możesz zmienić to ustawienie widoczności dla każdego postu z osobna.`,
        dontAskAgain: 'Nie pytaj ponownie (możesz również zmienić to ustawienie w sekcji "Profil" → "Prywatność")..',
      },
      post: {
        ifShown: `Dla ${guest_naming_plural} ukrycie`,
        ifHidden: `Dla ${guest_naming_plural} pokaz`,
        ifShownModal: `Wkład jest teraz dostępny dla ${guest_naming_plural} nie jest już widoczny.`,
        ifHiddenModal: `Wkład jest teraz dostępny dla ${guest_naming_plural} widoczny.`,
      },
    },
    searchModal: {
      landTitle: 'Wybierz kraj',
      corpsTitle: `${CorpsName('POL').single} Wybierz`,
      pleaseSelect: 'Wybierz',
    },
    btn: {
      sendBtn: 'Wyślij',
      cancelBtn: 'Anuluj',
      select: 'Wybierz',
      cancelEventBtn: acceptEventName('POL').cancel,
      acceptBtn: acceptEventName('POL').accept,
      saveBtn: 'Zapisz',
      clearBtn: 'Usuń',
      acceptReqBtn: 'Akceptuj',
      declineReqBtn: 'Anuluj',
      updateBtn: 'Odświeżanie',
      declineBtn: 'Odrzucić',
      yesUnderstood: 'Ja, verstanden',

      ok: 'OK',
    },
    validation: {
      email: 'Twój adres e-mail jest nieprawidłowy',
      password: 'Hasło musi składać się z co najmniej 6 znaków',
      repeatEmail: 'E-mail i powtórzenie e-maila nie pasują do siebie.',
      repeatPassword: 'Hasło i powtórzenie hasła nie są zgodne',
      required: 'To pole jest wymagane',
      matchPasswords: 'Stare i nowe hasło pasują do siebie',
      dontMatchPasswords: 'Hasło i powtórzenie hasła nie są zgodne.',
    },
    init: {
      title: 'Ładowanie',
      overlayLabel: 'Sprawdź połączenie internetowe',
    },
    screenTitles: {
      postsTitle: `Najnowsze wiadomości`,
      circlesTitle: KreiseName('POL').multi,
      searchTitle: 'Wyszukiwanie',
      profileTitle: ProfileName('POL'),
      eventTitle: CalendarName('POL'),
      eventDetailsTitle: 'wydarzenia',
      newEventTitle: 'Dodaj zdarzenie',
      contactsTitle: 'Kontakty',
      contactReqTitle: 'Zapytania kontaktowe',
      reqDetailsTitle: 'Zapytania kontaktow',
      outReqTitle: 'Zapytania kontaktowe',
      messagesTitle: MessagesName('POL'),
      changePasswordTitle: 'Zmiana hasła',
      appInfoTitle: 'O',
      reportTitle: 'Zgłoś użytkownika',
      createKreiseTitle: 'Tworzenie czatu grupowego',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      user: 'Użytkownicy',
      mainTitleLabel: ProfileName('POL'),
      basisLabel: 'Podstawa',
      clubLabel: CorpsName('POL').single,
      clubLabelPlural: CorpsName('POL').multi,
      professionLabel: 'Zawód',
      studyLabel: StudyName('POL').short,
      uploadPhotoLabel: 'Zrób zdjęcie',
      editPhotoLabel: 'Edytuj zdjęcie',
      profileCompletedLabel: 'Profil',
      profileCompletedGLabel: 'kompletny',
      birthday: 'Data urodzenia',
      functionLabel: `${FunctionName('POL')}`,
      dateOfJoinLabel: CorpsEditDate('POL'),
      positionDescLabel: 'Zawód',
      companyLabel: CompanyName('POL'),
      nameLabel: 'Nazwa',
      websiteLabel: 'Strona internetowa',
      fromLabel: 'Od',
      toLabel: 'Do',
      delete: 'Usuń',
      untilToday: 'Do dzisiaj',
      degreeLabel: StudyName('POL').abshchluss,
      miscellaneos: 'Różne',
      hobbies: 'Zainteresowania',
      cultural: 'Sponsorzy kultury',

      positionLabel: 'Zawód',
      firmaLabel: 'Firma',
      industryLabel: IndustryName('POL'),
      areaLabel: 'Zasięg',
      typeOfEmplLabel: 'Rodzaj zatrudnienia',
      activityLabel: ActivityName('POL'),
      careerStageLabel: 'Poziom kariery',
      activityDescLabel: 'Opis działania',
      employmentPeriodLabel: 'Okres',
      universityLabel: StudyName('POL').hochsule,
      courseLabel: StudyName('POL').studiengang,
      ageLabel: 'Wiek',
      birthdayLabel: 'Urodziny',
      editProfileLabel: 'Edytuj profil',
      addContactLabel: 'Dodaj jako kontakt',
      sendMessageLabel: 'Wyślij wiadomość',
      requestSentLabel: 'Prośba o kontakt została już wysłana',
      sendRequestSuccessLabel: 'Zapytanie wysłane pomyślnie',
      reportSentLabel: 'Użytkownik już zgłosił',
      addReportLabel: 'Zgłaszanie/blokowanie użytkownika',
      contactsLabel: 'Liczba kontaktów',
      showBirthday: 'Kontakty widzą moje urodziny',
      street: 'Ulica + Nie. ',

      partner: 'Nazwa partnera',
      mobileNumberLabel: 'Nr telefonu komórkowego.',
      handphoneNumberLabel: 'Numer telefonu komórkowego',
      telephoneNumberLabel: 'Numer telefonu',

      leibbursch: 'Leibbursch',
      leibfuechse: 'Leibfüchse',
      bio: 'O mnie',
      addDocuments: 'Dodawanie dokumentów do użytkownika',
      image: 'Zdjęcie',
      video: 'Zdjęcie',
      pdf: 'PDF',
      link: 'Link',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      title: 'Tytuł',
      email: 'E-Mail',
      name: 'Imię',
      lastname: 'Nazwisko',
      country: CountryName('POL'),
      wohnort: 'Miasto',
      postcode: 'KOD POCZTOWY',
      inReview: 'Confirmation via mail pending',
    },
    calendar: {
      birthdayLabel: 'Urodziny',
      noEventsLabel: 'Nie ma żadnych wydarzeń dla wybranej daty',
      allTabLabel: 'Wszystkie',
      myTabLabel: 'Mój',
      clubsTabLabel: CorpsName('POL').single,
      subsTabLabel: 'Subskrypcje',
      subscribedTabLabel: 'Subskrybenci',
      otherTabLabel: 'Inne',

      error: 'Data/godzina rozpoczęcia musi być wcześniejsza niż data/godzina zakończenia..',
      filter: {
        all: 'Wszystkie',
        invited: 'Zaproszeni',
        confirmed: 'Obiecane',
      },
    },
    eventLabels: {
      inviteTo: 'Zaproszenie do',
      addEndDate: 'Wprowadź datę/godzinę zakończenia',
      eventLabel: 'Organizator',
      startLabel: 'Zaczyna się od',
      locationLabel: 'Lokalizacja',
      participantLabel: 'Uczestnicy',
      moreInfo: 'Więcej informacji',
      commentsLabel: 'Komentarze',
      noCommentsLabel: 'Brak komentarzy',
      eventStartLabel: 'Start',
      eventEndLabel: 'Koniec',
      descLabel: 'Opis',
      creatorLabel: 'Stworzony przez',
      titleLabel: 'Tytuł',
      deleteEventTitle: 'Usunąć zdarzenie?',
      deleteEventLabel: 'Usunąć to wydarzenie?',
      clubsTitle: `${CorpsName('POL').single}s`,
      gruppenchat: 'Wybierz czat grupowy',
      contactsTitle: 'Kontakty',
      delCommentLabel: 'Usunąć komentarz?',
      sendInvite: 'Wyślij zaproszenie na adres',
      selectCorps: 'Wybór grupy docelowej',
      externalGuest: 'Zaproszeni goście zewnętrzni',
      externalGuestPlaceholder: 'Wprowadź swój adres e-mail',
      attachmentLabel: 'Dodatek',
      shareLinkLabel: 'Udostępnij link',
      linkLabel: `${guest_naming_singular} wejść`,
      copyLinkLabel: 'Kopiuj link',
      iCalDownload: 'Plik kalendarza',
      timezone: 'Strefa czasowa',
      visibility: 'Widoczność',
      errorDate: 'Data jest w przeszłości',
      startAfterEndError: 'Data końcowa musi być późniejsza niż początkowa',
      members: 'Members',
      waitlist: 'Warteliste',
      onWaitlist: 'auf der Warteliste',
      leaveWaitlist: 'Platz freigeben?',
      leaveWaitlistDesc: `Von Warteliste entfernen?\n\n Wenn du dich später wieder auf die Warteliste setzt, landest du ggf. auf einer hinteren Position.`,
      limitAchieved: (limit: number) =>
        `Die maximale Teilnehmeranzahl von ${limit} ist erreicht. Du wurdest auf die Warteliste gesetzt.`,
      visibilityOptions: {
        allWithoutGuests: 'Mitglieder und auf Webseite',
        invited_member: 'Tylko zaproszeni członkowie',
        verified_member: 'Wszyscy członkowie',
        authorized_member: 'Wszyscy członkowie i goście',
        all: 'Członkowie, goście i na stronie internetowej',
      },
      modalEvent: {
        title: 'Utworzono wydarzenie',
        subTitle: 'Wydarzenie zostało utworzone.\n\n',
        content:
          'W zależności od ustawień użytkownika, powiadomienia push/powiadomienia e-mail są teraz wysyłane dla wydarzenia. Jeśli ustawiłeś to w sekcji "Profil" → "Powiadomienia", otrzymasz również powiadomienie e-mail o wydarzeniu..',
      },
      modalComment: {
        title: 'Kogo powiadomić?',
        message: 'Kto powinien zostać poinformowany o Twoim komentarzu?',
        optionAll: 'Wszyscy uczestnicy',
        optionNone: 'Tylko organizatorzy',
        button: 'Wyślij komentarz',
      },
      cancelModal: {
        title: 'Anulować czy usunąć?',
        message: 'Anulować lub usunąć wydarzenie?',
        optionAll: 'Odwołania (uczestnicy zostaną poinformowani)',
        optionNone: 'Usuń (uczestnicy nie są informowani)',
      },
      updateEventModal: {
        title: 'Wysłano kolejne zaproszenia',
        label: 'Pozostałe grupy zostały zaproszone.',
        message:
          'W zależności od ustawień, powiadomienia push lub powiadomienia e-mail o wydarzeniu są teraz wysyłane do innych grup.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/Mailadresse',
        enterLink: 'Dodaj link/adres e-mail lub podobny',
      },
      parts: {
        part: 'Part',
        addPart: 'Add program point',
        hint: 'Explanation',
        question: 'Question',
        single: 'Only one option selectable',
        multi: 'Several options available',
        freeText: 'Free text answer',
        quiz: 'Quiz',
        questionnaire: 'Questionnaire',
        addQuestionnaire: 'Add Questionnaire',
        questionnaireType: 'Answer options',
        option: 'Option',
        addOption: 'Add option',
        showMembers: 'Participants',
        youJoined: 'You take part in:',
        totalToPay: 'Total amount to be paid:',

        yourAnswer: 'Your answer',
        visibleForCreators: 'Visible only for creators',
        allAnswers: 'All answers:',
        addUsers: 'Add users',
        isAnonym: 'Anonymous voting',
        isAnonymQuiz: 'Anonymes Quiz',
      },
    },
    rrEventLabels: {
      title: 'Powtórzenie',
      MO: 'Poniedziałek',
      TU: 'Wtorek',
      WE: 'Środa',
      TH: 'Czwartek',
      FR: 'Piątek',
      SA: 'Sobota',
      SU: 'Niedziela',
      YEARLY: { single: 'Rok', regular: 'Rocznie' },
      MONTHLY: { single: 'miesiąc', regular: 'Miesięcznie' },
      WEEKLY: { single: 'tydzień', regular: 'Co tydzień' },
      DAILY: { single: 'Dzień', regular: 'Codziennie' },
      SINGLE: { single: 'Wyjątkowy', regular: 'Wyjątkowy' },
      CUSTOM: {
        single: 'Dostosowane...',
        regular: 'Dostosowane...',
      },
      freq: 'Częstotliwość',
      interval: 'Interwał',
      notRepeat: 'Einmalig',
      every: 'Każdy',
      month: 'miesiąc',
      week: 'tydzień',
      last: 'ostatni',
      on: 'na',
      everyMonthOn: 'Co miesiąc na',
      ofTheMonth: 'miesiąca',
      editLabel: 'Które daty chcesz zmienić?',
      thisDate: 'Tylko ta data',
      thisDateAndFollowing: 'Diesen und alle folgenden',
      allDates: 'Wszystkie daty',
      after: 'Po',
      time: 'Nominacje',
      times: 'Nominacje',
      neverEnds: 'Nigdy się nie kończy',
      until: 'Nigdy się nie kończy',
      repeatedDefault: ({ frequency, freq }) =>
        `Czy każdy${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} powtórzony`,
      repeatedMonthly: ({ day, interval }) =>
        `Będzie każdy${interval == 1 ? '' : ` ${interval}.`} Miesiąc później ${
          day.length > 2 ? day : `${day}.`
        } powtórzony.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }) =>
        `Będzie każdy${bysetpos == -1 ? ' ostatni' : ` ${bysetpos}.`} ${day} des${
          interval === 1 ? '' : ` ${interval}.`
        } Powtarzający się miesiąc.`,

      repeatedYearly: ({ interval }) =>
        `Powtarzający się miesiąc${interval == 1 ? '' : ` ${interval}.`} Rok powtórzony.`,
      repeatedWeekly: ({ interval, byweekday }) =>
        `Czy każdy${interval != 1 ? ` ${interval}.` : ''} tydzień${
          !!byweekday?.length
            ? ' na ' +
              byweekday?.slice(0, -1).join(', ') +
              (byweekday?.length > 1 ? ' oraz ' : '') +
              byweekday?.slice(-1)
            : ''
        } powtórzony.`,
      repeatedDaily: ({ interval }) => `Będzie każdy${interval != 1 ? ` ${interval}.` : ''} Będzie każdy.`,
    },
    requestsLabels: {
      receiveLabel: 'Odbiór',
      sentLabel: 'Wysłany',
    },
    post: {
      adTitle: 'Informacje/wyświetlacz',
      toKreise: 'na',
      comment: 'Komentarze',
      myPost: 'I',
      openPdf: 'Kliknij, aby otworzyć',
      deletePost: 'Usunąć ten post?',
      posted: 'Wysłany',
      commented: 'Komentarz',
      contacts: 'Kontakty',
      sendToContacts: 'Wiadomość dla wszystkich',
      enterText: 'Napisz wiadomość tutaj',
      sendToCircle: `Wiadomość do ${KreiseName('POL').singleArticle}`,
      sendComment: 'Wprowadź komentarz',
      longMessage: 'Maksymalnie 20 000 znaków',
      noComments: 'Brak komentarzy',
      deleteComment: 'Usuń komentarz?',
      deleteEventPost: 'Usunąć ten post? Nie spowoduje to usunięcia wydarzenia.',
      editPostLabel: 'Edytuj post',
      editingPostLabel: 'Tryb edycji',
      deletePostLabel: 'Usuń post',
      editedAt: 'Przetworzone',
      pinned: 'Przypięty.',
      actions: {
        label: 'Działania po',
        pin: {
          label: 'Pin post',
          once: 'Przesuń się raz w górę',
          d3: '3 dni',
          d5: '5 dni',
          w1: '1 tydzień',
          w2: '2 tygodnie',
          unpin: 'Nie przypinaj więcej',
        },
      },
    },
    circles: {
      circlesTitle: `Subskrybenci ${KreiseName('POL').multi}`,
      noCirclesTitle: `Nie subskrybuje ${KreiseName('POL').multi}`,
      myPost: 'I',
      circlesInfoTitle: 'Informacje i uczestnicy',
      circlesSubscribe: 'Subskrybuj',
      unsubscribeAlert: 'Bezpowrotnie opuścić zamknięty czat grupowy?',
      continueAlert: 'Porzucenie',
      readOnlyKreise: `Jest to "tylko do odczytu-${
        KreiseName('POL').single
      }". Tylko administratorzy mogą tworzyć tutaj posty. Można jednak pisać komentarze do postów.`,
    },
    messages: {
      addMessageLabel: 'Wprowadź wiadomość',
      changesSaved: 'Zmiany zapisane!',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: 'Podstawa',
      clubsTab: `${CorpsName('POL').single}`,
      miscellaneosTab: 'Zainteresowania',
      professionTab: 'Zawód',
      studyTab: StudyName('POL')?.short,
      notificationsTab: 'Powiadomienia',
      privacy: 'Prywatność',
    },
    profile: {
      addPhotoLabel: 'Dodaj zdjęcie',
      genderInputLabel: 'Płeć',
      titleLabel: 'Tytuł',
      firstNameLabel: 'Imię',
      lastNameLabel: 'Nazwisko',

      passwordLabel: 'Ustaw hasło',
      birthDateLabel: 'Data urodzenia',

      changePasswordLabel: 'Ort des Wohnsitzes',
      defaultCountryLabel: 'Niemcy',
      defaultGraduationLabel: 'Nie określono',
      defaultCorpsLabel: 'brak',
      street: 'Ulica + nr. ',

      partner: 'Nazwa partnera',
      mobileNumberLabel: 'Nr telefonu komórkowego.',
      handphoneNumberLabel: 'Numer telefonu komórkowego',
      telephoneNumberLabel: 'Numer telefonu',
    },
    profileKorp: {
      verband: CorpsName('POL').single,
      function: FunctionName('GER'),
      dateOfJoin: CorpsEditDate('GER'),
      beername: 'Biername',
    },
    profileClubs: {
      noUserLabel: 'Przed rozpoczęciem korzystania z aplikacji należy podać wszystkie podstawowe informacje.',
      functionLabel: `${FunctionName('POL')}`,
      setClubLabel: `Wprowadź nazwę ${CorpsName('POL').single} określać.`,
      matchClubLabel: 'The',
      matchClubContinueLabel: 'już istnieje w profilu, sprawdź raz.',
      clubLabel: `${CorpsName('POL').single.toUpperCase()}`,
      clubCodeLabel: `KOD LOGOWANIA UŻYTKOWNIKA ${CorpsName('POL').single.toUpperCase()}S`,
      entryTimeLabel: CorpsEditDate('POL'),
      deleteClubLabel: 'Czy chciałbyś przedłużyć swoje członkostwo w',
      deleteClubContinueLabel: 'naprawdę usunąć?',
      selectVariant: 'Co chciałbyś robić??',
      requestExistingCorp: 'Dodaj istniejący wpis',
      requestNewCorps: 'Żądanie nowego wpisu',
      createCorp: 'Utwórz nowy wpis',
    },
    profileProfession: {
      jobTitle: 'Nazwa stanowiska',
      noUserLabel: 'Przed rozpoczęciem korzystania z aplikacji należy podać wszystkie podstawowe informacje.',
      untilToday: 'Do dzisiaj',
      positionTitleLabel: 'Zawód',
      itemNameLabel: 'Zawód',
      employmentLabel: 'Rodzaj zatrudnienia',
      activityLabel: ActivityName('POL'),
      careerStageLabel: 'Poziom kariery',
      activityDescLabel: 'Opis działania',
      companyLabel: CompanyName('POL'),
      nameLabel: 'Firma',
      industryLabel: IndustryName('POL'),
      areaLabel: 'Zasięg',
      websiteLabel: 'Strona internetowa firmy',
      employmentPeriodLabel: 'Okres zatrudnienia',
      fromLabel: 'Od',
      toLabel: 'Do',
    },
    profileStudy: {
      noUserLabel: 'Przed rozpoczęciem korzystania z aplikacji należy podać wszystkie podstawowe informacje..',
      universityLabel: StudyName('POL').hochsule,
      courseLabel: StudyName('POL').studiengang,
      degreeLabel: StudyName('POL').abshchluss,
    },
    profileOther: {
      noUserLabel: 'Przed rozpoczęciem korzystania z aplikacji należy podać wszystkie podstawowe informacje.',
      hobbiesLabel: 'Zainteresowania',
      culturalLabel: 'Sponsorzy kultury',
      socialMediaPlattform: 'Sponsorzy kultury',
      socialMediaLabel: 'Linki do mediów społecznościowych',
      socialMediaLink: 'Link',
      socialMediaError: 'Wprowadź pełny adres URL',
    },
    profileNotifications: {
      noUserLabel: 'Przed rozpoczęciem korzystania z aplikacji należy podać wszystkie podstawowe informacje.',
      notificationsInfo:
        'Tutaj możesz aktywować powiadomienia e-mail dla poszczególnych działań (domyślnie) lub dezaktywować je na 3 miesiące..',
      notificationsTitle: 'Powiadomienia push:',
      notificationsDescr0: 'Powiadomienia push można ustawić w aplikacji ',
      notificationsDescr1: 'Powiadomienia push można ustawić w aplikacji',
      notificationsDescr2: ' marka.',
      mailingTitle: 'Powiadomienia e-mail:',
      mailingDescr:
        'W tym miejscu można aktywować powiadomienia e-mail dla poszczególnych działań (domyślnie) lub dezaktywować je na 1 rok.',
      allNotificationsLabel: 'Wszystkie powiadomienia',
      postsLabel: `Wkład w ${KreiseName('POL').multi}`,
      messagesLabel: 'Prywatne wiadomości',
      commentsLabel: 'Komentarze dotyczące wkładu własnego',
      contactRequestsLabel: 'Zapytania kontaktowe',
      eventsLabel: 'wydarzenia',
      clubMemberLabel: 'Nowo zarejestrowani członkowie',
      enableLabel: 'Do',
      disableToLabel: 'Wyłączone do',
      disableLabel: 'Od',
      ownPosts: 'Mój wkład (poczta otrzymana również przeze mnie)',
    },
    searchInput: {
      title: 'Wyszukiwanie',
      placeholder: 'Wyszukiwanie',
    },
    emptyList: {
      title: 'Nie ma tu (jeszcze) żadnej zawartości',
    },
    switchLanguage: {
      placeholder: 'Język/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'russian (русский)',
      SP: 'Spanish (Español)',
      title: 'Wybierz język',
    },
    filePicker: {
      takePicLabel: 'Załączniki',
      uploadPicLabel: 'Zrób zdjęcie',
      selectPicLabel: 'Wybierz zdjęcie/wideo',
      selectDocLabel: 'Wybierz dokument',
    },
    datePicker: {
      selectDateLabel: 'Wybierz datę',
      confirmDateLabel: 'Potwierdzenie',
      selectTimeLabel: 'Zeit auswählen',
    },
    changePassword: {
      currentPasswordLabel: 'Aktualne hasło',
      newPasswordLabel: 'Neues Passwort',
      repeatPasswordLabel: 'Passwort wiederholen',
      changePasswordLabel: 'Passwort ändern',
    },
    appInfo: {
      appDescLabel:
        'Korponnect to aplikacja dla studentów zrzeszonych w bractwach. Wymiana między korporacjami, organizacja wydarzeń oraz profesjonalny i prywatny networking - wszystko w jednej aplikacji.',
      contactsLabel: InfoContactsName('POL'),
      infoLabel: 'Informacje na temat',
      versionLabel: 'Wersja aplikacji',
      agbLabel: 'GTC',
      dataProtectionLabel: 'Polityka prywatności',
      manualLabel: 'Instrukcje',
      appTextLabel: 'Tutaj znajdziesz nasze',
      appAndLabel: 'oraz',
      appMainTextLabel: `Chcesz dodać kółko lub masz inną sugestię? Chcesz usunąć swój profil? Po prostu wyślij nam krótką wiadomość e-mail na adres ${support_email}.`,
      deleteFeedback: 'Powód usunięcia / opinia 🙂:',
      deleteUserTitle: 'Usunąć konto?',
      deleteUserDesc:
        'Możesz nieodwracalnie usunąć swoje konto tutaj.\b Przy okazji: Możesz także po prostu wyłączyć powiadomienia. Edytuj ustawienia w sekcji Profil -> Powiadomienia lub wyślij nam wiadomość e-mail.',
      deleteUserAlert: 'Czy naprawdę chcesz usunąć swoje konto?',
      webLabel: 'Wersja internetowa',
    },
    infoMessages: {
      registartionSuccessLabel: 'Dziękujemy za rejestrację!',
      manualRegSuccessLabel: 'Dziękujemy za rejestrację. Zostaniesz wkrótce aktywowany i poinformowany e-mailem.',
      linkExpiredLabel: 'Link wygasł. Poproś o zmianę hasła.',
      emailExistLabel: 'Przepraszamy, wiadomość e-mail nie jest dostępna.',
    },
    logoutAlert: {
      title: 'Wylogowanie',
      text: 'Wylogowanie',
      confirm: 'Tak',
      dismiss: 'Anuluj',
    },
    invalidInputs: {
      passwordChars: `Hasło musi mieć co najmniej ${passwordDigits(
        'POL',
      )} znaków, w tym co najmniej jedną literę, jedną cyfrę i jeden znak specjalny.`,
      fieldRequired: 'To pole jest wymagane.',
      emailsNotMatching: 'Upewnij się, że twoje e-maile są zgodne',
      emailInvalid: 'Twój adres e-mail jest nieprawidłowy!',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: 'Wybierz co najmniej 1 zgłoszenie',
    },
    mentoring: {
      profile: {
        rules1: 'Zgadzam się z',
        rules2: ' Zrozumienie wartości ',
        rules3: '“Mentoring dla stypendystów.',
        menteeDescription:
          'Wybierz swój region mentorski, aby otrzymywać informacje o wydarzeniach na miejscu. UWAGA: Możesz zmienić swój wybór w dowolnym momencie. NIE ogranicza to wyszukiwania mentora.',
        mentorDescription:
          'Wypełnij swój profil mentora, aby stypendyści mogli znaleźć Cię jako mentora. UWAGA: Wymagany jest co najmniej 1 region i co najmniej 1 obszar tematyczny..',
        mentoringRegions: 'Regiony mentorskie',
        reigonsShort: 'Regiony',
        topics: 'Obszary tematyczne',
        departments: 'Dział',
        available: 'Jestem dostępny',
        unavailable: 'Obecnie nie jestem dostępny',
        deleteAccount: 'Usuń profil mentora i opuść grupę',
        deleteAccountDescr: 'Usuń profil mentora i opuść grupę',
        uploadPdf: 'Profesjonalny mentor (PDF)',
        myPdf: 'Mój profil (PDF)',
        pdf: 'Profil mentora',
      },
      tabNames: {
        info: 'Info',
        news: 'Aktualności',
        profile: 'Profil',
        search: 'Wyszukiwanie i zapytania',
        request: 'Mentorzy',
        requestDetails: 'Zapytanie dotyczące mentoringu',
        tabDeactivated: 'Zakładka wyłączona. Wypełnij swój profil mentora.',
      },
      search: {
        description:
          'Aby rozpocząć wyszukiwanie w sposób ukierunkowany, zalecamy rozpoczęcie od kilku pól wyboru, a następnie rozszerzenie siatki wyszukiwania w nowym przebiegu wyszukiwania. Jeśli nie możesz znaleźć odpowiedniego mentora w swoim regionie, rozszerz wyszukiwanie na sąsiedni region mentorski. Kryteria wyszukiwania: 1 region, od 1 do 3 obszarów tematycznych. Wszystkie pola są połączone spójnikiem AND!',
        searchBtn: 'Wyszukiwanie',
      },
      requests: {
        mentorIncoming: 'Moje zapytania dotyczące mentoringu',
        mentorCurrent: 'Moi mentorzy',
        menteeOutgoing: 'Moje zapytanie dotyczące mentoringu',
        menteeCurrent: 'Mój mentoring',
        cancelMentoring: 'Mój mentoring',
        sendRequest: 'Prośba o mentora',
        alreadyHaveMentor: 'Możesz mieć tylko jednego mentora',
        requestSuccess: 'Zapytanie wysłane pomyślnie',
        requestRetracted: 'Zapytanie zostało pomyślnie wycofane',
        cancelSendingRequest: 'Wycofanie wniosku o mentoring',
        approveRequest: 'Zaakceptuj zapytanie',
        rejectRequest: 'Odrzuć żądanie',
        invitaionText: 'Zapytanie',
        rejectionText: 'Podaj powody odrzucenia',
        sureRetractRequest: 'Czy naprawdę chcesz wycofać zapytanie?',
      },
    },
    downloads: {
      root: 'Katalog główny',
      delete: 'Usuń',
      rename: 'Zmiana nazwy',
      areYouSureDeleteFolder: 'Naprawdę usunąć folder? Cała zawartość zostanie usunięta!',
      renameFolder: 'Zmiana nazwy folderu',
      createNewFolder: 'Utwórz nowy folder',
      newFolderPlaceholder: 'Wprowadź nazwę folderu',
      create: 'Utwórz',
      areYouSureDeleteFile: '',
      downloadFile: 'Pobierz',
      deleteFile: 'Usuń z urządzenia',
      openInFinder: 'Otwieranie w plikach',
      cancel: 'Anuluj',
    },
    globalSearch: {
      postedBy: 'opublikowany przez',
      found: 'Znaleziono',
      loadMore: 'Załaduj więcej',
      enterQuery:
        'Wprowadź dowolny termin wyszukiwania. Możesz wyszukiwać cokolwiek (nazwę, dokument, wydarzenie, post, ...).',
      folder: 'teczka',
      post: 'Wkład',
      error: 'Wprowadź co najmniej 3 znaki',
    },
    kreiseSettings: {
      description: 'Opis',
      push: 'Powiadomienia push',
      email: 'Powiadomienia pocztowe',
      subscribers: 'Mitglieder',
      active: 'Aktiviert',
      oneYear: 'Stumm für 1 Jahr',
      forever: 'Stumm für immer',
      until: 'Bis',
      cantDeactive: 'Für diesen Gruppenchat ist diese Einstellung deaktiviert',
      SINGLE: 'Für jeden Beitrag',
      SUMMARY_ONCE: 'Zusammenfassung 1x pro Woche',
      SUMMARY_TWICE: 'Zusammenfassung 2x pro Woche',
      SUMMARY_DAY: 'Zusammenfassung 1x pro Tag',
      OFF: 'Aus',
      PUSH: 'Powiadomienia push',
      EMAIL: 'Powiadomienia mailowe',
      PUSH_AND_EMAIL: 'Powiadomienia push i mailowe',
      newUser: 'Powiadomienia o nowych użytkownikach',
      statistic: 'Wysyłka statystyk',
      off: 'Wyłączone',
      ownPosts: 'Wysyłaj statystyki mailowe dotyczące twoich postów',
      deleteMemberDescr: 'usunąć?',
      deleteMemberBtn: 'Usuń',
      retractAdminRights: 'Cofnij prawa administratora',
      deleteAdminAndUser: 'Usunąć całkowicie',
      toDeleteKreiseBtn: 'Usuń czat grupowy',
      deleteKreiseDescr: `${KreiseName('POL').single} Czy na pewno chcesz trwale usunąć`,
      deleteKreiseModalTitle: 'Usunąć członka?',
      addKreiseName: 'Nazwa',
      addKreiseDescription: 'Opis',
      addMembers: 'Dodaj członków',
      createKreise: 'Utwórz czat grupowy',
      toKreiseEditMessage: 'Uwaga: Wszelkie zmiany w członkach zostaną natychmiast wprowadzone.',
      kreiseCreator: 'Twórca tego czatu grupowego',
      kreiseDeleteMembersLeft: 'Czat grupowy można usunąć dopiero, gdy zostanie w nim tylko jeden członek.',
      kreiseDeleteMembersLeftTitle: 'Najpierw usuń członków',
      suggestionInNewsfeed: {
        admin: 'Administrator',
        member: 'Członkowie',
        corp: `${CorpsName('POL').single} członkowie`,
        corpAdmin: `${CorpsName('POL').single} administrator`,
      },
    },
    surveys: {
      checkVotes: 'Wyświetl wyniki',
      chooseOptions: {
        single: 'Wybierz jedną opcję.',
        multiple: 'Wybierz co najmniej jedną opcję.',
      },
      votesModalTitle: 'Wyniki',
      createModalTitle: 'Ankieta',
      noVotes: 'Brak głosów',
      question: 'Pytanie',
      askAQuestion: 'Zadaj pytanie',
      allowMultiple: 'Zezwól na kilka odpowiedzi',
      votes: {
        single: 'Głos',
        multiple: 'Głosy',
      },
      option: 'Opcja',
      optionsLabel: 'Opcje',
      anonymus: 'Anonymus poll',
      create: 'Utwórz',
    },
  },
  {
    key: 'RUS', //russian language / russisch
    key2: 'ru',
    responseHandler: {
      messageLabel: 'Неизвестная ошибка. Пожалуйста, обратитесь к info@connact.app',
      expiredLinkLabel: 'Ссылка устарела. Запросите новое изменение пароля.',
      emailExistLabel: 'Извините, этот адрес электронной почты уже зарегистрирован.',
      profileUpdated: 'Профиль обновлен',
      profileUpdatedCorpsError: `Профиль обновлен! Следующие ${
        CorpsName('RUS').single
      } не удалось добавить из-за неправильного кода входа в систему:`,
      appNeedsUpdate: 'Пожалуйста, обновите приложение в App Store / Google Play.',
      maximalVideoSize: 'Максимальный размер файла - 2 ГБ!',
    },
    report: {
      notBelongLabel: 'Пользователь больше не является участником.',
      incProfileLabel: 'Пользователь предоставляет неправильные данные профиля',
      badPicLabel: 'Изображение профиля пользователя нарушает правила',
      badComLabel: 'Комментарии пользователя нарушают правила',
      spamLabel: 'Пользователь отправляет спам в чате',
      reasonsLabel: 'Причины для жалобы',
    },
    drawer: {
      news: `Новости`,
      kreise: KreiseName('RUS').multi,
      search: 'Поиск',
      calendar: CalendarName('RUS'),
      documents: `${DocumentsName('RUS')}`,
      contacts: 'Контакты',
      requests: 'Запросы на контакты',
      messages: MessagesName('RUS'),
      profile: ProfileName('RUS'),
      logout: 'Выйти',
      map: 'Карта',
      mentoring: 'Наставничество',
    },
    imageCropper: {
      cropLabel: 'Обрезать',
      cancelLabel: 'Отмена',
      chooseLabel: 'Выбрать',
    },
    signIn: {
      register: 'Зарегистрироваться',
      title: 'Пожалуйста, войдите',
      loginPlaceholder: 'Адрес электронной почты',
      passwordPlaceholder: 'Пароль',
      forgotPassword: 'Забыли пароль?',
      loginBtn: 'Войти',
      noAccount: 'Нет аккаунта?',
      description0: 'По всем вопросам обращайтесь',
      description1: 'или',
      description2: '.',
      loginValidation: 'Указание адреса электронной почты неверно',
      passwordValidation: 'Пароль должен содержать не менее 6 символов',
    },
    search: {
      name: 'Имя',
      corps: CorpsName('RUS').single,
      country: CountryName('RUS'),
      city: 'Город',
      industry: IndustryName('RUS'),
      company: 'Компания',
      profession: 'Профессия',
      hobby: 'Хобби',
      cultural: 'Культурные партнеры',
      study: `${StudyName('RUS').short}`,
      office: 'Офис',
      degreeProgram: StudyName('RUS').studiengang,
      university: StudyName('RUS').university,
      position: `${FunctionName('RUS')}`,
      status: 'Статус',
      title: 'Результаты поиска для',
      filterTitle: 'Фильтр по',
      postCode: 'Почтовый индекс',
      global: 'Все',
      activity: ActivityName('RUS'),
    },
    resetPassword: {
      enterEmail: 'Пожалуйста, введите адрес электронной почты',
      inputPlaceholder: 'Адрес электронной почты',
      sendBtn: 'Отправить',
      cancelBtn: 'Отмена',
      message: 'Если адрес электронной почты известен, туда была отправлена ссылка.',
      desc: 'Вы должны ввести точный адрес электронной почты, который указан в приложении. Проверьте, возможно, вы можете найти его в своей почте.',
    },
    password: {
      resetLabel: 'Введите новый пароль',
      inputLabel: 'Новый пароль',
    },
    signUp: {
      createAccount: 'Создать аккаунт',
      inputPlaceholder: `Код входа в систему вашего ${CorpsName('RUS').single}s`,
      sendBtn: 'Далее',
      descFirst: 'Регистрируясь, вы соглашаетесь с нашими ',
      descSecond: 'и подтверждаете, что прочитали наши ',
      descThird: 'Если у вас есть вопросы, вы можете связаться с нами по адресу info@connact.app или +49 170 49 805.',
      terms: 'условиями использования',
      policy: 'политикой конфиденциальности.',
      description: 'Примечание',
      generatePassword: 'Сгенерировать пароль',
      noCodeSuccessRegister:
        'Спасибо за регистрацию! Вы будете уведомлены по электронной почте, когда ваш аккаунт будет активирован.',
    },
    signUpDefault: {
      withoutCode: 'Без кода',
      createAccount: 'Просто зарегистрируйтесь здесь',
      withCode: 'С пригласительным кодом',
      simpleRegister: 'Без пригласительного кода',
      existingAccount: 'Уже есть аккаунт?',
      descr01: 'Я соглашаюсь с',
      descr0: 'Регистрируясь, вы соглашаетесь с',
      descr1: 'и с',
      descr2: '.',
      guest: `Зарегистрируйтесь как ${guest_naming_singular}`,
      withCodeWithDescr: `Зарегистрируйтесь как ${member_naming_singular}`,
    },
    signUpManual: {
      title: 'Регистрация',
      photoDesc: 'Добавить фото',
      genderInput: 'Пол',
      firstName: 'Имя',
      lastName: 'Фамилия',
      club: CorpsName('RUS').single,
      specify: 'указать, пожалуйста',
      emailPlaceholder: 'Адрес электронной почты',
      emailHint: 'Пожалуйста, используйте постоянный адрес электронной почты.',
      repeatEmailPlaceholder: 'Повторите адрес электронной почты',
      passwordPlaceholder: 'Установите пароль',
      repeatPasswordPlaceholder: 'Повторите пароль',
      postcode: 'Почтовый индекс',
      place: 'Место проживания',
      country: 'Страна',
      loginCode: 'Пригласительный код',
      mobilePlaceholder: 'Мобильный номер (не виден в профиле)',
      corps: SignUpCorpsName('RUS'),
      searchCountry: 'Suche nach Land',
    },
    backToLogin: {
      desc: 'Уже есть аккаунт? ',
      login: 'Войти',
    },
    backToSignUp: {
      desc: 'Нет аккаунта? ',
      register: 'Зарегистрироваться',
    },
    privacy: {
      desc: 'Да, я соглашаюсь с',
      btn: 'Условиями использования',
      zu: '',
      map: {
        title: 'Уведомление о защите данных',
        description:
          'Это приложение было создано в соответствии с GDPR. Мы используем Google Maps только для отображения карты. Никакие персональные данные не передаются.\n\nКроме того, булавки отображаются только приблизительно, на основе почтового индекса/города, но без улицы/носа. Это означает, что невозможно сделать выводы о конкретных адресах пользователей.',
      },
      restrictions: {
        overall: 'Эта функция доступна только для участников.',
        overallWilkar: 'Эта функция недоступна по причинам конфиденциальности данных.',
        profile:
          'Только участники могут просматривать профили пользователей. Однако вы можете отправить пользователю личное сообщение.',
        groupchat: `Чаты ${KreiseName('RUS').single} доступны только для участников.`,
        groupchatWilkar: `По соображениям конфиденциальности чаты ${
          KreiseName('RUS').single
        } доступны только для участников`,
        comments: 'Комментарии доступны только для участников.',
        commentsWilkar: 'По соображениям конфиденциальности комментарии доступны только для участников.',
        likes: 'Список лайков доступен только для участников.',
        post: 'Только участники могут создавать записи.',
        participantsList: 'Список участников могут просматривать только члены клуба.',
        commentsOnlyForMembers: 'Комментарии видны только пользователям.',
        postsLimitation: 'Старые сообщения могут просматривать только пользователи.',
      },
      settings: {
        guestPost: {
          title: `Публикации видны только проверенным ${guest_naming_plural} `,
          ask: 'Всегда спрашивать',
          no: 'Нет',
          yes: 'Да',
        },
      },
      guestModal: {
        title: `Могут ли проверенные ${guest_naming_plural} идеть эту запись? Вы можете изменить эту настройку видимости для каждой отдельной записи позже.`,
        dontAskAgain:
          'Больше не спрашивать (вы можете изменить эту настройку в разделе "Профиль" → "Конфиденциальность").',
      },
      post: {
        ifShown: `Скрыть от ${guest_naming_plural} `,
        ifHidden: `Показать ${guest_naming_plural} `,
        ifShownModal: `Эта запись больше не будет видна ${guest_naming_plural}`,
        ifHiddenModal: `Эта запись теперь будет видна ${guest_naming_plural}`,
      },
    },
    searchModal: {
      landTitle: 'Выберите страну',
      corpsTitle: `${CorpsName('RUS').single} выберите`,
      pleaseSelect: 'Пожалуйста, выберите',
    },
    btn: {
      sendBtn: 'Отправить',
      cancelBtn: 'Отмена',
      select: 'Выбрать',
      cancelEventBtn: acceptEventName('RUS').cancel,
      acceptBtn: acceptEventName('RUS').accept,
      saveBtn: 'Сохранить',
      clearBtn: 'Очистить',
      acceptReqBtn: 'Принять',
      declineReqBtn: 'Отклонить',
      updateBtn: 'Обновить',
      declineBtn: 'Отклонить',
      yesUnderstood: 'Ja, verstanden',

      ok: 'OK',
    },
    validation: {
      email: 'Укажите корректный адрес электронной почты',
      password: 'Пароль должен содержать не менее 6 символов',
      repeatEmail: 'Адреса электронной почты не совпадают.',
      repeatPassword: 'Пароли не совпадают',
      required: 'Это поле обязательно для заполнения',
      matchPasswords: 'Старый и новый пароли совпадают',
      dontMatchPasswords: 'Пароли не совпадают',
    },
    init: {
      title: 'Загрузка',
      overlayLabel: 'Пожалуйста, проверьте подключение к Интернету',
    },
    screenTitles: {
      postsTitle: `Новости`,
      circlesTitle: KreiseName('RUS').multi,
      searchTitle: 'Поиск',
      profileTitle: ProfileName('RUS'),
      eventTitle: CalendarName('RUS'),
      eventDetailsTitle: 'События',
      newEventTitle: 'Добавить событие',
      contactsTitle: 'Контакты',
      contactReqTitle: 'Запросы на контакты',
      reqDetailsTitle: 'Запросы на контакты',
      outReqTitle: 'Запросы на контакты',
      messagesTitle: MessagesName('RUS'),
      changePasswordTitle: 'Изменить пароль',
      appInfoTitle: 'О приложении',
      reportTitle: 'Пожаловаться на пользователя',
      createKreiseTitle: 'Создать групповой чат',
      eventParticipants: 'Teilnehmer',
    },
    profileDetails: {
      user: 'Пользователь',
      mainTitleLabel: ProfileName('RUS'),
      basisLabel: 'Основное',
      clubLabel: CorpsName('RUS').single,
      clubLabelPlural: CorpsName('RUS').multi,
      professionLabel: 'Профессия',
      studyLabel: StudyName('RUS').short,
      uploadPhotoLabel: 'Загрузить фото',
      editPhotoLabel: 'Редактировать фото',
      profileCompletedLabel: 'Профиль',
      profileCompletedGLabel: 'завершен',
      birthday: 'Дата рождения',
      functionLabel: `${FunctionName('RUS')}`,
      dateOfJoinLabel: CorpsEditDate('RUS'),
      positionDescLabel: 'Профессия',
      companyLabel: CompanyName('RUS'),
      nameLabel: 'Имя',
      websiteLabel: 'Веб-сайт',
      fromLabel: 'С',
      toLabel: 'До',
      delete: 'удалить',
      untilToday: 'До сегодняшнего дня',
      degreeLabel: StudyName('RUS').abshchluss,
      miscellaneos: 'Разное',
      hobbies: 'Хобби',
      cultural: 'Культурные партнеры',

      positionLabel: 'Профессия',
      firmaLabel: 'Фирма',
      industryLabel: IndustryName('RUS'),
      areaLabel: 'Область',
      typeOfEmplLabel: 'Тип занятости',
      activityLabel: ActivityName('RUS'),
      careerStageLabel: 'Этап карьеры',
      activityDescLabel: 'Описание деятельности',
      employmentPeriodLabel: 'Период занятости',
      universityLabel: StudyName('RUS').hochsule,
      courseLabel: StudyName('RUS').studiengang,
      ageLabel: 'Возраст',
      birthdayLabel: 'Дата рождения',
      editProfileLabel: 'Редактировать профиль',
      addContactLabel: 'Добавить в контакты',
      sendMessageLabel: 'Отправить сообщение',
      requestSentLabel: 'Запрос на контакт уже отправлен',
      sendRequestSuccessLabel: 'Запрос успешно отправлен',
      reportSentLabel: 'Пользователь уже заблокирован/пожалован',
      addReportLabel: 'Пожаловаться/заблокировать пользователя',
      contactsLabel: 'Количество контактов',
      showBirthday: 'Мои контакты видят мой день рождения',
      street: 'Улица + номер',

      partner: 'Имя партнера',
      mobileNumberLabel: 'Мобильный номер',
      handphoneNumberLabel: 'Номер мобильного телефона',
      telephoneNumberLabel: 'Номер телефона',

      leibbursch: 'Ментор',
      leibfuechse: 'Подопечный',
      bio: 'Обо мне',
      addDocuments: 'Добавить документы',
      image: 'Изображение',
      video: 'Видео',
      pdf: 'PDF',
      link: 'Ссылка',
      firstName: 'Имя',
      lastName: 'Фамилия',
      title: 'Титул',
      email: 'E-Mail',
      name: 'Имя',
      lastname: 'Фамилия',
      country: CountryName('GER'),
      wohnort: 'Город',
      postcode: 'ZIP code',
      inReview: 'Confirmation via mail pending',
    },
    calendar: {
      birthdayLabel: 'День рождения',
      noEventsLabel: 'На выбранную дату событий нет',
      allTabLabel: 'Все',
      myTabLabel: 'Мои',
      clubsTabLabel: CorpsName('RUS').single,
      subsTabLabel: 'Подписки',
      subscribedTabLabel: 'Подписано',
      otherTabLabel: 'Другие',

      error: 'Дата начала должна быть перед датой окончания.',
      filter: {
        all: 'Все',
        invited: 'Приглашенные',
        confirmed: 'Подтвержденные',
      },
    },
    eventLabels: {
      inviteTo: 'Приглашение на',
      addEndDate: 'Введите дату и время окончания',
      eventLabel: 'Организатор',
      startLabel: 'Начало',
      locationLabel: 'Место проведения',
      participantLabel: 'Участник',
      moreInfo: 'Дополнительная информация',
      commentsLabel: 'Комментарии',
      noCommentsLabel: 'Пока нет комментариев',
      eventStartLabel: 'Начало события',
      eventEndLabel: 'Окончание события',
      descLabel: 'Описание',
      creatorLabel: 'Создатель',
      titleLabel: 'Название',
      deleteEventTitle: 'Удалить событие?',
      deleteEventLabel: 'Удалить это событие?',
      clubsTitle: `${CorpsName('RUS').single}s`,
      gruppenchat: 'Выберите групповой чат',
      contactsTitle: 'Контакты',
      delCommentLabel: 'Удалить комментарий?',
      sendInvite: 'Отправить приглашение',
      selectCorps: 'Выберите группу',
      externalGuest: 'Приглашенные внешние гости',
      externalGuestPlaceholder: 'Введите адрес электронной почты',
      attachmentLabel: 'Вложение',
      shareLinkLabel: 'Поделиться ссылкой',
      linkLabel: `${guest_naming_singular} зайдите на`,
      copyLinkLabel: 'Копировать ссылку',
      iCalDownload: 'Скачать файл календаря',
      timezone: 'Часовой пояс',
      visibility: 'Видимость',
      errorDate: 'Дата в прошлом',
      startAfterEndError: 'Дата окончания должна быть позже даты начала',
      members: 'Members',
      waitlist: 'Warteliste',
      onWaitlist: 'auf der Warteliste',
      leaveWaitlist: 'Platz freigeben?',
      leaveWaitlistDesc: `Von Warteliste entfernen?\n\n Wenn du dich später wieder auf die Warteliste setzt, landest du ggf. auf einer hinteren Position.`,
      limitAchieved: (limit: number) =>
        `Die maximale Teilnehmeranzahl von ${limit} ist erreicht. Du wurdest auf die Warteliste gesetzt.`,
      visibilityOptions: {
        allWithoutGuests: 'Mitglieder und auf Webseite',
        invited_member: 'Только приглашенные участники',
        verified_member: 'Все участники',
        authorized_member: 'Все участники и гости',
        all: 'Участники, гости и на веб-сайте',
      },
      modalEvent: {
        title: 'Событие создано',
        subTitle: 'Событие создано.\n\n',
        content:
          'В зависимости от настроек пользователя теперь будут отправлены уведомления о событии через Push или по электронной почте. \n\nЕсли вы настроили это в разделе “Профиль” → “Уведомления”, вы также получите уведомление о событии по электронной почте.',
      },
      modalComment: {
        title: 'Кому отправить уведомление?',
        message: 'Кто должен быть уведомлен о вашем комментарии?',
        optionAll: 'Все участники',
        optionNone: 'Только организатор(ы)',
        button: 'Отправить комментарий',
      },
      cancelModal: {
        title: 'Отменить или удалить?',
        message: 'Отменить или удалить событие?',
        optionAll: 'Отменить (участники будут уведомлены)',
        optionNone: 'Удалить (участники не будут уведомлены)',
      },
      updateEventModal: {
        title: 'Дополнительные приглашения отправлены',
        label: 'Дополнительные группы были приглашены.',
        message:
          'В соответствии с настройками теперь будут отправлены уведомления о событии через Push или по электронной почте дополнительным группам.',
      },
      eventRegistrationType: {
        app: 'App',
        event: 'Link/Mailadresse',
        enterLink: 'Добавить ссылку/адрес электронной почты или что-то подобное',
      },
      parts: {
        part: 'Part',
        addPart: 'Add program point',
        hint: 'Explanation',
        question: 'Question',
        single: 'Only one option selectable',
        multi: 'Several options available',
        freeText: 'Free text answer',
        quiz: 'Quiz',
        questionnaire: 'Questionnaire',
        addQuestionnaire: 'Add Questionnaire',
        questionnaireType: 'Answer options',
        option: 'Option',
        addOption: 'Add option',
        showMembers: 'Participants',
        youJoined: 'You take part in:',
        totalToPay: 'Total amount to be paid:',
        yourAnswer: 'Your answer',
        visibleForCreators: 'Visible only for creators',
        allAnswers: 'All answers:',
        addUsers: 'Add users',
        isAnonym: 'Anonymous voting',
        isAnonymQuiz: 'Anonymes Quiz',
      },
    },
    rrEventLabels: {
      title: 'Повторение',
      MO: 'Понедельник',
      TU: 'Вторник',
      WE: 'Среда',
      TH: 'Четверг',
      FR: 'Пятница',
      SA: 'Суббота',
      SU: 'Воскресенье',
      YEARLY: { single: 'Год', regular: 'Ежегодно' },
      MONTHLY: { single: 'Месяц', regular: 'Ежемесячно' },
      WEEKLY: { single: 'Неделя', regular: 'Еженедельно' },
      DAILY: { single: 'День', regular: 'Ежедневно' },
      SINGLE: { single: 'Однократно', regular: 'Однократно' },
      CUSTOM: {
        single: 'Пользовательский...',
        regular: 'Пользовательский...',
      },
      freq: 'Частота',
      interval: 'Интервал',
      notRepeat: 'Однократно',
      every: 'Каждый',
      month: 'месяц',
      week: 'неделю',
      last: 'последний',
      on: 'в',
      everyMonthOn: 'Каждый месяц в',
      ofTheMonth: 'месяца',
      editLabel: 'Какие даты вы хотите изменить?',
      thisDate: 'Только эту дату',
      thisDateAndFollowing: 'Эту и все последующие',
      allDates: 'Все даты',
      after: 'После',
      time: 'раз',
      times: 'раз',
      neverEnds: 'Никогда не заканчивается',
      until: 'до',
      repeatedDefault: ({ frequency, freq }) =>
        `Каждый ли${freq == 2 ? '' : freq == 0 ? 's' : 'n'} ${frequency} повторный`,
      repeatedMonthly: ({ day, interval }) =>
        `Каждый ли${interval == 1 ? '' : ` ${interval}.`} Месяц на ${day.length > 2 ? day : `${day}.`} неоднократно.`,
      repeatedMonthlyBySetPos: ({ day, interval, bysetpos }) =>
        `Каждый ли${bysetpos == -1 ? ' последний' : ` ${bysetpos}.`} ${day} ${
          interval === 1 ? '' : ` ${interval}.`
        } Месяц повторяется.`,

      repeatedYearly: ({ interval }) => `Каждый ли${interval == 1 ? '' : ` ${interval}.`} Повторный год.`,
      repeatedWeekly: ({ interval, byweekday }) =>
        `Каждый ли${interval != 1 ? ` ${interval}.` : ''} неделя${
          !!byweekday?.length
            ? ' на ' + byweekday?.slice(0, -1).join(', ') + (byweekday?.length > 1 ? ' и ' : '') + byweekday?.slice(-1)
            : ''
        } повторный.`,
      repeatedDaily: ({ interval }) => `Wird jeden${interval != 1 ? ` ${interval}.` : ''} День повторяется.`,
    },
    requestsLabels: {
      receiveLabel: 'Received',
      sentLabel: 'Отправлено',
    },
    post: {
      adTitle: 'Информация/отображение',
      toCircles: 'to',
      comment: 'Комментарии',
      myPost: 'Я',
      openPdf: 'Нажмите, чтобы открыть',
      deletePost: 'Удалить это сообщение?',
      posted: 'Опубликовано',
      commented: 'Прокомментировано',
      contacts: 'Контакты',
      sendToContacts: 'Сообщение всем',
      enterText: 'Напишите сообщение здесь',
      sendToCircle: `Nachricht an ${KreiseName('RUS').singleArticle}`,
      sendComment: 'Введите комментарий',
      longMessage: 'Максимум 20 000 символов',
      noComments: 'Комментариев пока нет',
      deleteComment: 'Удалить комментарий?',
      deleteEventPost: 'Удалить это сообщение? Это не приведет к удалению события',
      editPostLabel: 'Редактировать пост',
      editingPostLabel: 'Режим редактирования',
      deletePostLabel: 'Удалить пост',
      editedAt: 'Отредактировано',
      pinned: 'Прикрепленный',
      actions: {
        label: 'Действия',
        pin: {
          label: 'Закрепить пост',
          once: 'Переместить в топ один раз',
          d3: '3 дня',
          d5: '5 дней',
          w1: '1 неделя',
          w2: '2 недели',
          unpin: 'Больше не закреплять',
        },
      },
    },
    circles: {
      circlesTitle: `Подписанные ${KreiseName('RUS').multi}`,
      noCirclesTitle: `Неподписанные ${KreiseName('RUS').multi}`,
      myPost: 'Я',
      circlesInfoTitle: 'Информация и участники',
      circlesSubscribe: 'Подписаться',
      unsubscribeAlert: 'Безвозвратно покинуть закрытый групповой чат?',
      continueAlert: 'Оставить',
      readOnlyKreise: `Это закрытый для чтения чат  "Nur-lese-${
        KreiseName('RUS').single
      }". Создавать сообщения здесь могут только администраторы. Однако вы можете писать комментарии к постам.`,
    },
    messages: {
      addMessageLabel: 'Введите сообщение',
      changesSaved: 'Изменения сохранены!',
      newConversation: 'New conversation',
    },
    profileTabs: {
      basicTab: 'Основное',
      clubsTab: `${CorpsName('RUS').single}`,
      miscellaneosTab: 'Интересы',
      professionTab: 'Профессия',
      studyTab: StudyName('RUS')?.short,
      notificationsTab: 'Уведомления',
      privacy: 'Конфиденциальность',
    },
    profile: {
      addPhotoLabel: 'Добавить фото',
      genderInputLabel: 'Пол',
      titleLabel: 'Заголовок',
      firstNameLabel: 'Имя',
      lastNameLabel: 'Фамилия',

      passwordLabel: 'Установить пароль',
      birthDateLabel: 'Дата рождения',

      changePasswordLabel: 'Изменить пароль',
      defaultCountryLabel: 'Германия',
      defaultGraduationLabel: 'Без указания',
      defaultCorpsLabel: 'нет',
      street: 'Улица + номер дома',
      partner: 'Имя партнера/партнерши',
      mobileNumberLabel: 'Мобильный номер',
      handphoneNumberLabel: 'Номер мобильного телефона',
      telephoneNumberLabel: 'Номер телефона',
    },
    profileKorp: {
      verband: CorpsName('RUS').single,
      function: FunctionName('RUS'),
      dateOfJoin: CorpsEditDate('RUS'),
      beername: 'Biername',
    },
    profileClubs: {
      noUserLabel: 'Вся основная информация должна быть заполнена до начала использования приложения',
      functionLabel: `${FunctionName('RUS')}`,
      setClubLabel: `Пожалуйста, введите название ${CorpsName('RUS').single}`,
      matchClubLabel: 'The',
      matchClubContinueLabel: 'уже существует в профиле, пожалуйста, проверьте один раз',
      clubLabel: `${CorpsName('RUS').single.toUpperCase()}`,
      clubCodeLabel: `LOGIN CODE ВАШЕГО ${CorpsName('RUS').single.toUpperCase()}S`,
      entryTimeLabel: CorpsEditDate('RUS'),
      deleteClubLabel: 'Хотите ли вы удалить свое членство в клубе',
      deleteClubContinueLabel: 'Вы действительно хотите удалить свое членство?',
      selectVariant: 'Что вы хотите сделать?',
      requestExistingCorp: 'Добавить существующую запись',
      requestNewCorps: 'Запросить новую запись',
      createCorp: 'Создать новую запись',
    },
    profileProfession: {
      jobTitle: 'Название должности',
      noUserLabel: 'Вся основная информация должна быть заполнена до начала использования приложения',
      untilToday: 'До сегодняшнего дня',
      positionTitleLabel: 'Профессия',
      itemNameLabel: 'Профессия',
      employmentLabel: 'Тип занятости',
      activityLabel: ActivityName('RUS'),
      careerStageLabel: 'Стадия карьеры',
      activityDescLabel: 'Описание деятельности',
      companyLabel: CompanyName('RUS'),
      nameLabel: 'Имя',
      industryLabel: IndustryName('RUS'),
      areaLabel: 'Область',
      websiteLabel: 'Веб-сайт компании',
      employmentPeriodLabel: 'Период работы',
      fromLabel: 'От',
      toLabel: 'To',
    },
    profileStudy: {
      noUserLabel: 'Перед использованием приложения необходимо заполнить всю основную информацию.',
      universityLabel: StudyName('RUS').hochsule,
      courseLabel: StudyName('RUS').studiengang,
      degreeLabel: StudyName('RUS').abshchluss,
    },
    profileOther: {
      noUserLabel: 'Вся основная информация должна быть заполнена до начала использования приложения',
      hobbiesLabel: 'Интересы',
      culturalLabel: 'культурные спонсоры',
      socialMediaPlatform: 'Платформа социальных сетей',
      socialMediaLabel: 'ссылки на социальные сети',
      socialMediaLink: 'Ссылка',
      socialMediaError: 'Пожалуйста, введите полный URL',
    },
    profileNotifications: {
      noUserLabel: 'Вся основная информация должна быть заполнена до начала использования приложения',
      notificationsInfo:
        'Здесь вы можете активировать уведомления по электронной почте для отдельных действий (по умолчанию) или отключить их на 3 месяца',
      notificationsTitle: 'Push-уведомления:',
      notificationsDescr0: 'Вы можете активировать push-уведомления в ',
      notificationsDescr1: 'Системные настройки смартфона',
      notificationsDescr2: '.',
      mailingTitle: 'Уведомления по электронной почте:',
      mailingDescr:
        'Здесь вы можете активировать уведомления по электронной почте для отдельных действий (по умолчанию) или отключить их на 1 год',
      allNotificationsLabel: 'Все уведомления',
      postsLabel: `Сообщения в ${KreiseName('RUS').multi}`,
      messagesLabel: 'Личные сообщения',
      commentsLabel: 'Комментарии к собственным сообщениям',
      contactRequestsLabel: 'Запросы на контакты',
      eventsLabel: 'События',
      clubMemberLabel: 'Вновь зарегистрированные участники',
      enableLabel: 'Включено',
      disableToLabel: 'Выключено, пока',
      disableLabel: 'Выключено',
      ownPosts: 'Мои сообщения (почта также получена мной)',
    },
    searchInput: {
      title: 'Поиск',
      placeholder: 'Поиск',
    },
    emptyList: {
      title: 'Здесь нет содержимого (пока)',
    },
    switchLanguage: {
      placeholder: 'Язык/Language',
      ENG: 'English (English)',
      GER: 'German (Deutsch)',
      ITA: 'Italian (Italienisch)',
      FRA: 'French (Français)',
      HNG: 'Hungarian (Magyar)',
      JAP: 'Japanese (ジャパンスカ)',
      CIN: 'Chinese (中文)',
      KOR: 'Korean (한국어)',
      RUS: 'russian (русский)',
      SP: 'Spanish (Español)',
      title: 'Выберите язык',
    },
    filePicker: {
      takePicLabel: 'Вложения',
      uploadPicLabel: 'Сделать фото',
      selectPicLabel: 'Выбрать фото/видео',
      selectDocLabel: 'Выбрать документ',
    },
    datePicker: {
      selectDateLabel: 'Выберите дату',
      confirmDateLabel: 'Подтвердить',
      selectTimeLabel: 'Выберите время',
    },
    changePassword: {
      currentPasswordLabel: 'Текущий пароль',
      newPasswordLabel: 'Новый пароль',
      repeatPasswordLabel: 'Повторить пароль',
      changePasswordLabel: 'Изменить пароль',
    },
    appInfo: {
      appDescLabel:
        'Korponnect - это приложение для студенческого братства. Обмен опытом между корпорациями, организация мероприятий, а также профессиональный и частный нетворкинг - все в одном приложении',
      contactsLabel: InfoContactsName('RUS'),
      infoLabel: 'Информация',
      versionLabel: 'Версия приложения',
      agbLabel: 'AGB',
      dataProtectionLabel: 'Политика конфиденциальности',
      manualLabel: 'Инструкции',
      appTextLabel: 'Здесь вы можете найти наши',
      appAndLabel: 'и одно',
      appMainTextLabel: `Вы хотите добавить круг или получить другое предложение? Хотите удалить свой профиль? Просто напишите нам короткое письмо на ${support_email}.`,
      deleteFeedback: 'Причина удаления/отзыв 🙂:',
      deleteUserTitle: 'Удалить аккаунт?',
      deleteUserDesc:
        'Здесь вы можете безвозвратно удалить свой аккаунт.\b Кстати: вы также можете просто отключить уведомления. Отредактируйте настройки в разделе Профиль -> Уведомления или напишите нам по электронной почте',
      deleteUserAlert: 'Вы действительно хотите удалить свой аккаунт?',
      webLabel: 'Веб-версия',
    },
    infoMessages: {
      registartionSuccessLabel: 'Спасибо за регистрацию!',
      manualRegSuccessLabel:
        'Спасибо за регистрацию. Вы будете активированы в ближайшее время и проинформированы по электронной почте',
      linkExpiredLabel: 'Срок действия ссылки истек. Запросите смену пароля',
      emailExistLabel: 'Извините, электронная почта недоступна',
    },
    logoutAlert: {
      title: 'Выход из системы',
      text: 'Вы действительно хотите выйти из системы? Необязательно выходить, можно просто закрыть или свернуть приложение',
      подтвердить: 'Да',
      отменить: 'Отмена',
    },
    invalidInputs: {
      passwordChars: `Пароль должен быть не менее ${passwordDigits(
        'RUS',
      )} символов, включая как минимум одну букву, одну цифру и один специальный символ.`,
      fieldRequired: 'Это поле обязательно для заполнения',
      emailsNotMatching: 'Пожалуйста, убедитесь, что ваши адреса электронной почты совпадают',
      emailInvalid: 'Ваш адрес электронной почты не верен!',
      selectCorrectAnswer: 'You need to select correct answer',
      corp: 'Пожалуйста, выберите хотя бы 1 пункт',
    },
    mentoring: {
      profile: {
        rules1: 'Я соглашаюсь с',
        rules2: ' пониманием ценностей ',
        rules3: '“Наставничество для стипендиатов”.',
        menteeDescription:
          'Пожалуйста, выберите регион наставничества, чтобы вы могли получать информацию о событиях на месте. ПРИМЕЧАНИЕ: Вы можете изменить свой выбор в любое время. Это НЕ ограничивает ваш поиск наставника',
        mentorDescription:
          'Пожалуйста, заполните профиль наставника, чтобы стипендиаты могли найти вас в качестве наставника. ПРИМЕЧАНИЕ: Необходимо указать хотя бы 1 регион и хотя бы 1 предметную область',
        mentoringRegions: 'Регионы наставничества',
        reigonsShort: 'регионы',
        topics: 'предметные области',
        departments: 'факультет',
        available: 'Я доступен',
        unavailable: 'Я сейчас недоступен',
        deleteAccount: 'Удалить профиль мента и покинуть группу',
        deleteAccountDescr: 'Удалить профиль наставника и покинуть чат группы наставников?',
        uploadPdf: 'Профиль наставника (PDF)',
        myPdf: 'Мой профиль (PDF)',
        pdf: 'Профиль наставника',
      },
      tabNames: {
        info: 'Информация',
        news: 'Новости',
        profile: 'Профиль',
        search: 'поиск и запрос',
        request: 'Наставничество',
        requestDetails: 'Запрос на наставничество',
        tabDeactivated: 'Вкладка деактивирована. Пожалуйста, заполните профиль наставника',
      },
      search: {
        description:
          'Чтобы начать целенаправленный поиск, мы рекомендуем вам начать с небольшого количества полей выбора, а затем в следующем поисковом запросе расширить диапазон поиска. Если вы не находите подходящего наставника в вашем регионе, попробуйте расширить поиск на соседний регион наставничества. Критерии поиска: 1 регион, 1 до 3 областей тематики. Все поля связаны логическим оператором И!',
        searchBtn: 'Поиск',
      },
      requests: {
        mentorIncoming: 'Мои запросы на наставничество',
        mentorCurrent: 'Мои наставнические отношения',
        menteeOutgoing: 'Мои запросы на стажировку',
        menteeCurrent: 'Моя стажировка',
        cancelMentoring: 'Завершить стажировку',
        sendRequest: 'Запросить наставника',
        alreadyHaveMentor: 'Вы можете иметь только одного наставника',
        requestSuccess: 'Запрос успешно отправлен',
        requestRetracted: 'Запрос успешно отозван',
        cancelSendingRequest: 'Отменить отправку запроса на наставничество',
        approveRequest: 'Принять запрос',
        rejectRequest: 'Отклонить запрос',
        invitaionText: 'Запрос',
        rejectionText: 'Укажите причину отклонения',
        sureRetractRequest: 'Вы уверены, что хотите отозвать запрос?',
      },
    },
    downloads: {
      root: 'Главная папка',
      delete: 'Удалить',
      rename: 'Переименовать',
      areYouSureDeleteFolder: 'Действительно удалить папку? Все содержимое будет удалено!',
      renameFolder: 'Переименовать папку',
      createNewFolder: 'Создать новую папку',
      newFolderPlaceholder: 'Введите название папки',
      create: 'Создать',
      areYouSureDeleteFile: 'Вы уверены, что хотите удалить файл?',
      downloadFile: 'Скачать',
      deleteFile: 'Удалить с устройства',
      openInFinder: 'Открыть в файловом менеджере',
      cancel: 'Отмена',
    },
    globalSearch: {
      postedBy: 'опубликовал',
      found: 'Найдено',
      loadMore: 'Загрузить еще',
      enterQuery:
        'Введите любое ключевое слово для поиска. Вы можете искать по всему (имя, документ, событие, сообщение и т. д.)',
      folder: 'Папка',
      post: 'Пост',
      error: 'Введите минимум 3 символа',
    },
    kreiseSettings: {
      description: 'Описание',
      push: 'Уведомления push',
      email: 'Уведомления по электронной почте',
      subscribers: 'Подписчики',
      active: 'Активировано',
      oneYear: 'Без звука на 1 год',
      forever: 'Без звука навсегда',
      until: 'До',
      cantDeactive: 'Для этого группового чата эта настройка отключена',
      SINGLE: 'Для каждого сообщения',
      SUMMARY_ONCE: 'Резюме 1 раз в неделю',
      SUMMARY_TWICE: 'Резюме 2 раза в неделю',
      SUMMARY_DAY: 'Резюме 1 раз в день',
      OFF: 'Отключено',
      PUSH: 'Уведомление push',
      EMAIL: 'Уведомление по электронной почте',
      PUSH_AND_EMAIL: 'Уведомление push и по электронной почте',
      newUser: 'Уведомление для новых пользователей',
      statistic: 'Отправить статистику',
      off: 'Отключено',
      ownPosts: 'Отправить статистическое письмо для ваших сообщений',
      deleteMemberDescr: 'удалить?',
      deleteMemberBtn: 'Удалить',
      retractAdminRights: 'Отозвать права администратора',
      deleteAdminAndUser: 'Полностью удалить',
      toDeleteKreiseBtn: 'Удалить групповой чат',
      deleteKreiseDescr: `${KreiseName('RUS').single} unwiderruflich löschen?`,
      deleteKreiseModalTitle: 'Удалить участника?',
      addCirclesName: 'Имя',
      addKreiseDescription: 'Описание',
      addMembers: 'Добавить участников',
      createCircles: 'Создать групповой чат',
      toKreiseEditMessage: 'Примечание: Все изменения членов группы выполняются немедленно',
      kreiseCreator: 'Создатель:в этом групповом чате',
      kreiseDeleteMembersLeft: 'Групповой чат может быть удален, только если в нем остался только один участник',
      kreiseDeleteMembersLeftTitle: 'Сначала удалите участников',
      suggestionInNewsfeed: {
        admin: 'Admin',
        member: 'Mitglieder',
        corp: `${CorpsName('RUS').single} члены`,
        corpAdmin: `${CorpsName('RUS').single} Администратор`,
      },
    },
    surveys: {
      checkVotes: 'Показать результаты',
      chooseOptions: {
        single: 'Выберите вариант.',
        multiple: 'Выберите хотя бы один вариант.',
      },
      votesModalTitle: 'Результаты',
      createModalTitle: 'Исследование',
      noVotes: 'Голоса не подавались',
      question: 'Вопрос',
      askAQuestion: 'Задать вопрос',
      allowMultiple: 'Разрешите несколько ответов',
      votes: {
        single: 'Голос',
        multiple: 'Голос',
      },
      option: 'Вариант',
      optionsLabel: 'Опции',
      anonymus: 'Anonymus poll',
      create: 'Создать',
    },
  },
];

export default lang;
