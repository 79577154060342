import React, { useState, useEffect } from 'react';
import Linkify from 'react-linkify';
import { ButtonBase, makeStyles } from '@material-ui/core';

import { useAppDispatch } from 'store';
import { clearCommentsLikes, likeCommentRequest, unlikeCommentRequest } from 'store/actions/likes';
import { imagePath, timeAgo } from 'utilities';
import defaultImage from 'assets/default_avatar.png';
import DefaultModal from 'components/Shared/DefaultModal';
import Button from 'components/UI/Button';
import linkDecorator from 'components/UI/linkDecorator';
import { ReactComponent as DeleteIcon } from 'assets/delete_button_blue.svg';

import CommentsLikeButton from './CommentsLikeButton';
import CommentsLikesModal from './CommentLikesModal';
import { useLanguage } from 'languages/languageContext';
import { AttachmentsGallery } from 'components/Shared/AttachmentsGallery';
import { getConfig } from 'config/config';
import { SurveyPostOld } from 'scenes/Post/components/SurveyPost_old';
import { deleteComment } from 'store/reducers/commentsSlice';
import { UserType } from 'store/types/usersTypes';
import SurveyPost from 'scenes/Post/components/SurveyPost';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    width: '100%',
    padding: '20px 0 40px',
    marginBottom: 10,
    borderBottom: '1px solid',
  },
  senderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 60,
    height: 60,
    borderRadius: 30,
    border: '2px solid',
    overflow: 'hidden',
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
  },
  heading: {
    marginLeft: 20,
    marginBottom: 5,
    cursor: 'pointer',
  },
  name: {
    display: 'flex',
    fontFamily: 'Poppins',
    fontWeight: 600,
    marginRight: 5,
  },
  prefix: {
    display: 'block',
    fontFamily: 'Poppins',
    fontWeight: 600,
  },
  commentWrapper: {
    width: '100%',
  },
  comment: {
    fontFamily: 'Roboto',
    fontSize: 15,
    margin: 0,
    padding: '30px 10px 20px',
    wordBreak: 'break-word',
    maxWidth: 500,
    whiteSpace: 'pre-wrap',
  },
  kreise: {
    fontFamily: 'Poppins',
    marginLeft: 5,
  },
  date: {
    display: 'block',
    fontFamily: 'Roboto',
    fontSize: 14,
  },

  likeButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  leftButton: {
    boxSizing: 'border-box',
    width: 50,
    marginRight: 2,
    borderRadius: '50px 0 0 50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rightButton: {
    padding: '12px 0',
    width: 50,
    borderRadius: '0 50px 50px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  commentsButton: {
    padding: '13px 30px',
    borderRadius: 50,
  },
  commentsLabel: {
    fontFamily: 'Roboto',
    fontSize: 15,
  },
  likeLabel: {
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: '21px',
    display: 'block',
  },
  deleteButton: {
    position: 'absolute',
    top: 25,
    right: 0,
    width: 25,
    height: 25,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@media(max-width: 650px)': {
    name: {
      lineHeight: '16px',
    },
    comment: {
      maxWidth: 320,
    },
  },
});

interface Props {
  commenter: any;
  date: string;
  toProfileHandler: (user: UserType) => void;
  content: string;
  likedUsers: Array<string>;
  comment: any;
}
const { theme, config } = getConfig();

const Comment: React.FC<Props> = ({ commenter, date, content, toProfileHandler, comment }) => {
  const classes = useStyles({});
  const { btn, post } = useLanguage();
  const [showModal, setShowModal] = useState<boolean>(false);
  let image,
    likedPost = false;
  const userId = localStorage.getItem('userId');

  const owner = userId === commenter._id || userId === comment.commenter;
  const commenterName = `${commenter.name} ${commenter.lastname}`;

  const dispatch = useAppDispatch();

  // STATE
  const [showLikes, setShowLikes] = useState(false);
  const [currentLikes, setCurrentLikes] = useState<Array<any> | null>(null);

  // INIT COMPONENT STATE
  useEffect(() => {
    setCurrentLikes(comment.liked_users);
  }, []);

  if (userId && currentLikes) {
    likedPost = currentLikes.includes(userId);
  }

  if (commenter) {
    image = imagePath(null, commenter.photo);
  }

  // LIKES HANDLER
  const likeCommentHandler = () => {
    const userId = localStorage.getItem('userId');

    if (userId && !likedPost) {
      const updatedLikes = [...currentLikes];
      updatedLikes.push(userId);
      setCurrentLikes(updatedLikes);
      dispatch(likeCommentRequest(userId, comment._id));
    } else if (userId && likedPost) {
      const updatedLikes = [...currentLikes];

      const likeIndex = updatedLikes.indexOf(userId);
      const filteredLikes = updatedLikes.filter((like, i) => i !== likeIndex);
      setCurrentLikes(filteredLikes);
      dispatch(unlikeCommentRequest(userId, comment._id));
    }
  };

  // MODAL HANDLER
  const onOpenLikesModal = () => {
    setShowLikes(true);
    document.body.style.overflow = 'hidden';
  };

  const onCloseLikesModal = () => {
    setShowLikes(false);
    document.body.style.overflow = 'unset';
    dispatch(clearCommentsLikes());
  };

  const onDeleteComment = (commentId: string) => {
    dispatch(deleteComment({ commentId }));
    setShowModal(false);
  };

  return (
    <React.Fragment>
      {currentLikes ? (
        <div className={classes.container} style={{ borderBottomColor: theme.BACKGROUND_SECONDARY }}>
          {owner && (
            <ButtonBase
              className={classes.deleteButton}
              style={{ background: theme.BACKGROUND_SECONDARY }}
              onClick={() => setShowModal(true)}
            >
              <DeleteIcon />
            </ButtonBase>
          )}
          <div className={classes.senderWrapper}>
            <div
              className={classes.imageWrapper}
              style={{ borderColor: theme.BACKGROUND_SECONDARY }}
              onClick={() => toProfileHandler(commenter)}
            >
              <img src={image || defaultImage} alt=" " className={classes.image} />
            </div>
            <div className={classes.heading}>
              <span
                onClick={() => toProfileHandler(commenter)}
                className={classes.name}
                style={{ color: theme.TEXT_PRIMARY }}
              >
                {commenterName}
              </span>
              <span className={classes.date} style={{ color: theme.TEXT_PRIMARY }}>
                {timeAgo(date)}
              </span>
            </div>
          </div>

          <Linkify componentDecorator={linkDecorator}>
            <div className={classes.commentWrapper}>
              <p className={classes.comment} style={{ color: theme.TEXT_PRIMARY }}>
                {content}
              </p>
            </div>
          </Linkify>
          {!!comment?.questions?.[0]?.text && (
            <div className="px-6">
              <SurveyPost question={comment.questions[0]} postId={comment._id} showProgress={true} type="comment" />
            </div>
          )}
          {!!comment.survey?.length && (
            <SurveyPostOld
              postId={comment._id}
              content={comment.surveyQuestion}
              survey={comment.survey}
              multiAnswer={comment.multiAnswer}
              post={comment}
              type="comment"
            />
          )}
          <AttachmentsGallery documents={comment.documents} videos={comment.videos} images={comment.images} />
          <CommentsLikeButton
            likedUsers={currentLikes}
            likedPost={likedPost}
            showLikesHandler={onOpenLikesModal}
            likeHandler={likeCommentHandler}
          />
        </div>
      ) : null}
      {showLikes ? (
        <CommentsLikesModal
          onClose={onCloseLikesModal}
          modalTitle={`Likes (${comment.liked_users.length})`}
          comment={comment}
        />
      ) : null}
      <DefaultModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        modalTitle={config.APP_FULL_NAME}
        message={post.deleteComment}
      >
        <Button label={btn.cancelBtn} background={theme.BUTTON_SECONDARY} onClick={() => setShowModal(false)} />
        <Button label="OK" onClick={() => onDeleteComment(comment._id)} />
      </DefaultModal>
    </React.Fragment>
  );
};

export default Comment;
